import React from "react";
import auth_config from "./../auth_config.json";
import DataGrid, { Column, Editing, Paging, Pager, Lookup, Popup, Position, SearchPanel, Sorting, Export } from "devextreme-react/data-grid";
import { renderTitleHeader } from "./home-rankings-utils";

import { pager_lengths } from "./../utils/enums";
import { isString, utc2db } from "./../utils/common-funcs";
import { put_game_counts_for_sport, get_season_conferences } from "./../services/privateService";
import notify from "devextreme/ui/notify";
import { toast } from "react-toastify";
import { RangeRule } from "devextreme-react/validator";

class GameCounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = { season_conferences: new Set() };

  }

  async componentDidMount() {
    console.log("game-counts mount");
    try {
      if (this.props.selectedDivision.id && this.props.selectedSport.id && this.props.selectedSeason.id && this.props.selectedTeam.id) {
        const season_conferences = await this.get_season_conferences_set();
        this.setState({ season_conferences: season_conferences });
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidUpdate(prevProps, prevState, snapshot) {
    try {
      if (
        (this.props.user && !prevProps.user) ||
        (!this.props.user && prevProps.user) ||
        this.props.user.hq_current_user.userId !== prevProps.user.hq_current_user.userId ||
        this.props.isAuthenticated !== prevProps.isAuthenticated ||
        this.props.selectedSport.id !== prevProps.selectedSport.id ||
        this.props.selectedDivision.id !== prevProps.selectedDivision.id ||
        this.props.selectedSeason.id !== prevProps.selectedSeason.id ||
        this.props.selectedTeam.id !== prevProps.selectedTeam.id
      ) {
        notify(`Refreshing table`);
        if (this.props.selectedDivision.id && this.props.selectedSport.id && this.props.selectedSeason.id && this.props.selectedTeam.id) {
          const season_conferences = await this.get_season_conferences_set();
          this.setState({ season_conferences: season_conferences });
        } else {
          this.setState({ season_conferences: new Set() });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  get_season_conferences_set = async () => {
    // const { data: season_conferences } = await get_season_conferences(
    //   this.props.auth0jwt,
    //   this.props.selectedDivision.id,
    //   this.props.selectedSport.id,
    //   this.props.selectedSeason.id
    // );
    // console.log(season_conferences);

    const unique_conference_ids = new Set();
    for (const [team_id, team] of this.props.team_id_to_team_map) {
      if (team?.conferenceId > 0) {
        unique_conference_ids.add(team.conferenceId);
      }
    }

    if (unique_conference_ids.size < 1) {
      toast.error("Please ask a Faktor admin to seed resume-rankings for the selected season/sport", {
        autoClose: false,
      });
    }

    return unique_conference_ids;
  };

  onInitNewRow = async (e) => {
    const conferenceId = this.props.team_id_to_team_map.get(this.props.selectedTeam.id)?.conferenceId || 0;
    if (!conferenceId) {
      if (this.state.season_conferences.size < 1) {
        toast.error("Please ask a Faktor admin to seed resume-rankings for the selected season/sport", {
          autoClose: false,
        });
      } else {
        toast.error("could not get conference id");
      }

      return;
    }


    e.promise = this.checkTokenLocal();

    e.data.divisionId = this.props.selectedDivision.id;
    e.data.displaySportId = this.props.selectedSport.id;
    e.data.seasonId = this.props.selectedSeason.id;
    e.data.teamId = this.props.selectedTeam.id;
    e.data.conferenceId = conferenceId;
    e.data.sportMax = 0;
    e.data.confMax = 0;
    e.data.mteMax = 0;
    e.data.userId = this.props.user.hq_current_user.userId;
    e.data.userName = this.props.user.email;
    e.data.lastUpdate = new Date().toISOString();
    e.data.countType = 1;
  };

  onRowInserting = async (e) => {
    e.cancel = this.checkTokenLocal();
  }

  onRowInserted = async (e) => {
    console.log("inserted row");
    //do not allow insert with existing conference-ids

    const conferences_with_game_counts = new Set(this.props.table.map((x) => x.conferenceId));

    if (conferences_with_game_counts.has(e.data.conferenceId)) {
      toast.error("conference has game counts. use edit instead.");
    } else if (!this.state.season_conferences.has(e.data.conferenceId)) {
      toast.error("selected conference does not belong to selected division/season/sport");
    } else {
      await this.update_or_insert_game_count_row_to_db(e);
    }
    await this.props.refresh_data_sources();
  };

  
  onRowUpdating = async (e) => {
    e.cancel = this.checkTokenLocal();
  };

  checkTokenLocal = async (e) => {
    return await this.props.checkToken("Game Counts");
  };

  onRowUpdated = async (e) => {
    console.log("updated row");
    //this.handle_session_storage();
    await this.update_or_insert_game_count_row_to_db(e);
    await this.props.refresh_data_sources();
  };

  update_or_insert_game_count_row_to_db = async (e) => {
    try {
      if (
        e.data.divisionId &&
        e.data.displaySportId &&
        e.data.seasonId &&
        e.data.conferenceId &&
        e.data.sportMax > 0 &&
        e.data.confMax >= 0 &&
        e.data.mteMax >= 0 &&
        e.data.userId &&
        e.data.lastUpdate &&
        e.data.countType
      ) {
        let deep_copy = JSON.parse(JSON.stringify(e.data));

        deep_copy.userId = this.props.user.hq_current_user.userId;
        deep_copy.sub = this.props.user.sub;
        deep_copy.providerId = this.props.user.sub;
        deep_copy.userName = this.props.user.email;
        deep_copy.lastUpdate = new Date().toISOString();
        deep_copy.teamId = this.props.selectedTeam.id;

        if (isString(deep_copy.lastUpdate)) {
          deep_copy.lastUpdate = utc2db(deep_copy.lastUpdate);
        } else if (deep_copy.lastUpdate) {
          deep_copy.lastUpdate = utc2db(deep_copy.lastUpdate.toISOString());
        }

        const res = await put_game_counts_for_sport(
          this.props.auth0jwt,
          this.props.selectedDivision.id,
          this.props.selectedSport.id,
          deep_copy.conferenceId,
          this.props.selectedSeason.id,
          deep_copy
        );

        if (res.status === 200) {
          toast.success(`Successfully changed game counts!`);
        } else {
          console.log(res);
          toast.error(`Could not change game counts.`);
        }
      } else {
        console.log(e.data);
        toast.error(`Could not change game counts`);
      }
    } catch (error) {
      toast.error(`Could not change game counts`);
      console.log(error);
    }
  };

  onCalculateSortValueConference(data) {
    const value = this.calculateCellValue(data);
    return this.lookup.calculateCellValue(value);
  }

  render() {
    return (
      <div>
        <DataGrid
          width={"100%"}
          columnAutoWidth={true}
          dataSource={this.props.table.filter((x) => x.conferenceId > 0)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnResizingMode={"widget"}
          showBorders={false}
          showColumnLines={false}
          showRowLines={true}
          rowAlternationEnabled={true}
          allowSearch={true}
          onInitNewRow={this.onInitNewRow}
          onRowInserted={this.onRowInserted}
          onRowInserting={this.onRowInserting}
          onRowUpdated={this.onRowUpdated}
          onRowUpdating={this.onRowUpdating}
          //onRowRemoved={this.onRowRemoved}
          onEditorPreparing={this.onEditorPreparing}
          selection={{ mode: "multiple", showCheckBoxesMode: "none" }}
          //selectedRowKeys={this.props.selectedItemKeys}
          //onSelectionChanged={this.selectionChanged}
          //onToolbarPreparing={this.onToolbarPreparing}
        >
          <SearchPanel visible={true} />
          <Sorting mode="multiple" />
          <Export enabled={true} fileName={"KPI-Game-Counts"} />
          <Paging defaultPageSize={100} />
          <Pager showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />

          <Column
            dataField="conferenceId"
            dataType="number"
            allowEditing={this.props.userPermissions.includes(auth_config.writeDbPermission)}
            caption="Conference"
            headerCellRender={renderTitleHeader}
            calculateSortValue={this.onCalculateSortValueConference}
            fixed={true}
            fixedPosition="left"
            defaultSortOrder="asc"
            defaultSortIndex={0}
          >
            <Lookup dataSource={this.props.allConferences} displayExpr="name" valueExpr="id" />
          </Column>

          <Column dataField="sportMax" caption="Sport Max" dataType="number" allowEditing={true} headerCellRender={renderTitleHeader}>
            <RangeRule message="Sport max must be greater than 0" min={1} />
          </Column>
          <Column dataField="confMax" caption="Conf Max" dataType="number" allowEditing={true} headerCellRender={renderTitleHeader}>
            <RangeRule message="Conf max must be greater than 0" min={1} />
          </Column>

          <Column dataField="mteMax" caption="Mte Max" dataType="number" allowEditing={true} headerCellRender={renderTitleHeader}>
            <RangeRule message="Conf max must be positive" min={0} />
          </Column>
          <Column dataField="userName" caption="Entered by" dataType="string" alignment="right" allowEditing={false} headerCellRender={renderTitleHeader}></Column>
          <Column dataField="lastUpdate" caption="Last update" dataType="datetime" alignment="right" allowEditing={false} headerCellRender={renderTitleHeader}></Column>
          <Editing
            mode={this.props.usePopupEditinMode ? "popup" : "row"}
            selectTextOnEditStart={true}
            useIcons={true}
            allowUpdating={true}
            allowDeleting={false}
            allowAdding={true}
          >
            <Popup title="Game Schedule Entry" showTitle={true}>
              <Position my="center" at="center" of={window} />
            </Popup>
          </Editing>
        </DataGrid>
      </div>
    );
  }
}

export default GameCounts;
