import React, { Component, Fragment } from "react";

import Tabs from "devextreme-react/tabs";
import DateBox from "devextreme-react/date-box";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Export,
  SearchPanel,
  FilterRow,
  FilterPanel,
  ColumnFixing,
  Grouping,
  GroupPanel,
  Summary,
  GroupItem,
  Sorting,
  Lookup,
} from "devextreme-react/data-grid";
import { SelectBox } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { Button } from "devextreme-react";
import { CheckBox } from "devextreme-react/check-box";
import { renderTitleHeader } from "./../utils/common-rendering-funcs";
import {
  getMinMaxDateValues,
  getDateSequence,
  isString,
  get_team_id_to_conference_id_map,
  make_game_count_limits_map,
  makeClinetFriendlyComputedSandboxFrom,
} from "./../utils/common-funcs";

import { Row, Col, CardHeader, Card, CardBody } from "reactstrap";
import { toast } from "react-toastify";

import { time_format, pager_lengths, isConferenceEnum, blocked_for_dic, dateTypeId_lookup, dateTypeId_color_dict, computeSandboxFrom } from "./../utils/enums";
import {
  has_resume_rankings,
  get_game_dates_for_teams_in_date_range,
  get_all_unavailable_team_date_ranges,
  get_all_team_metadata_for_unavailability_resolution,
  get_blocked_dates,
  get_team_distances,
  get_all_contacts,
  get_priority_dates_for_sport,
} from "../services/privateService";

import { GameCountService } from "./../services/gameCountService";

import { format, parse } from "fecha";

import { group } from "d3-array";

const tabs = [
  {
    id: 0,
    text: "Find Games",
    title: "Find all teams that do not have any games during the specified search window",
  },
  {
    id: 1,
    text: "Find Opponents",
    title: "Find available dates for selected team during the specified search window",
  },
  {
    id: 2,
    text: "Find Contact",
    title: "Contact list",
  },
];

const searchAtLeastChoices = [
  { id: 0, name: "Available for all dates" },
  { id: 1, name: "Available for at least one date" },
];

const searchInConferenceTypeChoices = [
  { id: -1, name: "All opponents", msg: "" },
  { id: 1, name: "Conference opponents only", msg: "Warning: available days are for conference opponents only" },
  { id: 0, name: "Non-conference  opponents only", msg: "Warning: available days are for non-conference opponents only" },
];

const searchInConferenceTypeChoiceMessageDic = searchInConferenceTypeChoices.reduce(function (result, item) {
  result[item.id] = item.msg;
  return result;
}, {});

const searchGameTypeChoices = [
  { id: -1, name: "All game types", msg: "" },
  { id: isConferenceEnum.Conference, name: "Conference games only", msg: "Warning: available days are for conference games only" },
  { id: isConferenceEnum.NonConference, name: "Non-conference games only", msg: "Warning: available days are for non-conference games only" },
  { id: isConferenceEnum.CTRN, name: "Conf Tournament games only", msg: "Warning: available days are for Conf Tournament games only" },
  { id: isConferenceEnum.MTE, name: "MTE games only", msg: "Warning: available days are for MTE games only" },
  { id: isConferenceEnum.Exhibition, name: "Exhibition games only", msg: "Warning: available days are for Exhibition games only" },
  { id: isConferenceEnum.PostSeason, name: "PostSeason games only", msg: "Warning: available days are for PostSeason games only" },
];

const searchGameTypeChoicesMessageDic = searchGameTypeChoices.reduce(function (result, item) {
  result[item.id] = item.msg;
  return result;
}, {});

function make_distance_key(team1Id, team2Id) {
  return `${team1Id}-${team2Id}`;
}

function make_contact_key(divisionId, displaySportId, team1Id) {
  return `${divisionId}-${displaySportId}-${team1Id}`;
}

class GameSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      minDateValue: undefined,
      maxDateValue: undefined,
      table: [],
      open_dates: [],
      contact_list: [],
      distances: new Map(),
      contact_map: new Map(),
      hasDistances: false,
      selectedIndex: 0,
      searchStart: undefined,
      searchEnd: undefined,
      checkBoxValueAvailableForAtLeastOneDate: searchAtLeastChoices[1].id,
      checkBoxValueInConferenceType: searchInConferenceTypeChoices[0].id,
      checkBoxValueSearchAcrossDivisions: false,
      checkBoxValueGameType: searchGameTypeChoices[0].id,
      metadata_map_by_team: new Map(),
      autoExpandAll: false,
      team_id_to_conference_id_map: new Map(),
      divisions_in_data: [],
      selected_compute_sandbox_from: computeSandboxFrom[1].id,
    };

    this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);
    this.onValueChangedStartDate = this.onValueChangedStartDate.bind(this);
    this.onValueChangedEndDate = this.onValueChangedEndDate.bind(this);
    this.calculateDisplayValueContact = this.calculateDisplayValueContact.bind(this);

    this.onClickRefresh = this.onClickRefresh.bind(this);
    this.onValueChangedAvailableForAtLeastOneDate = this.onValueChangedAvailableForAtLeastOneDate.bind(this);
    this.onAutoExpandAllChanged = this.onAutoExpandAllChanged.bind(this);
    this.onValueChangedInConferenceType = this.onValueChangedInConferenceType.bind(this);
    this.onValueChangedGameType = this.onValueChangedGameType.bind(this);
    this.onValueChangedSearchAcrossDivisions = this.onValueChangedSearchAcrossDivisions.bind(this);
  }

  async componentDidMount() {
    await this.refresh_data_sources();
  }

  refresh_data_sources = async () => {
    try {
      let minDateValue = undefined;
      let maxDateValue = undefined;

      const team_id_to_conference_id_map = get_team_id_to_conference_id_map(this.props.allTeams);

      if (this.props.selectedDivision.id && this.props.selectedSport.id && this.props.selectedSeason.id) {
        [minDateValue, maxDateValue] = getMinMaxDateValues(this.props.selectedSport.name, this.props.selectedSeason.name);
        await this.verify_has_resume_rankings();
        const { data: contact_list } = await get_all_contacts(
          this.props.auth0jwt,
          0, //this.props.selectedDivision.id,
          this.props.selectedSport.id,
          this.props.selectedSeason.id
        );

        const { data: metadata } = await get_all_team_metadata_for_unavailability_resolution(
          this.props.auth0jwt,
          0, //this.props.selectedDivision.id
          this.props.selectedSport.id,
          this.props.selectedSeason.id
        );

        const metadata_map_by_team = group(metadata, (d) => d.teamId);
        
        const contact_map = this.get_contact_map(contact_list);

        let compute_sandbox_from_local = makeClinetFriendlyComputedSandboxFrom(this.props.selectedSport.name, this.props.selectedSeason.name);

        this.setState({
          minDateValue: minDateValue,
          maxDateValue: maxDateValue,
          contact_list: contact_list,
          contact_map: contact_map,
          metadata_map_by_team: metadata_map_by_team,
          team_id_to_conference_id_map: team_id_to_conference_id_map,
          compute_sandbox_from: compute_sandbox_from_local,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  async onClickRefresh(e) {
    notify(`Refreshing table`);
    await this.refresh_data_sources();
  }

  verify_has_resume_rankings = async () => {
    const hasResumeRankings = await has_resume_rankings(this.props.auth0jwt, this.props.selectedDivision.id, this.props.selectedSport.id, this.props.selectedSeason.id);

    if (!hasResumeRankings.data.hasResumeRankings) {
      toast.info("Resume rankings have not been loaded for the selected division/sport/season. Please ask a Faktor admin to upload them", {
        autoClose: false,
      });
    }
  };

  async get_distances_map() {
    const distances_map = new Map();
    if (this.props.selectedTeam.id) {
      const { data: distances_array } = await get_team_distances(this.props.auth0jwt, this.props.selectedTeam.id);

      for (var j = 0; j < distances_array.length; j++) {
        let row = distances_array[j];
        distances_map.set(make_distance_key(row.team1Id, row.team2Id), row.distance);
        distances_map.set(make_distance_key(row.team2Id, row.team1Id), row.distance);
      }
    }

    return distances_map;
  }

  get_contact_map(contact_list) {
    const contact_map = new Map();
    if (this.props.selectedDivision.id && this.props.selectedSport.id && this.props.selectedSeason.id) {
      for (var j = 0; j < contact_list.length; j++) {
        let row = contact_list[j];
        contact_map.set(make_contact_key(row.divisionId, row.displaySportId, row.teamId), `${row.name} (${row.email})`);
      }
    }
    return contact_map;
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.user && !prevProps.user) ||
      (!this.props.user && prevProps.user) ||
      this.props.user.hq_current_user.userId !== prevProps.user.hq_current_user.userId ||
      this.props.isAuthenticated !== prevProps.isAuthenticated ||
      this.props.selectedSport.id !== prevProps.selectedSport.id ||
      this.props.selectedDivision.id !== prevProps.selectedDivision.id ||
      this.props.selectedSeason.id !== prevProps.selectedSeason.id ||
      this.props.selectedTeam.id !== prevProps.selectedTeam.id
    ) {
      const team_id_to_conference_id_map = get_team_id_to_conference_id_map(this.props.allTeams);

      if (this.props.selectedDivision.id && this.props.selectedSport.id && this.props.selectedSeason.id) {
        await this.verify_has_resume_rankings();
        if (this.props.selectedTeam.id && this.props.selectedTeam.id !== prevProps.selectedTeam.id) {
          const distances = await this.get_distances_map();

          const { data: metadata } = await get_all_team_metadata_for_unavailability_resolution(
            this.props.auth0jwt,
            0, //this.props.selectedDivision.id,
            this.props.selectedSport.id,
            this.props.selectedSeason.id
          );

          const metadata_map_by_team = group(metadata, (d) => d.teamId);
          this.setState({
            distances: distances,
            hasDistances: true,
            metadata_map_by_team: metadata_map_by_team,
            team_id_to_conference_id_map: team_id_to_conference_id_map,
          });
        }
      } else {
        this.setState({ table: [], open_dates: [] });
      }
    }
  }

  onValueChangedStartDate(e) {
    const tmp = e.value;

    if (!isString(tmp)) {
      tmp.setHours(new Date().getHours());
    }

    this.setState({
      searchStart: tmp,
      searchEnd: tmp,
    });
  }

  onValueChangedEndDate(e) {
    const tmp = e.value;

    if (!isString(tmp)) {
      tmp.setHours(new Date().getHours());
    }

    this.setState({
      searchEnd: tmp,
    });
  }

  onTabsSelectionChanged(args) {
    if (args.name === "selectedIndex" && this.state.selectedIndex !== args.value) {
      this.setState({
        selectedIndex: args.value,
      });
    }
  }

  onValueChangedAvailableForAtLeastOneDate(args) {
    this.setState({
      checkBoxValueAvailableForAtLeastOneDate: args.value,
    });
  }

  onValueChangedInConferenceType(args) {
    this.setState({
      checkBoxValueInConferenceType: args.value,
    });
  }

  onValueChangedSearchAcrossDivisions(args) {
    this.setState({
      checkBoxValueSearchAcrossDivisions: args.value,
    });
  }

  onValueChangedGameType(args) {
    this.setState({
      checkBoxValueGameType: args.value,
    });
  }

  on_click_search_get_available_teams_with_dates = async (window_dates_array, open_bounds_array, searchStart, searchEnd) => {
    if (!Array.isArray(window_dates_array) || window_dates_array.length < 1) {
      return null;
    }

    if (!this.props.selectedTeam.id) {
      toast.error("Please select a team.");
      return null;
    }

    let { data: blocked_dates } = await get_all_unavailable_team_date_ranges(
      this.props.auth0jwt,
      this.props.selectedDivision.id,
      this.props.selectedSport.id,
      this.props.selectedSeason.id,
      open_bounds_array,
      -1
    );

    const division_id_filter = this.state.checkBoxValueSearchAcrossDivisions ? 0 : this.props.selectedDivision.id;

    let { data: all_priority_dates_table } = await get_priority_dates_for_sport(
      this.props.auth0jwt,
      this.props.selectedSeason.id,
      0, //this.props.selectedTeam.id no team filter
      this.props.selectedSport.id,
      division_id_filter
    );

    let division_ids_in_data = new Set();
    const team1_conf = this.state.team_id_to_conference_id_map.get(this.props.selectedTeam.id) || 0;

    let search_start_yyyy_mm_dd = format(searchStart, time_format.utc).split("T")[0];
    let search_end_yyyy_mm_dd = format(searchEnd, time_format.utc).split("T")[0];

    let distances = null;
    let set_distances = false;
    if (!this.state.hasDistances) {
      distances = await this.get_distances_map();
      set_distances = true;
    } else {
      distances = this.state.distances;
    }

    let priority_dates_to_add_by_team = [];
    if (Array.isArray(all_priority_dates_table)) {
      for (var jj = 0; jj < all_priority_dates_table.length; jj++) {
        const { teamId, startDate, endDate, blockedForId, dateTypeId } = all_priority_dates_table[jj];
        const row_seq = getDateSequence(startDate, endDate, time_format.utc);

        for (var jd = 0; jd < row_seq.length; jd++) {
          const x = row_seq[jd];
          if ((x >= search_start_yyyy_mm_dd && x <= search_end_yyyy_mm_dd) || x < search_start_yyyy_mm_dd || x > search_end_yyyy_mm_dd) {
            priority_dates_to_add_by_team.push({ teamId: teamId, date: x, blockedForId: blockedForId, isConference: 0, dateTypeId: dateTypeId });
          }
        }
      }
    }

    let dates_to_remove_by_team = [];
    if (Array.isArray(blocked_dates)) {
      for (var jj = 0; jj < blocked_dates.length; jj++) {
        const { teamId, startDate, endDate, blockedForId, isConference } = blocked_dates[jj];
        const row_seq = getDateSequence(startDate, endDate, time_format.utc);

        for (var jd = 0; jd < row_seq.length; jd++) {
          const x = row_seq[jd];
          if ((x >= search_start_yyyy_mm_dd && x <= search_end_yyyy_mm_dd) || x < search_start_yyyy_mm_dd || x > search_end_yyyy_mm_dd) {
            dates_to_remove_by_team.push({ teamId: teamId, date: x, blockedForId: blockedForId, isConference: isConference });
          }
        }
      }
    }

    var blocked_dates_by_team = group(dates_to_remove_by_team, (d) => d.teamId);
    var priority_dates_by_team = group(priority_dates_to_add_by_team, (d) => d.teamId);

    var newTable = [];

    for (let [teamId, teamMetadata] of this.state.metadata_map_by_team) {
      
      const teamMetadataValue = teamMetadata[0];
      if (!this.state.checkBoxValueSearchAcrossDivisions && teamMetadataValue.divisionId !== this.props.selectedDivision.id) {
        continue;
      }
      division_ids_in_data.add(teamMetadataValue.divisionId);

      const team2_conf = this.state.team_id_to_conference_id_map.get(teamId) || 0;
      const conf_opponent = team2_conf === team1_conf;
      if (this.state.checkBoxValueInConferenceType === 0 && conf_opponent) {
        continue;
      } else if (this.state.checkBoxValueInConferenceType === 1 && !conf_opponent) {
        continue;
      }

      if (this.state.checkBoxValueGameType === isConferenceEnum.Conference) {
      }

      const distance = distances.get(make_distance_key(teamId, this.props.selectedTeam.id));
      const distance_value = distance ? Math.round(distance) : undefined;

      if (priority_dates_by_team.has(teamId)) {
        const staging_table = [];

        const team_priority_dates = priority_dates_by_team.get(teamId);

        var team_priority_dates_map = group(team_priority_dates, (x) => x.date);

        for (var jj = 0; jj < window_dates_array.length; jj++) {
          const date = window_dates_array[jj];
          const setup = team_priority_dates_map.get(date) || null;
          if (!setup) {
            continue;
          }

          for (let setup_index = 0; setup_index < setup.length; setup_index++) {
            const setup_j = setup[setup_index];
            const blockedForId = setup_j.blockedForId || null;
            const isConference = setup_j.isConference === 1 || null;
            staging_table.push({
              ...teamMetadata[0],
              date: date,
              distance: distance_value,
              availability: blocked_for_dic[blockedForId],
              isConference: isConference,
              conf_opponent: conf_opponent,
              dateTypeId: setup_j.dateTypeId,
            });
          }
        }

        if (this.state.checkBoxValueAvailableForAtLeastOneDate) {
          newTable = newTable.concat(staging_table);
        } else {
          var lookup = new Set(staging_table.map((x) => x.date));

          let has_all_dates_in_search_window = true;
          for (let i = 0; i < window_dates_array.length; i++) {
            const date = window_dates_array[i];

            if (!lookup.has(date)) {
              has_all_dates_in_search_window = false;

              break;
            }
          }
          if (has_all_dates_in_search_window) {
            newTable = newTable.concat(staging_table);
          }
        }
      }

      if (blocked_dates_by_team.has(teamId)) {
        const staging_table = [];

        const team_blocked_dates = blocked_dates_by_team.get(teamId);

        var team_blocked_dates_map = group(team_blocked_dates, (x) => x.date);

        for (var jj = 0; jj < window_dates_array.length; jj++) {
          const date = window_dates_array[jj];
          if (!team_blocked_dates_map.has(date)) {
            staging_table.push({ ...teamMetadata[0], date: date, distance: distance_value, availability: "Home/Away", dateTypeId: 2 }); //open because not blocked
          } else {
            const setup = team_blocked_dates_map.get(date) || [];

            const blockedForId = setup[0].blockedForId || null;
            const isConference = setup[0].isConference === 1 || null;
            if (blockedForId === 1) {
              //blocked for 1: "Away",
              staging_table.push({
                ...teamMetadata[0],
                date: date,
                distance: distance_value,
                availability: "Home",
                isConference: isConference,
                conf_opponent: conf_opponent,
                dateTypeId: 2, //open because flip side of blocked date
              });
            } else if (blockedForId === 2) {
              //blocked for 2: "Home",
              staging_table.push({
                ...teamMetadata[0],
                date: date,
                distance: distance_value,
                availability: "Away",
                isConference: isConference,
                conf_opponent: conf_opponent,
                dateTypeId: 2, //open because flip side of blocked date
              });
            }
          }
        }

        if (this.state.checkBoxValueAvailableForAtLeastOneDate) {
          newTable = newTable.concat(staging_table);
        } else {
          var lookup = new Set(staging_table.map((x) => x.date));

          let has_all_dates_in_search_window = true;
          for (let i = 0; i < window_dates_array.length; i++) {
            const date = window_dates_array[i];

            if (!lookup.has(date)) {
              has_all_dates_in_search_window = false;

              break;
            }
          }
          if (has_all_dates_in_search_window) {
            newTable = newTable.concat(staging_table);
          }
        }
      } else {
        window_dates_array.forEach((date) => {
          newTable.push({
            ...teamMetadata[0],
            date: date,
            distance: distance_value,
            availability: "Home/Away",
            isConference: -1,
            conf_opponent: conf_opponent,
            dateTypeId: 2, //open because no data
          });
        });
      }
    }

    let divisions_in_data = this.props.allDivisions.filter((x) => division_ids_in_data.has(x.id));

    if (set_distances) {
      return { table: newTable, distances: distances, hasDistances: true, divisions_in_data: divisions_in_data };
    } else {
      return { table: newTable, divisions_in_data: divisions_in_data };
    }
  };

  on_click_search_get_available_dates_for_team = async (window_dates_array, open_bounds_array, searchStart, searchEnd) => {
    if (!Array.isArray(window_dates_array) || window_dates_array.length < 1) {
      return null;
    }

    if (!this.props.selectedTeam.id) {
      toast.error("Please select a team.");
      return null;
    }

    const { data: game_dates_array } = await get_game_dates_for_teams_in_date_range(
      this.props.auth0jwt,
      this.props.selectedDivision.id,
      this.props.selectedSport.id,
      this.props.selectedSeason.id,
      this.props.selectedTeam.id,
      open_bounds_array
    );

    const { data: blocked_dates } = await get_blocked_dates(this.props.auth0jwt, this.props.selectedSeason.id, this.props.selectedTeam.id, this.props.selectedSport.id, 0);

    let search_start_yyyy_mm_dd = format(searchStart, time_format.utc).split("T")[0];
    let search_end_yyyy_mm_dd = format(searchEnd, time_format.utc).split("T")[0];

    let dates_to_remove = new Set();
    if (Array.isArray(game_dates_array)) {
      for (var ii = 0; ii < game_dates_array.length; ii++) {
        const x = format(parse(game_dates_array[ii].date, time_format.utc), "YYYY-MM-DD");
        if (x >= search_start_yyyy_mm_dd && x <= search_end_yyyy_mm_dd) {
          dates_to_remove.add(x);
        }
      }
    }

    if (Array.isArray(blocked_dates)) {
      for (var i = 0; i < blocked_dates.length; i++) {
        const { startDate, endDate } = blocked_dates[i];
        const row_seq = getDateSequence(startDate, endDate, time_format.utc);
        for (var jd = 0; jd < row_seq.length; jd++) {
          const x = row_seq[jd];
          if (x >= search_start_yyyy_mm_dd && x <= search_end_yyyy_mm_dd) {
            dates_to_remove.add(x);
          }
        }
      }
    }

    const open_dates = [];
    window_dates_array.forEach((x) => {
      if (!dates_to_remove.has(x)) {
        open_dates.push({ date: x });
      }
    });

    return { open_dates: open_dates };
  };

  onClickSearch = async () => {
    if (this.state.searchStart && this.state.searchEnd && this.props.selectedDivision.id && this.props.selectedSport.id && this.props.selectedSeason.id) {
      if (this.state.searchStart > this.state.searchEnd) {
        toast.error("End date must be after start date.");
      } else {
        notify(`Refreshing table`);

        const game_count_service = new GameCountService(this.props.auth0jwt, this.props.selectedDivision.id, this.props.selectedSport.id, 0, this.props.selectedSeason.id);
        const team_id_to_game_counts = await game_count_service.Run(this.state.team_id_to_conference_id_map);

        const window_dates_array = getDateSequence(this.state.searchStart, this.state.searchEnd, time_format.utc);

        let date_before_start = isString(this.state.searchStart) ? parse(this.state.searchStart, time_format.utc) : new Date(this.state.searchStart);
        let date_after_end = isString(this.state.searchEnd) ? parse(this.state.searchEnd, time_format.utc) : new Date(this.state.searchEnd);
        date_before_start.setDate(date_before_start.getDate() - 1);
        date_after_end.setDate(date_after_end.getDate() + 1);
        date_before_start = format(date_before_start, time_format.utc).split("T")[0];
        date_after_end = format(date_after_end, time_format.utc).split("T")[0];

        if (this.state.selectedIndex === 0) {
          const new_state = await this.on_click_search_get_available_teams_with_dates(
            window_dates_array,
            [date_before_start, date_after_end],
            this.state.searchStart,
            this.state.searchEnd
          );

          if (new_state) {
            if (Array.isArray(new_state.table)) {
              for (let index = 0; index < new_state.table.length; index++) {
                const element = new_state.table[index];
                const game_count_meta = team_id_to_game_counts.get(element.teamId);

                const remainingGameCount = game_count_meta ? game_count_meta.remainingGameCount : 1;

                element["remainingGameCount"] = remainingGameCount;
              }
            }

            this.setState(new_state);
          }
        } else if (this.state.selectedIndex === 1) {
          const new_state = await this.on_click_search_get_available_dates_for_team(
            window_dates_array,
            [date_before_start, date_after_end],
            this.state.searchStart,
            this.state.searchEnd
          );

          if (new_state) {
            this.setState(new_state);
          }
        }
      }
    } else {
      toast.error("Please enter start and end dates for search");
    }
  };

  calculateDisplayValueContact(e) {
    if (e.teamId) {
      const contact_key = make_contact_key(this.props.selectedDivision.id, this.props.selectedSport.id, e.teamId);
      return this.state.contact_map.get(contact_key) || "";
    } else {
      return "";
    }
  }

  onAutoExpandAllChanged() {
    this.setState({
      autoExpandAll: !this.state.autoExpandAll,
    });
  }

  customizeTextGroupItemSummary = (cellInfo) => {
    // return String(cellInfo.value).split(".").length > 1 ? cellInfo.value.toFixed(2) : cellInfo.value;
    return cellInfo.value ? cellInfo.value.toFixed(1) : cellInfo.value;
  };

  onCalculateSortValueTeam(data) {
    const value = this.calculateCellValue(data);
    return this.lookup.calculateCellValue(value);
  }

  onCalculateSortValueConference(data) {
    const value = this.calculateCellValue(data);
    return this.lookup.calculateCellValue(value);
  }

  onRowPrepared = async (e) => {
    if (e.rowType === "data") {
      if (e.data.dateTypeId === 1) {
        e.rowElement.style.backgroundColor = dateTypeId_color_dict[1];
        e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
      } else if (e.data.dateTypeId === 2) {
        e.rowElement.style.backgroundColor = dateTypeId_color_dict[2];
        e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
      }
    }
  };

  handleComputeSandboxFromTagBoxOnValueChanged = async (event) => {
    const foreceRefresh = [...this.state.table];
    this.setState({ selected_compute_sandbox_from: event.value, table: foreceRefresh });
  };

  vtpCellRenderForIndex = (obj) => {
    const cellData = obj.data;
    const kpIndex = obj.target;
    const key = `${kpIndex}${this.state.selected_compute_sandbox_from}`;
    const output = cellData[key] || "";
    //console.log(`${key} -- ${vtpObject}`)
    // const output = vtpObject[key] || 0;
    //return <div>{output}</div>;
    return output;
  };

  render() {
    return (
      <Fragment>
        <Card className="main-card my-2 mx-2 border border-primary rounded">
          <CardHeader className="card-header-tab">
            <Tabs dataSource={tabs} selectedIndex={this.state.selectedIndex} onOptionChanged={this.onTabsSelectionChanged} />
            <div className="mt-3">
              {this.state.selectedIndex === 0 && (
                <div style={{ float: "left" }} className="card-header-title thick">
                  {tabs[this.state.selectedIndex].title}
                </div>
              )}
              {this.state.selectedIndex === 1 && this.props.selectedTeam.name && (
                <div style={{ float: "left" }} className="card-header-title thick">
                  Find available dates for [{this.props.selectedTeam.name}] during the specified search window
                </div>
              )}
              {this.state.selectedIndex === 1 && !this.props.selectedTeam.name && (
                <div style={{ float: "left" }} className="card-header-title thick">
                  Select a team from drop down above{" "}
                </div>
              )}
              {this.state.selectedIndex === 2 && (
                <div style={{ float: "left" }} className="card-header-title thick">
                  {tabs[this.state.selectedIndex].title}
                </div>
              )}
              <div style={{ float: "right" }}>
                <Button icon="refresh" hint="Refesh Table" type="back" stylingMode="contained" onClick={this.onClickRefresh} />
              </div>
            </div>
          </CardHeader>

          <CardBody className="pt-1">
            {this.state.selectedIndex < 2 && (
              <div>
                <small>Search Window</small>

                <Row className="mb-2">
                  <Col xs={6} md={4}>
                    <DateBox placeholder="Start date" type="date" min={this.state.minDateValue} max={this.state.maxDateValue} onValueChanged={this.onValueChangedStartDate} />
                  </Col>
                  <Col xs={6} md={4}>
                    <DateBox
                      placeholder="End date"
                      value={this.state.searchEnd}
                      type="date"
                      min={this.state.minDateValue}
                      max={this.state.maxDateValue}
                      onValueChanged={this.onValueChangedEndDate}
                    />
                  </Col>
                  <Col xs={6} md={4}>
                    <SelectBox
                      width={"100%"}
                      placeholder={"Compute rankings from ..."}
                      items={this.state.compute_sandbox_from}
                      displayExpr="name"
                      valueExpr="id"
                      value={this.state.selected_compute_sandbox_from}
                      onValueChanged={this.handleComputeSandboxFromTagBoxOnValueChanged}
                    />
                  </Col>
                </Row>
                {this.state.selectedIndex === 0 && (
                  <Fragment>
                    <Row>
                      <Col xs={12}>
                        <small>Search Options</small>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs={4}>
                        <SelectBox
                          placeholder={"Availability type ..."}
                          items={searchAtLeastChoices}
                          displayExpr="name"
                          valueExpr="id"
                          value={this.state.checkBoxValueAvailableForAtLeastOneDate}
                          onValueChanged={this.onValueChangedAvailableForAtLeastOneDate}
                        />
                      </Col>
                      <Col xs={4}>
                        <SelectBox
                          placeholder={"Conference type ..."}
                          items={searchInConferenceTypeChoices}
                          displayExpr="name"
                          valueExpr="id"
                          value={this.state.checkBoxValueInConferenceType}
                          onValueChanged={this.onValueChangedInConferenceType}
                        />
                      </Col>
                      <Col>
                        <CheckBox
                          value={this.state.checkBoxValueSearchAcrossDivisions}
                          onValueChanged={this.onValueChangedSearchAcrossDivisions}
                          width={120}
                          text="Search across divisions"
                        />
                      </Col>
                      {/* <Col xs={4}>
                        <SelectBox
                          placeholder={"Game type ..."}
                          items={searchGameTypeChoices}
                          displayExpr="name"
                          valueExpr="id"
                          value={this.state.checkBoxValueGameType}
                          onValueChanged={this.onValueChangedGameType}
                        />
                      </Col> */}
                    </Row>
                  </Fragment>
                )}
                <Row className="mt-3 mb-1">
                  <Col xs={3}>
                    <Button style={{ fontSize: "14px", fontWeight: "bold" }} icon="search" type="default" width="100%" height="30px" onClick={this.onClickSearch}>
                      Fetch
                    </Button>
                  </Col>
                </Row>
                {this.state.checkBoxValueInConferenceType !== -1 && (
                  <Row>
                    <Col className="ml-2" xs={12}>
                      <div style={{ color: "red" }}>{searchInConferenceTypeChoiceMessageDic[this.state.checkBoxValueInConferenceType]}</div>
                    </Col>
                  </Row>
                )}
                {this.state.checkBoxValueGameType !== -1 && (
                  <Row>
                    <Col className="ml-2" xs={12}>
                      <div style={{ color: "red" }}>{searchGameTypeChoicesMessageDic[this.state.checkBoxValueGameType]}</div>
                    </Col>
                  </Row>
                )}
              </div>
            )}

            <Row className="mt-1">
              <Col xs="12">
                {this.state.selectedIndex === 0 && (
                  <Fragment>
                    <Row>
                      <DataGrid
                        width={"100%"}
                        columnAutoWidth={true}
                        dataSource={this.state.table}
                        allowColumnReordering={true}
                        showBorders={false}
                        showColumnLines={false}
                        showRowLines={true}
                        rowAlternationEnabled={false}
                        allowSearch={true}
                        selection={{ mode: "multiple", showCheckBoxesMode: "none" }}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                        onRowPrepared={this.onRowPrepared}
                      >
                        <SearchPanel visible={true} />
                        <Sorting mode="multiple" />
                        <Export
                          enabled={true}
                          fileName={`KPI-Game-Search-Teams-${this.state.searchStart ? this.state.searchStart.toLocaleDateString().replace("/", "_") : ""}-${
                            this.state.searchEnd ? this.state.searchEnd.toLocaleDateString().replace("/", "_") : ""
                          }`}
                        />
                        <Paging defaultPageSize={100} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />
                        <FilterRow visible={true} />
                        <FilterPanel visible={true} />
                        {/* <ColumnFixing enabled={true} /> */}
                        <Grouping contextMenuEnabled={true} autoExpandAll={this.state.autoExpandAll} />
                        <GroupPanel visible={true} /> {/* or "auto" */}
                        <Column dataField="date" caption="Date" headerCellRender={renderTitleHeader}></Column>
                        {/* do not assign dataType for date column */}
                        <Column dataField="teamId" caption="Team" headerCellRender={renderTitleHeader} calculateSortValue={this.onCalculateSortValueTeam}>
                          <Lookup dataSource={this.props.allTeams} displayExpr="name" valueExpr="id" />
                        </Column>
                        <Column dataField="conferenceId" caption="Conference" headerCellRender={renderTitleHeader} calculateSortValue={this.onCalculateSortValueConference}>
                          <Lookup dataSource={this.props.allConferences} displayExpr="name" valueExpr="id" />
                        </Column>
                        <Column dataField="divisionId" caption="Division" headerCellRender={renderTitleHeader}>
                          <Lookup dataSource={this.state.divisions_in_data} displayExpr="name" valueExpr="id" />
                        </Column>
                        <Column dataField="dateTypeId" caption="Priority" headerCellRender={renderTitleHeader}>
                          <Lookup dataSource={dateTypeId_lookup} displayExpr="name" valueExpr="id" />
                        </Column>
                        <Column
                          dataField="remainingGameCount"
                          caption="Games Open #"
                          dataType="number"
                          alignment="left"
                          selectedFilterOperation=">="
                          filterValue="1"
                          headerCellRender={renderTitleHeader}
                        ></Column>
                        <Column
                          dataField="distance"
                          caption="Distance (miles)"
                          dataType="number"
                          alignment="left"
                          selectedFilterOperation="<="
                          sortOrder="asc"
                          headerCellRender={renderTitleHeader}
                        ></Column>
                        <Column dataField="availability" caption="Availability" dataType="string" headerCellRender={renderTitleHeader}></Column>
                        <Column caption="Contact" dataType="string" headerCellRender={renderTitleHeader} calculateDisplayValue={this.calculateDisplayValueContact}></Column>
                        {/* <Column dataField="KPI1" caption="" dataType="number" alignment="left" headerCellRender={renderTitleHeader}></Column>
                        <Column dataField="KP1" caption="" dataType="number" alignment="left" headerCellRender={renderTitleHeader}></Column>
                        <Column dataField="BPI1" caption="" dataType="number" alignment="left" headerCellRender={renderTitleHeader}></Column>
                        <Column dataField="NET1" caption="" dataType="number" alignment="left" headerCellRender={renderTitleHeader}></Column>
                        <Column dataField="SAG1" caption="" dataType="number" alignment="left" headerCellRender={renderTitleHeader}></Column>
                        <Column dataField="SOR1" caption="" dataType="number" alignment="left" headerCellRender={renderTitleHeader}></Column>
                        <Column dataField="RPI1" caption="" dataType="number" alignment="left" headerCellRender={renderTitleHeader}></Column>  */}
                        {["KPI", "KP", "BPI", "NET", "SAG", "SOR", "RPI"].map((kpIndex, i) => (
                          <Column
                            key={`${kpIndex}${i}`}
                            dataField={`${kpIndex}${this.state.selected_compute_sandbox_from}`}
                            caption={kpIndex}
                            dataType="number"
                            alignment="left"
                            headerCellRender={renderTitleHeader}
                          />
                        ))}
                        <Column dataField="city" caption="City" dataType="string" headerCellRender={renderTitleHeader}></Column>
                        <Column dataField="state" caption="State" dataType="string" headerCellRender={renderTitleHeader}></Column>
                        <Column dataField="arena" caption="Arena" dataType="string" headerCellRender={renderTitleHeader}></Column>
                        <Column dataField="TravelNotes" caption="Travel Notes" dataType="string" headerCellRender={renderTitleHeader}></Column>
                        <Summary>
                          <GroupItem
                            column="Distance (miles)"
                            summaryType="avg"
                            customizeText={this.customizeTextGroupItemSummary}
                            displayFormat={"{0}"}
                            showInGroupFooter={false}
                            alignByColumn={true}
                          />
                          {["KPI", "KP", "BPI", "NET", "SAG", "SOR", "RPI"].map((kpIndex, i) => (
                            <GroupItem
                              key={`${kpIndex}${i}`}
                              column={`${kpIndex}${this.state.selected_compute_sandbox_from}`}
                              summaryType="avg"
                              customizeText={this.customizeTextGroupItemSummary}
                              displayFormat={"{0}"}
                              showInGroupFooter={false}
                              alignByColumn={true}
                            />
                          ))}
                        </Summary>
                      </DataGrid>
                    </Row>
                    <Row>
                      <Col className="ml-2">
                        <CheckBox text="Expand All Groups" value={this.state.autoExpandAll} onValueChanged={this.onAutoExpandAllChanged} />
                      </Col>
                    </Row>
                  </Fragment>
                )}
                {this.state.selectedIndex === 1 && (
                  <Row>
                    <DataGrid
                      width={"100%"}
                      columnAutoWidth={true}
                      dataSource={this.state.open_dates}
                      allowColumnReordering={true}
                      wordWrapEnabled={true}
                      showBorders={false}
                      showColumnLines={false}
                      showRowLines={true}
                      rowAlternationEnabled={true}
                      allowSearch={true}
                      selection={{ mode: "multiple", showCheckBoxesMode: "none" }}
                    >
                      <SearchPanel visible={true} />
                      <Sorting mode="multiple" />
                      <Export
                        enabled={true}
                        fileName={`KPI-Game-Search-Open-Dates-${this.state.searchStart ? this.state.searchStart.toLocaleDateString().replace("/", "_") : ""}-${
                          this.state.searchEnd ? this.state.searchEnd.toLocaleDateString().replace("/", "_") : ""
                        }`}
                      />
                      <Paging defaultPageSize={100} />
                      <Pager showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />
                      <FilterRow visible={true} />
                      <FilterPanel visible={true} />
                      <ColumnFixing enabled={true} />
                      <Column dataField="date" caption="Open Dates" dataType="date" headerCellRender={renderTitleHeader}></Column>
                    </DataGrid>
                  </Row>
                )}
                {this.state.selectedIndex === 2 && (
                  <div>
                    <Row>
                      <DataGrid
                        width={"100%"}
                        columnAutoWidth={true}
                        dataSource={this.state.contact_list}
                        allowColumnReordering={true}
                        wordWrapEnabled={false}
                        showBorders={false}
                        showColumnLines={false}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        allowSearch={true}
                        selection={{ mode: "multiple", showCheckBoxesMode: "none" }}
                      >
                        <SearchPanel visible={true} />
                        <Sorting mode="multiple" />
                        <Paging defaultPageSize={100} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />
                        <FilterRow visible={true} />
                        <FilterPanel visible={true} />
                        <ColumnFixing enabled={true} />

                        <Column dataField="name" caption="Name" dataType="string" fixed={true} fixedPosition="left" headerCellRender={renderTitleHeader}></Column>
                        <Column dataField="email" caption="E-Mail" dataType="string" headerCellRender={renderTitleHeader}></Column>
                        <Column dataField="Phone" caption="Phone *" dataType="string" headerCellRender={renderTitleHeader}></Column>
                        <Column dataField="organizationName" caption="Organization" dataType="string" headerCellRender={renderTitleHeader}></Column>
                        <Column dataField="teamName" caption="Team" dataType="string" headerCellRender={renderTitleHeader}></Column>
                        <Column dataField="sportName" caption="Sport" dataType="string" headerCellRender={renderTitleHeader}></Column>
                        <Column dataField="divisionName" caption="Division" dataType="string" headerCellRender={renderTitleHeader}></Column>
                        <Column dataField="conferenceName" caption="Conference" dataType="string" headerCellRender={renderTitleHeader}></Column>
                      </DataGrid>
                    </Row>

                    <Row className="mt-2">
                      <small>* Phone is available for users who provide it on the "User Profile and access level" page</small>
                    </Row>
                  </div>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default GameSearch;
