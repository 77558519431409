import React, { Component, Fragment } from "react";
import { Row, Col, CardHeader, Card, CardBody, Badge } from "reactstrap";
import { get_resume_for_division_sport_season } from "../services/publicService";
import notify from "devextreme/ui/notify";
import { get_overtime_suffix } from "./../utils/common-funcs";
import ResumeTable from "./resume-table";
import { display_sports_needing_regions } from "./../utils/enums";

const default_logo_height = 30;

class AllGameValues extends Component {
  constructor(props) {
    super(props);
    this.state = { resume: [], logoHeight: default_logo_height, tableFontSize: 14, overtime_suffix: "" ,showRegionsName:false};
  }

  async componentDidMount() {
    if (this.props.selectedDivision && this.props.selectedDivision.id > 0 && this.props.selectedSport.id > 0 && this.props.selectedSeason.id > 0) {
      const resume = await this.handleDataLoad();
      const overtime_suffix = get_overtime_suffix(this.props.selectedSport.id);
      this.setState({ resume: resume, overtime_suffix: overtime_suffix });
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.selectedDivision.id !== prevProps.selectedDivision.id ||
      this.props.selectedSport.id !== prevProps.selectedSport.id ||
      this.props.selectedSeason.id !== prevProps.selectedSeason.id
    ) {
      const resume = await this.handleDataLoad();
      const overtime_suffix = get_overtime_suffix(this.props.selectedSport.id);
      const showRegionsName = display_sports_needing_regions.has(this.props.selectedSport.id) && this.props.selectedDivision.id === 2;
   
      this.setState({ resume: resume, overtime_suffix: overtime_suffix,showRegionsName:showRegionsName });
    }
  }

  handleDataLoad = async () => {
    notify(`Refreshing table`);
    let { data: resume } = await get_resume_for_division_sport_season(
      this.props.selectedDivision.id,
      this.props.selectedSport.id,
      this.props.selectedSeason.id,
      this.props.auth0jwt
    );

    const resume_length = resume.length;
    if (Array.isArray(resume) && resume_length > 0) {
      resume = resume.map((currElement, index) => ({
        ...currElement,
        kpiRank: index + 1,
        kpiPercentile: (((resume_length - index) / resume_length) * 100).toFixed(3),
      }));
    }

    console.log("all game values from db");
    return resume;
  };



  render() {
    return (
      <Fragment>
        <Card className="main-card my-2 mx-2 border border-primary rounded">
          <CardHeader className="card-header-tab">
            <div className="card-header-title thick">All Game Values</div>
          </CardHeader>
          <div style={{ marginLeft: "auto", marginRight: "auto" }}>
            <Badge color="primary">{this.props.db_last_update}</Badge>
          </div>
          <CardBody className="pt-1">
            <Row className="mb-5">
              <Col xs="12">
                <ResumeTable
                  logoHeight={this.state.logoHeight}
                  tableFontSize={this.state.tableFontSize}
                  resume={this.state.resume}
                  addTeam1Columns={true}
                  id={"AllGameValuesTable"}
                  metadata={{ overtime_suffix: this.state.overtime_suffix }}
                  dateDefaultSortIndex={1}
                  rankDefaultSortIndex={0}
                  showKpiRank={true}
                  showKpiPercentile={true}
                  db_last_update={this.props.db_last_update}
                  showBroadcaster={false}
                  selectedSport_id={this.props.selectedSport.id}
                  showRegionsName={this.state.showRegionsName}
                />
              </Col>
            </Row>
            <Row>
              <Col>{`*: ${Intl.DateTimeFormat().resolvedOptions().timeZone} time zone`}</Col>
            </Row>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default AllGameValues;
