import React, { Component, Fragment } from "react";
import Select from "react-select";
import { Row, Col } from "reactstrap";

class ViewSelector extends Component {
  render() {
    return (
      <Fragment>
        <Row className="mt-2 mb-0">
          <Col xs="12">
            <div className="px-2">
              <label className="mb-0 ">
                <b>View</b>
              </label>
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                isSearchable={false}
                options={this.props.tab_names}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                onChange={this.props.handle_active_tab_change}
                value={this.props.selectedTab}
                maxMenuHeight={600}
              />
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default ViewSelector;
