import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import jwt_decode from "jwt-decode";

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [userPermissions, setUserPermissions] = useState([]);
  const [tokenInterval, setTokenInterval] = useState(null);
  const [auth0jwt, setAuth0jwt] = useState();

  useEffect(() => {
    const initAuth0 = async () => {
      //console.log(initOptions);
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      try {
        if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        }
      } catch (error) {
        console.log(error);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);

        let jwt_decoded = null;
        let auth0jwt = null;

        try {
          auth0jwt = await auth0FromHook.getTokenSilently()
          setAuth0jwt(auth0jwt)
          jwt_decoded = jwt_decode(auth0jwt);
        } catch (error) {
          console.error(error);
        } finally {
          try {
            setUserPermissions((jwt_decoded && jwt_decoded.permissions) || []);
            setTokenInterval(jwt_decoded ? { iat: new Date(1000 * jwt_decoded.iat), exp: new Date(1000 * jwt_decoded.exp) } : null);
          } catch (error) {
            console.error(error);
          }
        }

        //console.log(jwt_decoded);
        //console.log(user);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };
  return (
    <Auth0Context.Provider
      value={{
        auth0jwt,
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        userPermissions,
        tokenInterval,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
