import React, { Component, Fragment } from "react";
import Select from "react-select";
import { Row, Col } from "reactstrap";

class ActiveSet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cols: this.props.showTeams ? "3" : "4",
    };
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    console.log("ActiveSet did update");
    if (this.props.showTeams !== prevProps.showTeams || this.props.applyUserFilter !== prevProps.applyUserFilter) {
      this.setState({ cols: this.props.showTeams ? "3" : "4" });
    }
  }

  render() {
    return (
      <Fragment>
        <Row className="mt-0 mb-4">
          {this.props.showDivisions && (
            <Col sm={this.state.cols}>
              <div className="px-2">
                <label className="mb-0 pt-2">
                  <b>Division</b>
                </label>
                <Select
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  isSearchable={true}
                  options={this.props.useAll ? this.props.allDivisions : this.props.divisions}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  onChange={this.props.divisionsOnChange}
                  value={this.props.divisionsValue}
                />
              </div>
            </Col>
          )}
          <Col sm={this.state.cols}>
            <div className="px-2">
              <label className="mb-0 pt-2">
                <b>Sport</b>
              </label>
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                isSearchable={true}
                options={this.props.useAll ? this.props.allSports : this.props.sports}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                onChange={this.props.sportsOnChange}
                value={this.props.sportsValue}
              />
            </div>
          </Col>
          <Col sm={this.state.cols}>
            <div className="px-2">
              <label className="mb-0 pt-2">
                <b>Season</b>
              </label>
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                isSearchable={true}
                options={this.props.useAll ? this.props.allSeasons : this.props.seasons}
                getOptionValue={(option) => option.id}
                getOptionLabel={this.props.seasonsGetOptionLabel}
                onChange={this.props.seasonsOnChange}
                value={this.props.seasonsValue}
              />
            </div>
          </Col>
          {this.props.showTeams && (
            <Col sm={this.state.cols}>
              <div className="px-2">
                <label className="mb-0 pt-2">
                  <b>Team</b>
                </label>
                <Select
                  styles={{
                    // Fixes the overlapping problem of the component
                    control: (base, state) => ({
                      ...base,
                      boxShasow: state.isFocused ? 0 : 0,
                      borderColor: state.isFocused ? "#FF5733" : base.borderColor,
                      "&:hover": {
                        borderColor: state.isFocused ? "#FF5733" : base.borderColor,
                      },
                    }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  isSearchable={true}
                  options={this.props.useAll ? this.props.allTeams.filter((x) => x.id > 0) : this.props.teams.filter((x) => x.id > 0)}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  onChange={this.props.teamOnChange}
                  value={this.props.teamValue}
                  autoFocus
                />
              </div>
            </Col>
          )}
        </Row>
      </Fragment>
    );
  }
}

export default ActiveSet;
