import React, { Component, Fragment } from "react";

import { Row, Col, CardHeader, Card, CardBody, Badge } from "reactstrap";

import DataGrid, { Column, HeaderFilter, Export, StateStoring, ColumnChooser, ColumnFixing, Editing, Lookup, Paging, Pager } from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import { Button } from "devextreme-react";

import { RequiredRule } from "devextreme-react/validator";
import { CheckBox } from "devextreme-react/check-box";
import { SelectBox } from "devextreme-react";
import { renderTitleHeader } from "./home-rankings-utils";

import { get_sandbox_for_team_season_sport } from "./../services/privateService";
import { get_seed_cutlines } from "./../services/publicService";
import {
  siteIdWithoutBlank,
  winLossTie,
  isConference,
  computeSandboxFrom,
  siteIdToNameDict,
  winLossTieIdToNameDict,
  time_format,
  isConferenceEnum,
  pager_lengths,
} from "../utils/enums";
import { get_sandbox_vtp_for_season_and_sport } from "./../services/privateService";
import { format, parse } from "fecha";
import { group } from "d3-array";
import {
  infoRowStyleMeta,
  makeConferenceWinLossTieString,
  makeOverallWinLossTieString,
  isString,
  ToMap,
  is_undefined,
  makeClinetFriendlyComputedSandboxFrom,
  to_map_async
} from "./../utils/common-funcs";

const logo_height = 25;
function team2LogoCellRender(props) {
  if (props.data.team2Id) {
    return <img height={logo_height} width={logo_height} src={`logos_svg/${props.data.team2Id}.svg`} alt={props.data.team2Name} />;
  } else {
    return <div>*</div>;
  }
}

// Men's Ice Hockey, Women's Ice Hockey, Men's Soccer and Women's Soccer
const display_sport_ids_that_need_if_tie = new Set([31,  32,  47,  48]);

//Remove NET and QUAD for all sports except Men's Basketball and Women's Basketball
const display_sport_ids_that_need_net_quad = new Set([7,8]);

//Remove SOR, BPI, KP and SAG for all sports except Men's Basketball
const display_sport_ids_that_need_sor = new Set([7]);


class ResumeSandbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarData: [],
      hasNewBlockedDates: false,
      selected_compute_sandbox_from: computeSandboxFrom[1].id,
      teamIdToVtpMap: new Map(),
      cutLineGroups: new Map(),
      usePopupEditinMode: false,
      team_id_to_team_meta: new Map(),
      kpi_rank_sorted: [],
    };

    this.setCellValueForTeam = this.setCellValueForTeam.bind(this);
    this.onValueChangedUsePopupEditinMode = this.onValueChangedUsePopupEditinMode.bind(this);
    this.onClickRefresh = this.onClickRefresh.bind(this);
  }

  calculateDisplayValueDate(e) {
    if (e.dateTBD) {
      return "<TBD>";
    } else if (isString(e.startDate)) {
      return format(parse(e.startDate, time_format.utc), "YYYY-MM-DD ddd");
    } else if (e.startDate) {
      return format(e.startDate, "YYYY-MM-DD ddd");
    } else {
      return null;
    }
  }

  async componentDidMount() {
    await this.refresh_data_sources();
  }

  onValueChangedUsePopupEditinMode(args) {
    const tableToForceRefrsh = [...this.state.calendarData];
    this.setState({
      usePopupEditinMode: args.value,
      calendarData: tableToForceRefrsh,
    });
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.isAuthenticated !== prevProps.isAuthenticated ||
      this.props.auth0jwt !== prevProps.auth0jwt ||
      this.props.selectedSeason.id !== prevProps.selectedSeason.id ||
      this.props.selectedTeam.id !== prevProps.selectedTeam.id ||
      this.props.selectedSport.id !== prevProps.selectedSport.id
    ) {
      await this.refresh_data_sources();
    }
  }

  get_cut_line_value_map_by_season = (cut_lines) => {
    if (!cut_lines || !Array.isArray(cut_lines) || cut_lines.length < 1) {
      return new Map();
    }
    var cut_line_groups = group(
      cut_lines,
      (d) => d.aQ,
      (d) => d.seasonName
    );

    for (let [key, value] of cut_line_groups.get(0)) {
      value.sort((a, b) => Number(b.value) - Number(a.value));
    }

    return cut_line_groups;
  };

  onInitNewRow = async (e) => {
    e.promise = this.checkTokenLocal();

    e.data.userId = this.props.user.hq_current_user.userId;
    e.data.WL = 1;
    e.data.isPrivate = 0;
    e.data.gameKeyId = 0;
  };

  onRowInserting = async (e) => {
    e.cancel = this.checkTokenLocal();
  };

  onRowInserted = (e) => {
    console.log("inserted row");
    this.setState({ hasNewBlockedDates: true });
  };

  onRowUpdating = async (e) => {
    e.cancel = this.checkTokenLocal();
  };

  checkTokenLocal = async (e) => {
    return await this.props.checkToken("Resume Sandbox");
  };

  onRowUpdated = (e) => {
    console.log("updated row");
    this.setState({ hasNewBlockedDates: true });
  };

  onRowRemoved = (e) => {
    console.log("removed row");
    this.setState({ hasNewBlockedDates: true });
  };

  showSaveButton = () => {
    console.log("save");
    return this.state.hasNewBlockedDates;
  };

  handleComputeSandboxFromTagBoxOnValueChanged = async (event) => {
    const calendarDataForceRefresh = [...this.state.calendarData];
    this.setState({ selected_compute_sandbox_from: event.value, calendarData: calendarDataForceRefresh });
  };

  makeAvgKpiString = (nullIfNoData) => {
    const calendarData = this.state.calendarData;
    let avgKPI = 0.0;
    let n = 0;

    for (var j = 0; j < calendarData.length; j++) {
      let row = calendarData[j];
      if (row.isConference !== isConferenceEnum.Exhibition && (row.WL === 1 || row.WL === 2 || row.WL === 3)) {
        let kpi = this.vtpCellRenderForWL({ target: "VALUE", data: row, digits: 2 });
        avgKPI = avgKPI + Number(kpi);
        n = n + 1;
      }
    }

    if (nullIfNoData && n === 0) {
      return null;
    } else if (n > 0 && avgKPI) {
      return (avgKPI / n).toFixed(3);
    } else {
      return 0.0;
    }
  };

  makeKpiRankString = (avgKpi) => {
    if (!avgKpi || this.state.kpi_rank_sorted.length < 1) {
      return null;
    }

    let rank = this.state.kpi_rank_sorted.length;
    for (let index = 0; index < this.state.kpi_rank_sorted.length; index++) {
      const element = this.state.kpi_rank_sorted[index];
      if (avgKpi > element.kpiNum0) {
        rank = element.kpiRank0;
      } else {
        break;
      }
    }
    return rank;
  };

  makeCutLineSeedNameArray = () => {
    const kpi = this.makeAvgKpiString(true);

    let seedNameArray = [];
    if (kpi === null) {
      seedNameArray.push({ key: "Seed", value: "Please set WL column" });
    } else {
      const nonAq = this.state.cutLineGroups.get(0) || new Map();

      for (let [key, seedArray] of nonAq) {
        let keySeedName = this.get_seed_from_kpi(kpi, seedArray);

        seedNameArray.push({ key: key, value: keySeedName });
      }
    }

    return seedNameArray;
  };

  get_seed_from_kpi = (kpi, seedArray) => {
    let lastSeedName = "";
    for (let j = 0; j < seedArray.length; ++j) {
      const obj = seedArray[j];
      lastSeedName = obj.seedName;
      if (kpi >= obj.value) {
        break;
      }
    }
    return lastSeedName;
  };

  vtpCellRenderForWL = (obj) => {
    const cellData = obj.data;
    let target = obj.target;
    let prefix = "VTP";
    if (obj.target === "VALUE") {
      const winLossId = cellData["WL"];
      target = winLossTieIdToNameDict[winLossId];
    } else if (obj.target === "Quad") {
      target = "";
      prefix = "Q";
    }

    const siteId = cellData["siteId"];
    const siteName = siteIdToNameDict[siteId];

    const key = `${prefix}${siteName}${target}${this.state.selected_compute_sandbox_from}`;
    const vtpObject = this.state.teamIdToVtpMap.get(cellData.team2Id) || {};
    
    let output = vtpObject[key] || 0;

    return output.toFixed(obj.digits);
  };

  vtpCellRenderForIndex = (obj) => {
    const cellData = obj.data;
    const kpIndex = obj.target;
    const key = `${kpIndex}${this.state.selected_compute_sandbox_from}`;
    const vtpObject = this.state.teamIdToVtpMap.get(cellData.team2Id) || {};
    const output = vtpObject[key] || 0;
    return <div>{output}</div>;
  };

  setCellValueForTeam(newData, value, currentRowData) {
    newData.team2Id = value;

    if (currentRowData.team2Id !== value) {
      //we only want to change the conference if they update the teams
      const vtpFromRowSelection = this.state.teamIdToVtpMap.get(value) || {};
      const vtpFromTeamSelection = this.state.teamIdToVtpMap.get(this.props.selectedTeam.id) || {};

      newData.isConference = vtpFromRowSelection["conferenceId"] === vtpFromTeamSelection["conferenceId"] ? 1 : 2;
    }
  }

  async onClickRefresh(e) {
    await this.refresh_data_sources();
  }

  refresh_data_sources = async () => {
    notify(`Refreshing table`);
    let calendarData = [];
    let cutLineGroups = new Map();
    let teamIdToVtpMap = new Map();
    let team_id_to_team_meta = new Map();
    let kpi_rank_sorted = [];

    try {
      if (
        this.props.isAuthenticated &&
        this.props.auth0jwt &&
        this.props.selectedSport.id &&
        this.props.selectedSeason.id &&
        this.props.selectedTeam.id &&
        this.props.selectedDivision.id
      ) {
        const res = await get_sandbox_for_team_season_sport(
          this.props.auth0jwt,
          this.props.selectedTeam.id,
          this.props.selectedSeason.id,
          this.props.selectedSport.id,
          this.props.selectedDivision.id
        );
        calendarData = res.data;

        const { data: cut_lines } = await get_seed_cutlines(this.props.auth0jwt, this.props.selectedDivision.id, this.props.selectedSport.id, this.props.selectedSeason.id);

        cutLineGroups = this.get_cut_line_value_map_by_season(cut_lines);

        const resVtp = await get_sandbox_vtp_for_season_and_sport(this.props.auth0jwt, this.props.selectedSeason.id, this.props.selectedSport.id, this.props.selectedDivision.id, 0);

        teamIdToVtpMap = await to_map_async(resVtp.data, current=>current.team2Id);

        for (let index = 0; index < resVtp.data.length; index++) {
          const element = resVtp.data[index];
          if (is_undefined(element.kpiRank0)) {
            //no data exists. stop
            kpi_rank_sorted = [];
            break;
          }
          kpi_rank_sorted.push({ kpiNum0: element.kpiNum0, kpiRank0: element.kpiRank0 });
        }
        kpi_rank_sorted.sort((a, b) => Number(a.kpiNum0) - Number(b.kpiNum0));

        team_id_to_team_meta = ToMap(this.props.teams);
      }
    } catch (error) {
      console.log(error);
    }

    let compute_sandbox_from_local = makeClinetFriendlyComputedSandboxFrom(this.props.selectedSport.name, this.props.selectedSeason.name);
    
    this.setState({
      calendarData: calendarData,
      teamIdToVtpMap: teamIdToVtpMap,
      cutLineGroups: cutLineGroups,
      team_id_to_team_meta: team_id_to_team_meta,
      kpi_rank_sorted: kpi_rank_sorted,
      compute_sandbox_from: compute_sandbox_from_local,
    });
  };

  render() {
    const info_row_style = this.props.selectedTeam.id ? infoRowStyleMeta(this.state.team_id_to_team_meta.get(this.props.selectedTeam.id)) : {};
    const avgKpiString = this.makeAvgKpiString(false);
    return (
      <Fragment>
        <Card className="main-card my-2 mx-2 border border-primary rounded">
          {!this.props.selectedTeam.id && (
            <CardHeader className="card-header-tab">
              <div className="card-header-title thick">Select a team using the above Team selector</div>
            </CardHeader>
          )}
          {this.props.selectedTeam.id && (
            <CardHeader className="card-header-tab" style={{ height: "110px" }}>
              <Row xs="12">
                <Col>
                  <div style={{ display: "flex", alignItems: "center", verticalAlign: "middle", justifyContent: "space-between" }}>
                    <div style={{ height: "100px" }}>
                      <img height={100} width={100} src={`../logos_svg/${this.props.selectedTeam.id}.svg`} alt={this.props.selectedTeam.name} />
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div style={info_row_style}>
                        <small>{makeOverallWinLossTieString(this.state.calendarData)}</small>
                        <br />
                        <small>Overall</small>
                      </div>

                      <div style={info_row_style}>
                        <small>{makeConferenceWinLossTieString(this.state.calendarData)}</small>
                        <br />
                        <small>Conf</small>
                      </div>

                      <div style={info_row_style}>
                        <small>{avgKpiString}</small>
                        <br />
                        <small>KPI</small>
                      </div>

                      <div style={info_row_style}>
                        <small>{this.makeKpiRankString(avgKpiString)}</small>
                        <br />
                        <small>~Rank</small>
                      </div>

                      {this.makeCutLineSeedNameArray().map((x) => {
                        return (
                          <div key={x.key} style={info_row_style}>
                            <small>{x.value}</small>
                            <br />
                            <small>{x.key}</small>
                          </div>
                        );
                      })}
                      <div>
                        <Button icon="refresh" hint="Refesh Table" type="back" stylingMode="contained" onClick={this.onClickRefresh} />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardHeader>
          )}

          <div style={{ marginLeft: "auto", marginRight: "auto" }}>
            <Badge color="primary">{this.props.db_last_update}</Badge>
          </div>
          <CardBody className="pt-1">
            <Row className="px-1 py-1">
              <Col xs={{ size: 8 }}>
                <small>
                  Changes to sandbox values are not saved in the Faktor database. Please use the 'Export all data' button (table top right corner) to download and save your
                  changes.
                </small>
              </Col>
              <Col xs={{ size: 4 }}>
                <Row className="px-3 py-1">
                  <CheckBox width={"100%"} value={this.state.usePopupEditinMode} onValueChanged={this.onValueChangedUsePopupEditinMode} text="Use Popup editing" />
                </Row>
                <Row className="px-3 py-1">
                  <SelectBox
                    width={"100%"}
                    placeholder={"Compute rankings from ..."}
                    items={this.state.compute_sandbox_from}
                    displayExpr="name"
                    valueExpr="id"
                    value={this.state.selected_compute_sandbox_from}
                    onValueChanged={this.handleComputeSandboxFromTagBoxOnValueChanged}
                  />
                </Row>
              </Col>
            </Row>

            <Row className="mb-5">
              <Col xs="12">
                <DataGrid
                  //columnAutoWidth={true}
                  dataSource={this.state.calendarData}
                  allowColumnReordering={true}
                  showBorders={true}
                  rowAlternationEnabled
                  wordWrapEnabled={false}
                  onInitNewRow={this.onInitNewRow}
                  onRowInserted={this.onRowInserted}
                  onRowInserting={this.onRowInserting}
                  onRowUpdated={this.onRowUpdated}
                  onRowUpdating={this.onRowUpdating}
                  onRowRemoved={this.onRowRemoved}
                  selection={{ mode: "multiple", showCheckBoxesMode: "none" }}
                >
                  <Editing
                    mode={this.state.usePopupEditinMode ? "popup" : "cell"}
                    allowUpdating={true}
                    allowDeleting={true}
                    allowAdding={true}
                    selectTextOnEditStart={true}
                    startEditAction={"dblClick"}
                  />
                  {/* <Editing mode="cell" allowUpdating={true} allowDeleting={true} allowAdding={true} selectTextOnEditStart={true} startEditAction={"dblClick"} /> */}
                  {/* <Editing mode="form" allowUpdating={true} useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true}>
                    <Form>
                      <Item dataField="startDate" editorType="dxDateBox" />
                      <Item dataField="team2Id" editorType="dxSelectBox" />
                      <Item dataField="siteId" editorType="dxSelectBox" />
                      <Item dataField="WL" editorType="dxSelectBox" />
                      <Item dataField="Notes" editorType="dxTextArea" />
                      <Item dataField="isConference" editorType="dxSelectBox" />
                    </Form>
                  </Editing> */}
                  <Export enabled={true} fileName={"KPI-Sandbox"} />
                  {/* <Scrolling columnRenderingMode="virtual" /> */}
                  {/* <Scrolling mode="infinite" /> */}
                  {/* <Scrolling mode="virtual" rowRenderingMode="virtual" /> */}
                  <Paging defaultPageSize={50} />
                  <Pager visible={true} showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />
                  <HeaderFilter visible={true} />
                  <StateStoring enabled={true} type="localStorage" storageKey={this.props.id} />
                  <ColumnChooser enabled={true} />
                  <ColumnFixing enabled={true} />
                  <Column
                    caption="Date"
                    dataField="startDate"
                    dataType="date"
                    defaultSortOrder="asc"
                    defaultSortIndex={0}
                    minWidth={100}
                    alignment="left"
                    fixed={true}
                    fixedPosition="left"
                    calculateDisplayValue={this.calculateDisplayValueDate}
                    headerCellRender={renderTitleHeader}
                    //columnWidth={"auto"}
                  >
                    <RequiredRule message="Start date is required" />
                  </Column>
                  <Column
                    caption="Site"
                    dataField="siteId"
                    dataType="number"
                    // width={70}
                    alignment="center"
                    headerCellRender={renderTitleHeader}
                    fixed={true}
                    fixedPosition="left"
                    //columnWidth={"auto"}
                  >
                    <Lookup dataSource={siteIdWithoutBlank} displayExpr="name" valueExpr="id" />
                    <RequiredRule message="Site is required" />
                  </Column>
                  {/* <Column
                    caption="KPI"
                    dataField="kpi"
                    dataType="number"
                    minWidth={60}
                    allowSorting={true}
                    alignment="center"
                    fixed={true}
                    fixedPosition="left"
                    headerCellRender={renderTitleHeader}
                    allowHeaderFiltering={false}
                    calculateCellValue={(data) => data && data.kpi && data.kpi.toFixed(4)}
                    sortingMethod={customNumberSortingMethod}
                    allowEditing={false}
                  /> */}
                  <Column
                    caption="Team"
                    dataField="team2Id"
                    dataType="number"
                    // minWidth={160}
                    headerCellRender={renderTitleHeader}
                    fixed={true}
                    fixedPosition="left"
                    setCellValue={this.setCellValueForTeam}
                    //columnWidth={"auto"}
                  >
                    <Lookup dataSource={this.props.teams} displayExpr="name" valueExpr="id" />
                    <RequiredRule message="Team is required" />
                  </Column>
                  <Column
                    caption="Logo"
                    dataField="teamLogo"
                    dataType="object"
                    // minWidth={60}
                    cellRender={team2LogoCellRender}
                    allowSorting={false}
                    alignment="center"
                    headerCellRender={renderTitleHeader}
                    allowEditing={false}
                    allowHeaderFiltering={false}
                    allowFiltering={false}
                    visible={false}
                    //columnWidth={"auto"}
                  />
                  <Column
                    caption="Game Type"
                    dataField="isConference"
                    dataType="number"
                    // width={80}
                    headerCellRender={renderTitleHeader}
                    //columnWidth={"auto"}
                  >
                    <Lookup dataSource={isConference} displayExpr="name" valueExpr="id" />
                    <RequiredRule message="Conference/Non-conference is required" />
                  </Column>
                  <Column
                    caption="WL"
                    dataField="WL"
                    dataType="string"
                    // width={70}
                    alignment="center"
                    headerCellRender={renderTitleHeader}
                    //columnWidth={"auto"}
                  >
                    <Lookup dataSource={winLossTie} displayExpr="name" valueExpr="id" />
                    <RequiredRule message="Win/Loss/Tie is required" />
                  </Column>
                  <Column
                    caption="Record"
                    dataType="string"
                    // minWidth={40}
                    allowSorting={false}
                    alignment="center"
                    headerCellRender={renderTitleHeader}
                    allowHeaderFiltering={false}
                    cellRender={(x) => this.vtpCellRenderForIndex({ target: "RECORD", data: x.data })}
                    allowEditing={false}
                    //columnWidth={"auto"}
                  />
                  <Column
                    caption="Value"
                    dataType="numeric"
                    // width={50}
                    allowSorting={true}
                    alignment="center"
                    headerCellRender={renderTitleHeader}
                    allowHeaderFiltering={false}
                    cellRender={(x) => this.vtpCellRenderForWL({ target: "VALUE", data: x.data, digits: 2 })}
                    allowEditing={false}
                    //columnWidth={"auto"}
                  />
                  <Column
                    caption="if W"
                    dataType="numeric"
                    // width={50}
                    allowSorting={true}
                    alignment="center"
                    headerCellRender={renderTitleHeader}
                    allowHeaderFiltering={false}
                    cellRender={(x) => this.vtpCellRenderForWL({ target: "W", data: x.data, digits: 2 })}
                    allowEditing={false}
                    //columnWidth={"auto"}
                  />
                  <Column
                    caption="if L"
                    dataType="numeric"
                    // width={50}
                    allowSorting={true}
                    alignment="center"
                    headerCellRender={renderTitleHeader}
                    allowHeaderFiltering={false}
                    cellRender={(x) => this.vtpCellRenderForWL({ target: "L", data: x.data, digits: 2 })}
                    allowEditing={false}
                    //columnWidth={"auto"}
                  />
                  {display_sport_ids_that_need_if_tie.has(this.props?.selectedSport?.id) && (
                    <Column
                      caption="if T"
                      dataType="numeric"
                      // width={50}
                      allowSorting={true}
                      alignment="center"
                      headerCellRender={renderTitleHeader}
                      allowHeaderFiltering={false}
                      cellRender={(x) => this.vtpCellRenderForWL({ target: "T", data: x.data, digits: 2 })}
                      allowEditing={false}
                      //columnWidth={"auto"}
                    />
                  )}
                  {display_sport_ids_that_need_net_quad.has(this.props?.selectedSport?.id) && (
                    <Column
                      caption="Quad"
                      dataType="numeric"
                      // width={50}
                      allowSorting={true}
                      alignment="center"
                      headerCellRender={renderTitleHeader}
                      allowHeaderFiltering={false}
                      cellRender={(x) => this.vtpCellRenderForWL({ target: "Quad", data: x.data, digits: 0 })}
                      allowEditing={false}
                      //columnWidth={"auto"}
                    />
                  )}
                  {display_sport_ids_that_need_net_quad.has(this.props?.selectedSport?.id) && (
                    <Column
                      caption="NET"
                      dataType="numeric"
                      // width={50}
                      allowSorting={true}
                      alignment="center"
                      headerCellRender={renderTitleHeader}
                      allowHeaderFiltering={false}
                      allowEditing={false}
                      cellRender={(x) => this.vtpCellRenderForIndex({ target: "NET", data: x.data })}
                      //columnWidth={"auto"}
                    />
                  )}
                  <Column
                    caption="KPI"
                    dataType="numeric"
                    // width={50}64
                    allowSorting={true}
                    alignment="center"
                    headerCellRender={renderTitleHeader}
                    allowHeaderFiltering={false}
                    allowEditing={false}
                    cellRender={(x) => this.vtpCellRenderForIndex({ target: "KPI", data: x.data })}
                    //columnWidth={"auto"}
                  />
                  {display_sport_ids_that_need_sor.has(this.props?.selectedSport?.id) &&
                    ["SOR", "BPI", "KP", "SAG"].map((kpIndex, i) => (
                      <Column
                        key={`${kpIndex}${i}`}
                        caption={kpIndex}
                        dataType="numeric"
                        // width={50}
                        allowSorting={true}
                        alignment="center"
                        headerCellRender={renderTitleHeader}
                        allowHeaderFiltering={false}
                        allowEditing={false}
                        cellRender={(x) => this.vtpCellRenderForIndex({ target: kpIndex, data: x.data })}
                        //columnWidth={"auto"}
                      />
                    ))}
                  {!display_sport_ids_that_need_net_quad.has(this.props?.selectedSport?.id) && (
                    <Column
                      caption="RPI"
                      dataType="numeric"
                      // width={50}
                      allowSorting={true}
                      alignment="center"
                      headerCellRender={renderTitleHeader}
                      allowHeaderFiltering={false}
                      allowEditing={false}
                      cellRender={(x) => this.vtpCellRenderForIndex({ target: "RPI", data: x.data })}
                      //columnWidth={"auto"}
                    />
                  )}
                </DataGrid>
              </Col>
            </Row>
            <Row>
              <Col>{`*: ${Intl.DateTimeFormat().resolvedOptions().timeZone} time zone`}</Col>
            </Row>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default ResumeSandbox;
