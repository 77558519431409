import React from "react";
import { Route, Routes } from "react-router-dom";
import { Container } from "reactstrap";
import NotFound from "./components/notFound";
import NavBar from "./components/navBar";
import LoginForm from "./components/loginForm";
import RegisterForm from "./components/registerForm";
// import Shiny from "./components/shiny";
import Home from "./components/home";
import Resume from "./components/resume";
import ConferenceKpiBrowser from "./components/conference-kpi-browser";
//import Profile from "./components/profile";
// import PrivateRoute from "./components/privateRoute";
import Loading from "./components/loading";
import Footer from "./components/footer";
import FaktorPrivacyPolicy from "./components/faktor-privary-policy";
import FaktorTermsOfService from "./components/faktor-terms-of-service";
import { useAuth0 } from "./react-auth0-spa";
//import history from "./utils/history";
import { CookiesProvider } from "react-cookie";

// styles
import "./App.css";

const App = () => {
  const { loading } = useAuth0();

  if (loading) {
    return <Loading />;
  }

  return (
    <CookiesProvider>
      {/* <HashRouter> */}
        <Container fluid>
          <NavBar />
        </Container>
        <Container fluid>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/conferenceKPI" element={<ConferenceKpiBrowser />} />
            {/* <PrivateRoute path="/analytics" element={<Shiny />} /> */}
            <Route path="/register" element={<RegisterForm />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/terms" element={<FaktorTermsOfService />} />
            <Route path="/policy" element={<FaktorPrivacyPolicy />} />
            {/* <PrivateRoute path="/profile" element={<Profile />} /> */}
            <Route path="/not-found" element={<NotFound />} />
            <Route path="/" exact element={<Home />} />
            {/* <Navigate to="/not-found" /> */}
          </Routes>
        </Container>
        <Container fluid>
          <Footer />
        </Container>
      {/* </HashRouter> */}
    </CookiesProvider>
  );
};

export default App;
