import { get_teams_for_sport_season } from "./publicService";

export function set_team_id_to_team_map(all_teams) {
  return new Promise((resolve, reject) => {
    const teamIdToTeamMap = new Map();
    if (!Array.isArray(all_teams)) {
      resolve(all_teams);
      return;
    }
    for (let j = 0; j < all_teams.length; j++) {
      const t = all_teams[j];
      teamIdToTeamMap.set(t.id, t);
    }

    resolve(teamIdToTeamMap);
    return;
  });
}

export function set_team_id_to_team_map_lambda(all_teams, lambda) {
  return new Promise((resolve, reject) => {
    const teamIdToTeamMap = new Map();
    if (!Array.isArray(all_teams)) {
      resolve(all_teams);
      return;
    }
    for (let j = 0; j < all_teams.length; j++) {
      const t = all_teams[j];
      if(lambda(t))
      {
        teamIdToTeamMap.set(t.id, t);
      }
    }

    resolve(teamIdToTeamMap);
    return;
  });
}


export async function get_common_metadata(display_sport_id, season_id) {
  try {
    const { data: allTeams } = await get_teams_for_sport_season(display_sport_id, season_id);

    const team_id_to_team_map = await set_team_id_to_team_map(allTeams);

    return { allTeams: allTeams, team_id_to_team_map: team_id_to_team_map };
  } catch (error) {
    console.log(error);
    return { allTeams: [], team_id_to_team_map: new Map() };
  }
}
