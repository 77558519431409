import React, { Component, Fragment } from "react";

import Tabs from "devextreme-react/tabs";
import notify from "devextreme/ui/notify";
import { Button } from "devextreme-react";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Pager,
  Lookup,
  FormItem,
  Popup,
  Position,
  Export,
  SearchPanel,
  CustomRule,
  NumericRule,
  RangeRule,
  FilterRow,
  FilterPanel,
  ColumnFixing,
} from "devextreme-react/data-grid";
import { pager_lengths } from "./../utils/enums";
import { renderTitleHeader } from "../utils/common-rendering-funcs";
import { isString, utc2db, getMinMaxDateValues } from "../utils/common-funcs";

import "devextreme-react/text-area";
import { StringLengthRule, RequiredRule } from "devextreme-react/validator";
import { Row, Col, CardHeader, Card, CardBody } from "reactstrap";
import { toast } from "react-toastify";
import { parse } from "fecha";
import { time_format, tournamentFormatId, isConference, isConferenceEnum } from "../utils/enums";
import {
  put_user_defined_tournament,
  delete_user_defined_tournament,
  get_is_tournament_used,
  get_user_defined_toutnaments,
  patch_user_defined_tournament,
  get_user_defined_toutnament_score_card,
} from "../services/privateService";

const tabs = [
  {
    id: 1,
    text: "Score Card",
    title: "Score card of tournaments with games",
  },
  {
    id: 0,
    text: "Tournament Entry",
    title: "Add tournaments by clicking + on top-right corner of table.",
  },
];

class TournamentManager extends Component {
  constructor(props) {
    super(props);

    this.state = { minDateValue: undefined, maxDateValue: undefined, table: [], scoreCard: [], tournamentNameToIdMap: new Map(), selectedIndex: 0 };

    this.onEditorPreparing = this.onEditorPreparing.bind(this);
    this.onRowRemoving = this.onRowRemoving.bind(this);
    this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);
    this.onClickRefresh = this.onClickRefresh.bind(this);
  }

  onTabsSelectionChanged(args) {
    if (args.name === "selectedIndex" && this.state.selectedIndex !== args.value) {
      this.setState({
        selectedIndex: args.value,
      });
    }
  }

  GetNameToIdMap(objectWithIdAndName) {
    let output = new Map();

    for (var j = 0; j < objectWithIdAndName.length; ++j) {
      const current = objectWithIdAndName[j];
      output.set(current.name, current.tournamentId);
    }
    return output;
  }

  async componentDidMount() {
    try {
      let table = [];
      let scoreCard = [];
      let minDateValue = undefined;
      let maxDateValue = undefined;
      if (this.props.selectedDivision.id && this.props.selectedSport.id && this.props.selectedSeason.id) {
        [minDateValue, maxDateValue] = getMinMaxDateValues(this.props.selectedSport.name, this.props.selectedSeason.name);
        let qres = await get_user_defined_toutnaments(this.props.auth0jwt, this.props.selectedDivision.id, this.props.selectedSport.id, this.props.selectedSeason.id);
        table = qres.data;

        qres = await get_user_defined_toutnament_score_card(this.props.auth0jwt, this.props.selectedDivision.id, this.props.selectedSport.id, this.props.selectedSeason.id);
        scoreCard = qres.data;
      }

      let tournamentNameToId = this.GetNameToIdMap(table);

      this.setState({
        table: table,
        scoreCard: scoreCard,
        minDateValue: minDateValue,
        maxDateValue: maxDateValue,
        tournamentNameToId: tournamentNameToId,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.user && !prevProps.user) ||
      (!this.props.user && prevProps.user) ||
      this.props.user.hq_current_user.userId !== prevProps.user.hq_current_user.userId ||
      this.props.isAuthenticated !== prevProps.isAuthenticated ||
      this.props.selectedSport.id !== prevProps.selectedSport.id ||
      this.props.selectedDivision.id !== prevProps.selectedDivision.id ||
      this.props.selectedSeason.id !== prevProps.selectedSeason.id
    ) {
      await this.refresh_data_sources();
    }
  }

  refresh_data_sources = async () => {
    if (this.props.auth0jwt && this.props.selectedDivision.id && this.props.selectedSport.id && this.props.selectedSeason.id) {
      let [minDateValue, maxDateValue] = getMinMaxDateValues(this.props.selectedSport.name, this.props.selectedSeason.name);
      let { data: table } = await get_user_defined_toutnaments(this.props.auth0jwt, this.props.selectedDivision.id, this.props.selectedSport.id, this.props.selectedSeason.id);

      let { data: scoreCard } = await get_user_defined_toutnament_score_card(
        this.props.auth0jwt,
        this.props.selectedDivision.id,
        this.props.selectedSport.id,
        this.props.selectedSeason.id
      );

      let tournamentNameToId = this.GetNameToIdMap(table);

      this.setState({
        table: table,
        scoreCard: scoreCard,
        minDateValue: minDateValue,
        maxDateValue: maxDateValue,
        tournamentNameToId: tournamentNameToId,
      });
    }
  };

  async onClickRefresh(e) {
    notify(`Refreshing table`);
    await this.refresh_data_sources();
  }

  getNewScoreCard = async () => {
    let { data: scoreCard } = await get_user_defined_toutnament_score_card(
      this.props.auth0jwt,
      this.props.selectedDivision.id,
      this.props.selectedSport.id,
      this.props.selectedSeason.id
    );
    this.setState({ scoreCard: scoreCard });
  };

  onInitNewRow = async (e) => {
    e.promise = this.checkTokenLocal();

    e.data.divisionId = this.props.selectedDivision.id;
    e.data.displaySportId = this.props.selectedSport.id;
    e.data.seasonId = this.props.selectedSeason.id;

    e.data.tournamentId = null;
    e.data.userId = this.props.user.hq_current_user.userId;
    e.data.userName = this.props.user.email;
    e.data.sub = this.props.user.sub;
    e.data.lastUpdate = new Date().toISOString();
    e.data.tournamentGameTypeId=isConferenceEnum.NonConference
  };

  onRowInserting = async (e) => {
    e.cancel = this.checkTokenLocal();
  };

  onRowInserted = async (e) => {
    console.log("inserted row");
    await this.update_or_insert_tournament_manager(e);
    await this.getNewScoreCard();
  };

  onRowUpdating = async (e) => {
    e.cancel = this.checkTokenLocal();
  };

  checkTokenLocal = async (e) => {
    return await this.props.checkToken("Tournament Manager");
  };

  onRowUpdated = async (e) => {
    console.log("updated row");
    await this.update_or_insert_tournament_manager(e);
    await this.getNewScoreCard();
  };

  update_or_insert_tournament_manager = async (e) => {
    let deep_copy = JSON.parse(JSON.stringify(e.data));
    deep_copy.userId = this.props.user.hq_current_user.userId;
    deep_copy.sub = this.props.user.sub;
    deep_copy.userName = this.props.user.email;

    deep_copy.lastUpdate = utc2db(new Date().toISOString());
    if (isString(deep_copy.startDate)) {
      deep_copy.startDate = utc2db(deep_copy.startDate);
    } else if (deep_copy.startDate) {
      deep_copy.startDate = utc2db(deep_copy.startDate.toISOString());
    }

    if (isString(deep_copy.endDate)) {
      deep_copy.endDate = utc2db(deep_copy.endDate);
    } else if (deep_copy.endDate) {
      deep_copy.endDate = utc2db(deep_copy.endDate.toISOString());
    }

    try {
      let res = null;
      if (deep_copy.tournamentId) {
        res = await patch_user_defined_tournament(this.props.auth0jwt, deep_copy);
      } else {
        res = await put_user_defined_tournament(this.props.auth0jwt, deep_copy);

        e.data.tournamentId = res.data.tournamentId;
      }

      if (res.status === 200) {
        toast.success(`Successfully added tournament!`);
      } else {
        toast.error(`Could not add tournament!`);
      }
    } catch (error) {
      toast.error(`Could not add tournament!`);
      console.log(error);
    }
  };

  async onRowRemovingCancel(tournament_name, divisionId, displaySportId) {
    const cancel_due_to_token = await this.props.checkToken("Tournament Manager");
    if (cancel_due_to_token) {
      return true;
    }

    const usedRes = await get_is_tournament_used(this.props.auth0jwt, tournament_name, divisionId, displaySportId);

    if (usedRes.data[0].isUsed) {
      toast.error(`Cannot delete [${tournament_name}] because a game references it`);
      return true;
    } else {
      return false;
    }
  }

  onRowRemoving(e) {
    e.cancel = this.onRowRemovingCancel(e.data.name, e.data.divisionId, e.data.displaySportId);
  }

  onRowRemoved = async (e) => {
    console.log("removed row");

    let deep_copy = JSON.parse(JSON.stringify(e.data));
    deep_copy.userId = this.props.user.hq_current_user.userId;
    deep_copy.sub = this.props.user.sub;

    try {
      var res = await delete_user_defined_tournament(this.props.auth0jwt, deep_copy);
      if (res.status === 200) {
        toast.success(`Successfully removed [${deep_copy.name}] tournament!`);
      } else {
        toast.error(`[${deep_copy.name}] tournament could not be removed`);
      }
    } catch (error) {
      toast.error(`[${deep_copy.name}] tournament could not be removed`);
      console.log(error);
    }
    await this.getNewScoreCard();
  };

  validateEndDateLaterThanStartDate(e) {
    const start = e.data.startDate && isString(e.data.startDate) ? parse(e.data.startDate, time_format.utc) : e.data.startDate;
    const end = e.value && isString(e.value) ? parse(e.value, time_format.utc) : e.value;
    return end >= start;
  }

  onEditorPreparing(e) {
    if (e.dataField === "startDate" || e.dataField === "endDate") {
      e.editorOptions.min = this.state.minDateValue;
      e.editorOptions.max = this.state.maxDateValue;
    }
  }

  render() {
    return (
      <Fragment>
        <Card className="main-card my-2 mx-2 border border-primary rounded">
          <CardHeader className="card-header-tab">
            <Tabs dataSource={tabs} selectedIndex={this.state.selectedIndex} onOptionChanged={this.onTabsSelectionChanged} />

            <div style={{ float: "left" }} className="card-header-title thick mt-3">
              {tabs[this.state.selectedIndex].title}
            </div>
            <div style={{ float: "right" }} className="mt-3">
              <Button icon="refresh" hint="Refesh Table" type="back" stylingMode="contained" onClick={this.onClickRefresh} />
            </div>
          </CardHeader>

          <CardBody className="pt-1">
            <Row>
              <Col xs="12">
                {this.state.selectedIndex === 1 && (
                  <DataGrid
                    width={"100%"}
                    columnAutoWidth={true}
                    dataSource={this.state.table}
                    allowColumnReordering={true}
                    wordWrapEnabled={false}
                    showBorders={false}
                    showColumnLines={false}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    allowSearch={true}
                    onInitNewRow={this.onInitNewRow}
                    onRowInserted={this.onRowInserted}
                    onRowInserting={this.onRowInserting}
                    onRowUpdated={this.onRowUpdated}
                    onRowUpdating={this.onRowUpdating}
                    onRowRemoved={this.onRowRemoved}
                    onRowRemoving={this.onRowRemoving}
                    onEditorPreparing={this.onEditorPreparing}
                    selection={{ mode: "multiple", showCheckBoxesMode: "none" }}
                    allowColumnResizing={false}
                    columnResizingMode={"widget"}
                  >
                    <SearchPanel visible={true} />
                    <Export enabled={true} fileName={"KPI-Tournament-Manager"} />
                    <Paging defaultPageSize={100} />
                    <Pager showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />
                    <Column dataField="name" caption="Tournament Name" dataType="string" fixed={true} fixedPosition="left" headerCellRender={renderTitleHeader}>
                      <RequiredRule message="Tournament name is required" />
                      <StringLengthRule min={5} max={124} message="Name must be 5-124 characters" />
                    </Column>
                    <Column dataField="formatId" caption="Format" headerCellRender={renderTitleHeader}>
                      <Lookup dataSource={tournamentFormatId} displayExpr="name" valueExpr="id" />
                      <RequiredRule message="Format is required" />
                    </Column>

                    <Column dataField="startDate" caption="Start date" dataType="date" headerCellRender={renderTitleHeader}>
                      {/* //#  capacity, lastUpdate, startDate, endDate, style */}
                      <RequiredRule message="Start date is required" />
                    </Column>
                    <Column dataField="endDate" caption="End date" dataType="date" sortOrder="asc" headerCellRender={renderTitleHeader}>
                      <RequiredRule message="End date is required" />
                      <CustomRule validationCallback={this.validateEndDateLaterThanStartDate} message="Start date is after End date" />
                    </Column>
                    <Column dataField="capacity" caption="Tournament Capacity" dataType="number" alignment="center" headerCellRender={renderTitleHeader}>
                      <RequiredRule message="Tournament capacity is required" />
                      <NumericRule message="Tournament capacity must be a number" />
                      <RangeRule message="Tournament capacity must be at least 2" min={2} />
                    </Column>
                    <Column dataField="tournamentGameTypeId" dataType="number" caption="Game Type" headerCellRender={renderTitleHeader}>
                      <Lookup dataSource={isConference} displayExpr="name" valueExpr="id" />
                      <RequiredRule message="Game type selection is required" />
                    </Column>
                    <Column dataField="note" caption="Note" dataType="string" headerCellRender={renderTitleHeader}>
                      <FormItem colSpan={2} editorType="dxTextArea" />
                    </Column>
                    <Column dataField="userName" caption="Entered by" allowEditing={false} headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="lastUpdate" caption="Last update" dataType="datetime" allowEditing={false} headerCellRender={renderTitleHeader}></Column>

                    <Editing mode="popup" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true}>
                      <Popup title="Tournament Entry" showTitle={true}>
                        <Position my="center" at="center" of={window} />
                      </Popup>
                    </Editing>
                  </DataGrid>
                )}
                {this.state.selectedIndex === 0 && (
                  <DataGrid
                    width={"100%"}
                    columnAutoWidth={true}
                    dataSource={this.state.scoreCard}
                    allowColumnReordering={true}
                    wordWrapEnabled={false}
                    showBorders={false}
                    showColumnLines={false}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    allowSearch={true}
                    selection={{ mode: "multiple", showCheckBoxesMode: "none" }}
                    allowColumnResizing={false}
                    columnResizingMode={"widget"}
                  >
                    <SearchPanel visible={true} />
                    <Export enabled={true} fileName={"KPI-Tournament-Score-Card"} />
                    <Paging defaultPageSize={100} />
                    <Pager showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />
                    <FilterRow visible={true} />
                    <FilterPanel visible={true} />

                    {/* <StateStoring enabled={true} type="localStorage" storageKey={"tournament-score-card"} /> */}
                    {/* <ColumnChooser enabled={true} /> */}
                    <ColumnFixing enabled={true} />
                    <Column dataField="tournamentName" caption="Tournament Name" fixed={true} fixedPosition="left" headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="formatId" caption="Format" headerCellRender={renderTitleHeader}>
                      <Lookup dataSource={tournamentFormatId} displayExpr="name" valueExpr="id" />
                    </Column>
                    <Column dataField="startDate" caption="Start date" dataType="date" headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="endDate" caption="End date" dataType="date" sortOrder="asc" headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="capacity" caption="Tournament capacity" dataType="number" alignment="center" headerCellRender={renderTitleHeader}></Column>
                    <Column
                      caption="Team Count"
                      dataType="number"
                      calculateCellValue={(data) => (data && data.teams && data.teams.split(",").length) || 0}
                      alignment="center"
                      headerCellRender={renderTitleHeader}
                    ></Column>
                    <Column dataField="teams" caption="Teams" dataType="string" headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="location" caption="Locations" dataType="string" headerCellRender={renderTitleHeader}></Column>
                  </DataGrid>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default TournamentManager;
