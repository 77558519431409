export const pager_lengths = [50, 100, 200, 400, "all"];
export const time_format = { db: "YYYY-MM-DD HH:mm:ss", utc: "YYYY-MM-DDTHH:mm:ssZ", yyymmdd: "YYYY-MM-DD" };

export const display_sports_needing_regions = new Set([24, 25, 47, 48, 66, 7, 8, 5, 33, 34, 50]);

export const blocked_for_dic = {
  1: "Away",
  2: "Home",
  3: "Home, Away, & Neutral",
};

export const blockedForId_lookup = [
  {
    id: 1,
    name: "Away",
  },
  {
    id: 2,
    name: "Home",
  },
  {
    id: 3,
    name: "All (Home, Away, & Neutral)",
  },
];

export const timeBlockType_lookup = [
  { id: 1, name: "Morning" },
  { id: 2, name: "Afternoon" },
  { id: 3, name: "All Day" },
];

export const dateTypeId_color_dict = {
  1: "#e6ffe6",
  2: "#fcfbd7",
  3: "#ffe9ef",
  4: "#e6f2ff",
  5: "",
};

export const dateTypeId_lookup = [
  {
    id: 1,
    name: "Priority",
    text: "Priority",
    color: dateTypeId_color_dict[1],
  },
  {
    id: 2,
    name: "Open",
    text: "Open",
    color: dateTypeId_color_dict[2],
  },
  {
    id: 3,
    name: "Blocked",
    text: "Conference",
    color: dateTypeId_color_dict[3],
  },
];

export const dateTypeId_dict = {
  1: "Priority",
  2: "Open",
  3: "Blocked",
};

export const is_conference_lookup = [
  {
    id: -1,
    name: "All",
  },
  {
    id: 0,
    name: "Conf",
  },
  {
    id: 1,
    name: "Non-Conf",
  },
];

export const countries = [
  "U.S.A.",
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa (U.S.A.)",
  "Andorra",
  "Angola",
  "Anguilla (United Kingdom)",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba (Netherlands)",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda (United Kingdom)",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands (United Kingdom)",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Caribbean Netherlands (Netherlands)",
  "Cayman Islands (United Kingdom)",
  "Central African Republic",
  "Chad",
  "Channel Islands (United Kingdom)",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands (New Zealand)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao (Netherlands)",
  "Cyprus",
  "Czech Republic",
  "DR Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "F.S. Micronesia",
  "Falkland Islands (United Kingdom)",
  "Faroe Islands (Denmark)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana (France)",
  "French Polynesia (France)",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar (United Kingdom)",
  "Greece",
  "Greenland (Denmark)",
  "Grenada",
  "Guadeloupe (France)",
  "Guam (U.S.A.)",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong (China)",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man (United Kingdom)",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau (China)",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique (France)",
  "Mauritania",
  "Mauritius",
  "Mayotte (France)",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat (United Kingdom)",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia (France)",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue (New Zealand)",
  "North Korea",
  "North Macedonia",
  "Northern Mariana Islands (U.S.A.)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico (U.S.A.)",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Réunion (France)",
  "Saint Helena (United Kingdom)",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon (France)",
  "Saint Vincent and the Grenadines",
  "San Marino",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Netherlands)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "São Tomé and Príncipe",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tokelau (New Zealand)",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (United Kingdom)",
  "Tuvalu",
  "U.S. Virgin Islands (U.S.A.)",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "U.S.A.",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Wallis and Futuna (France)",
  "Western Sahara",
  "Western Samoa",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const american_states = [
  "<TBD>",
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "<NA>",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const views = [
  // { does not look right
  //   type: "agenda",
  //   name: "agenda"
  // },
  {
    type: "day",
    name: "day",
  },
  {
    type: "week",
    name: "week",
    maxAppointmentsPerCell: "unlimited",
  },
  {
    type: "month",
    name: "month",
    maxAppointmentsPerCell: "unlimited",
  },
  { name: "time-line", type: "timelineMonth", intervalCount: 1, maxAppointmentsPerCell: "unlimited" },
];

export const siteIdDict = {
  1: "vs.",
  2: "@",
  3: "(N)",
  4: "",
};

export const siteIdColorDict = {
  1: "#e6ffe6",
  2: "#ffe9ef",
  3: "#e6f2ff",
  4: "",
};

export const siteNames = ["H", "A", "N"];

export const tournamentFormatId = [
  { id: 1, name: "Bracketed" },
  { id: 2, name: "Straight Round Robin" },
  { id: 3, name: "Semi-Round Robin" },
  { id: 4, name: "Single Elimination" },
  { id: 5, name: "Double Elimination" },
  { id: 6, name: "Custom" },
];

export const siteId = [
  { id: 1, name: "H" },
  { id: 2, name: "A" },
  { id: 3, name: "N" },
  { id: 4, name: "" },
];

export const siteIdWithoutBlank = [
  { id: 1, name: "H" },
  { id: 2, name: "A" },
  { id: 3, name: "N" },
];

export const siteIdToNameDict = { 1: "H", 2: "A", 3: "N", 4: "" };

export const winLossTie = [
  { id: 1, name: "W" },
  { id: 2, name: "L" },
  { id: 3, name: "T" },
  { id: 4, name: "-" },
];

export const winLossTieIdToNameDict = {
  1: "W",
  2: "L",
  3: "T",
  4: "-",
};

export const blockedForIdEnum = {
  1: "Away",
  2: "Home",
  3: "All (H/A/N)",
};

export const isConferenceEnum = { Conference: 1, NonConference: 2, CTRN: 4, MTE: 8, Exhibition: 16, PostSeason: 32 };
export const isConferenceDict = { 1: "Conf", 2: "Non-Conf", 4: "Conf Tournament", 8: "MTE", 16: "Exhibition", 32: "Post Season" };

export const isConference = [
  {
    id: isConferenceEnum.Conference,
    text: "Conference",
    name: isConferenceDict[isConferenceEnum.Conference],
    color: "#BD3D3A",
  },
  {
    id: isConferenceEnum.NonConference,
    text: "Non-Conference",
    name: isConferenceDict[isConferenceEnum.NonConference],
    color: "#3F69AA",
  },
  {
    id: isConferenceEnum.CTRN,
    text: "Conference Tournament",
    name: isConferenceDict[isConferenceEnum.CTRN],
    color: "#9A6324",
  },
  {
    id: isConferenceEnum.MTE,
    text: "MTE",
    name: isConferenceDict[isConferenceEnum.MTE],
    color: "#f58231",
  },
  {
    id: isConferenceEnum.Exhibition,
    text: "Exhibition",
    name: isConferenceDict[isConferenceEnum.Exhibition],
    color: "#911eb4",
  },
  {
    id: isConferenceEnum.PostSeason,
    text: "Post Season",
    name: isConferenceDict[isConferenceEnum.PostSeason],
    color: "#800000",
  },
];

export const computeSandboxFrom = [
  { id: 0, name: "Current Season" },
  { id: 1, name: "Previous Season" },
  { id: 2, name: "Previous 2-Season Averages" },
  { id: 3, name: "Previous 3-Season Averages" },
];
