import { get_game_count_limits_for_sport, get_computed_game_counts_for_sport } from "./privateService";

export class GameCountService {
  constructor(token, division_id, display_sport_id, conference_id, season_id) {
    this.token = token;
    this.division_id = division_id;
    this.display_sport_id = display_sport_id;
    this.conference_id = conference_id;
    this.season_id = season_id;
  }

  async Run(team_id_to_conference_id_map) {
    const team_id_to_game_counts = new Map();
    if (!(this.token && this.division_id > 0 && this.display_sport_id > 0 && this.conference_id >= 0 && this.season_id > 0)) {
      return team_id_to_game_counts;
    }

    if (!team_id_to_conference_id_map) {
      return team_id_to_game_counts;
    }

    const { data: game_counts_limits } = await get_game_count_limits_for_sport(
      this.token,
      this.division_id,
      this.display_sport_id,
      this.conference_id,
      this.season_id
    );

    const conference_id_to_game_count_limits = await GameCountService.make_game_count_limits_map(game_counts_limits);

    const { data: computed_game_counts } = await get_computed_game_counts_for_sport(
      this.token,
      this.division_id,
      this.display_sport_id,
      this.conference_id,
      this.season_id
    );

    for (let index = 0; index < computed_game_counts.length; index++) {
      const element = computed_game_counts[index];
      const conference_id_for_team = team_id_to_conference_id_map.get(element.team1Id) || 0;
      if (conference_id_for_team === 0) {
        continue;
      }
      const limits_for_conference = conference_id_to_game_count_limits.get(conference_id_for_team);

      if (!limits_for_conference) {
        continue;
      }

      team_id_to_game_counts.set(element.team1Id, {
        ...element,
        ...limits_for_conference,
        remainingGameCount: limits_for_conference.sportMax - element.CTRNCount - element.ConferenceCount - element.NonConferenceCount - element.MTECount,
      });
    }
    return team_id_to_game_counts;
  }

  static async make_game_count_limits_map(game_counts_limits) {
    return new Promise(function (resolve, reject) {
      const conference_id_to_limits = new Map();
      try {
        if (!Array.isArray(game_counts_limits)) {
          return conference_id_to_limits;
        }

        for (let index = 0; index < game_counts_limits.length; index++) {
          const element = game_counts_limits[index];
          if (!element.conferenceId) {
            continue;
          }
          conference_id_to_limits.set(element.conferenceId, element);
        }
        resolve(conference_id_to_limits);
      } catch (error) {
        console.log(error);
        reject(conference_id_to_limits);
      }
    });
  }
}
