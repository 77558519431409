import React from "react";
import { Link } from "react-router-dom";
import { getTok } from "./../utils/common-funcs";

const logo_height = 20;
export function teamLogoCellRender(props) {
  return <img height={logo_height} width={logo_height} src={`/logos_svg/${props.data.teamId}.svg`} alt={props.data.teamName} />;
}

export function conferenceLogoCellRender(props) {
  return <img height={logo_height} width={logo_height} src={`/conference_logos_svg/${props.data.conferenceId}.svg`} alt={props.data.conferenceName} />;
}

export function customNumberSortingMethod(a, b) {
  if (!a || !b) {
    return -1;
  }
   
  return Number(a) > Number(b) ? 1 : -1;
}

export function teamLinkCellRender(props) {
  return (
    <Link
      target="_blank"
      to={{
        pathname: `/resume/`,
        search: `?team=${props.data.teamName}&v1=${props.data.divisionId}&v2=${props.data.displaySportId}&v3=${props.data.seasonId}&v4=${
          props.data.teamId
        }&v5=${getTok()}`,
        state: { x: true },
      }}
    >
      {props.data.teamName}
    </Link>
  );
}

export function conferenceLinkCellRender(props) {
  return (
    <Link
      target="_blank"
      to={{
        pathname: `/conferenceKPI/`,
        search: `?conferenceName=${props.data.conferenceName}&v1=${props.data.divisionId}&v2=${props.data.displaySportId}&v3=${props.data.seasonId}&v4=${props.data.teamId}&v5=${props.data.conferenceId}&v6=${getTok()}`,
        state: { x: true },
      }}
    >
      {props.data.conferenceName}
    </Link>
  );
}


export function team1LinkCellRender(props) {
  return (
    <Link
      target="_blank"
      to={{
        pathname: `/resume/`,
        search: `?team=${props.data.team1Name}&v1=${props.data.divisionId}&v2=${props.data.displaySportId}&v3=${props.data.seasonId}&v4=${
          props.data.team1Id
        }&v5=${getTok()}`,
        state: { x: true },
      }}
    >
      {props.data.team1Name}
    </Link>
  );
}

export function team2LinkCellRender(props) {
  return (
    <Link
      target="_blank"
      to={{
        pathname: `/resume/`,
        search: `?team=${props.data.team2Name}&v1=${props.data.divisionId}&v2=${props.data.displaySportId}&v3=${props.data.seasonId}&v4=${
          props.data.team2Id
        }&v5=${getTok()}`,
        state: { x: true },
      }}
    >
      {props.data.team2Name}
    </Link>
  );
}

export const renderTitleHeader = (data) => {
  return <p style={{ fontSize: "14px", fontWeight: "bold", color: "black" }}>{data.column.caption}</p>;
};

export function make_ranking_table_columns(logo_height = 80, conferences) {
  const default_columns = [
    {
      Header: "KPI",
      accessor: "KPI",
      id: 1,
      minWidth: 60,
      Cell: (props) => {
        return <div className="kpi-resume-cell-right">{Math.round(props.original.KPI)}</div>;
      },
      filterMethod: (filter, row) => {
        const id = filter.pivotId || filter.id;
        const rowid = row[id];
        if (rowid === undefined) {
          return true;
        }

        // const colon_index = filter.value.indexOf(":");
        // if (colon_index > 0) {
        //   const start = Number(filter.value.slice(0, colon_index) || 0);
        //   const end = Number(filter.value.slice(colon_index + 1) || 1000);
        //   return Number(rowid) >= start;
        // }
        if (filter.value.startsWith("<")) {
          const comp = Number(filter.value.slice(1));
          return Number(rowid) < comp;
        }
        if (filter.value.startsWith(">")) {
          const comp = Number(filter.value.slice(1));
          return Number(rowid) > comp;
        }
        return String(rowid).startsWith(filter.value);
      },
    },
    {
      Header: "Logo",
      accessor: "teamName",
      id: 3,
      minWidth: Math.max(80, 1.5 * logo_height),
      className: "resume-rankings-sticky",
      headerClassName: "resume-rankings-sticky",
      Cell: (props) => {
        return (
          <div className="kpi-resume-cell-center">
            <img height={logo_height} width={logo_height} src={`logos_svg/${props.original.teamId}.svg`} alt={props.original.teamName} />
          </div>
        );
      },
    },
    {
      Header: "Team",
      accessor: "teamName",
      id: 2,
      minWidth: 160,
      Cell: (props) => (
        <Link
          target="_blank"
          to={{
            pathname: `/resume/`,
            search: `?team=${props.original.teamName}&v1=${props.original.divisionId}&v2=${props.original.displaySportId}&v3=${props.original.seasonId}&v4=${props.original.teamId}`,
            state: { x: true },
          }}
        >
          {props.original.teamName}
        </Link>
      ),
      filterMethod: (filter, row) => {
        const id = filter.pivotId || filter.id;
        const rowid = row[id];
        return rowid !== undefined ? String(rowid.toLowerCase()).startsWith(filter.value.toLowerCase()) : true;
      },
    },
    {
      Header: "Conf Logo",
      accessor: "conferenceName",
      id: 5,
      minWidth: Math.max(80, 1.5 * logo_height),
      Cell: (props) => {
        return (
          <div className="kpi-resume-cell-center">
            <img height={logo_height} width={logo_height} src={`conference_logos_svg/${props.original.conferenceId}.svg`} alt={props.original.conferenceName} />
          </div>
        );
      },
    },
    {
      Header: "Conf",
      accessor: "conferenceName",
      id: 4,
      minWidth: 120,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        return row[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
            <option value="all">Show All</option>
            {conferences.map((x) => (
              <option key={x.name} value={x.name}>
                {x.name}
              </option>
            ))}
          </select>
        );
      },
    },
    {
      Header: "KPI #",
      accessor: "avgKPI",
      id: 6,
      minWidth: 60,
      Cell: (props) => <div className="kpi-resume-cell-center">{props.original.avgKPI && props.original.avgKPI.toFixed(3)}</div>,
    },
    {
      Header: "W/L",
      accessor: "W",
      id: 7,
      minWidth: 60,
      Cell: (props) => <div className="kpi-resume-cell-center">{props.original.W + "-" + props.original.L}</div>,
    },
    {
      Header: "W/L Conf",
      accessor: "confW",
      id: 8,
      minWidth: 60,
      Cell: (props) => <div className="kpi-resume-cell-center">{props.original.confW + "-" + (props.original.confTotal - props.original.confW)}</div>,
    },
    {
      Header: "W/L Home",
      accessor: "homeW",
      id: 9,
      minWidth: 60,
      Cell: (props) => <div className="kpi-resume-cell-center">{props.original.homeW + "-" + (props.original.homeTotal - props.original.homeW)}</div>,
    },
    {
      Header: "W/L AN ",
      accessor: "awayW",
      id: 10,
      minWidth: 60,
      Cell: (props) => (
        <div className="kpi-resume-cell-center">{props.original.awayW + "-" + (props.original.L - (props.original.homeTotal - props.original.homeW))}</div>
      ),
    },
    {
      Header: "High Game",
      accessor: "highGame",
      id: 11,
      minWidth: 190,
    },
    {
      Header: "Low Game",
      accessor: "lowGame",
      id: 12,
      minWidth: 190,
    },
    {
      Header: "AQ",
      accessor: "aq",
      id: 15,
      minWidth: 60,
      Cell: (props) => <div className="kpi-resume-cell-center">{props.original.aq}</div>,
    },
    {
      Header: "Seed",
      accessor: "seed",
      id: 14,
      minWidth: 80,
      Cell: (props) => <div className="kpi-resume-cell-center">{props.original.seed}</div>,
    },
    {
      Header: "SOS",
      accessor: "sos",
      id: 13,
      minWidth: 60,
      Cell: (props) => <div className="kpi-resume-cell-center">{props.original.sos && props.original.sos.toFixed(3)}</div>,
    },
    {
      Header: "SOS Rk",
      accessor: "sosRank",
      id: 16,
      minWidth: 60,
      Cell: (props) => <div className="kpi-resume-cell-right">{props.original.sosRank}</div>,
    },
    {
      Header: "DIFFs",
      accessor: "diffs",
      id: 17,
      minWidth: 80,
    },
    {
      Header: "O-Diffs",
      accessor: "diffsOff",
      id: 18,
      minWidth: 80,
    },
    {
      Header: "D-DIFFs",
      accessor: "diffsDef",
      id: 19,
      minWidth: 80,
    },
  ];

  return default_columns;
}
