import { get_resume_for_division_sport_season_team, make_session_key_with_ddst } from "../services/publicService";

export async function get_resume_table_values(keys, force, auth0jwt) {
  const session_storage_key = make_session_key_with_ddst(keys.division_id, keys.display_sport_id, keys.season_id, keys.team_id);

  let cachedResults = null;
  if (!force) {
    cachedResults = sessionStorage.getItem(session_storage_key);
  }

  let resume = [];
  if (cachedResults) {
    console.log("resume from cache");
    resume = JSON.parse(cachedResults);
  } else {
    const res = await get_resume_for_division_sport_season_team(keys.division_id, keys.display_sport_id, keys.season_id, keys.team_id, auth0jwt);
    resume = res.data;
    console.log("resume from db");
    if (!force) {
      sessionStorage.setItem(session_storage_key, JSON.stringify(resume));
    }
  }
  return resume;
}
