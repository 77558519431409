import React, { Component } from "react";
import { format } from "fecha";
import DataGrid, { Column, HeaderFilter, Export, StateStoring, ColumnChooser, ColumnFixing, FilterRow, FilterPanel, Paging, Pager, Sorting } from "devextreme-react/data-grid";
import { pager_lengths } from "./../utils/enums";
import { teamLogoCellRender, conferenceLogoCellRender, renderTitleHeader, customNumberSortingMethod, teamLinkCellRender, conferenceLinkCellRender } from "./home-rankings-utils";

class ResumeRankingsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      
    };
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    
  }

  onCalculateSortValueWinLoss(win, loss) {
    if (win && loss) {
      return win / (loss + win);
    }
    if (win) {
      return win;
    }
    return -1 * loss;
  }

  is_conference_kpi = () => {
    return this.props.page_type === 1;
  };

  is_global_kpi = () => {
    return !this.is_conference_kpi();
  };

  render() {
    return (
      <DataGrid
        dataSource={this.props.homeKpiTableData}
        showBorders={true}
        rowAlternationEnabled
        columnAutoWidth={true}
        allowColumnReordering={true}
        wordWrapEnabled={false}
        selection={{ mode: "multiple", showCheckBoxesMode: "none" }}
        keyExpr="teamId"
      >
        <Sorting mode="multiple" />
        <Export enabled={true} fileName={`${this.props.xls_export_file_name}_${format(new Date(), "YYYY_MM_DD_hh_mm_ss_A")}`} />
        {/* <Scrolling columnRenderingMode="virtual" /> */}
        {/* <Scrolling mode="infinite" /> */}
        {/* <Scrolling mode="virtual" rowRenderingMode="virtual" /> */}
        <Paging defaultPageSize={50} />
        <Pager visible={true} showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />
        <FilterRow visible={true} />
        <FilterPanel visible={true} />
        <HeaderFilter visible={true} />
        <StateStoring enabled={true} type="localStorage" storageKey={`KpiRankingsTableDx_${this.props.page_type}_0003`} />
        <ColumnChooser enabled={true} />
        <ColumnFixing enabled={true} />
        {this.is_global_kpi() && (
          <Column
            caption="KPI"
            dataField="KPI"
            dataType="number"
            minWidth={60}
            alignment="right"
            calculateCellValue={(data) => (data && data.avgKPI ? Math.round(data.KPI) : 1)}
            fixed={true}
            fixedPosition="left"
            headerCellRender={renderTitleHeader}
          />
        )}
        {this.is_conference_kpi() && (
          <Column
            caption="Conf KPI Rank"
            dataField="confKPI0Rank"
            dataType="number"
            minWidth={60}
            alignment="center"
            headerCellRender={renderTitleHeader}
            allowHeaderFiltering={false}
            sortingMethod={customNumberSortingMethod}
            allowSorting={true}
            fixed={true}
            fixedPosition="left"
          />
        )}

        <Column
          caption="Logo"
          dataField="teamLogo"
          dataType="object"
          minWidth={60}
          cellRender={teamLogoCellRender}
          allowSorting={false}
          alignment="center"
          fixed={true}
          fixedPosition="left"
          headerCellRender={renderTitleHeader}
          allowHeaderFiltering={false}
          allowFiltering={false}
        />
        <Column caption="Team" dataField="teamName" minWidth={160} headerCellRender={renderTitleHeader} cellRender={teamLinkCellRender} />
        <Column
          caption="Conf Logo"
          dataField="conferenceLogo"
          dataType="object"
          minWidth={60}
          cellRender={conferenceLogoCellRender}
          allowSorting={false}
          alignment="center"
          headerCellRender={renderTitleHeader}
          allowHeaderFiltering={false}
          allowFiltering={false}
        />
        <Column caption="Conf" dataField="conferenceName" minWidth={120} headerCellRender={renderTitleHeader} cellRender={conferenceLinkCellRender} />
        {this.is_conference_kpi() && (
          <Column
            caption="Conf KPI"
            dataField="confKPI0"
            dataType="number"
            minWidth={60}
            alignment="center"
            calculateCellValue={(data) => data && data.confKPI0 && data.confKPI0.toFixed(3)}
            headerCellRender={renderTitleHeader}
            allowHeaderFiltering={false}
            sortingMethod={customNumberSortingMethod}
            allowSorting={true}
            sortIndex={this.props.page_type === 0 ? 1 : 0}
            sortOrder="desc"
          />
        )}
        {this.is_conference_kpi() && (
          <Column
            caption="W/L Conf"
            dataField="confW"
            dataType="number"
            minWidth={60}
            alignment="center"
            calculateCellValue={(data) => (data.confT > 0 ? `${data.confW || 0} - ${data.confL || 0} - ${data.confT}` : `${data.confW || 0} - ${data.confL || 0}`)}
            headerCellRender={renderTitleHeader}
            calculateSortValue={(data) => this.onCalculateSortValueWinLoss(data.confW, data.confTotal - data.confW)}
          />
        )}

        {this.is_conference_kpi() && <Column caption="Conf High Game" dataField="highConfKpiGame" dataType="string" minWidth={190} headerCellRender={renderTitleHeader} />}
        {this.is_conference_kpi() && <Column caption="Conf Low Game" dataField="lowConfKpiGame" dataType="string" minWidth={190} headerCellRender={renderTitleHeader} />}

        {this.is_conference_kpi() && (
          <Column
            caption="KPI"
            dataField="KPI"
            dataType="number"
            minWidth={60}
            alignment="right"
            calculateCellValue={(data) => (data && data.avgKPI ? Math.round(data.KPI) : 1)}
            fixed={false}
            fixedPosition="left"
            headerCellRender={renderTitleHeader}
          />
        )}
        <Column
          caption="KPI #"
          dataField="avgKPI"
          dataType="number"
          minWidth={60}
          alignment="center"
          calculateCellValue={(data) => data && data.avgKPI && data.avgKPI.toFixed(3)}
          headerCellRender={renderTitleHeader}
          allowHeaderFiltering={false}
          sortingMethod={customNumberSortingMethod}
          sortIndex={this.props.page_type === 0 ? 0 : 1}
          sortOrder="desc"
        />
        <Column
          caption="W/L"
          dataField="W"
          dataType="number"
          minWidth={60}
          alignment="center"
          calculateCellValue={(data) => (data.T > 0 ? `${data.W || 0} - ${data.L || 0} - ${data.T}` : `${data.W || 0} - ${data.L || 0}`)}
          headerCellRender={renderTitleHeader}
          calculateSortValue={(data) => this.onCalculateSortValueWinLoss(data.W, data.L)}
        />
        {this.is_global_kpi() && (
          <Column
            caption="W/L Conf"
            dataField="confW"
            dataType="number"
            minWidth={60}
            alignment="center"
            calculateCellValue={(data) => (data.confT > 0 ? `${data.confW || 0} - ${data.confL || 0} - ${data.confT}` : `${data.confW || 0} - ${data.confL || 0}`)}
            headerCellRender={renderTitleHeader}
            calculateSortValue={(data) => this.onCalculateSortValueWinLoss(data.confW, data.confTotal - data.confW)}
          />
        )}

        <Column
          caption="W/L Home"
          dataField="homeW"
          dataType="number"
          minWidth={60}
          alignment="center"
          calculateCellValue={(data) => (data.homeT > 0 ? `${data.homeW || 0} - ${data.homeL || 0} - ${data.homeT}` : `${data.homeW || 0} - ${data.homeL || 0}`)}
          headerCellRender={renderTitleHeader}
          calculateSortValue={(data) => this.onCalculateSortValueWinLoss(data.homeW, data.homeTotal - data.homeW)}
        />
        <Column
          caption="W/L AN "
          dataField="awayW"
          dataType="number"
          minWidth={60}
          alignment="center"
          calculateCellValue={(data) => (data.awayT ? `${data.awayW || 0} - ${data.awayL || 0} - ${data.awayT || 0}` : `${data.awayW || 0} - ${data.awayL || 0}`)}
          headerCellRender={renderTitleHeader}
          calculateSortValue={(data) => this.onCalculateSortValueWinLoss(data.awayW, data.L - (data.homeTotal - data.homeW))}
        />
        <Column caption="High Game" dataField="highGame" dataType="string" minWidth={190} headerCellRender={renderTitleHeader} />
        <Column caption="Low Game" dataField="lowGame" dataType="string" minWidth={190} headerCellRender={renderTitleHeader} />
        {this.props.showRegionsName && <Column caption="Region" dataField="regionsName" dataType="string" minWidth={190} headerCellRender={renderTitleHeader} />}
        {this.props.showRegionsName && <Column caption="High Region" dataField="lowRegionKpiGame" dataType="string" minWidth={190} headerCellRender={renderTitleHeader} />}
        {this.props.showRegionsName && <Column caption="Low Region" dataField="highRegionKpiGame" dataType="string" minWidth={190} headerCellRender={renderTitleHeader} />}
        {this.is_global_kpi() && (
          <Column
            caption="Conf KPI"
            dataField="confKPI0"
            dataType="number"
            minWidth={60}
            alignment="center"
            calculateCellValue={(data) => data && data.confKPI0 && data.confKPI0.toFixed(3)}
            headerCellRender={renderTitleHeader}
            allowHeaderFiltering={false}
            sortingMethod={customNumberSortingMethod}
            allowSorting={true}
            sortIndex={this.props.page_type === 0 ? 1 : 0}
            sortOrder="desc"
          />
        )}
        {this.is_global_kpi() && (
          <Column
            caption="Conf KPI Rank"
            dataField="confKPI0Rank"
            dataType="number"
            minWidth={60}
            alignment="center"
            headerCellRender={renderTitleHeader}
            allowHeaderFiltering={false}
            sortingMethod={customNumberSortingMethod}
            allowSorting={true}
          />
        )}

        <Column caption="Seed" dataField="seed" dataType="string" minWidth={80} alignment="center" headerCellRender={renderTitleHeader} />
        <Column
          caption="SOS"
          dataField="sos"
          dataType="number"
          minWidth={60}
          alignment="center"
          calculateCellValue={(data) => data && data.sos && data.sos.toFixed(3)}
          headerCellRender={renderTitleHeader}
          allowHeaderFiltering={false}
          sortingMethod={customNumberSortingMethod}
        />
        <Column caption="SOS Rk" dataField="sosRank" dataType="number" minWidth={60} alignment="center" headerCellRender={renderTitleHeader} />
        {/*<Column caption="AQ" dataField="aq" dataType="string" minWidth={60} alignment="center" headerCellRender={renderTitleHeader} />
        <Column caption="DIFFs" dataField="diffs" dataType="number" minWidth={80} headerCellRender={renderTitleHeader} allowHeaderFiltering={false} />
        <Column caption="O-Diffs" dataField="diffsOff" dataType="number" minWidth={80} headerCellRender={renderTitleHeader} allowHeaderFiltering={false} />
        <Column caption="D-DIFFs" dataField="diffsDef" dataType="number" minWidth={80} headerCellRender={renderTitleHeader} allowHeaderFiltering={false} allowFiltering={false} />*/}
      </DataGrid>
    );
  }
}

export default ResumeRankingsTable;
