import React, { Component, Fragment } from "react";
import { Row, Col, CardHeader, Card, CardBody } from "reactstrap";
import DataGrid, { Column, Paging, Pager, Lookup, GroupPanel } from "devextreme-react/data-grid";
import { renderTitleHeader } from "../utils/common-rendering-funcs";
import { post_hq_current_user_metadata } from "./../services/privateService";
import { american_states } from "../utils/enums";
import { ToastContainer, toast } from "react-toastify";
import Form, { ButtonItem, GroupItem, SimpleItem, Label, EmailRule, PatternRule, RequiredRule, StringLengthRule, ButtonOptions } from "devextreme-react/form";
import { pager_lengths } from "./../utils/enums";

import "devextreme-react/autocomplete";
import "devextreme-react/text-area";

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = { activate_form_submit: false, form_user_metadata: {} };

    this.stateOptions = {
      items: american_states,
    };
    this.phoneOptions = { mask: "+1 (000) 000-0000" };
    this.notesOptions = { height: 70 };
  }

  populate_from_user_metadata = () => {
    let form_user_metadata = {};
    if (this.props.user && this.props.user.hq_current_user && this.props.user.hq_current_user.user_metadata) {
      let personal_data = {};
      try {
        personal_data = JSON.parse(this.props.user.hq_current_user.user_metadata);
      } catch (error) {
        console.log(this.props.user.hq_current_user.user_metadata);
      }

      form_user_metadata = {
        nameFirst: this.props.user.hq_current_user.nameFirst || "",
        nameLast: this.props.user.hq_current_user.nameLast || "",
        organizationName: this.props.user.hq_current_user.organizationName || "",
        email: this.props.user.email || "",
        Address: personal_data.Address || "",
        State: personal_data.State || "",
        Position: personal_data.Position || "",
        City: personal_data.City || "",
        ZipCode: personal_data.ZipCode || "",
        Phone: personal_data.Phone || "",
        Notes: personal_data.Notes || "",
      };
    } else {
      form_user_metadata = {
        nameFirst: "",
        nameLast: "",
        organizationName: "",
        email: "",
        Address: "",
        State: "",
        Position: "",
        City: "",
        ZipCode: "",
        Phone: "",
        Notes: "",
      };
    }
    return form_user_metadata;
  };

  componentDidMount = async () => {
    const form_user_metadata = this.populate_from_user_metadata();
    this.setState({ activate_form_submit: false, form_user_metadata: form_user_metadata });
  };

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.isAuthenticated !== prevProps.isAuthenticated ||
      this.props.auth0jwt !== prevProps.auth0jwt ||
      (prevProps.user && !this.props.user) ||
      (!prevProps.user && this.props.user) ||
      (this.props.user && prevProps.user && this.props.user.sub !== prevProps.user.sub)
    ) {
      const form_user_metadata = this.populate_from_user_metadata();
      this.setState({ form_user_metadata: form_user_metadata });
    }
  }

  handleSubmit = async (e) => {
    if (this.props.user && this.props.user.sub && this.props.loginApproved) {
      const body = {
        organizationName: this.state.form_user_metadata.organizationName,
        email: this.state.form_user_metadata.email,
        nameFirst: this.state.form_user_metadata.nameFirst,
        nameLast: this.state.form_user_metadata.nameLast,
        user_metadata: {
          Address: this.state.form_user_metadata.Address,
          State: this.state.form_user_metadata.State,
          Position: this.state.form_user_metadata.Position,
          City: this.state.form_user_metadata.City,
          ZipCode: this.state.form_user_metadata.ZipCode,
          Phone: this.state.form_user_metadata.Phone,
          Notes: this.state.form_user_metadata.Notes,
        },
      };

      try {
        await post_hq_current_user_metadata(this.props.auth0jwt, this.props.user.sub, body);

        toast.success("Success");
      } catch (error) {
        toast.error("Could not update Faktor server");
      }

      this.setState({ activate_form_submit: false });
      //e.preventDefault();
    } else {
      toast.error("Please ask a Faktor administrator to approve your account");
    }
  };

  handleOnFieldDataChanged = (e) => {
    if (!this.state.activate_form_submit) {
      this.setState({ activate_form_submit: true });
    }
  };

  render() {
    return (
      <Fragment>
        <ToastContainer />
        <Card className="main-card my-2 mx-2 border border-primary rounded font-weight-bold">
          <CardHeader className="card-header-tab">
            {this.props.user && this.props.user.name && (
              <Row className="align-items-center profile-header my-3 text-center text-md-left">
                <Col md={2}>
                  <img src={this.props.user.picture} alt="Profile" className="rounded-circle img-fluid profile-picture mb-3 mb-md-0" />
                </Col>
                <Col md>
                  <h2>{this.props.user.name}</h2>
                  <p className="lead text-muted">{this.props.user.email}</p>
                </Col>
              </Row>
            )}
          </CardHeader>
          <CardBody className="pt-1">
            {this.props.isAuthenticated && this.props.loginApproved && this.props.userAccessMap.size < 1 && (
              <Row className="px-2 my-2 py-1">
                <Col xs={12}>
                  <div style={{ fontWeight: "bold" }}>Please ask a Faktor administrator to give you access to teams, sports, and divisions.</div>
                </Col>
              </Row>
            )}
            {!this.props.isAuthenticated && (
              <Row className="px-2 my-2 py-1">
                <Col xs={12}>
                  <div style={{ fontWeight: "bold" }}>
                    You do not have access to any KPI analytical tools. Please register, and ask a Faktor administrator to approve your login, to gain access.
                  </div>
                </Col>
              </Row>
            )}
            {this.props.isAuthenticated && !this.props.loginApproved && (
              <Row className="px-2 my-2 py-1">
                <Col xs={12}>
                  <div style={{ fontWeight: "bold" }}>
                    You do not have access to any KPI analytical tools. Please ask a Faktor administrator to approve your registration and give you access to
                    teams, sports, and divisions.
                  </div>
                </Col>
              </Row>
            )}

            {this.props.user && this.props.user.name && (
              <div className="my-4">
                {/* <form action="your-action" onSubmit={this.handleSubmit}> */}
                <Form
                  formData={this.state.form_user_metadata}
                  showValidationSummary={true}
                  showColonAfterLabel={true}
                  onFieldDataChanged={this.handleOnFieldDataChanged}
                >
                  <GroupItem cssClass="first-group" colCount={2}>
                    {/* <SimpleItem render={avatarRender}></SimpleItem> */}
                    <SimpleItem dataField="nameFirst">
                      <Label text="First Name" />
                      <RequiredRule message="First name is required" />
                      <StringLengthRule min={2} message="First name should be at least 2 characters long" />
                      <PatternRule message="Do not use digits in the Name" pattern={/^[^0-9]+$/} />
                    </SimpleItem>
                    <SimpleItem dataField="nameLast">
                      <Label text="Last Name" />
                      <RequiredRule message="Last name is required" />
                      <StringLengthRule min={1} message="Last name should be at least 2 characters long" />
                      <PatternRule message="Do not use digits in the Name" pattern={/^[^0-9]+$/} />
                    </SimpleItem>
                    <SimpleItem dataField="organizationName">
                      <Label text="Organization" />
                      <RequiredRule message="Organization is required" />
                      <StringLengthRule min={2} message="Organization should be at least 4 characters long" />
                    </SimpleItem>

                    <SimpleItem dataField="email">
                      <Label text="Email" />
                      <RequiredRule message="Email is required" />
                      <EmailRule message="Email is invalid" />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem cssClass="second-group" colCount={2}>
                    <GroupItem>
                      <SimpleItem dataField="Address" />

                      <SimpleItem dataField="State" editorType="dxSelectBox" editorOptions={this.stateOptions} />

                      <SimpleItem dataField="Position" />
                    </GroupItem>
                    <GroupItem>
                      <SimpleItem dataField="City" />
                      <SimpleItem dataField="ZipCode" />
                      <SimpleItem dataField="Phone" editorOptions={this.phoneOptions}>
                        <Label text="Phone" />
                      </SimpleItem>
                    </GroupItem>
                    <SimpleItem colSpan={2} dataField="Notes" editorType="dxTextArea" editorOptions={this.notesOptions} />
                  </GroupItem>

                  <GroupItem>
                    <ButtonItem horizontalAlignment="left" colSpan={2}>
                      <ButtonOptions
                        text="Submit / Update"
                        type="danger"
                        useSubmitBehavior={true}
                        visible={this.state.activate_form_submit}
                        onClick={this.handleSubmit}
                      />
                    </ButtonItem>
                  </GroupItem>
                </Form>
                {/* </form> */}
              </div>
            )}

            {this.props.isAuthenticated && this.props.loginApproved && this.props.userAccessMap.size > 0 && (
              <Row className="px-2 py-1">
                <Col xs={12}>
                  <div className="py-2 pm-22" style={{ fontWeight: "bold", color: "#DD4124" }}>
                    Permissions
                  </div>
                  <DataGrid
                    width={"100%"}
                    columnAutoWidth={true}
                    dataSource={Array.from(this.props.userAccessMap.values())}
                    allowColumnReordering={true}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                  >
                    {/* <FilterRow visible={true} /> */}
                    {/* <HeaderFilter visible={true} /> */}
                    {/* <SearchPanel visible={true} width={240} placeholder="Search..." /> */}
                    <GroupPanel visible={true} />
                    <Paging defaultPageSize={100} />
                    <Pager showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />
                    {/* <Editing mode="form" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true} /> */}
                    {/* <Editing mode="row" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true} /> */}
                    <Column dataField="divisionId" caption="Division" minWidth={60} headerCellRender={renderTitleHeader} groupIndex={0}>
                      <Lookup dataSource={this.props.allDivisions} displayExpr="name" valueExpr="id" />
                    </Column>
                    <Column dataField="displaySportId" caption="Sport" minWidth={60} headerCellRender={renderTitleHeader} groupIndex={1}>
                      <Lookup dataSource={this.props.allSports} displayExpr="name" valueExpr="id" />
                    </Column>
                    <Column dataField="teamId" caption="Team" minWidth={60} headerCellRender={renderTitleHeader}>
                      <Lookup dataSource={this.props.allTeams} displayExpr="name" valueExpr="id" />
                    </Column>
                  </DataGrid>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default UserProfile;
