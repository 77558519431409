import React, { Component, Fragment } from "react";
import { Row, Alert, Button } from "reactstrap";

class CoockieWarning extends Component {
  render() {
    return (
      <Fragment>
        {this.props.cookieApproval === "0" && (
          <div>
            <Alert color="info">
              <Row>Our website uses cookies to improve your experience but you can opt-out.</Row>
              <Row className="xs-12">
                <Button outline className="mr-1" size="sm" color="primary" onClick={this.props.acceptOnClick}>
                  Accept
                </Button>
                <Button outline className="ml-1" size="sm" color="danger" onClick={this.props.rejectOnClick}>
                  Opt-out
                </Button>
              </Row>
            </Alert>
          </div>
        )}
      </Fragment>
    );
  }
}

export default CoockieWarning;
