import React, { Component, Fragment } from "react";

import { Row, Col, CardHeader, Card, CardBody } from "reactstrap";
import { get_resume_table_values } from "./home-resume-browser-utils";
import { get_overtime_suffix, infoRowStyleMeta, isDoubleYearSportId, makeDoubleYearSport } from "./../utils/common-funcs";
import { get_game_count_limits_for_sport } from "../services/privateService";
import { get_team_for_division_sport_season, get_resume_for_division_sport_season_team_no_auth, get_seasons, get_sports_for_division_team } from "../services/publicService";
import ResumeTable from "./resume-table";
import notify from "devextreme/ui/notify";
import { Auth0Context } from "../react-auth0-spa";
import ResumeTableHeader from "./resume-table-header";
import { SelectBox } from "devextreme-react";
import { display_sports_needing_regions } from "./../utils/enums";

const queryString = require("query-string");
const default_logo_height = 30;

class Resume extends Component {
  static contextType = Auth0Context;
  constructor(props) {
    super(props);
    this.state = {
      keys: {},
      resume: [],
      logoHeight: default_logo_height,
      tableFontSize: 14,
      overtime_suffix: "",
      game_counts_limits: {},
      team_meta: {},
      auth0jwt: null,
      seasonsWithData: [],
      sportsWithData: [],
      selected_season_id: null,
      selected_display_sport_id: null,
      showRegionsName: false,
    };

    // var i;
    // console.log("local storage");
    // for (i = 0; i < localStorage.length; i++) {
    //   console.log(localStorage.key(i) + "=[" + localStorage.getItem(localStorage.key(i)) + "]");
    // }
    this.onClickRefresh = this.onClickRefresh.bind(this);
  }

  async onClickRefresh(e) {
    notify(`Refreshing table`);
    await this.handleTeamChangeDataLoad();
  }

  async componentDidMount() {
    try {
      const { auth0jwt } = this.context;

      const parsed = queryString.parse(window.location.hash.replace("#/resume/", ""));

      console.log(parsed);

      const selected_display_sport_id = this.state.selected_display_sport_id ? this.state.selected_display_sport_id : parseInt(parsed.v2);
      const selected_season_id = this.state.selected_season_id ? this.state.selected_season_id : parseInt(parsed.v3);

      const keys = {
        team_name: parsed.team,
        division_id: parseInt(parsed.v1),
        display_sport_id: selected_display_sport_id,
        season_id: selected_season_id,
        team_id: parseInt(parsed.v4),
        conference_id: 0,
      };

      let team_meta_qres = await get_team_for_division_sport_season(keys.division_id, keys.display_sport_id, keys.season_id, keys.team_id);
      let team_meta = {};
      if (Array.isArray(team_meta_qres.data) && team_meta_qres.data.length > 0) {
        team_meta = team_meta_qres.data[0];
        keys.conference_id = team_meta.conferenceId;
      }

      const { data: sportsWithData } = await get_sports_for_division_team(keys.division_id, keys.team_id);

      const { data: allSeasons } = await get_seasons();
      const is_double_season_sport = isDoubleYearSportId(keys.display_sport_id);
      const seasonsWithData = allSeasons
        .filter((x) => x.hasData > 0)
        .map(function (z) {
          const new_name = is_double_season_sport ? makeDoubleYearSport(z.name) : z.name;
          return { id: z.id, name: new_name };
        });

      this.setState(
        {
          seasonsWithData: seasonsWithData,
          sportsWithData: sportsWithData,
          auth0jwt: auth0jwt,
          keys: keys,
          selected_season_id: selected_season_id,
          selected_display_sport_id: selected_display_sport_id,
          team_meta: team_meta,
        },
        async () => {
          await this.handleTeamChangeDataLoad(false);
        }
      );
    } catch (error) {}

    await this.handleTeamChangeDataLoad();
  }

  handleTeamChangeDataLoad = async () => {
    try {
      if (this.state.keys.division_id > 0 && this.state.keys.display_sport_id > 0 && this.state.keys.season_id > 0 && this.state.keys.team_id) {
        if (this.state.keys.team_name) {
          document.title = this.state.keys.team_name;
        }

        const overtime_suffix = get_overtime_suffix(this.state.keys.display_sport_id);
        let resume = [];
        if (this.state.auth0jwt) {
          resume = await get_resume_table_values(this.state.keys, true, this.state.auth0jwt);
        } else {
          const qres = await get_resume_for_division_sport_season_team_no_auth(
            this.state.keys.division_id,
            this.state.keys.display_sport_id,
            this.state.keys.season_id,
            this.state.keys.team_id,
            this.state.auth0jwt
          );
          resume = qres.data;
        }

        const { data: game_counts_limits } = await get_game_count_limits_for_sport(
          this.state.auth0jwt,
          this.state.keys.division_id,
          this.state.keys.display_sport_id,
          this.state.keys.conference_id,
          this.state.keys.season_id
        );

        const showRegionsName = display_sports_needing_regions.has(this.state.keys.display_sport_id) && this.state.keys.division_id === 2;

        this.setState({
          resume: resume,
          overtime_suffix: overtime_suffix,
          game_counts_limits: game_counts_limits && game_counts_limits.length > 0 ? game_counts_limits[0] : {},
          showRegionsName: showRegionsName,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleSeasonSelectBoxBoxOnValueChanged = async (event) => {
    if (this.state.selected_season_id != event.value) {
      var updated_keys = { ...this.state.keys };
      updated_keys.season_id = event.value;

      this.setState({ selected_season_id: event.value, keys: updated_keys }, async () => {
        await this.handleTeamChangeDataLoad(false);
      });
    }
  };

  handleSportSelectBoxBoxOnValueChanged = async (event) => {
    if (this.state.selected_display_sport_id != event.value) {
      var updated_keys = { ...this.state.keys };
      updated_keys.display_sport_id = event.value;

      this.setState({ selected_display_sport_id: event.value, keys: updated_keys }, async () => {
        await this.handleTeamChangeDataLoad(false);
      });
    }
  };

  render() {
    const info_row_style = infoRowStyleMeta(this.state.team_meta);
    return (
      <Fragment>
        <Card className="main-card my-2 mx-2 border border-primary rounded">
          <CardHeader className="card-header-tab" style={{ height: "110px" }}>
            <Row xs="12">
              <Col xs="12">
                <ResumeTableHeader
                  keys={this.state.keys}
                  resume={this.state.resume}
                  game_counts_limits={this.state.game_counts_limits}
                  onClickRefresh={this.onClickRefresh}
                  info_row_style={info_row_style}
                  auth0jwt={this.state.auth0jwt}
                />
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row xs={12}>
              <Col xs={6}>
                {!this.state.auth0jwt && <div style={{ color: "red", fontWeight: "bold" }}>Displaying past games only. Please login to see all future games.</div>}
                {this.state.auth0jwt && <div style={{ color: "black", fontWeight: "bold" }}>{this.state.keys.team_name}</div>}
              </Col>
              <Col xs={3}>
                <SelectBox
                  width={"100%"}
                  placeholder={"Season"}
                  items={this.state.sportsWithData}
                  displayExpr="name"
                  valueExpr="id"
                  value={this.state.keys.display_sport_id}
                  onValueChanged={this.handleSportSelectBoxBoxOnValueChanged}
                />
              </Col>
              <Col xs={3}>
                <SelectBox
                  width={"100%"}
                  placeholder={"Season"}
                  items={this.state.seasonsWithData}
                  displayExpr="name"
                  valueExpr="id"
                  value={this.state.keys.season_id}
                  onValueChanged={this.handleSeasonSelectBoxBoxOnValueChanged}
                />
              </Col>
            </Row>
            <Row className="mb-5">
              <Col xs="12">
                <ResumeTable
                  logoHeight={this.state.logoHeight}
                  tableFontSize={this.state.tableFontSize}
                  resume={this.state.resume}
                  addTeam1Columns={false}
                  id={"ResumeTable"}
                  metadata={{ overtime_suffix: this.state.overtime_suffix }}
                  dateDefaultSortIndex={0}
                  rankDefaultSortIndex={1}
                  showKpiRank={false}
                  showKpiPercentile={false}
                  showBroadcaster={true}
                  selectedSport_id={this.state.keys.display_sport_id}
                  showRegionsName={this.state.showRegionsName}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default Resume;
