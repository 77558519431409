import React, { Component, Fragment } from "react";
import { Row, Col, CardHeader, Card, CardBody, Badge } from "reactstrap";
import ResumeUpload from "./resume-upload";

import ActiveSet from "./home-active-set";

import { Auth0Context } from "../react-auth0-spa";

class Scheduling extends Component {
  static contextType = Auth0Context;
  constructor(props) {
    super(props);
    const selections_from_cookie = 0; //props.cookies.get("active_set");
    this.state = {
      selectedDivision: (selections_from_cookie && selections_from_cookie.division) || {},
      selectedSport: (selections_from_cookie && selections_from_cookie.sport) || {},
      selectedSeason: (selections_from_cookie && selections_from_cookie.season) || {},
      selectedTeam: {},
    };
  }

  async componentDidMount() {}

  handleDivisionsSelectChange = (selectedDivision) => {
    if (this.state.selectedDivision.id !== selectedDivision.id) {
      this.setState({ selectedDivision });
    }
  };

  //x
  handleSportsSelectChange = (selectedSport) => {
    if (this.state.selectedSport.id !== selectedSport.id) {
      this.setState({ selectedSport });
    }
  };

  //x
  handleSeasonsSelectChange = (selectedSeason) => {
    if (this.state.selectedSeason.id !== selectedSeason.id) {
      this.setState({ selectedSeason });
    }
  };

  //x
  handleTeamSelectChange = (selectedTeam) => {
    if (this.state.selectedTeam.id !== selectedTeam.id) {
      this.setState({ selectedTeam });
    }
  };

  //x
  sport_covers_two_calendar_years = (option) => {
    if (
      option.name &&
      this.state.selectedSport.name &&
      (this.state.selectedSport.name.includes("Ice Hockey") || this.state.selectedSport.name.includes("Basketball"))
    ) {
      return `${option.name}-${Number(option.name) + 1}`;
    } else {
      return option.name;
    }
  };

  render() {
    return (
      <Fragment>
        <ActiveSet
          divisions={this.props.allDivisions}
          divisionsOnChange={this.handleDivisionsSelectChange}
          divisionsValue={this.state.selectedDivision}
          sports={this.props.allSports}
          sportsOnChange={this.handleSportsSelectChange}
          sportsValue={this.state.selectedSport}
          seasons={this.props.allSeasons}
          seasonsGetOptionLabel={this.sport_covers_two_calendar_years}
          seasonsOnChange={this.handleSeasonsSelectChange}
          seasonsValue={this.state.selectedSeason}
          showTeams={false}
          showDivisions={false}
          teams={this.props.allTeams}
          teamOnChange={this.handleTeamSelectChange}
        />

        {this.props.selectedTab.id === 800 && (
          <Card className="main-card my-2 mx-2 border border-primary rounded">
            <CardHeader className="card-header-tab">
              <div className="card-header-title thick">
                {this.props.selectedTab.name} for: [ {this.state.selectedSport.name} / {this.sport_covers_two_calendar_years(this.state.selectedSeason)} ]
              </div>
            </CardHeader>
            <div style={{ marginLeft: "auto", marginRight: "auto" }}>
              <Badge color="primary">{this.props.dblstupd}</Badge>
            </div>
            <CardBody className="pt-1">
              <Row className="mb-5">
                <Col xs="12">
                  <ResumeUpload selectedSport={this.state.selectedSport} selectedSeason={this.state.selectedSeason} />
                </Col>
              </Row>
              <Row>
                <Col>{`*: ${Intl.DateTimeFormat().resolvedOptions().timeZone} time zone`}</Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Fragment>
    );
  }
}

export default Scheduling;
