import React, { Component, Fragment } from "react";
import { Auth0Context } from "../react-auth0-spa";
import { Row, Col } from "reactstrap";
import {
  get_divisions,
  get_sports,
  get_seasons,
  get_conferences,
  get_teams,
  get_teams_for_sport_season,
  get_division_sport_season,
  make_rakings_session_key,
  get_db_last_update_by_display_sport_id_and_season_id,
  get_rounds,
} from "../services/publicService";
import { alert } from "devextreme/ui/dialog";

import { make_ranking_table_columns } from "./home-rankings-utils";
import { ToastContainer, toast } from "react-toastify";
import { withCookies } from "react-cookie";
import { get_db_last_update } from "./../services/publicService";
import HomeRankingsDx from "./home-rankings-dx";
import HomeResumeBrowser from "./home-resume-browser";
import AllGameValues from "./home-all-game-values";
import ActiveSet from "./home-active-set";
import ViewSelector from "./home-view";
import CoockieWarning from "./home-cookie-warning";
import TeamCalendar from "./home-team-calendar";
import DateManager from "./home-date-manager";
import ResumeSandbox from "./home-resume-sandbox";
import CompareSchedules from "./home-compare-schedules";
import Scheduling from "./scheduling";
import GameScheduleEntry from "./home-game-schedule-entry";
import NewGameScheduleEntries from "./home-new-game-schedule-entries";
import GameSearch from "./home-game-search";
import { parse } from "fecha";
import auth_config from "./../auth_config.json";
import UserProfile from "./home-user-profile";
import AccessRights from "./home-access-rights";
import { GetUserAccessKey, GetUserAccess, isString, isDoubleYearSport, makeDoubleYearSport } from "../utils/common-funcs";
import { patch_hq_current_user_metadata } from "./../services/privateService";
import { time_format } from "./../utils/enums";
import TournamentManager from "./home-tournament-manager";
import KpiCaller from "./home-kpi-caller";

const tab_names = [
  { id: 25, admin: false, name: "User Profile and access level" },
  { id: 100, admin: false, name: "KPI Rankings" },
  { id: 110, admin: false, name: "Team Resumes" },
  { id: 120, admin: false, name: "Top Game Values" },
  { id: 130, admin: false, name: "Team Calendar" },
  { id: 145, admin: false, name: "Scheduling Board/Date Manager" },
  { id: 150, admin: false, name: "Sandbox" },
  { id: 160, admin: false, name: "Compare Schedules" },
  { id: 170, admin: false, name: "Game Schedule Entry" },
  { id: 180, admin: false, name: "Tournament Manager" },
  { id: 190, admin: false, name: "Game & Contact Search" },
  { id: 800, admin: true, name: "Admin: Resume Upload Tools" },
  { id: 810, admin: true, name: "Admin: Grant Access Rights" },
  { id: 815, admin: true, name: "Admin: New Entries" },
  { id: 820, admin: true, name: "Admin: KPI Dialer" },
];

const db_last_update_string_prefix = "Database last updated: ";

class Home extends Component {
  static contextType = Auth0Context;

  constructor(props) {
    super(props);

    const selections_from_cookie = props.cookies.get("active_set");

    const default_logo_height = (selections_from_cookie && selections_from_cookie.logoHeight) || 30;

    this.state = {
      user: {},
      userAccessMap: new Map(),
      applyUserFilter: false,
      allowedDivisionIds: new Set(),
      allowedDisplaySportIds: new Set(),
      allowedTeamIds: new Set(),
      cookie_approval: (selections_from_cookie && selections_from_cookie.cookie_approval) || "0",
      allDivisions: [],
      allSports: [],
      allConferences: [],
      allTeams: [],
      allAllowedTeams: [],
      allSportSeasonTeams: [],
      allSeasons: [],
      divisions: [],
      sports: [],
      seasons: [],
      conferences: [],

      rounds: [],

      homeKpiTableData: [],
      columns: make_ranking_table_columns(default_logo_height, []).slice(0, 19),
      selectedDivision: (selections_from_cookie && selections_from_cookie.division) || {},
      selectedSport: (selections_from_cookie && selections_from_cookie.sport) || {},
      selectedSeason: (selections_from_cookie && selections_from_cookie.season) || {},
      selectedTeam: {},
      displayAsFraction: true,
      tableModal: false,
      logoHeight: default_logo_height,
      logoHeightMax: 125,
      logoHeightMin: 25,
      tableFontSize: (selections_from_cookie && selections_from_cookie.tableFontSize) || 14,
      tableFontSizeMin: 8,
      tableFontSizeMax: 34,
      loadingFromDb: false,
      db_last_update: "",
      selectedTab: tab_names[1],
      auth0jwt: "",
      isAuthenticated: false,
      userPermissions: [],
      tokenInterval: null,
    };
  }

  applyUserFilter = () => {
    return this.applyUserFilterBaseOnTabId(this.state.selectedTab.id);
  };

  applyUserFilterBaseOnTabId = (tabId) => {
    return tabId === 150 || tabId === 170 || tabId === 145;
  };

  needs_to_ckeck_if_db_has_new_data = (tabId) => {
    return tabId === 100;
  };

  handleTableModalToggle = () => {
    this.setState({
      tableModal: !this.state.tableModal,
    });
  };

  load_aggregated_resume_table = async (force) => {
    const session_division_sport_season_key = make_rakings_session_key(this.state.selectedDivision.id, this.state.selectedSport.id, this.state.selectedSeason.id);

    const homeKpiTableData = sessionStorage.getItem(session_division_sport_season_key);

    if (!force && homeKpiTableData) {
      console.log("loading kpi table from cache");
      return JSON.parse(homeKpiTableData);
    } else {
      this.setState({ loadingFromDb: true });
      console.log("loading kpi table from db");
      const { data: homeKpiTableData } = await get_division_sport_season(this.state.selectedDivision.id, this.state.selectedSport.id, this.state.selectedSeason.id, 0 );

      // if (homeKpiTableData && Array.isArray(homeKpiTableData)) {
      //   homeKpiTableData.sort((a, b) => {
      //     return a.avgKPI && b.avgKPI ? b.avgKPI - a.avgKPI : 1;
      //   });

      //   for (let j = 0; j < homeKpiTableData.length; j++) {
      //     homeKpiTableData[j]["KPI"] = j + 1;
      //   }
      // }

      sessionStorage.setItem(session_division_sport_season_key, JSON.stringify(homeKpiTableData));
      return homeKpiTableData;
    }
  };

  handle_active_set_change = async (resetTeam) => {
    console.log("handle_active_set_change");

    if (this.state.selectedDivision.id && this.state.selectedSport.id && this.state.selectedSeason.id) {
      const homeKpiTableData = await this.load_aggregated_resume_table(true);

      let seenConferenceIds = new Set();
      let conferences_unique = [];

      for (var i = 0; i < homeKpiTableData.length; i++) {
        if (!seenConferenceIds.has(homeKpiTableData[i].conferenceId)) {
          seenConferenceIds.add(homeKpiTableData[i].conferenceId);
          conferences_unique.push({
            id: homeKpiTableData[i].conferenceId,
            name: homeKpiTableData[i].conferenceName,
          });
        }
      }

      const { data: db_last_update } = await get_db_last_update_by_display_sport_id_and_season_id(this.state.selectedSport.id, this.state.selectedSeason.id);
      let date_string = " ... ";
      if (db_last_update[0].date) {
        date_string = `${parse(db_last_update[0].date, time_format.utc).toLocaleString()} *`;
        //date_string = `${parse(db_last_update[0].date, time_format.utc)}`;
      }

      const db_last_update_string = `${db_last_update_string_prefix}${date_string}`;

      const newState = {
        db_last_update: db_last_update_string,

        conferences: conferences_unique,
        columns: make_ranking_table_columns(this.state.logoHeight, conferences_unique),
        homeKpiTableData: homeKpiTableData,
        loadingFromDb: false,
      };
      if (resetTeam) {
        newState.selectedTeam = {};
      }

      this.setState(newState);

      this.set_resume_rankings_cookie();
    }
  };

  componentWillUnmount = () => {
    this.set_resume_rankings_cookie();
  };

  handleDivisionsSelectChange = async (selectedDivision) => {
    if (selectedDivision && this.state.selectedDivision.id !== selectedDivision.id) {
      let key = GetUserAccessKey(selectedDivision.id, this.state.selectedSport.id, this.state.selectedTeam.id);
      if (this.state.applyUserFilter && key && !this.state.userAccessMap.get(key)) {
        toast.error(`Access to ${selectedDivision.name} denied`);
      } else {
        let rounds = [];
        if (selectedDivision.id && this.state.selectedSport.id) {
          try {
            const qres = await get_rounds(selectedDivision.id, this.state.selectedSport.id);
            rounds = qres.data;
          } catch (error) {
            console.log(error);
          }
        }

        this.setState({ selectedDivision: selectedDivision, rounds: rounds }, async function () {
          await this.handle_active_set_change(false);
        });
      }
    }
  };

  get_teams_for_sport_season_impl = async (selectedSport, selectedSeason) => {
    if (!(selectedSport && selectedSport.id >= 0 && selectedSeason && selectedSeason.id > 0)) {
      return [];
    }

    let allSportSeasonTeams = [];
    try {
      const qres = await get_teams_for_sport_season(selectedSport.id, selectedSeason.id);
      allSportSeasonTeams = qres.data;
      if (allSportSeasonTeams.length === 0) {
        allSportSeasonTeams = this.state.allTeams;
      }
    } catch (error) {
      console.log(error);
      allSportSeasonTeams = [];
    }

    return allSportSeasonTeams;
  };

  handleSportsSelectChange = async (selectedSport) => {
    if (selectedSport && this.state.selectedSport.id !== selectedSport.id) {
      let key = GetUserAccessKey(this.state.selectedDivision.id, selectedSport.id, this.state.selectedTeam.id);
      if (this.state.applyUserFilter && key && !this.state.userAccessMap.get(key)) {
        toast.error(`Access to ${selectedSport.name} denied`);
      } else {
        const allSportSeasonTeams = await this.get_teams_for_sport_season_impl(selectedSport, this.state.selectedSeason);

        let rounds = [];
        if (this.state.selectedDivision.id && selectedSport.id) {
          try {
            const qres = await get_rounds(this.state.selectedDivision.id, selectedSport.id);
            rounds = qres.data;
          } catch (error) {
            console.log(error);
          }
        }

        this.setState({ selectedSport: selectedSport, allSportSeasonTeams: allSportSeasonTeams, rounds: rounds }, async function () {
          await this.handle_active_set_change(false);
        });
      }
    }
  };

  //set the cancel value of data grid insert and update and delete
  checkToken = async (from) => {
    console.log(`checking token from: ${from}`);

    if (this.state.isAuthenticated && this.state.auth0jwt && this.state.tokenInterval) {
      const number_of_hours_to_token_expiration = (this.state.tokenInterval.exp.getTime() - new Date().getTime()) / (60 * 60 * 1000);
      
      if (number_of_hours_to_token_expiration < 0.05) {
        //remain on current page
        //return to login page
        let loginRedirectConfirmed = await alert(`You have been logged out of Faktor due to passage of time. Click OK to return to the login page.`, "Log in!");
        if (loginRedirectConfirmed) {
          await this.context.loginWithRedirect({});
        } else {
          //cancel it, because they do not want to login
          return true;
        }
      } else {
        //dont cancel, because token is still valid
        return false;
      }
    }

    //cancel
    return true;
  };

  handleSeasonsSelectChange = async (selectedSeason) => {
    if (selectedSeason && this.state.selectedSeason.id !== selectedSeason.id) {
      const allSportSeasonTeams = await this.get_teams_for_sport_season_impl(this.state.selectedSport, selectedSeason);

      this.setState({ selectedSeason: selectedSeason, allSportSeasonTeams: allSportSeasonTeams }, async function () {
        await this.handle_active_set_change(false);
      });
    }
  };

  handleTeamSelectChange = async (selectedTeam) => {
    if (selectedTeam && this.state.selectedTeam.id !== selectedTeam.id) {
      let key = GetUserAccessKey(this.state.selectedDivision.id, this.state.selectedSport.id, selectedTeam.id);
      if (this.state.applyUserFilter && key && !this.state.userAccessMap.get(key)) {
        toast.error(`Access to ${selectedTeam.name} denied`);
      } else {
        this.setState({ selectedTeam });
      }
    }
  };

  handleTableColumnChange = (selectedColumns) => {
    this.setState({ columns: selectedColumns });
  };

  handleLogoHeightChange = (logoHeight) => {
    const cleanHeight = logoHeight < this.state.logoHeightMin ? this.state.logoHeightMin : logoHeight > this.state.logoHeightMax ? this.state.logoHeightMax : logoHeight;

    this.setState({
      logoHeight: cleanHeight,
      columns: make_ranking_table_columns(cleanHeight, this.state.conferences),
    });
  };

  handleTableFontSizeChange = (tableFontSize) => {
    this.setState({ tableFontSize: tableFontSize });
  };

  set_resume_rankings_cookie = () => {
    const { cookies } = this.props;
    const expires = new Date();
    expires.setDate(expires.getDate() + 180);
    if (this.state.cookie_approval === "-1") {
      cookies.set(
        "active_set",
        {
          cookie_approval: this.state.cookie_approval,
        },
        { path: "/", expires: expires, sameSite: "Strict", secure: true }
      );
    } else {
      cookies.set(
        "active_set",
        {
          division: this.state.selectedDivision,
          sport: this.state.selectedSport,
          season: this.state.selectedSeason,
          team: this.state.selectedTeam,
          tableFontSize: this.state.tableFontSize,
          logoHeight: this.state.logoHeight,
          cookie_approval: this.state.cookie_approval,
        },
        { path: "/", expires: expires, sameSite: "Strict", secure: true }
      );
    }
  };

  check_db_last_update = async () => {
    const { data: db_last_update } = await get_db_last_update();

    let date_string = " ... ";
    if (db_last_update[0].date) {
      date_string = parse(db_last_update[0].date, time_format.utc) || new Date(1970, 1, 1);
    }

    let has_new_data = false;
    //console.log(`current db_last_update ${date_string}`);
    const sessionStoragelastKnownDblstupd = sessionStorage.getItem("last_known_db_last_update");
    const lastKnownDblstupdStorageString = sessionStoragelastKnownDblstupd ? JSON.parse(sessionStoragelastKnownDblstupd) : new Date(1970, 1, 1).toISOString();
    const last_known_db_last_update = parse(lastKnownDblstupdStorageString, time_format.utc);
    //console.log(`last known db_last_update: ${last_known_db_last_update}`);
    if (last_known_db_last_update && last_known_db_last_update instanceof Date && !isNaN(last_known_db_last_update)) {
      //console.log(`db_last_update newer than lastKnownDblStupdDate ? ${date_string > last_known_db_last_update}`);
      if (date_string > last_known_db_last_update) {
        has_new_data = true;
        //console.log("cleaning cache because db has new data");

        sessionStorage.clear();
        sessionStorage.setItem("last_known_db_last_update", JSON.stringify(date_string.toISOString()));
      }
    } else {
      has_new_data = true;
      //console.log("seting db_last_update");
      sessionStorage.clear();
      sessionStorage.setItem("last_known_db_last_update", JSON.stringify(date_string.toISOString()));
      //no need to wipe the cache because we have latest data
    }
    console.log(`DB has new data? ${has_new_data}`);
    return [db_last_update, date_string, has_new_data];
  };

  async componentDidMount() {
    const [db_last_update, date_string, has_new_data] = await this.check_db_last_update();
    const { data: allDivisions } = await get_divisions();
    const { data: allSports } = await get_sports();
    const { data: allSeasons } = await get_seasons();
    const { data: allConferences } = await get_conferences();
    const { data: allTeams } = await get_teams();

    let userAccessMap = new Map();
    let allowedDivisionIds = new Set();
    let allowedDisplaySportIds = new Set();
    let allowedTeamIds = new Set();
    const { auth0jwt, user, isAuthenticated, tokenInterval, userPermissions } = this.context;

    if (isAuthenticated) {
      const auth0_current_user = {
        organizationName: null,
        email: user.email,
        nameFirst: null,
        nameLast: null,
      };

      //console.log(user);
      let hq_current_user = {};
      //if (userPermissions && userPermissions.includes(auth_config.loginApproved)) {
      try {
        const qres = await patch_hq_current_user_metadata(auth0jwt, user.sub, auth0_current_user);
        hq_current_user = qres.data;
      } catch (error) {
        console.log(error);
        hq_current_user = {};
      }
      //}

      user.hq_current_user = hq_current_user || {};

      const user_access_json = isString(hq_current_user.user_access) ? JSON.parse(hq_current_user.user_access) : hq_current_user.user_access;

      if (user_access_json && user_access_json.access) {
        [userAccessMap, allowedDivisionIds, allowedDisplaySportIds, allowedTeamIds] = GetUserAccess(user_access_json.access, allTeams, allConferences, allSports, allDivisions);
      }
    }

    const divisionsWithData = allDivisions.filter((x) => x.hasData > 0);
    const seasonsWithData = allSeasons.filter((x) => x.hasData > 0);
    const sportsWithData = allSports.filter((x) => x.hasData > 0);
    const allAllowedTeams = allTeams.filter((x) => allowedTeamIds.has(x.id));

    let active_season = this.state.selectedSeason;
    if (!(active_season && active_season.id)) {
      const current_year = new Date().getFullYear();
      active_season = allSeasons.filter((x) => x.name === current_year)[0] || {};
    }

    const allSportSeasonTeams = await this.get_teams_for_sport_season_impl(this.state.selectedSport, active_season);

    const db_last_update_string = `${db_last_update_string_prefix}${date_string}`;
    this.setState(
      {
        user: user,
        userAccessMap: userAccessMap,
        allowedDivisionIds: allowedDivisionIds,
        allowedDisplaySportIds: allowedDisplaySportIds,
        allowedTeamIds: allowedTeamIds,
        db_last_update: db_last_update_string,
        divisions: divisionsWithData,
        sports: sportsWithData,
        seasons: seasonsWithData,
        auth0jwt: auth0jwt,
        tokenInterval: tokenInterval,
        isAuthenticated: isAuthenticated,
        userPermissions: userPermissions,
        allDivisions: allDivisions,
        allSports: allSports,
        allConferences: allConferences,
        allSeasons: allSeasons,
        allTeams: allTeams,
        allAllowedTeams: allAllowedTeams,
        selectedSeason: active_season,
        allSportSeasonTeams: allSportSeasonTeams,
      },
      async () => {
        await this.handle_active_set_change(false);
      }
    );
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {}

  customNoDataComponent = () => {
    if (this.state.selectedDivision.id && this.state.selectedSport.id && this.state.selectedSeason.id && this.state.homeKpiTableData.length === 0) {
      return (
        <div className="rt-noData">
          No data found for [{this.state.selectedDivision.name}] / [{this.state.selectedSport.name}] / [{this.sport_covers_two_calendar_years(this.state.selectedSeason)}].
        </div>
      );
    }

    return <div className="rt-noData">Please make a selection for division, sport, and season.</div>;
  };

  accept_cookie_policy = () => {
    console.log("cookie_approval: 1");
    this.setState({ cookie_approval: "1" }, () => {
      this.set_resume_rankings_cookie();
    });
  };
  reject_cookie_policy = () => {
    console.log("cookie_approval: -1");
    this.setState({ cookie_approval: "-1" }, () => {
      this.set_resume_rankings_cookie();
    });
  };

  sport_covers_two_calendar_years = (option) => {
    if (option.name && this.state.selectedSport.name && isDoubleYearSport(this.state.selectedSport.name)) {
      return makeDoubleYearSport(option.name);
    } else {
      return option.name;
    }
  };

  use_all = (tab_id) => {
    return tab_id === 170 || tab_id === 820 || tab_id === 145;
  };

  handle_active_tab_change = async (tab) => {
    if (this.state.selectedTab.id !== tab.id) {
      console.log("tab change");
      const applyUserFilter = this.applyUserFilterBaseOnTabId(tab.id);

      await this.checkToken("tab change");

      if (this.needs_to_ckeck_if_db_has_new_data(tab.id)) {
        const [db_last_update, date_string, has_new_data] = await this.check_db_last_update();
        if (has_new_data) {
          await this.handle_active_set_change(false);
        }
      }

      let selectedDivision = this.state.selectedDivision;
      let selectedSport = this.state.selectedSport;
      let selectedTeam = this.state.selectedTeam;

      if (applyUserFilter) {
        if (!this.state.allowedDivisionIds.has(this.state.selectedDivision.id)) {
          selectedDivision = {};
        }
        if (!this.state.allowedDisplaySportIds.has(this.state.selectedSport.id)) {
          selectedSport = {};
        }
        if (!this.state.allowedTeamIds.has(this.state.selectedTeam.id)) {
          selectedTeam = {};
        }
      }

      this.setState({
        selectedTab: tab,
        applyUserFilter: applyUserFilter,
        selectedDivision: selectedDivision,
        selectedSport: selectedSport,
        selectedTeam: selectedTeam,
      });
    }
  };

  render() {
    //return <Combobox data={this.state.divisions} valueField="id" textField="name" defaultValue={1} />;
    return (
      <Fragment>
        <ToastContainer />
        <CoockieWarning cookieApproval={this.state.cookie_approval} acceptOnClick={this.accept_cookie_policy} rejectOnClick={this.reject_cookie_policy} />

        <ViewSelector
          tab_names={this.state.userPermissions.includes(auth_config.writeDbPermission) ? tab_names : tab_names.filter((x) => x.admin !== true)}
          handle_active_tab_change={this.handle_active_tab_change}
          selectedTab={this.state.selectedTab}
          userPermissions={this.state.userPermissions}
        />

        {![25, 800, 810, 815].includes(this.state.selectedTab.id) && (
          <ActiveSet
            showDivisions={true}
            divisions={this.state.applyUserFilter ? this.state.divisions.filter((x) => this.state.allowedDivisionIds.has(x.id)) : this.state.divisions}
            divisionsOnChange={this.handleDivisionsSelectChange}
            divisionsValue={this.state.selectedDivision}
            sports={this.state.applyUserFilter ? this.state.sports.filter((x) => this.state.allowedDisplaySportIds.has(x.id)) : this.state.sports}
            sportsOnChange={this.handleSportsSelectChange}
            sportsValue={this.state.selectedSport}
            seasons={this.state.seasons}
            seasonsGetOptionLabel={this.sport_covers_two_calendar_years}
            seasonsOnChange={this.handleSeasonsSelectChange}
            seasonsValue={this.state.selectedSeason}
            showTeams={
              this.state.selectedTab.id === 110 ||
              this.state.selectedTab.id === 145 ||
              this.state.selectedTab.id === 150 ||
              this.state.selectedTab.id === 160 ||
              this.state.selectedTab.id === 170 ||
              this.state.selectedTab.id === 190
            }
            teams={this.state.applyUserFilter ? this.state.allSportSeasonTeams.filter((x) => this.state.allowedTeamIds.has(x.id)) : this.state.allSportSeasonTeams}
            teamOnChange={this.handleTeamSelectChange}
            teamValue={this.state.selectedTeam}
            isAuthenticated={this.state.isAuthenticated}
            loginApproved={this.state.userPermissions.includes(auth_config.loginApproved)}
            user={this.state.user}
            userAccessMap={this.state.userAccessMap}
            allDivisions={this.state.applyUserFilter ? this.state.allDivisions.filter((x) => this.state.allowedDivisionIds.has(x.id)) : this.state.allDivisions}
            allSports={this.state.applyUserFilter ? this.state.allSports.filter((x) => this.state.allowedDisplaySportIds.has(x.id)) : this.state.allSports}
            allConferences={this.state.allConferences}
            allTeams={this.state.applyUserFilter ? this.state.allAllowedTeams : this.state.allTeams}
            allSeasons={this.state.allSeasons}
            applyUserFilter={this.state.applyUserFilter}
            useAll={this.use_all(this.state.selectedTab.id)}
            selectedTab={this.state.selectedTab}
          />
        )}

        {this.state.selectedTab.id === 100 && (
          <HomeRankingsDx
            auth0jwt={this.state.auth0jwt}
            isAuthenticated={this.state.isAuthenticated}
            user={this.state.user}
            db_last_update={this.state.db_last_update}
            handleTableModalToggle={this.handleTableModalToggle}
            loadingFromDb={this.state.loadingFromDb}
            tableModal={this.state.tableModal}
            columns={this.state.columns}
            handleTableColumnChange={this.handleTableColumnChange}
            logoHeightMin={this.state.logoHeightMin}
            logoHeightMax={this.state.logoHeightMax}
            logoHeight={this.state.logoHeight}
            handleLogoHeightChange={this.handleLogoHeightChange}
            tableFontSizeMin={this.state.tableFontSizeMin}
            tableFontSizeMax={this.state.tableFontSizeMax}
            tableFontSize={this.state.tableFontSize}
            handleTableFontSizeChange={this.handleTableFontSizeChange}
            customNoDataComponent={this.customNoDataComponent}
            homeKpiTableData={this.state.homeKpiTableData}
            handle_active_set_change={this.handle_active_set_change}
            selectedSport={this.state.selectedSport}
            selectedSeason={this.state.selectedSeason}
            selectedDivision={this.state.selectedDivision}
          />
        )}
        {this.state.selectedTab.id === 110 && this.state.isAuthenticated && this.state.userPermissions.includes(auth_config.loginApproved) && (
          <HomeResumeBrowser
            auth0jwt={this.state.auth0jwt}
            isAuthenticated={this.state.isAuthenticated}
            user={this.state.user}
            db_last_update={this.state.db_last_update}
            teams={this.state.allSportSeasonTeams}
            selectedDivision={this.state.selectedDivision}
            selectedSport={this.state.selectedSport}
            selectedSeason={this.state.selectedSeason}
            selectedTeam={this.state.selectedTeam}
          />
        )}
        {this.state.selectedTab.id === 120 && this.state.isAuthenticated && this.state.userPermissions.includes(auth_config.loginApproved) && (
          <AllGameValues
            auth0jwt={this.state.auth0jwt}
            isAuthenticated={this.state.isAuthenticated}
            user={this.state.user}
            db_last_update={this.state.db_last_update}
            selectedDivision={this.state.selectedDivision}
            selectedSport={this.state.selectedSport}
            selectedSeason={this.state.selectedSeason}
          />
        )}
        {[110, 120, 130, 145, 150, 160, 170, 180, 190, 800, 810, 815, 820].includes(this.state.selectedTab.id) && !this.state.isAuthenticated && (
          <Row>
            <Col className="mx-3">{`Please login to view [${this.state.selectedTab.name}]`}</Col>
          </Row>
        )}
        {[110, 120, 130, 145, 150, 160, 170, 180, 190].includes(this.state.selectedTab.id) &&
          this.state.isAuthenticated &&
          !this.state.userPermissions.includes(auth_config.loginApproved) && (
            <Row>
              <Col className="mx-3">{`Please ask an administrator to approve your login for [${this.state.selectedTab.name}]`}</Col>
            </Row>
          )}
        {this.state.selectedTab.id === 130 && this.state.isAuthenticated && this.state.userPermissions.includes(auth_config.loginApproved) && (
          <TeamCalendar
            auth0jwt={this.state.auth0jwt}
            isAuthenticated={this.state.isAuthenticated}
            selectedSport={this.state.selectedSport}
            selectedSeason={this.state.selectedSeason}
            selectedDivision={this.state.selectedDivision}
            selectedTeam={this.state.selectedTeam}
            sports={this.state.sports}
            teams={this.state.allSportSeasonTeams}
            conferences={this.state.conferences}
            divisions={this.state.divisions}
            allTeamsAndSportsInSeason={true}
            allTeams={this.state.allTeams}
            allDivisions={this.state.allDivisions}
            allConferences={this.state.allConferences}
            allSeasons={this.state.allSeasons}
          />
        )}

        {this.state.selectedTab.id === 145 && this.state.isAuthenticated && this.state.userPermissions.includes(auth_config.loginApproved) && (
          <DateManager
            checkToken={this.checkToken}
            divisions={this.state.divisions}
            auth0jwt={this.state.auth0jwt}
            user={this.state.user}
            isAuthenticated={this.state.isAuthenticated}
            selectedSport={this.state.selectedSport}
            selectedSeason={this.state.selectedSeason}
            selectedDivision={this.state.selectedDivision}
            selectedTeam={this.state.selectedTeam}
            sports={this.state.sports}
            teams={this.state.allAllowedTeams}
          />
        )}
        {this.state.selectedTab.id === 150 && this.state.isAuthenticated && this.state.userPermissions.includes(auth_config.loginApproved) && (
          <ResumeSandbox
            checkToken={this.checkToken}
            auth0jwt={this.state.auth0jwt}
            user={this.state.user}
            isAuthenticated={this.state.isAuthenticated}
            selectedSport={this.state.selectedSport}
            selectedSeason={this.state.selectedSeason}
            selectedDivision={this.state.selectedDivision}
            selectedTeam={this.state.selectedTeam}
            sports={this.state.sports}
            teams={this.state.allSportSeasonTeams}
            db_last_update={this.state.db_last_update}
          />
        )}
        {this.state.selectedTab.id === 160 && this.state.isAuthenticated && this.state.userPermissions.includes(auth_config.loginApproved) && (
          <CompareSchedules
            auth0jwt={this.state.auth0jwt}
            isAuthenticated={this.state.isAuthenticated}
            selectedSport={this.state.selectedSport}
            selectedSeason={this.state.selectedSeason}
            selectedDivision={this.state.selectedDivision}
            selectedTeam={this.state.selectedTeam}
            seasons={this.state.seasons}
            sports={this.state.sports}
            teams={this.state.allSportSeasonTeams}
            conferences={this.state.conferences}
            allTeams={this.state.allSportSeasonTeams}
            allConferences={this.state.allConferences}
            divisions={this.state.divisions}
            allTeamsAndSportsInSeason={true}
          />
        )}
        {this.state.selectedTab.id === 170 && this.state.isAuthenticated && this.state.userPermissions.includes(auth_config.loginApproved) && (
          <GameScheduleEntry
            checkToken={this.checkToken}
            auth0jwt={this.state.auth0jwt}
            isAuthenticated={this.state.isAuthenticated}
            user={this.state.user}
            selectedSport={this.state.selectedSport}
            selectedSeason={this.state.selectedSeason}
            selectedDivision={this.state.selectedDivision}
            selectedTeam={this.state.selectedTeam}
            userPermissions={this.state.userPermissions}
            allDivisions={this.state.allDivisions}
            allSports={this.state.allSports}
            allConferences={this.state.allConferences}
            allTeams={this.state.allSportSeasonTeams}
            allAllowedTeams={this.state.allAllowedTeams}
            allSeasons={this.state.allSeasons}
            allowedDivisionIds={this.state.allowedDivisionIds}
            allowedDisplaySportIds={this.state.allowedDisplaySportIds}
            allowedTeamIds={this.state.allowedTeamIds}
            rounds={this.state.rounds}
          />
        )}
        {this.state.selectedTab.id === 25 && (
          <UserProfile
            auth0jwt={this.state.auth0jwt}
            isAuthenticated={this.state.isAuthenticated}
            loginApproved={this.state.userPermissions.includes(auth_config.loginApproved)}
            user={this.state.user}
            userAccessMap={this.state.userAccessMap}
            allDivisions={this.state.allDivisions}
            allSports={this.state.allSports}
            allConferences={this.state.allConferences}
            allTeams={this.state.allTeams}
          />
        )}

        {[800].includes(this.state.selectedTab.id) && this.state.userPermissions.includes(auth_config.writeDbPermission) && (
          <Scheduling
            auth0jwt={this.state.auth0jwt}
            isAuthenticated={this.state.isAuthenticated}
            loginApproved={this.state.userPermissions.includes(auth_config.loginApproved)}
            user={this.state.user}
            userAccessMap={this.state.userAccessMap}
            allDivisions={this.state.allDivisions}
            allSports={this.state.allSports}
            allConferences={this.state.allConferences}
            allTeams={this.state.allSportSeasonTeams}
            allSeasons={this.state.allSeasons}
            selectedTab={this.state.selectedTab}
          />
        )}
        {this.state.selectedTab.id === 810 && this.state.userPermissions.includes(auth_config.writeDbPermission) && (
          <AccessRights
            checkToken={this.checkToken}
            auth0jwt={this.state.auth0jwt}
            isAuthenticated={this.state.isAuthenticated}
            loginApproved={this.state.userPermissions.includes(auth_config.loginApproved)}
            user={this.state.user}
            userPermissions={this.state.userPermissions}
            allDivisions={this.state.allDivisions}
            allSports={this.state.allSports}
            allConferences={this.state.allConferences}
            allTeams={this.state.allTeams}
            allSeasons={this.state.allSeasons}
            selectedTab={this.state.selectedTab}
          />
        )}

        {this.state.selectedTab.id === 815 && this.state.userPermissions.includes(auth_config.writeDbPermission) && (
          <NewGameScheduleEntries
            auth0jwt={this.state.auth0jwt}
            isAuthenticated={this.state.isAuthenticated}
            loginApproved={this.state.userPermissions.includes(auth_config.loginApproved)}
            user={this.state.user}
            userPermissions={this.state.userPermissions}
          />
        )}
        {this.state.selectedTab.id === 820 && this.state.userPermissions.includes(auth_config.writeDbPermission) && (
          <KpiCaller
            auth0jwt={this.state.auth0jwt}
            isAuthenticated={this.state.isAuthenticated}
            user={this.state.user}
            selectedSport={this.state.selectedSport}
            selectedSeason={this.state.selectedSeason}
            selectedDivision={this.state.selectedDivision}
            userPermissions={this.state.userPermissions}
            allConferences={this.state.allConferences}
            allTeams={this.state.allSportSeasonTeams}
            allSports={this.state.allSports}
          />
        )}
        {this.state.selectedTab.id === 180 && this.state.isAuthenticated && this.state.userPermissions.includes(auth_config.loginApproved) && (
          <TournamentManager
            checkToken={this.checkToken}
            auth0jwt={this.state.auth0jwt}
            isAuthenticated={this.state.isAuthenticated}
            selectedSport={this.state.selectedSport}
            selectedSeason={this.state.selectedSeason}
            selectedDivision={this.state.selectedDivision}
            selectedTeam={this.state.selectedTeam}
            user={this.state.user}
            userPermissions={this.state.userPermissions}
          />
        )}
        {this.state.selectedTab.id === 190 && this.state.isAuthenticated && this.state.userPermissions.includes(auth_config.loginApproved) && (
          <GameSearch
            auth0jwt={this.state.auth0jwt}
            isAuthenticated={this.state.isAuthenticated}
            selectedSport={this.state.selectedSport}
            selectedSeason={this.state.selectedSeason}
            selectedDivision={this.state.selectedDivision}
            selectedTeam={this.state.selectedTeam}
            user={this.state.user}
            userPermissions={this.state.userPermissions}
            allDivisions={this.state.allDivisions}
            allConferences={this.state.allConferences}
            allTeams={this.state.allSportSeasonTeams}
          />
        )}
      </Fragment>
    );
  }
}

export default withCookies(Home);
