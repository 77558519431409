import React, { Fragment } from "react";
import { makeConferenceWinLossTieString, makeOverallWinLossTieString, get_overall_game_counts, get_remaing_game_count_string } from "./../utils/common-funcs";
import { Button } from "devextreme-react";
import { isConferenceEnum } from "./../utils/enums";

class ResumeTableHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  makeAvgKpiString(calendarData) {
    let avgKPI = 0.0;
    let n = 0;

    for (var j = 0; j < calendarData.length; j++) {
      let row = calendarData[j];

      if ((row.WL === 1 || row.WL === 2 || row.WL === 3) && row.isConference !== isConferenceEnum.Exhibition) {
        avgKPI = avgKPI + Number(row.kpi);
        n = n + 1;
      }
    }

    if (n === 0) {
      return null;
    } else if (n > 0 && avgKPI) {
      return (avgKPI / n).toFixed(3);
    } else {
      return 0.0;
    }
  }



  render() {
    const count_meta = get_overall_game_counts(this.props.resume);
    return (
      <Fragment>
        <div style={{ display: "flex", alignItems: "center", verticalAlign: "middle", justifyContent: "space-between" }}>
          <div style={{ height: "100px" }}>
            <img height={100} width={100} src={`../logos_svg/${this.props.keys.team_id}.svg`} alt={this.props.keys.team_name} />
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {this.props.auth0jwt && (
              <div style={this.props.info_row_style}>
                <small>{makeOverallWinLossTieString(this.props.resume)}</small>
                <br />
                <small>Overall</small>
              </div>
            )}
            {this.props.auth0jwt && (
              <div style={this.props.info_row_style}>
                <small>{makeConferenceWinLossTieString(this.props.resume)}</small>
                <br />
                <small>Conf</small>
              </div>
            )}
            {this.props.auth0jwt && (
              <div style={this.props.info_row_style}>
                <small>{this.makeAvgKpiString(this.props.resume)}</small>
                <br />
                <small>KPI</small>
              </div>
            )}
            {this.props.auth0jwt && (
              <div style={this.props.info_row_style}>
                <small>Conf #</small>
                <br />
                <small>{count_meta.confCount}</small>
              </div>
            )}
            {this.props.auth0jwt && (
              <div style={this.props.info_row_style}>
                <small>Non Conf #</small>
                <br />
                <small>{count_meta.nonConfCount}</small>
              </div>
            )}
            {this.props.auth0jwt && count_meta.mteCount ? (
              <div style={this.props.info_row_style}>
                <small>MTE #</small>
                <br />
                <small>{count_meta.mteCount}</small>
              </div>
            ) : null}
            {this.props.auth0jwt && (
              <div style={this.props.info_row_style}>
                <small>Games open #</small>
                <br />
                <small>{get_remaing_game_count_string(this.props.game_counts_limits.sportMax, count_meta)}</small>
              </div>
            )}

            <div>
              <Button icon="refresh" hint="Refesh Table" type="back" stylingMode="contained" onClick={this.props.onClickRefresh} />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ResumeTableHeader;
