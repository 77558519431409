import React, { Component, Fragment } from "react";
import { Row, Col, CardHeader, Card, CardBody } from "reactstrap";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Pager,
  Lookup,
  Form,
  Popup,
  Position,
  FormItem,
  ColumnChooser,
  ColumnFixing,
  SearchPanel,
  FilterRow,
  FilterPanel,
} from "devextreme-react/data-grid";
import { Item } from "devextreme-react/form";

import { renderTitleHeader } from "../utils/common-rendering-funcs";
import { isString } from "../utils/common-funcs";
import auth_config from "../auth_config.json";
import { get_hq_all_access_rights, post_hq_current_user_access } from "../services/privateService";
import { RequiredRule, EmailRule, StringLengthRule } from "devextreme-react/validator";
import TagBox from "devextreme-react/tag-box";
import { Button } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { toast } from "react-toastify";
import { pager_lengths } from "./../utils/enums";

class AccessRights extends Component {
  constructor(props) {
    super(props);
    this.grid = null;
    this.access_cache = [];
    this.state = {
      all_access_rights: [],
      access_cache: [],
      access_helper_team_selection: [],
      access_helper_sport_selection: [],
      access_helper_division_selection: [],
      access_helper_json: JSON.stringify({}),
    };
    this.onClickRefresh = this.onClickRefresh.bind(this);
  }

  componentDidMount = async () => {
    await this.load_all_access_rights();
  };

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isAuthenticated !== prevProps.isAuthenticated || !this.props.userPermissions.includes(auth_config.writeDbPermission)) {
      this.setState({ all_access_rights: [] });
    }
  }

  load_all_access_rights = async () => {
    if (this.props.userPermissions && this.props.userPermissions.includes(auth_config.writeDbPermission)) {
      const res = await get_hq_all_access_rights(this.props.auth0jwt);
      if (res.data) {
        const all_access_rights = res.data.map((x) => ({
          userId: x.userId,
          organizationName: x.organizationName,
          email: x.email,
          nameFirst: x.nameFirst,
          nameLast: x.nameLast,
          providerID: x.providerID,
          user_metadata: isString(x.user_metadata) ? x.user_metadata : JSON.stringify(x.user_metadata),
          user_access: isString(x.user_access) ? x.user_access : JSON.stringify(x.user_access),
          teamId: x.teamId,
          displaySportId: x.displaySportId,
          divisionId: x.divisionId,
          conferenceId: x.conferenceId,
        }));

        this.setState({ all_access_rights: all_access_rights });
      }
    }
  };

  async onClickRefresh(e) {
    notify(`Refreshing table`);
    await this.load_all_access_rights();
  }

  onRowUpdating = async (e) => {
    e.cancel = this.checkTokenLocal();
  };

  checkTokenLocal = async (e) => {
    return await this.props.checkToken("Access-Rights");
  };

  onRowUpdated = async (e) => {
    const x = e.data;
    let clean_user_metadata = null;
    let clean_user_access = null;
    try {
      clean_user_metadata = JSON.parse(x.user_metadata);
    } catch (error) {
      console.log(error);
    }
    try {
      clean_user_access = JSON.parse(x.user_access);
    } catch (error) {
      console.log(error);
    }
    const y = {
      userId: x.userId,
      organizationName: x.organizationName,
      email: x.email,
      nameFirst: x.nameFirst,
      nameLast: x.nameLast,
      providerID: x.providerID,
      user_metadata: clean_user_metadata,
      user_access: clean_user_access,
      teamId: x.teamId,
      displaySportId: x.displaySportId,
      divisionId: x.divisionId,
      conferenceId: x.conferenceId,
    };
    try {
      await post_hq_current_user_access(this.props.auth0jwt, e.data.providerID, y);
      toast.success("Successfully saved record");
    } catch (error) {
      toast.error("Could not save record");
      console.log(error);
    }
  };

  button_item_on_click = async (e) => {
    let selectedRowsData = await this.grid.instance.getSelectedRowsData();

    let j = JSON.parse(selectedRowsData[0].user_access);
    if (j && j.access) {
      j.access.push({ team: 1, sport: 7, division: 1 });
    } else {
      j = { access: [{ team: 1, sport: 7, division: 1 }] };
    }
    selectedRowsData[0].user_access = JSON.stringify(j);

    this.access_cache.push("cats");
  };

  onValueChangedAccessHelperForTeam = async (event) => {
    this.setState({ access_helper_team_selection: event.value });
  };
  onValueChangedAccessHelperForSport = async (event) => {
    this.setState({ access_helper_sport_selection: event.value });
  };
  onValueChangedAccessHelperForDivision = async (event) => {
    this.setState({ access_helper_division_selection: event.value });
  };

  generate_json = () => {
    let access = new Array(this.state.access_helper_team_selection.length * this.state.access_helper_sport_selection.length * this.state.access_helper_division_selection.length);
    let j = 0;
    for (let d = 0; d < this.state.access_helper_division_selection.length; d++) {
      for (let s = 0; s < this.state.access_helper_sport_selection.length; s++) {
        for (let t = 0; t < this.state.access_helper_team_selection.length; t++) {
          access[j] = {
            division: this.state.access_helper_division_selection[d],
            sport: this.state.access_helper_sport_selection[s],
            team: this.state.access_helper_team_selection[t],
          };
          j = j + 1;
        }
      }
    }

    this.setState({ access_helper_json: JSON.stringify({ access }) });
  };

  render() {
    return (
      <Fragment>
        <Card className="main-card my-2 mx-2 border border-primary rounded font-weight-bold">
          <CardHeader className="card-header-tab">
            <div style={{ float: "left" }} className="card-header-title thick">
              Access Rights
            </div>
            <div style={{ float: "right" }}>
              <Button icon="refresh" hint="Refesh Table" type="back" stylingMode="contained" onClick={this.onClickRefresh} />
            </div>
          </CardHeader>
          <Card className="my-4 mx-5 border border-warning rounded">
            <CardHeader>
              <div className="card-header-title normal">Access JSON Generator</div>
            </CardHeader>

            <CardBody>
              <Row className="mx-2 py-1">
                <Col xs={4}>
                  <TagBox
                    placeholder="Select divisions"
                    //value={this.state.access_helper_division_selection}
                    maxDisplayedTags={6}
                    items={this.props.allDivisions}
                    displayExpr="name"
                    valueExpr="id"
                    showSelectionControls={true}
                    applyValueMode="useButtons"
                    onValueChanged={this.onValueChangedAccessHelperForDivision}
                    searchEnabled={true}
                    showClearButton
                  />
                </Col>
                <Col xs={4}>
                  <TagBox
                    placeholder="Select sports"
                    //value={this.state.access_helper_sport_selection}
                    maxDisplayedTags={6}
                    items={this.props.allSports}
                    displayExpr="name"
                    valueExpr="id"
                    showSelectionControls={true}
                    applyValueMode="useButtons"
                    onValueChanged={this.onValueChangedAccessHelperForSport}
                    searchEnabled={true}
                    showClearButton
                  />
                </Col>
                <Col xs={4}>
                  <TagBox
                    placeholder="Select teams"
                    //value={this.state.access_helper_team_selection}
                    maxDisplayedTags={6}
                    items={this.props.allTeams}
                    displayExpr="name"
                    valueExpr="id"
                    showSelectionControls={true}
                    applyValueMode="useButtons"
                    onValueChanged={this.onValueChangedAccessHelperForTeam}
                    searchEnabled={true}
                    showClearButton
                  />
                </Col>
              </Row>
              <Row className="mx-4 py-1">
                <Button width={120} text="Generate JSON" type="normal" stylingMode="contained" onClick={this.generate_json} />
              </Row>
              <Row className="mx-4 py-1">{this.state.access_helper_json}</Row>
            </CardBody>
          </Card>

          <CardBody className="pt-1">
            {this.props.isAuthenticated && this.props.loginApproved && (
              <Row className="px-2 py-1">
                <Col xs={12}>
                  <DataGrid
                    width={"100%"}
                    columnAutoWidth={true}
                    dataSource={this.state.all_access_rights}
                    allowColumnReordering={true}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    onRowUpdated={this.onRowUpdated}
                    onRowUpdating={this.onRowUpdating}
                    ref={(ref) => (this.grid = ref)}
                    selection={{ mode: "single" }}
                    allowColumnResizing={true}
                    columnResizingMode={"widget"}
                    wordWrapEnabled={false}
                  >
                    <SearchPanel visible={true} />
                    <Paging defaultPageSize={50} />
                    <Pager showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />
                    <FilterRow visible={true} />
                    <FilterPanel visible={true} />

                    <ColumnChooser enabled={true} />
                    <ColumnFixing enabled={true} />
                    <Editing mode="popup" useIcons={true} allowUpdating={true} allowDeleting={false} allowAdding={false}>
                      <Popup title="Access Rights" showTitle={true}>
                        <Position my="center" at="center" of={window} />
                      </Popup>
                      <Form>
                        <Item itemType="group" caption="Personal Information" colCount={2} colSpan={2}>
                          <Item dataField="nameFirst" />
                          <Item dataField="nameLast" />
                          <Item dataField="organizationName" />
                          <Item dataField="email" />
                        </Item>

                        <Item itemType="group" caption="KPI Metadata" colCount={2} colSpan={2}>
                          <Item dataField="userId" />
                          <Item dataField="providerID" />

                          <Item dataField="divisionId" />
                          <Item dataField="displaySportId" />
                          <Item dataField="conferenceId" />
                          <Item dataField="teamId" />

                          {/* <ButtonItem alignment="left">
                            <ButtonOptions text="Add to access" type="success" onClick={this.button_item_on_click} />
                          </ButtonItem> */}
                          <Item dataField="user_access" editorType="dxTextArea" colSpan={2} editorOptions={{ height: 100 }} />
                          <Item dataField="user_metadata" editorType="dxTextArea" colSpan={2} editorOptions={{ height: 100 }} />
                        </Item>
                      </Form>
                    </Editing>
                    {/* <Editing mode="row" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true} /> */}
                    <Column dataField="nameLast" caption="Last Name" fixed={true} fixedPosition="left" headerCellRender={renderTitleHeader}>
                      <RequiredRule message="Last name is required" />
                      <StringLengthRule min={1} message="Last name should be at least 2 characters long" />
                    </Column>
                    <Column dataField="nameFirst" caption="Name" headerCellRender={renderTitleHeader}>
                      <RequiredRule message="First name is required" />
                      <StringLengthRule min={2} message="First name should be at least 2 characters long" />
                    </Column>

                    <Column
                      dataField="userId"
                      dataType="number"
                      caption="user ID"
                      minWidth={60}
                      sortOrder="desc"
                      allowEditing={false}
                      headerCellRender={renderTitleHeader}
                    ></Column>
                    <Column dataField="providerID" caption="provider ID" minWidth={60} headerCellRender={renderTitleHeader} allowEditing={false} visible={false}>
                      <FormItem visible={true} />
                    </Column>
                    <Column dataField="organizationName" caption="Organization" minWidth={60} headerCellRender={renderTitleHeader}>
                      <RequiredRule message="Organization is required" />
                      <StringLengthRule min={2} message="Organization should be at least 4 characters long" />
                    </Column>
                    <Column dataField="email" caption="E-Mail" minWidth={60} headerCellRender={renderTitleHeader}>
                      <RequiredRule message="Email is required" />
                      <EmailRule message="Email is invalid" />
                    </Column>
                    <Column dataField="user_access" caption="Access JSON" width={40} headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="user_metadata" caption="Metadata JSON" width={40} headerCellRender={renderTitleHeader} allowEditing={false}></Column>
                    <Column dataField="divisionId" caption="Division" minWidth={60} headerCellRender={renderTitleHeader}>
                      <Lookup dataSource={this.props.allDivisions} displayExpr="name" valueExpr="id" />
                    </Column>
                    <Column dataField="displaySportId" caption="Sport" minWidth={60} headerCellRender={renderTitleHeader}>
                      <Lookup dataSource={this.props.allSports} displayExpr="name" valueExpr="id" />
                    </Column>
                    <Column dataField="conferenceId" caption="Conference" minWidth={60} headerCellRender={renderTitleHeader}>
                      <Lookup dataSource={this.props.allConferences} displayExpr="name" valueExpr="id" />
                    </Column>
                    <Column dataField="teamId" caption="Team" minWidth={120} headerCellRender={renderTitleHeader}>
                      <Lookup dataSource={this.props.allTeams} displayExpr="name" valueExpr="id" />
                    </Column>
                  </DataGrid>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default AccessRights;
