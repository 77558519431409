import React, { Component, Fragment } from "react";
import { Row, Col, CardHeader, Card, CardBody } from "reactstrap";
import { Button } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { group } from "d3-array";

import { SelectBox } from "devextreme-react";
import { format, parse } from "fecha";
import { renderTitleHeader } from "./home-rankings-utils";
import DataGrid, { Column, HeaderFilter, Export, Paging, Selection, FilterRow, ColumnFixing, Pager } from "devextreme-react/data-grid";
import { CheckBox } from "devextreme-react/check-box";
import { get_sandbox_for_team_season_sport, get_priority_dates_for_sport, get_blocked_dates_for_sport, get_game_count_limits_for_sport } from "../services/privateService";
import { get_broadcasters } from "../services/publicService";
import { GetIdToNameMap, zero_pad, getDateSequence, get_overall_game_counts, get_remaing_game_count_string } from "../utils/common-funcs";

import TagBox from "devextreme-react/tag-box";
import { DropDownBox } from "devextreme-react";
import { Chart, Series, ArgumentAxis, CommonAxisSettings, CommonSeriesSettings, Legend, Margin, Title, Subtitle, Tooltip, Grid, Size } from "devextreme-react/chart";
import { siteIdDict, siteIdColorDict, time_format, pager_lengths, dateTypeId_dict, dateTypeId_color_dict } from "../utils/enums";

const nameLogoItems = [
  { id: 1, name: "Name" },
  { id: 2, name: "Logo" },
  { id: 3, name: "Name & Logo" },
];

const logo_height = 50;

class CompareSchedules extends Component {
  constructor(props) {
    super(props);
    this.grid = null;

    this.state = {
      gridBoxValueOtherSelectedTeamIds: [],
      calendarData: [],
      priorityDates: [],
      calendarDataFrameOnClickCompare: [],
      teamIdToNameMap: new Map(),
      seasonIdToNameMap: new Map(),
      sportIdToNameMap: new Map(),
      otherSelectedSportIds: [],
      otherSelectedSeasonIds: [],
      selectedNameLogo: 1,
      conferenceIdToNameMap: new Map(),
      checkBoxValueShowBroacdcasters: false,
      checkBoxValueShowGameCounts: true,
      checkBoxValueShowTournaments: false,
      broadcasterIdToNameMap: new Map(),
      loadedSportsInSeasonMap: new Map(),
      checkBoxValueFitGridToWindow: true,
      checkBoxValueShowAllMissingDates: false,
      checkBoxValueShowPriorityDates: false,
      teamDropDownDataSource: [],
      gameCountLimitsMap: new Map(),
      gameCountsMap: new Map(),
    };
    this.onClickRefresh = this.onClickRefresh.bind(this);
    this.onClickCompare = this.onClickCompare.bind(this);
    this.onValueChangedShowBroacdcasters = this.onValueChangedShowBroacdcasters.bind(this);
    this.onValueChangedShowGameCounts = this.onValueChangedShowGameCounts.bind(this);
    this.onValueChangedShowTournaments = this.onValueChangedShowTournaments.bind(this);
    this.onValueChangedFitGridToWindow = this.onValueChangedFitGridToWindow.bind(this);
    //this.onValueChangedShowAllMissingDates = this.onValueChangedShowAllMissingDates.bind(this);
    //this.renderCompareSchedulesTitleHeader = this.renderCompareSchedulesTitleHeader.bind(this);
  }

  get_game_count_limit_key = (row) => {
    if (!row) {
      return null;
    }

    return `d:${row.divisionId}-s:${row.displaySportId}-y:${row.seasonId}-c:${row.conferenceId}`;
  };

  do_load_calendar_data = async (loadedSportsInSeasonMap, selectedDisplaySportIds, selectedSeasonIds, gameCountLimitsMap, teamIdToNameMap) => {
    let calendarData = [];
    let priorityDates = [];
    let hasNewData = false;

    for (var seasonIdx = 0; seasonIdx < selectedSeasonIds.length; seasonIdx++) {
      const season_to_load = selectedSeasonIds[seasonIdx];
      let loadedSportIdsSet = loadedSportsInSeasonMap.get(season_to_load);
      if (!loadedSportIdsSet) {
        loadedSportIdsSet = new Set();
        loadedSportsInSeasonMap.set(season_to_load, loadedSportIdsSet);
      }

      for (var sportIdx = 0; sportIdx < selectedDisplaySportIds.length; sportIdx++) {
        const sport_to_load = selectedDisplaySportIds[sportIdx];
        if (!loadedSportIdsSet.has(sport_to_load)) {
          console.log(`loading season ${season_to_load} -- sport ${sport_to_load}`);
          loadedSportIdsSet.add(sport_to_load);
          const res = await get_sandbox_for_team_season_sport(this.props.auth0jwt, 0, season_to_load, sport_to_load, this.props.selectedDivision.id);
          
          if (this.props.selectedSeason.id === season_to_load) {
            //priority dates only make sense for current season
            let { data: all_priority_dates_table } = await get_priority_dates_for_sport(
              this.props.auth0jwt,
              season_to_load,
              0, //no team filter
              sport_to_load,
              this.props.selectedDivision.id
            );

            let { data: all_blocked_dates_table } = await get_blocked_dates_for_sport(
              this.props.auth0jwt,
              season_to_load,
              0, //no team filter
              sport_to_load,
              this.props.selectedDivision.id
            );

            if (this.state.checkBoxValueShowGameCounts) {
              const { data: game_counts_limits } = await get_game_count_limits_for_sport(
                this.props.auth0jwt,
                this.props.selectedDivision.id,
                sport_to_load,
                0, //get all the conferences
                season_to_load
              );

              if (Array.isArray(game_counts_limits)) {
                for (var gcl = 0; gcl < game_counts_limits.length; gcl++) {
                  const row = game_counts_limits[gcl];
                  const gcl_key = this.get_game_count_limit_key(row);
                  if (gcl_key) {
                    gameCountLimitsMap.set(gcl_key, { mteMax: row.mteMax, sportMax: row.sportMax, confMax: row.confMax });
                  }
                }
              }
            }

            let all_priority_and_blocked_dates_table = [];
            if (Array.isArray(all_priority_dates_table)) {
              all_priority_and_blocked_dates_table = all_priority_dates_table;
            }
            if (Array.isArray(all_blocked_dates_table)) {
              all_priority_and_blocked_dates_table = all_priority_and_blocked_dates_table.concat(all_blocked_dates_table);
            }

            let all_priority_dates_table_expanded = [];
            if (Array.isArray(all_priority_and_blocked_dates_table)) {
              for (var jj = 0; jj < all_priority_and_blocked_dates_table.length; jj++) {
                const { teamId, startDate, endDate, blockedForId, dateTypeId, dateId, displaySportId, divisionId, isPrivate, lastUpdate, note, seasonId } =
                  all_priority_and_blocked_dates_table[jj];

                const team1Name = teamIdToNameMap.get(teamId) || null;
                const row_seq = getDateSequence(startDate, endDate, time_format.utc);

                for (var jd = 0; jd < row_seq.length; jd++) {
                  const x = format(parse(row_seq[jd], time_format.yyymmdd), time_format.utc);

                  all_priority_dates_table_expanded.push({
                    teamId: teamId,
                    date: x,
                    blockedForId: blockedForId,
                    isConference: 0,
                    dateTypeId: dateTypeId,
                    dateTBD: 0,
                    displaySportId: displaySportId,
                    divisionId: divisionId,
                    endDate: x,
                    isPrivate: isPrivate,
                    overtime: null,
                    seasonId: seasonId,
                    siteId: null,
                    startDate: x,
                    team1Id: teamId,
                    team1Name: team1Name,
                    text: note,
                    timeTBD: 0,
                  });
                }
              }
            }

            priorityDates = [...priorityDates, ...all_priority_dates_table_expanded];
          }

          calendarData = [...calendarData, ...res.data];
          hasNewData = true;
        }
      }
    }

    return [calendarData, loadedSportsInSeasonMap, hasNewData, priorityDates, gameCountLimitsMap];
  };

  async componentDidMount() {
    const teamIdToNameMap = GetIdToNameMap(this.props.allTeams);
    const seasonIdToNameMap = GetIdToNameMap(this.props.seasons);
    const sportIdToNameMap = GetIdToNameMap(this.props.sports);
    const { data: broadcasters } = await get_broadcasters();
    const broadcasterIdToNameMap = GetIdToNameMap(broadcasters);
    const conferenceIdToNameMap = GetIdToNameMap(this.props.allConferences);

    let calendarData = [];
    let priorityDates = [];
    let calendarDataFrame = [];
    let gameCountsMap = new Map();
    let loadedSportsInSeasonMap = new Map();
    let gameCountLimitsMap = new Map();
    if (this.props.isAuthenticated && this.props.auth0jwt && this.props.selectedSeason.id && this.props.selectedDivision.id) {
      [calendarData, calendarDataFrame, loadedSportsInSeasonMap, priorityDates, gameCountsMap, gameCountLimitsMap] = await this.get_clean_calendar_data_frame(
        teamIdToNameMap,
        [this.props.selectedSeason.id],
        [this.props.selectedSport.id],
        seasonIdToNameMap,
        sportIdToNameMap
      );
    }

    this.setState({
      calendarData: calendarData,
      priorityDates: priorityDates,
      calendarDataFrameOnClickCompare: calendarDataFrame,
      gameCountsMap: gameCountsMap,
      loadedSportsInSeasonMap: loadedSportsInSeasonMap,
      teamIdToNameMap: teamIdToNameMap,
      seasonIdToNameMap: seasonIdToNameMap,
      sportIdToNameMap: sportIdToNameMap,
      broadcasterIdToNameMap: broadcasterIdToNameMap,
      conferenceIdToNameMap: conferenceIdToNameMap,
      gameCountLimitsMap: gameCountLimitsMap,
    });
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.isAuthenticated &&
      this.props.auth0jwt &&
      this.props.selectedSeason.id &&
      this.props.selectedTeam.id &&
      this.props.selectedDivision.id &&
      (this.props.isAuthenticated !== prevProps.isAuthenticated ||
        this.props.auth0jwt !== prevProps.auth0jwt ||
        this.props.selectedSeason.id !== prevProps.selectedSeason.id ||
        this.props.selectedTeam.id !== prevProps.selectedTeam.id ||
        this.props.selectedSport.id !== prevProps.selectedSport.id ||
        this.props.selectedDivision.id !== prevProps.selectedDivision.id ||
        this.props.allTeamsAndSportsInSeason !== prevProps.allTeamsAndSportsInSeason)
    ) {
      const teamIdToNameMap = GetIdToNameMap(this.props.allTeams);

      const seasonIdToNameMap = GetIdToNameMap(this.props.seasons);
      const sportIdToNameMap = GetIdToNameMap(this.props.sports);

      let [calendarData, calendarDataFrame, loadedSportsInSeasonMap, priorityDates, gameCountsMap, gameCountLimitsMap] = await this.get_clean_calendar_data_frame(
        teamIdToNameMap,
        [...this.state.otherSelectedSeasonIds, this.props.selectedSeason.id],
        [...this.state.otherSelectedSportIds, this.props.selectedSport.id],
        seasonIdToNameMap,
        sportIdToNameMap
      );

      this.setState({
        calendarData: calendarData,
        priorityDates: priorityDates,
        calendarDataFrameOnClickCompare: calendarDataFrame,
        gameCountsMap: gameCountsMap,
        teamIdToNameMap: teamIdToNameMap,
        seasonIdToNameMap: seasonIdToNameMap,
        sportIdToNameMap: sportIdToNameMap,

        gridBoxValueOtherSelectedTeamIds: [],
        loadedSportsInSeasonMap: loadedSportsInSeasonMap,
        gameCountLimitsMap: gameCountLimitsMap,
      });
    }
  }

  get_clean_calendar_data_frame = async (teamIdToNameMap, selectedSeasonIds, selectedSportIds, seasonIdToNameMap, sportIdToNameMap) => {
    let loadedSportsInSeasonMap = new Map();
    let gameCountLimitsMap = new Map();

    let [calendarDataNew, loadedSportsInSeasonNew, hasNewData, priorityDatesNew, gameCountLimitsMapNew] = await this.do_load_calendar_data(
      loadedSportsInSeasonMap,
      selectedSportIds,
      selectedSeasonIds,
      gameCountLimitsMap,
      teamIdToNameMap
    );

    let selectedTeams = [];
    if (this.props.selectedTeam.id) {
      selectedTeams = [...this.state.gridBoxValueOtherSelectedTeamIds, this.props.selectedTeam.id];
    } else {
      selectedTeams = [...this.state.gridBoxValueOtherSelectedTeamIds];
    }

    const { calendarDataFrame, gameCountsMap } = await this.makeCalendarDataFramePromiseAsync(
      calendarDataNew,
      selectedTeams,
      teamIdToNameMap,
      selectedSeasonIds,
      selectedSportIds,
      this.state.checkBoxValueShowAllMissingDates,
      priorityDatesNew,
      this.state.checkBoxValueShowPriorityDates,
      this.state.checkBoxValueShowGameCounts,
      gameCountLimitsMapNew,
      seasonIdToNameMap,
      sportIdToNameMap
    );

    return [calendarDataNew, calendarDataFrame, loadedSportsInSeasonNew, priorityDatesNew, gameCountsMap, gameCountLimitsMapNew];
  };

  async onClickRefresh(e) {
    notify(`Refreshing table`);

    const [calendarData, calendarDataFrame, loadedSportsInSeasonMap, priorityDates, gameCountsMap, gameCountLimitsMap] = await this.get_clean_calendar_data_frame(
      this.state.teamIdToNameMap,
      [...this.state.otherSelectedSeasonIds, this.props.selectedSeason.id],
      [...this.state.otherSelectedSportIds, this.props.selectedSport.id],
      this.state.seasonIdToNameMap,
      this.state.sportIdToNameMap
    );
    this.setState({
      calendarData: calendarData,
      priorityDates: priorityDates,
      calendarDataFrameOnClickCompare: calendarDataFrame,
      gameCountsMap: gameCountsMap,
      loadedSportsInSeasonMap: loadedSportsInSeasonMap,
      gameCountLimitsMap: gameCountLimitsMap,
    });
  }

  update_game_counts_map = (keyTeam, keySport, keySeason, showGameCounts, gameCountsMap, game_count_limits) => {
    let game_count_text = null;
    
    if (showGameCounts && gameCountsMap && keyTeam && keySport && keySeason) {
      const fullKey = `x_${keyTeam}_${keySport}_${keySeason}_text`;
      let { count_meta } = gameCountsMap.get(fullKey) || null;

      let remaining_games_text = "0";
      if (game_count_limits) {
        remaining_games_text = get_remaing_game_count_string(game_count_limits.sportMax, count_meta);
      }

      if (count_meta) {
        game_count_text = `C: ${count_meta.confCount}, NC: ${count_meta.nonConfCount}`;
        if (count_meta.mteCount > 0) {
          game_count_text = `${game_count_text}, MTE:${count_meta.mteCount}`;
        }
        game_count_text = `${game_count_text}, Open:${remaining_games_text}`;
        gameCountsMap.set(fullKey, { count_meta: count_meta, game_count_text: game_count_text });
      }
    }

    return game_count_text;
  };

  renderCompareSchedulesTitleHeader = (obj) => {
    const { sportName, seasonName, teamName, game_count_text } = obj;

    //    return <div style={{ fontSize: "14px", fontWeight: "bold", color: "black" }}>{data.column.caption.replace(/\r\n/g, "<br>")}</div>;
    //console.log(data);

    //console.log(this.grid.instance);

    // let rows = data.column.caption.split(",");

    return (
      <div style={{ fontSize: "14px", fontWeight: "bold", color: "black" }}>
        <div>
          {sportName}
          <br />
          {seasonName}
          <br />
          {teamName}
        </div>
        <div style={{ borderRadius: "4px", paddingRight: "4px", paddingLeft: "4px", backgroundColor: "lightgrey" }}>{game_count_text}</div>
      </div>
    );
  };

  makeCalendarDataFramePromiseAsync = async (
    calendarData,
    selectedTeamIds,
    teamIdToNameMap,
    selectedSeasonIds,
    selectedSportIds,
    showAllMissingDates,
    priorityDates,
    showPriorityDates,
    showGameCounts,
    gameCountLimitsMap,
    seasonIdToNameMap,
    sportIdToNameMap
  ) => {
    const teamFilter = new Set(selectedTeamIds);
    if (this.props.selectedTeam.id) {
      teamFilter.add(this.props.selectedTeam.id);
    }

    const sportFilter = new Set(selectedSportIds);
    if (this.props.selectedSport.id) {
      sportFilter.add(this.props.selectedSport.id);
    }

    const seasonFilter = new Set(selectedSeasonIds);
    if (this.props.selectedSeason.id) {
      seasonFilter.add(this.props.selectedSeason.id);
    }

    return await this.makeCalendarDataFrameByDateAsync(
      calendarData,
      teamFilter,
      teamIdToNameMap,
      seasonFilter,
      sportFilter,
      showAllMissingDates,
      priorityDates,
      showPriorityDates,
      showGameCounts,
      gameCountLimitsMap,
      seasonIdToNameMap,
      sportIdToNameMap
    );
  };

  makeCalendarDataFrameByDateAsync = async (
    calendarData,
    teamFilter,
    teamIdToNameMap,
    seasonFilter,
    sportFilter,
    showAllMissingDates,
    priorityDates,
    showPriorityDates,
    showGameCounts,
    gameCountLimitsMap,
    seasonIdToNameMap,
    sportIdToNameMap
  ) => {
    return new Promise((resolve, reject) => {
      let mergedCalendarData = [];
      if (showPriorityDates) {
        mergedCalendarData = [...calendarData, ...priorityDates];
      } else {
        mergedCalendarData = calendarData;
      }

      let calendarDataFrame = [];
      const gameCountsMap = new Map();
      if (!Array.isArray(mergedCalendarData)) {
        resolve({ calendarDataFrame: calendarDataFrame, gameCountsMap: gameCountsMap });
        return;
      }

      let teamDataGroups = null;
      let row = -1;
      if (showGameCounts && Array.isArray(calendarData)) {
        teamDataGroups = group(
          calendarData.filter((x) => sportFilter.has(x.displaySportId) && seasonFilter.has(x.seasonId) && teamFilter.has(x.team1Id)),
          (d) => d.seasonId,
          (d) => d.displaySportId,
          (d) => d.team1Id
        );

        for (let [keySeason, valueSeasonMap] of teamDataGroups) {
          for (let [keySport, valueSportMap] of valueSeasonMap) {
            for (let [keyTeam, valueTeamArray] of valueSportMap) {
              let count_meta = get_overall_game_counts(valueTeamArray);
              gameCountsMap.set(`x_${keyTeam}_${keySport}_${keySeason}_text`, { count_meta: count_meta, game_count_text: "" });
            }
          }
        }
      }

      const tbd_map = new Map();
      const tbd_stem = "<TBD-";

      for (let index = 0; index < mergedCalendarData.length; index++) {
        const row = mergedCalendarData[index];
        if (row.dateTBD) {
          let next_tbd_id = tbd_map.get(row.team1Id);
          if (!next_tbd_id) {
            next_tbd_id = 0;
          }

          row["dateTBDKey"] = `${tbd_stem}${zero_pad(next_tbd_id, 4)}>`;
          tbd_map.set(row.team1Id, next_tbd_id + 1);
        }
      }

      const desired_row_date_format = "YYYY-MM-DD ddd";
      var calendarDataGroups = group(
        mergedCalendarData.filter((x) => sportFilter.has(x.displaySportId) && seasonFilter.has(x.seasonId) && teamFilter.has(x.team1Id)),
        (d) => (d.dateTBD ? d.dateTBDKey : format(parse(d.startDate, time_format.utc), `${desired_row_date_format}`)),
        (d) => d.seasonId,
        (d) => d.displaySportId,
        (d) => d.team1Id
      );

      let missing_dates_set = new Set();
      if (showAllMissingDates) {
        //get list of dates in data
        let all_data_dates = [];
        for (let key of calendarDataGroups.keys()) {
          if (!key.startsWith(tbd_stem)) {
            all_data_dates.push(key);
          }
        }
        all_data_dates.sort();

        //find min and max of dates in data and make a sequence
        let first_date = null;
        let last_date = null;
        let all_dates = [];
        if (all_data_dates.length > 1) {
          first_date = all_data_dates[0];
          last_date = all_data_dates[all_data_dates.length - 1];
          all_dates = getDateSequence(first_date, last_date, desired_row_date_format);
        }

        //find missing dates
        let all_data_dates_set = new Set(all_data_dates);

        for (let index = 0; index < all_dates.length; index++) {
          const row = all_dates[index];
          const row_formatted = format(parse(row, time_format.yyymmdd), `${desired_row_date_format}`);
          if (!all_data_dates_set.has(row_formatted)) {
            missing_dates_set.add(row_formatted);
          }
        }
      }

      let count = 0;
      for (let [keyDate, valueDateMap] of calendarDataGroups) {
        row = row + 1;
        let candidateRow = { row: row, x_startDate: keyDate };
        for (let [keySeason, valueSeasonMap] of valueDateMap) {
          for (let [keySport, valueSportMap] of valueSeasonMap) {
            let subRows = [];
            candidateRow["subRows"] = subRows;
            calendarDataFrame.push(candidateRow);
            for (let [keyTeam, valueTeamArray] of valueSportMap) {
              let row_text = "";
              let team1ConfId = 0;
              let divisionId = 0;

              for (let teamIndex = 0; teamIndex < valueTeamArray.length; ++teamIndex) {
                count = count + 1;
                const item = valueTeamArray[teamIndex];

                let candidateSubRow = {};
                subRows.push(candidateSubRow);

                let siteName = "";
                let bg = "";
                let text = "";
                team1ConfId = item.team1ConfId;
                divisionId = item.divisionId;
                if (item.dateTypeId) {
                  //priority date
                  bg = dateTypeId_color_dict[item.dateTypeId] || "black";
                  text = `Date: ${dateTypeId_dict[item.dateTypeId]}`;
                } else {
                  siteName = siteIdDict[item.siteId] || "";
                  bg = siteIdColorDict[item.siteId] || "black";
                  text = `${siteName} ${item.team2Name}`;
                }

                if (row_text) {
                  row_text = `${row_text}, ${text}`;
                } else {
                  row_text = `${text}`;
                }

                const broadcasterName = this.state.broadcasterIdToNameMap.get(item.broadcasterId) || "<None>";
                const tournamentName = item.tournamentName || "<None>";
                candidateSubRow[`key`] = count;
                candidateSubRow[`x_${keyTeam}_${keySport}_${keySeason}_text`] = text;
                candidateSubRow[`x_${keyTeam}_${keySport}_${keySeason}_team2Id`] = item.team2Id;
                candidateSubRow[`x_${keyTeam}_${keySport}_${keySeason}_dateTypeId`] = item.dateTypeId;
                candidateSubRow[`x_${keyTeam}_${keySport}_${keySeason}_team2Name`] = item.team2Name;
                candidateSubRow[`x_${keyTeam}_${keySport}_${keySeason}_siteId`] = item.siteId;
                candidateSubRow[`x_${keyTeam}_${keySport}_${keySeason}_kpi`] = item.kpi;
                candidateSubRow[`x_${keyTeam}_${keySport}_${keySeason}_tournamentName`] = tournamentName.includes("<None>") ? " " : tournamentName;
                candidateSubRow[`x_${keyTeam}_${keySport}_${keySeason}_broadcasterName`] = broadcasterName.includes("<None>") ? " " : broadcasterName;
                candidateSubRow[`x_${keyTeam}_${keySport}_${keySeason}_bg`] = bg;
                candidateSubRow[`x_${keyTeam}_${keySport}_${keySeason}_team2ConfId`] = item.team2ConfId;
              }

              const game_count_limit_key = this.get_game_count_limit_key({ divisionId: divisionId, displaySportId: keySport, seasonId: keySeason, conferenceId: team1ConfId });
              if (game_count_limit_key) {
                const game_count_limits = gameCountLimitsMap.get(game_count_limit_key);
                this.update_game_counts_map(keyTeam, keySport, keySeason, showGameCounts, gameCountsMap, game_count_limits);
              }
              candidateRow[`x_${keyTeam}_${keySport}_${keySeason}_text`] = row_text; //we need this for the xls export, because it does by dataField, which does not know about sub rows
            }
          }
        }
      }

      for (let keyDate of missing_dates_set) {
        row = row + 1;
        let candidateRow = { row: row, x_startDate: keyDate };
        calendarDataFrame.push(candidateRow);
        console.log("push missing dates");
      }

      resolve({ calendarDataFrame: calendarDataFrame, gameCountsMap: gameCountsMap });
      return;
    });
  };

  syncDataGridSelection = async (e) => {
    console.log("syncDataGridSelection");
    //this is the clear all event of the drop down box
    this.setState({
      gridBoxValueOtherSelectedTeamIds: e.value || [],
    });
  };

  teamDropDownBoxDataGridOnSelectionChanged = (e) => {
    const v = (e.selectedRowKeys.length && e.selectedRowKeys) || [];

    this.setState({
      gridBoxValueOtherSelectedTeamIds: v.slice(0, 18),
    });
  };

  teamDropDownBoxOnClosed = async () => {
    console.log("on closed");
  };

  dataGridRender = () => {
    return (
      <Fragment>
        <DataGrid
          keyExpr="id"
          dataSource={this.props.teams}
          hoverStateEnabled={true}
          selectedRowKeys={this.state.gridBoxValueOtherSelectedTeamIds}
          onSelectionChanged={this.teamDropDownBoxDataGridOnSelectionChanged}
          height="100%"
        >
          <Selection mode="multiple" />
          {/* <Scrolling mode="infinite" /> */}
          <Paging enabled={true} pageSize={30} />
          <FilterRow visible={true} />
          <Column caption="Team" dataField="name" dataType="string" />
          <Column caption="Conference" dataField="conferenceName" dataType="string" />
        </DataGrid>
      </Fragment>
    );
  };

  handleSportTagBoxOnValueChanged = async (event) => {
    let newIds = event.value;

    if (!newIds) {
      this.setState({
        otherSelectedSportIds: [],
      });
      return;
    }

    if (!Array.isArray(newIds)) {
      newIds = [newIds];
    }

    let loadedSportsInSeasonMapCopy = new Map(this.state.loadedSportsInSeasonMap);
    let gameCountLimitsMapCopy = new Map(this.state.gameCountLimitsMap);

    const selectedSeasonIds = [...this.state.otherSelectedSeasonIds, this.props.selectedSeason.id];
    const selectedSportIds = [...newIds, this.props.selectedSport.id];

    let [calendarDataNew, loadedSportsInSeasonNew, hasNewData, priorityDatesNew, gameCountLimitsMapNew] = await this.do_load_calendar_data(
      loadedSportsInSeasonMapCopy,
      selectedSportIds,
      selectedSeasonIds,
      gameCountLimitsMapCopy,
      this.state.teamIdToNameMap
    );

    if (hasNewData) {
      let calendarDataUpdate = [...this.state.calendarData, ...calendarDataNew];
      let priorityDatesUpdate = [...this.state.priorityDates, ...priorityDatesNew];

      this.setState({
        loadedSportsInSeasonMap: loadedSportsInSeasonNew,
        otherSelectedSportIds: newIds,
        calendarData: calendarDataUpdate,
        priorityDates: priorityDatesUpdate,
        gameCountLimitsMap: gameCountLimitsMapNew,
      });
    } else {
      this.setState({
        otherSelectedSportIds: newIds,
      });
    }
  };

  handleSeasonTagBoxOnValueChanged = async (event) => {
    let newIds = event.value;
    if (!newIds) {
      this.setState({
        otherSelectedSeasonIds: [],
      });
      return;
    }

    if (!Array.isArray(newIds)) {
      newIds = [newIds];
    }

    let loadedSportsInSeasonMapCopy = new Map(this.state.loadedSportsInSeasonMap);
    let gameCountLimitsMapCopy = new Map(this.state.gameCountLimitsMap);

    const selectedSeasonIds = [...new Set([...newIds, this.props.selectedSeason.id])];
    const selectedSportIds = [...this.state.otherSelectedSportIds, this.props.selectedSport.id];

    let [calendarDataNew, loadedSportsInSeasonNew, hasNewData, priorityDatesNew, gameCountLimitsMapNew] = await this.do_load_calendar_data(
      loadedSportsInSeasonMapCopy,
      selectedSportIds,
      selectedSeasonIds,
      gameCountLimitsMapCopy,
      this.state.teamIdToNameMap
    );

    if (hasNewData) {
      let calendarDataUpdate = [...this.state.calendarData, ...calendarDataNew];
      let priorityDatesUpdate = [...this.state.priorityDates, ...priorityDatesNew];

      this.setState({
        loadedSportsInSeasonMap: loadedSportsInSeasonNew,
        otherSelectedSeasonIds: newIds,
        calendarData: calendarDataUpdate,
        priorityDates: priorityDatesUpdate,
        gameCountLimitsMap: gameCountLimitsMapNew,
      });
    } else {
      this.setState({
        otherSelectedSeasonIds: newIds,
      });
    }
  };

  get_sub_row_style = (sr, fullKey) => {
    return {
      borderRadius: "4px",
      paddingRight: "4px",
      paddingLeft: "4px",
      backgroundColor: sr[`x_${fullKey}_bg`],
    };
  };

  get_sub_row_broadcaster = (sr, fullKey) => {
    
    return `${sr[`x_${fullKey}_broadcasterName`] || ""}`;
  };

  get_sub_row_tournament = (sr, fullKey) => {
    return `${sr[`x_${fullKey}_tournamentName`] || ""}` ;
  };

  get_sub_row_text = (sr, fullKey) => {
    return sr[`x_${fullKey}_text`] || "";
  };

  get_sub_row_team2Id = (sr, fullKey) => {
    return sr[`x_${fullKey}_team2Id`] || 0;
  };

  compareSchedulesCellRender = (obj) => {
    if (!obj?.data) {
      return <div></div>;
    }

    const { keyTeam, keySport, keySeason } = obj;
    const fullKey = `${keyTeam}_${keySport}_${keySeason}`;

    let styles = {
      borderRadius: "4px",
      paddingRight: "4px",
      paddingLeft: "4px",
    };

    const subRows = obj.data[`subRows`] || [];
    const cell_logo_height = logo_height;
    const cell_logo_width = this.state.checkBoxValueFitGridToWindow ? "100%" : logo_height;    

    if (this.state.selectedNameLogo === 1) {
      return (
        <div style={styles}>
          {subRows.map((sr) => (
            <div key={sr["key"]} style={this.get_sub_row_style(sr, fullKey)}>
              <div>{this.get_sub_row_text(sr, fullKey)}</div>
              <div style={{ whiteSpace: "pre" }}>{this.state.checkBoxValueShowBroacdcasters ? this.get_sub_row_broadcaster(sr, fullKey) : ""}</div>
              <div style={{ whiteSpace: "pre" }}>{this.state.checkBoxValueShowTournaments ? this.get_sub_row_tournament(sr, fullKey) : ""}</div>
            </div>
          ))}
        </div>
      );
    } else if (this.state.selectedNameLogo === 2) {
      return (
        <div style={styles}>
          {subRows
            .filter((srx) => this.get_sub_row_text(srx, fullKey))
            .map((sr) => (
              <div key={sr["key"]} style={this.get_sub_row_style(sr, fullKey)}>
                <img height={cell_logo_height} width={cell_logo_width} src={`logos_svg/${this.get_sub_row_team2Id(sr, fullKey)}.svg`} alt={""} />
                <div style={{ whiteSpace: "pre" }}>{this.state.checkBoxValueShowBroacdcasters ? this.get_sub_row_broadcaster(sr, fullKey) : ""}</div>
                <div style={{ whiteSpace: "pre" }}>{this.state.checkBoxValueShowTournaments ? this.get_sub_row_tournament(sr, fullKey) : ""}</div>
              </div>
            ))}
        </div>
      );
    } else {
      return (
        <div style={styles}>
          {subRows
            .filter((srx) => this.get_sub_row_text(srx, fullKey))
            .map((sr) => (
              <div key={sr["key"]} style={this.get_sub_row_style(sr, fullKey)}>
                <div>
                  <img height={cell_logo_height} width={cell_logo_width} src={`logos_svg/${this.get_sub_row_team2Id(sr, fullKey)}.svg`} alt={""} />
                </div>
                <div>{this.get_sub_row_text(sr, fullKey)}</div>
                <div style={{ whiteSpace: "pre" }}>{this.state.checkBoxValueShowBroacdcasters ? this.get_sub_row_broadcaster(sr, fullKey) : ""}</div>
                <div style={{ whiteSpace: "pre" }}>{this.state.checkBoxValueShowTournaments ? this.get_sub_row_tournament(sr, fullKey) : ""}</div>
              </div>
            ))}
        </div>
      );
    }

    // return <div>{`${siteName} ${name}`}</div>;
    //return <div>{` ${name}`}</div>;
  };

  nameLogoOnValueChanged = async (e) => {
    this.setState({ selectedNameLogo: e.value });
  };

  onValueChangedShowBroacdcasters(args) {
    this.setState({
      checkBoxValueShowBroacdcasters: args.value,
    });
  }

  onValueChangedShowGameCounts(args) {
    this.setState({
      checkBoxValueShowGameCounts: args.value,
    });
  }

  onValueChangedShowTournaments(args) {
    this.setState({
      checkBoxValueShowTournaments: args.value,
    });
  }

  onValueChangedFitGridToWindow(args) {
    this.setState({
      checkBoxValueFitGridToWindow: args.value,
    });
  }

  onValueChangedShowAllMissingDates = async (args) => {
    this.setState({
      checkBoxValueShowAllMissingDates: args.value,
    });
  };

  onValueChangedShowPriorityDates = async (args) => {
    this.setState({
      checkBoxValueShowPriorityDates: args.value,
    });
  };

  async onClickCompare(e) {
    notify(`Comparing schedules`);
    let selectedTeams = [];
    if (this.props.selectedTeam.id) {
      selectedTeams = [...this.state.gridBoxValueOtherSelectedTeamIds, this.props.selectedTeam.id];
    } else {
      selectedTeams = [...this.state.gridBoxValueOtherSelectedTeamIds];
    }

    const { calendarDataFrame, gameCountsMap } = await this.makeCalendarDataFramePromiseAsync(
      this.state.calendarData,
      selectedTeams,
      this.state.teamIdToNameMap,
      [...this.state.otherSelectedSeasonIds, this.props.selectedSeason.id],
      [...this.state.otherSelectedSportIds, this.props.selectedSport.id],
      this.state.checkBoxValueShowAllMissingDates,
      this.state.priorityDates,
      this.state.checkBoxValueShowPriorityDates,
      this.state.checkBoxValueShowGameCounts,
      this.state.gameCountLimitsMap,
      this.state.seasonIdToNameMap,
      this.state.sportIdToNameMap
    );

    notify(`Generating comparison grid`);
    this.setState({
      calendarDataFrameOnClickCompare: calendarDataFrame,
      gameCountsMap: gameCountsMap,
    });
  }

  render() {    
    return (
      <Fragment>
        <Card className="main-card my-2 mx-2 border border-primary rounded">
          {!this.props.selectedTeam.id && (
            <CardHeader className="card-header-tab">
              <div style={{ color: "red" }} className="card-header-title thick">
                Select a team using the above Team selector
              </div>
            </CardHeader>
          )}
          {this.props.selectedTeam.id && (
            <CardHeader className="card-header-tab">
              <div style={{ float: "left" }} className="card-header-title thick">
                Compare schedules
              </div>
              <div style={{ float: "right" }}>
                <Button icon="refresh" hint="Refesh Table" type="back" stylingMode="contained" onClick={this.onClickRefresh} />
              </div>
            </CardHeader>
          )}

          <CardBody className="pt-1">
            <Row className="py-1">
              <Col xs={12}>
                <div className="px-0">
                  <label className="mb-0 pt-2">
                    <b>Other teams</b>
                  </label>
                  <DropDownBox
                    value={this.state.gridBoxValueOtherSelectedTeamIds}
                    deferRendering={false}
                    placeholder="Select other teams to show"
                    showClearButton={true}
                    dataSource={this.props.teams}
                    valueExpr="id"
                    displayExpr="name"
                    onValueChanged={this.syncDataGridSelection}
                    contentRender={this.dataGridRender}
                    //onClosed={this.teamDropDownBoxOnClosed}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pb-1">
              <Col xs={6}>
                <div className="px-0">
                  <label className="mb-0 pt-0">
                    <b>Other years</b>
                  </label>
                  <TagBox
                    placeholder="Select other years to show"
                    //value={this.state.otherSelectedSeasonIds}
                    maxDisplayedTags={6}
                    items={this.props.seasons}
                    displayExpr="name"
                    valueExpr="id"
                    showSelectionControls={true}
                    applyValueMode="useButtons"
                    onValueChanged={this.handleSeasonTagBoxOnValueChanged}
                  />
                </div>
              </Col>
              <Col xs={6}>
                <div className="px-0">
                  <label className="mb-0 pt-0">
                    <b>Other sports</b>
                  </label>
                  <TagBox
                    placeholder="Select other sports to show"
                    maxDisplayedTags={6}
                    items={this.props.sports}
                    displayExpr="name"
                    valueExpr="id"
                    showSelectionControls={true}
                    applyValueMode="useButtons"
                    onValueChanged={this.handleSportTagBoxOnValueChanged}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pb-1">
              <Col xs={6}>
                <div className="px-0">
                  <label className="mb-0 pt-0">
                    <b>Name/Logo</b>
                  </label>
                  <SelectBox
                    value={this.state.selectedNameLogo}
                    valueExpr="id"
                    displayExpr="name"
                    items={nameLogoItems}
                    placeholder="Choose name or logo"
                    showClearButton={false}
                    onValueChanged={this.nameLogoOnValueChanged}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <CheckBox value={this.state.checkBoxValueShowGameCounts} onValueChanged={this.onValueChangedShowGameCounts} width={120} text="Show Game Counts" />
                <CheckBox value={this.state.checkBoxValueShowBroacdcasters} onValueChanged={this.onValueChangedShowBroacdcasters} width={120} text="Add Broadcasters" />
                <CheckBox value={this.state.checkBoxValueShowTournaments} onValueChanged={this.onValueChangedShowTournaments} width={120} text="Add Tournaments" />
                <CheckBox value={this.state.checkBoxValueFitGridToWindow} onValueChanged={this.onValueChangedFitGridToWindow} width={120} text="Fit grid to window" />
                <CheckBox value={this.state.checkBoxValueShowAllMissingDates} onValueChanged={this.onValueChangedShowAllMissingDates} width={120} text="Show all dates" />
                <CheckBox value={this.state.checkBoxValueShowPriorityDates} onValueChanged={this.onValueChangedShowPriorityDates} width={140} text="Show priority dates" />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={3} className="ml-2">
                <Button style={{ fontSize: "14px", fontWeight: "bold" }} icon="search" type="danger" width="100%" height="30px" onClick={this.onClickCompare}>
                  Compare
                </Button>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col xs={12}>
                <DataGrid
                  // height={"100%"}
                  width={`100%`}
                  columnAutoWidth={true}
                  dataSource={this.state.calendarDataFrameOnClickCompare}
                  allowColumnReordering={true}
                  showBorders={true}
                  rowAlternationEnabled
                  wordWrapEnabled={false}
                  // ref={(ref) => (this.grid = ref)}
                  selection={{ mode: "multiple", showCheckBoxesMode: "none" }}
                >
                  <Export enabled={true} fileName={this.props.dblstupd ? this.props.dblstupd : "KPI-CompareSchedules"} />
                  {/* <Scrolling mode="virtual" rowRenderingMode="virtual" /> this is broken in latest*/}
                  <Paging defaultPageSize={400} />
                  <Pager showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />
                  <HeaderFilter visible={false} />
                  {/* <StateStoring enabled={true} type="localStorage" storageKey={this.props.id} /> */}
                  {/* <ColumnChooser enabled={true} /> */}
                  <ColumnFixing enabled={true} />
                  <Column
                    caption="Date"
                    dataField="x_startDate"
                    alignment="left"
                    headerCellRender={renderTitleHeader}
                    // fixed={true}
                    // fixedPosition="left"
                    dataType="string"
                    sortOrder="asc"
                  ></Column>

                  {[...new Set([this.props.selectedSport.id, ...this.state.otherSelectedSportIds])].map((keySport) => {
                    const sportName = this.state.sportIdToNameMap.get(keySport);
                    let seasons_in_play = [...new Set([this.props.selectedSeason.id, ...this.state.otherSelectedSeasonIds])];
                    seasons_in_play.sort((a, b) => a - b);

                    return seasons_in_play.map((keySeason) => {
                      const seasonName = this.state.seasonIdToNameMap.get(keySeason);
                      return [...new Set([this.props.selectedTeam.id, ...this.state.gridBoxValueOtherSelectedTeamIds])].map((keyTeam, i) => {
                        const teamName = this.state.teamIdToNameMap.get(keyTeam);
                        const key_stem = `x_${keyTeam}_${keySport}_${keySeason}`;

                        const gameCountsMap_tuple = this.state.gameCountsMap.get(`${key_stem}_text`) || null;
                        let game_count_text = "";
                        if (gameCountsMap_tuple) {
                          game_count_text = gameCountsMap_tuple.game_count_text;
                        }

                        let header_caption = `${sportName},${seasonName},${teamName}`;
                        if (game_count_text) {
                          header_caption = `${header_caption},(${game_count_text})`;
                        }

                        return (
                          <Column
                            key={`${keyTeam}_${i}`}
                            caption={`${header_caption}`}
                            width={this.state.checkBoxValueFitGridToWindow ? "100%" : null}
                            dataField={`${key_stem}_text`}
                            alignment="center"
                            headerCellRender={(e) =>
                              this.renderCompareSchedulesTitleHeader({
                                teamName: teamName,
                                sportName: sportName,
                                seasonName: seasonName,
                                game_count_text: game_count_text,
                                data: e,
                              })
                            }
                            //fixed={true}
                            // fixedPosition="left"
                            cellRender={(e) => this.compareSchedulesCellRender({ keyTeam: keyTeam, keySport: keySport, keySeason: keySeason, data: e.data })}
                          ></Column>
                        );
                      });
                    });
                  })}
                </DataGrid>
              </Col>
            </Row>
            <Row className="my-5">
              {
                <Col xs={12}>
                  <Chart id="compareSchedulesChart1" palette="Carmine" dataSource={this.state.calendarDataFrameOnClickCompare}>
                    <CommonSeriesSettings argumentField="row" type={"line"} />
                    <CommonAxisSettings>
                      <Grid visible={true} />
                    </CommonAxisSettings>
                    {[...new Set([this.props.selectedSport.id, ...this.state.otherSelectedSportIds])].map((keySport) => {
                      const sportName = this.state.sportIdToNameMap.get(keySport);
                      let seasons_in_play = [...new Set([this.props.selectedSeason.id, ...this.state.otherSelectedSeasonIds])];
                      seasons_in_play.sort((a, b) => a - b);
                      return seasons_in_play.map((keySeason) => {
                        const seasonName = this.state.seasonIdToNameMap.get(keySeason);
                        return [...new Set([this.props.selectedTeam.id, ...this.state.gridBoxValueOtherSelectedTeamIds])].map((keyTeam, i) => {
                          const teamName = this.state.teamIdToNameMap.get(keyTeam);
                          const key = `x_${keyTeam}_${keySport}_${keySeason}_kpi`;
                          return <Series key={key} valueField={key} name={`${teamName}<br/>${seasonName}<br/>${sportName}`} />;
                        });
                      });
                    })}
                    <Size height={500} />
                    <Margin bottom={20} />
                    <ArgumentAxis valueMarginsEnabled={false} discreteAxisDivisionMode="crossLabels" type="discrete">
                      <Grid visible={true} />
                    </ArgumentAxis>
                    <Legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="bottom" />
                    <Export enabled={true} />
                    <Title text="KPI">
                      <Subtitle text="KPI value vs. game" />
                    </Title>
                    <Tooltip enabled={true} />
                  </Chart>
                </Col>
              }
            </Row>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default CompareSchedules;
