import http from "./httpService";
import httpExternal from "./httpExternal";
import { getTok } from "./../utils/common-funcs";

export function get_sandbox_for_team_season_sport(token, team_id, season_id, display_sport_id, division_id) {
  return http.get(`/sandboxResumes/f/${getTok()}/${team_id}/${season_id}/${display_sport_id}/${division_id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function get_sandbox_vtp_for_season_and_sport(token, season_id, display_sport_id, division_id, conference_id) {
  return http.get(`/sandboxResumes/vtp/${getTok()}/${season_id}/${display_sport_id}/${division_id}/${conference_id}`, { headers: { Authorization: `Bearer ${token}` } });
}

export function get_sandbox_kpi_for_season_and_sport(token, season_id, display_sport_id, division_id) {
  return http.get(`/sandboxResumes/kpi/${getTok()}/${season_id}/${display_sport_id}/${division_id}`, { headers: { Authorization: `Bearer ${token}` } });
}

export function get_new_blocked_date_entries(token, interval_days) {
  return http.get(`/dateBlocker/new-entries/${getTok()}/${interval_days}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function get_blocked_dates(token, season_id, team_id, display_sport_id, division_id) {
  return http.get(`/dateBlocker/${getTok()}/${team_id}/${season_id}/${display_sport_id}/${division_id}`, { headers: { Authorization: `Bearer ${token}` } });
}

export function get_priority_dates(token, season_id, team_id, display_sport_id, division_id) {
  return http.get(`/dateBlocker/priority-dates/${getTok()}/${team_id}/${season_id}/${display_sport_id}/${division_id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function get_priority_dates_for_sport(token, season_id, team_id, display_sport_id, division_id) {
  return http.get(`/dateBlocker/priority-dates/all/${getTok()}/${team_id}/${season_id}/${display_sport_id}/${division_id}/p`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function get_blocked_dates_for_sport(token, season_id, team_id, display_sport_id, division_id) {
  return http.get(`/dateBlocker/priority-dates/all/${getTok()}/${team_id}/${season_id}/${display_sport_id}/${division_id}/b`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function get_date_manager_dates(token, season_id, team_id, display_sport_id) {
  return http.get(`/dateBlocker/${getTok()}/${team_id}/${season_id}/${display_sport_id}`, { headers: { Authorization: `Bearer ${token}` } });
}

export function get_season_blocked_and_priority_dates(token, season_id, division_id) {
  return http.get(`/dateBlocker/${getTok()}/${season_id}/${division_id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function post_priority_dates(token, data) {
  return http.post(`/dateBlocker/priority-dates/add/${getTok()}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function delete_priority_dates(token, data) {
  return http.post(`/dateBlocker/priority-dates/delete/${getTok()}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function dial_kpi_calculator(token, division_id, display_sport_id, season_id, dry_run, conference_id) {
  return http.post(
    `/kpiCaller/process/${getTok()}/${dry_run}/${division_id}/${display_sport_id}/${season_id}/${conference_id}`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
}

export function dial_vtp_calculator(token, division_id, display_sport_id, season_id, dry_run) {
  return http.post(
    `/kpiCaller/process_historical_vtp/${getTok()}/${dry_run}/${division_id}/${display_sport_id}/${season_id}`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
}

export function dial_team_information_calculator(token, division_id, display_sport_id, season_id, dry_run) {
  return http.post(
    `/kpiCaller/update_team_information/${getTok()}/${dry_run}/${division_id}/${display_sport_id}/${season_id}`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
}

export function dial_net_calculator(token, division_id, display_sport_id, season_id, dry_run) {
  return http.post(
    `/kpiCaller/process_net/${getTok()}/${dry_run}/${division_id}/${display_sport_id}/${season_id}`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
}

export function dial_scores_calculator(token, division_id, display_sport_id, season_id, dry_run, date, date_lhs, date_rhs) {
  return http.post(
    `/kpiCaller/update_scores/${getTok()}/${dry_run}/${division_id}/${display_sport_id}/${season_id}`,
    { date: date, date_lhs: date_lhs, date_rhs: date_rhs },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
}

export function dial_data_quality_calculator(token, division_id, display_sport_id, season_id, dry_run, date) {
  return http.post(
    `/kpiCaller/data_quality/${getTok()}/${dry_run}/${division_id}/${display_sport_id}/${season_id}`,
    { date: date },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
}

export function dial_teams_conferences_calculator(token, division_id, display_sport_id, season_id, dry_run) {
  return http.post(
    `/kpiCaller/update_conferences/${getTok()}/${dry_run}/${division_id}/${display_sport_id}/${season_id}`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
}

export function get_team_distances(token, team_id) {
  return http.get(`/unavailability/distances/${getTok()}/${team_id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function has_resume_rankings(token, division_id, display_sport_id, season_id) {
  return http.get(`/unavailability/hasResumeRankings/${getTok()}/${division_id}/${display_sport_id}/${season_id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function get_available_teams_in_date_range(token, division_id, display_sport_id, season_id, dates_array) {
  return http.post(`/unavailability/teams/${getTok()}/${division_id}/${display_sport_id}/${season_id}`, dates_array, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function get_game_dates_for_teams_in_date_range(token, division_id, display_sport_id, season_id, team_id, open_bounds) {
  return http.post(`/unavailability/gameDates/${getTok()}/${division_id}/${display_sport_id}/${season_id}/${team_id}`, open_bounds, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function get_all_unavailable_team_date_ranges(token, division_id, display_sport_id, season_id, dates_array, is_conf) {
  return http.post(
    `/unavailability/ranges/${getTok()}/${division_id}/${display_sport_id}/${season_id}`,
    { open_bounds: dates_array, is_conf: is_conf },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
}

export function get_all_team_metadata_for_unavailability_resolution(token, division_id, display_sport_id, season_id, dates_array) {
  return http.get(`/unavailability/metadata/${getTok()}/${division_id}/${display_sport_id}/${season_id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function get_user_defined_toutnament_score_card(token, division_id, display_sport_id, season_id) {
  return http.get(`/tournament-manager/score-card/${getTok()}/${division_id}/${display_sport_id}/${season_id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function get_user_defined_toutnaments(token, division_id, display_sport_id, season_id) {
  return http.get(`/tournament-manager/${getTok()}/${division_id}/${display_sport_id}/${season_id}`, { headers: { Authorization: `Bearer ${token}` } });
}

export function get_is_tournament_used(token, tournament_name, divisionId, displaySportId) {
  return http.get(`/tournament-manager/used/${getTok()}/${tournament_name}/${divisionId}/${displaySportId}`, { headers: { Authorization: `Bearer ${token}` } });
}

export function delete_user_defined_tournament(token, body) {
  return http.post(`/tournament-manager/del/${getTok()}`, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function put_user_defined_tournament(token, body) {
  return http.put(`/tournament-manager/${getTok()}`, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function patch_user_defined_tournament(token, body) {
  return http.patch(`/tournament-manager/${getTok()}`, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function patch_hq_current_user_metadata(token, provider_id, body) {
  return http.patch(`/user/current_user/user_metadata/${getTok()}/${provider_id}`, body, { headers: { Authorization: `Bearer ${token}` } });
}

export function post_hq_current_user_metadata(token, provider_id, body) {
  return http.post(`/user/current_user/user_metadata/${getTok()}/${provider_id}`, body, { headers: { Authorization: `Bearer ${token}` } });
}

export function post_hq_current_user_access(token, provider_id, body) {
  return http.post(`/user/current_user/user_access/${getTok()}/${provider_id}`, body, { headers: { Authorization: `Bearer ${token}` } });
}

export function get_hq_all_access_rights(token) {
  return http.get(`/user/all/${getTok()}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function get_all_contacts(token, division_id, display_sport_id, season_id) {
  return http.get(`/user/contacts/${getTok()}/${division_id}/${display_sport_id}/${season_id}`, { headers: { Authorization: `Bearer ${token}` } });
}

export function get_new_game_schedule_entry(token, entry_type, interval_days) {
  return http.get(`/game-schedule-entry/new-games/${getTok()}/${entry_type}/${interval_days}`, { headers: { Authorization: `Bearer ${token}` } });
}

export function put_game_schedule_entry(token, body) {
  return http.put(`/game-schedule-entry/games/${getTok()}`, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
export function patch_game_schedule_entry(token, body) {
  return http.patch(`/game-schedule-entry/games/${getTok()}`, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function delete_game_schedule_entry(token, body) {
  return http.post(`/game-schedule-entry/delete/${getTok()}`, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function get_game_schedule_entry(token, entry_type, team1_id, division_id, display_sport_id, season_id) {
  return http.get(`/game-schedule-entry/games/${getTok()}/${entry_type}/${team1_id}/${division_id}/${display_sport_id}/${season_id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function get_game_schedule_entry_for_conference(token, entry_type, conference_id, division_id, display_sport_id, season_id, tournament_id) {
  return http.get(
    `/game-schedule-entry/filteredGames/${getTok()}/${entry_type}/${division_id}/${display_sport_id}/${season_id}/${conference_id}/${tournament_id}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
}

export function get_game_count_limits_for_sport(token, division_id, display_sport_id, conference_id, season_id) {
  return http.get(`/public/gameCounts/${getTok()}/${division_id}/${display_sport_id}/${conference_id}/${season_id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function get_computed_game_counts_for_sport(token, division_id, display_sport_id, conference_id, season_id) {
  return http.get(`/public/computedGameCounts/${getTok()}/${division_id}/${display_sport_id}/${conference_id}/${season_id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function get_season_conferences(token, division_id, display_sport_id, season_id) {
  return http.get(`/public/seasonConferences/${getTok()}/${division_id}/${display_sport_id}/${season_id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function put_game_counts_for_sport(token, division_id, display_sport_id, conference_id, season_id, body) {
  return http.put(`/public/gameCounts/${getTok()}/${division_id}/${display_sport_id}/${conference_id}/${season_id}`, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function get_game_schedule_entry_for_interval(token, entry_type, division_id, display_sport_id, season_id, start_date, end_date) {
  return http.get(`/game-schedule-entry/intervalGames/${getTok()}/${entry_type}/${division_id}/${display_sport_id}/${season_id}/${start_date}/${end_date}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function patch_auth0_current_user_metadata(token, json, user_id) {
  return httpExternal.patch(`https://os-dev.auth0.com/api/v2/users/${user_id}`, json, {
    headers: {
      Authorization: `Bearer  ${token}`,
      "Content-Type": "application/json",
    },
  });

  // return httpExternal.get(`https://os-dev.auth0.com/api/v2/users/${user_id}`, json, {
  //   headers: { Authorization: `Bearer  ${token}`, "Content-Type": "application/json" },
  // });
}

// var options = {
//   method: "PATCH",
//   url: "https://os-dev.auth0.com/api/v2/users/user_id",
//   headers: { authorization: "Bearer ABCD", "content-type": "application/json" },
//   body: { user_metadata: { addresses: { home: "123 Main Street, Anytown, ST 12345" } } },
//   json: true,
// };

// request(options, function (error, response, body) {
//   if (error) throw new Error(error);

// });
