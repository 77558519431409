import React, { Component, Fragment } from "react";
import { Row, Col, CardHeader, Card, CardBody, Badge } from "reactstrap";
import { Button } from "devextreme-react";
import notify from "devextreme/ui/notify";
import ResumeRankingTable from "./resume-rankings-table";
import { display_sports_needing_regions } from "./../utils/enums";

class HomeRankingsDx extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showRegionsName: false,
    };

    this.onClickRefresh = this.onClickRefresh.bind(this);
  }

  async componentDidMount() {
    await this.refresh_data_sources();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.isAuthenticated !== prevProps.isAuthenticated ||
      this.props.auth0jwt !== prevProps.auth0jwt ||
      this.props.selectedSeason.id !== prevProps.selectedSeason.id ||
      this.props.selectedDivision.id !== prevProps.selectedDivision.id ||
      this.props.selectedSport.id !== prevProps.selectedSport.id
    ) {
      await this.refresh_data_sources();
    }
  }

  async onClickRefresh(e) {
    notify(`Refreshing table`);
    this.props.handle_active_set_change(false);
    await this.refresh_data_sources();
  }

  refresh_data_sources = async () => {
    notify(`Refreshing local data`);

    try {
      if (this.props.isAuthenticated && this.props.auth0jwt && this.props.selectedSport.id && this.props.selectedSeason.id && this.props.selectedDivision.id) {
        const showRegionsName = display_sports_needing_regions.has(this.props.selectedSport.id) && this.props.selectedDivision.id === 2;
        this.setState({
          showRegionsName: showRegionsName,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <Fragment>
        {!this.props.columns && <div></div>}
        {this.props.columns && (
          <Card className="main-card my-2 mx-2 border border-primary rounded">
            <CardHeader className="card-header-tab">
              <div style={{ float: "left" }} className="card-header-title thick">
                KPI Rankings
              </div>
              <div style={{ float: "right" }}>
                <Button icon="refresh" hint="Refesh Table" type="back" stylingMode="contained" onClick={this.onClickRefresh} />
              </div>
            </CardHeader>

            <div style={{ marginLeft: "auto", marginRight: "auto" }}>
              <Badge color="primary">{this.props.db_last_update}</Badge>
            </div>
            <CardBody className="pt-1">
              <Row className="mt-0">
                <Col>{this.props.loadingFromDb && <div style={{ color: "red" }}>Reading values from Faktor servers</div>}</Col>
              </Row>
              <Row className="mb-5">
                <Col xs="12">
                  <ResumeRankingTable {...this.props} xls_export_file_name={"KPI-Rankings"} id={"ResumeRankingTable"} page_type={0} showRegionsName={this.state.showRegionsName} />
                </Col>
              </Row>
              <Row>
                <Col>{`*: ${Intl.DateTimeFormat().resolvedOptions().timeZone} time zone`}</Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Fragment>
    );
  }
}

export default HomeRankingsDx;
