import React, { Component, Fragment } from "react";
import Scheduler, { Resource } from "devextreme-react/scheduler";
import DataGrid, { Column, HeaderFilter, Selection, Paging, FilterRow, Pager, Export, Lookup } from "devextreme-react/data-grid";

import { Row, Col, CardHeader, Card, CardBody } from "reactstrap";
import { get_season_blocked_and_priority_dates, get_sandbox_for_team_season_sport } from "../services/privateService";
import { GetIdToNameMap, makeClinetFriendlySeasonName } from "../utils/common-funcs";
import { renderTitleHeader } from "./../utils/common-rendering-funcs";
import TagBox from "devextreme-react/tag-box";
import { get_broadcasters } from "../services/publicService";
import { colors } from "../utils/colors";
import { views, siteIdDict, siteIdToNameDict, blocked_for_dic, isConference, isConferenceDict, dateTypeId_dict, dateTypeId_lookup,  pager_lengths } from "../utils/enums";
import RadioGroup from "devextreme-react/radio-group";
import { DropDownBox } from "devextreme-react";

const currentDate = new Date();

const calendarTemplateItems = [
  { id: 1, name: "Show Location" },
  { id: 2, name: "Show Sport" },
];

const div_style = { borderRadius: "5px", borderWidth: "4px", borderStyle: "solid", borderColor: "green", padding: "10px", margin: "10px" };

export const resourcesList = ["Team", "Sport", "Conference", "Site", "DateType"];

const siteResourceData = [
  {
    id: 1,
    text: "Home",
    color: "#3F69AA",
  },
  {
    id: 2,
    text: "Away",
    color: "#BD3D3A",
  },
  {
    id: 3,
    text: "Neutral",
    color: "#006E6D",
  },
  {
    id: 4,
    text: "Other",
    color: "grey",
  },
];

class TeamCalendar extends Component {
  constructor(props) {
    super(props);

    const total_number_of_available_colors = colors.length;
    const sport_with_color = this.props.sports.map((s) => ({
      ...s,
      color: colors[s.id % total_number_of_available_colors],
    }));

    this.state = {
      gridBoxValueSelectedTeamIds: [],
      calendarData: [],
      sports: sport_with_color || {},
      selectedDisplaySportIds: [this.props.selectedSport.id],
      loadedSportIds: new Set(),
      selectedResourceItem: resourcesList[0],
      teamIdToNameMap: new Map(),
      sportIdToNameMap: new Map(),
      conferenceIdToNameMap: new Map(),
      divisionIdToNameMap: new Map(),
      seasonIdToNameMap: new Map(),
      broadcasterToNameMap: new Map(),
      selectedCalendarTemplateItemIds: calendarTemplateItems.map((x) => x.id),
    };

    this.onAppointmentFormOpening = this.onAppointmentFormOpening.bind(this);
    this.update_priority_dateFields_in_place = this.update_priority_dateFields_in_place.bind(this);
    this.calculateDisplayValueSeason = this.calculateDisplayValueSeason.bind(this);
  }

  do_load_calendar_data = async (loadedSportIds, selectedDisplaySportIds) => {
    let calendarData = [];
    let loadedSportIdsSet = new Set(loadedSportIds);
    for (var sportIdx = 0; sportIdx < selectedDisplaySportIds.length; sportIdx++) {
      const sport_to_load = selectedDisplaySportIds[sportIdx];
      if (!loadedSportIdsSet.has(sport_to_load)) {
        loadedSportIdsSet.add(sport_to_load);
        const res = await get_sandbox_for_team_season_sport(this.props.auth0jwt, 0, this.props.selectedSeason.id, sport_to_load, this.props.selectedDivision.id);

        const fixed = res.data.map((x) => (x.endDate === x.startDate ? { ...x, allDay: true } : x));



        calendarData = [...calendarData, ...fixed];
      }
    }

    return [calendarData, loadedSportIdsSet];
  };

  async componentDidMount() {
    let calendarData = [];
    let all_priority_dates = [];
    let broadcasterToNameMap = new Map();
    let teamIdToNameMap = new Map();
    let sportIdToNameMap = new Map();
    let conferenceIdToNameMap = new Map();
    let divisionIdToNameMap = new Map();
    let seasonIdToNameMap = new Map();
    let loadedSportIds = new Set(this.state.loadedSportIds);

    if (this.props.isAuthenticated && this.props.auth0jwt && this.props.selectedSeason.id && this.props.selectedDivision.id && this.props.selectedSport.id) {
      let { data: broadcasters } = await get_broadcasters();
      broadcasterToNameMap = GetIdToNameMap(broadcasters);

      teamIdToNameMap = GetIdToNameMap(this.props.allTeams);
      sportIdToNameMap = GetIdToNameMap(this.props.sports);
      conferenceIdToNameMap = GetIdToNameMap(this.props.allConferences);
      divisionIdToNameMap = GetIdToNameMap(this.props.allDivisions);
      seasonIdToNameMap = GetIdToNameMap(this.props.allSeasons);

      [calendarData, loadedSportIds] = await this.do_load_calendar_data(new Set(), [this.props.selectedSport.id]);

      const res2 = await get_season_blocked_and_priority_dates(this.props.auth0jwt, this.props.selectedSeason.id, this.props.selectedDivision.id);

      all_priority_dates = this.update_priority_dateFields_in_place(res2.data, teamIdToNameMap);
    }

    this.setState({
      calendarData: calendarData.concat(all_priority_dates),
      broadcasterToNameMap: broadcasterToNameMap,
      teamIdToNameMap: teamIdToNameMap,
      sportIdToNameMap: sportIdToNameMap,
      conferenceIdToNameMap: conferenceIdToNameMap,
      divisionIdToNameMap: divisionIdToNameMap,
      seasonIdToNameMap: seasonIdToNameMap,
      loadedSportIds: loadedSportIds,
    });
  }

  update_priority_dateFields_in_place(table, teamIdToNameMap) {
    if (!Array.isArray(table)) {
      return [];
    }

    //we have to set allDay to true, because some times, the blocked dates have identical start and end hours
    return table.map((x) => ({
      ...x,
      allDay: true,
      text: `${teamIdToNameMap.get(x.team1Id)} ${dateTypeId_dict[x.dateTypeId]} Date for ${blocked_for_dic[x.blockedForId]}`,
    }));
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.isAuthenticated &&
      this.props.auth0jwt &&
      this.props.selectedSeason.id &&
      this.props.selectedDivision.id &&
      this.props.selectedSport.id &&
      (this.props.isAuthenticated !== prevProps.isAuthenticated ||
        this.props.auth0jwt !== prevProps.auth0jwt ||
        this.props.selectedSeason.id !== prevProps.selectedSeason.id ||
        this.props.selectedSport.id !== prevProps.selectedSport.id ||
        this.props.selectedDivision.id !== prevProps.selectedDivision.id ||
        this.props.allTeamsAndSportsInSeason !== prevProps.allTeamsAndSportsInSeason)
    ) {
      const teamIdToNameMap = GetIdToNameMap(this.props.allTeams);
      const sportIdToNameMap = GetIdToNameMap(this.props.sports);
      const conferenceIdToNameMap = GetIdToNameMap(this.props.allConferences);
      const divisionIdToNameMap = GetIdToNameMap(this.props.allDivisions);
      const seasonIdToNameMap = GetIdToNameMap(this.props.allSeasons);

      let calendarData = [];
      let all_priority_dates = [];
      let loadedSportIds = new Set();
      const selectedDisplaySportIds = [this.props.selectedSport.id];

      [calendarData, loadedSportIds] = await this.do_load_calendar_data(new Set(), selectedDisplaySportIds);

      const res2 = await get_season_blocked_and_priority_dates(this.props.auth0jwt, this.props.selectedSeason.id, this.props.selectedDivision.id);
      all_priority_dates = this.update_priority_dateFields_in_place(res2.data, teamIdToNameMap);

      this.setState({
        calendarData: calendarData.concat(all_priority_dates),
        selectedDisplaySportIds: selectedDisplaySportIds,
        teamIdToNameMap: teamIdToNameMap,
        sportIdToNameMap: sportIdToNameMap,
        conferenceIdToNameMap: conferenceIdToNameMap,
        divisionIdToNameMap: divisionIdToNameMap,
        seasonIdToNameMap: seasonIdToNameMap,
        loadedSportIds: loadedSportIds,
      });
    }
  }

  handleSportTagBoxOnValueChanged = async (event) => {
    const newIds = event.value;
    if (newIds && newIds.length > 0) {
      let [calendarData, loadedSportIds] = await this.do_load_calendar_data(new Set(this.state.loadedSportIds), newIds);

      const newCalendarData = [...this.state.calendarData, ...calendarData];

      this.setState({ selectedDisplaySportIds: newIds, calendarData: newCalendarData, loadedSportIds: loadedSportIds });
    }
  };

  handleCalendarTemplateItemsTagBoxOnValueChanged = async (event) => {
    const newIds = event.value;
    if (newIds) {
      this.setState({ selectedCalendarTemplateItemIds: newIds });
    }
  };

  onResourcesListValueChanged = (args) => {
    this.setState({
      selectedResourceItem: args.value,
    });
  };

  selectItem = (e) => {
    this.setState({
      currentItem: Object.assign({}, e.itemData),
    });
  };

  syncDataGridSelection = (e) => {
    //this is the clear all even of the drop down box
    this.setState({
      gridBoxValueSelectedTeamIds: e.value || [],
    });
  };

  dataGrid_onSelectionChanged = (e) => {
    const v = (e.selectedRowKeys.length && e.selectedRowKeys) || [];
    this.setState({
      gridBoxValueSelectedTeamIds: v.slice(0, 18),
    });
  };

  AppointmentTemplate = (model) => {
    const team1Name = this.state.teamIdToNameMap.get(model.appointmentData.team1Id) || "";

    if (model.appointmentData.blockedForId) {
      const blocked_for_note = model.appointmentData.isPrivate ? "" : ` (Note: ${model.appointmentData.note} )`;

      return (
        <div>
          <div>
            {team1Name} <strong>{dateTypeId_dict[model.appointmentData.dateTypeId]}</strong> Date for {blocked_for_dic[model.appointmentData.blockedForId]} by{" "}
            {model.appointmentData.userName}
          </div>
          <div>{blocked_for_note}</div>
        </div>
      );
    }

    const team2Name = this.state.teamIdToNameMap.get(model.appointmentData.team2Id) || "";
    const siteName = siteIdDict[model.appointmentData.siteId] || "";
    return (
      <div>
        {/* <img height={20} width={20} src={`../logos_svg/${model.appointmentData.team2Id}.svg`} alt={model.appointmentData.text} /> */}
        <div>{this.state.gridBoxValueSelectedTeamIds.length > 1 && <strong>{team1Name}</strong>}</div>
        <div>
          {siteName}
          <strong> {team2Name}</strong>
        </div>
        <div>{this.state.selectedCalendarTemplateItemIds.includes(1) && <span style={{ fontSize: "11px" }}>{` ${model.appointmentData.location} `}</span>}</div>
        <div>{this.state.selectedCalendarTemplateItemIds.includes(2) && <span style={{ fontSize: "11px" }}>{` ${model.appointmentData.name} `}</span>}</div>
        {/* <div>
            {Globalize.formatDate(model.appointmentData.startDate, { time: 'short' })}
            {' - '}
            {Globalize.formatDate(model.appointmentData.endDate, { time: 'short' }) }
          </div> */}
      </div>
    );
  };

  dataGridRender = () => {
    return (
      <DataGrid
        keyExpr="id"
        dataSource={this.props.teams}
        hoverStateEnabled={true}
        selectedRowKeys={this.state.gridBoxValueSelectedTeamIds}
        onSelectionChanged={this.dataGrid_onSelectionChanged}
        height="100%"
      >
        <Selection mode="multiple" />
        {/* <Scrolling mode="infinite" /> */}
        <Paging enabled={true} pageSize={20} />
        <FilterRow visible={true} />
        <Column caption="Team" dataField="name" dataType="string" />
        <Column caption="Conference" dataField="conferenceName" dataType="string" />
        {/* <Column dataField="conferenceId">
          <Lookup dataSource={this.props.conferences} valueExpr="id" displayExpr="name" />
        </Column> */}
      </DataGrid>
    );
  };

  onAppointmentFormOpening(data) {
    let form = data.form;

    if (data.appointmentData.blockedForId) {
      form.option("items", [
        {
          label: {
            text: `${dateTypeId_dict[data.appointmentData.dateTypeId]} Date`,
          },
          colSpan: 2,
          name: "blockedDate",
          editorType: "dxTextBox",
          editorOptions: {
            value: `${this.state.teamIdToNameMap.get(data.appointmentData.team1Id)} ${dateTypeId_dict[data.appointmentData.dateTypeId]} Date for ${
              blocked_for_dic[data.appointmentData.blockedForId]
            } by ${data.appointmentData.userName}`,
            readOnly: true,
          },
        },
        {
          dataField: "startDate",
          editorType: "dxDateBox",
          editorOptions: {
            width: "100%",
            type: "datetime",
            readOnly: true,
          },
        },
        {
          name: "endDate",
          dataField: "endDate",
          editorType: "dxDateBox",
          editorOptions: {
            width: "100%",
            type: "datetime",
            readOnly: true,
          },
        },
        {
          label: {
            text: "Date Type",
          },
          name: "DateType",
          dataField: "dateTypeId",
          editorType: "dxTextBox",
          editorOptions: {
            value: dateTypeId_dict[data.appointmentData.dateTypeId],
            readOnly: true,
          },
        },
        {
          name: "Note",
          dataField: "note",
          editorType: "dxTextBox",
          colSpan: 2,
          editorOptions: {
            width: "100%",
            type: "string",
            readOnly: true,
          },
        },
        {
          label: {
            text: "Division",
          },
          name: "division",
          editorType: "dxTextBox",
          editorOptions: {
            value: this.state.divisionIdToNameMap.get(data.appointmentData.divisionId) || "Not Found",
            readOnly: true,
          },
        },
        {
          label: {
            text: "Sport",
          },
          name: "sport",
          editorType: "dxTextBox",
          editorOptions: {
            value: this.state.sportIdToNameMap.get(data.appointmentData.displaySportId) || "Not Found",
            readOnly: true,
          },
        },
        {
          label: {
            text: "Season",
          },
          name: "Season",
          editorType: "dxTextBox",
          editorOptions: {
            value: makeClinetFriendlySeasonName(
              this.state.sportIdToNameMap.get(data.appointmentData.displaySportId),
              this.state.seasonIdToNameMap.get(data.appointmentData.seasonId)
            ),
            readOnly: true,
          },
        },
      ]);
    } else {
      form.option("items", [
        // {
        //   label: {
        //     text: "Team",
        //   },
        //   editorType: "dxSelectBox",
        //   dataField: "team1Id",
        //   editorOptions: {
        //     items: this.props.teams,
        //     displayExpr: "name",
        //     valueExpr: "id",
        //     readOnly: true,
        //     // onValueChanged: function (args) {
        //     //   movieInfo = getMovieById(args.value);
        //     //   form.getEditor("director").option("value", movieInfo.director);
        //     //   form.getEditor("endDate").option("value", new Date(startDate.getTime() + 60 * 1000 * movieInfo.duration));
        //     // },
        //   },
        // },
        {
          label: {
            text: "Division",
          },
          name: "division",
          editorType: "dxTextBox",
          editorOptions: {
            value: this.state.divisionIdToNameMap.get(data.appointmentData.divisionId) || "Not Found",
            readOnly: true,
          },
        },
        {
          label: {
            text: "Sport",
          },
          name: "sport",
          editorType: "dxTextBox",
          editorOptions: {
            value: data.appointmentData.name,
            readOnly: true,
          },
        },
        {
          label: {
            text: "Season",
          },
          name: "Season",
          editorType: "dxTextBox",
          editorOptions: {
            value: makeClinetFriendlySeasonName(data.appointmentData.name, this.state.seasonIdToNameMap.get(data.appointmentData.seasonId)),
            readOnly: true,
          },
        },
        {
          label: {
            text: "Game Type",
          },
          name: "isConference",
          editorType: "dxTextBox",
          editorOptions: {
            value: isConferenceDict[data.appointmentData.isConference],
            readOnly: true,
          },
        },
        {
          label: {
            text: "Team",
          },
          name: "team1",
          editorType: "dxTextBox",
          editorOptions: {
            value: this.state.teamIdToNameMap.get(data.appointmentData.team1Id) || "Not Found",
            readOnly: true,
          },
        },
        {
          label: {
            text: "Opponent",
          },
          name: "team2",
          editorType: "dxTextBox",
          editorOptions: {
            value: this.state.teamIdToNameMap.get(data.appointmentData.team2Id) || "Not Found",
            readOnly: true,
          },
        },
        {
          label: {
            text: "Team Conf",
          },
          name: "TeamConf",
          editorType: "dxTextBox",
          editorOptions: {
            value: this.state.conferenceIdToNameMap.get(data.appointmentData.team1ConfId) || "Not Found",
            readOnly: true,
          },
        },
        {
          label: {
            text: "Opponent Conf",
          },
          name: "OpponentConf",
          editorType: "dxTextBox",
          editorOptions: {
            value: this.state.conferenceIdToNameMap.get(data.appointmentData.team2ConfId) || "Not Found",
            readOnly: true,
          },
        },
        {
          dataField: "startDate",
          editorType: "dxDateBox",
          editorOptions: {
            width: "100%",
            type: "datetime",
            readOnly: true,
            // onValueChanged: function (args) {
            //   startDate = args.value;
            //   form.getEditor("endDate").option("value", new Date(startDate.getTime() + 60 * 1000 * movieInfo.duration));
            // },
          },
        },
        {
          name: "endDate",
          dataField: "endDate",
          editorType: "dxDateBox",
          editorOptions: {
            width: "100%",
            type: "datetime",
            readOnly: true,
          },
        },
        // {
        //   label: {
        //     text: "Site",
        //   },
        //   dataField: "price",
        //   editorType: "dxRadioGroup",
        //   editorOptions: {
        //     dataSource: [5, 10, 15, 20],
        //     itemTemplate: function (itemData) {
        //       return `$${itemData}`;
        //     },
        //   },
        // },
        {
          label: {
            text: "Site",
          },
          editorType: "dxTextBox",
          dataField: "siteId",
          editorOptions: {
            value: siteIdToNameDict[data.appointmentData.siteId],
            readOnly: true,
          },
        },
        {
          name: "Location",
          dataField: "location",
          editorType: "dxTextBox",
          editorOptions: {
            width: "100%",
            type: "string",
            readOnly: true,
          },
        },
        {
          name: "Tournament",
          dataField: "tournamentName",
          editorType: "dxTextBox",
          editorOptions: {
            width: "100%",
            type: "string",
            readOnly: true,
          },
        },
        {
          name: "Round",
          dataField: "roundName",
          editorType: "dxTextBox",
          editorOptions: {
            width: "100%",
            type: "string",
            readOnly: true,
          },
        },
        {
          label: {
            text: "Broadcaster",
          },
          name: "broadcaster",
          editorType: "dxTextBox",
          editorOptions: {
            value: this.state.broadcasterToNameMap.get(data.appointmentData.broadcasterId) || "Not Found",
            readOnly: true,
          },
        },
      ]);
    }
  }

  calculateDisplayValueSeason(e) {
    
    const sport_name = this.state.sportIdToNameMap.get(e.displaySportId);
    const season_name = this.state.seasonIdToNameMap.get(e.seasonId);


    if (sport_name && season_name) {
      return makeClinetFriendlySeasonName(sport_name, season_name);
    } else {
      return "";
    }
  }

  render() {
    
    return (
      <Fragment>
        <Card className="main-card my-2 mx-2 border border-primary rounded">
          <CardHeader className="card-header-tab">
            <div className="card-header-title thick">Calendar</div>
          </CardHeader>
          <CardBody className="pt-1">
            <Row>
              <Col xs={12}>
                <DropDownBox
                  value={this.state.gridBoxValueSelectedTeamIds}
                  deferRendering={false}
                  placeholder="Select a team..."
                  showClearButton={true}
                  dataSource={this.props.teams}
                  valueExpr="id"
                  displayExpr="name"
                  onValueChanged={this.syncDataGridSelection}
                  contentRender={this.dataGridRender}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <TagBox
                  value={this.state.selectedDisplaySportIds}
                  maxDisplayedTags={6}
                  items={this.state.sports}
                  displayExpr="name"
                  valueExpr="id"
                  showSelectionControls={true}
                  applyValueMode="useButtons"
                  onValueChanged={this.handleSportTagBoxOnValueChanged}
                />
              </Col>
              <Col xs={6}>
                <TagBox
                  value={this.state.selectedCalendarTemplateItemIds}
                  maxDisplayedTags={6}
                  items={calendarTemplateItems}
                  displayExpr="name"
                  valueExpr="id"
                  showSelectionControls={true}
                  applyValueMode="useButtons"
                  onValueChanged={this.handleCalendarTemplateItemsTagBoxOnValueChanged}
                />
              </Col>
            </Row>

            {/* <div className="form">
          <TreeView id="simple-treeview" items={this.state.teamTreeViewData} width={300} onItemClick={this.selectItem} />
        </div> */}

            <Scheduler
              height={600}
              showAllDayPanel={true}
              dataSource={this.state.calendarData.filter(
                (x) =>
                  this.state.gridBoxValueSelectedTeamIds &&
                  this.state.selectedDisplaySportIds.includes(x.displaySportId) &&
                  this.state.gridBoxValueSelectedTeamIds.includes(x.team1Id) &&
                  x.dateTBD === 0
              )}
              views={views}
              defaultCurrentView="time-line"
              defaultCurrentDate={currentDate}
              cellDuration={30}
              startDayHour={0}
              endDayHour={24}
              agendaDuration={31}
              appointmentRender={this.AppointmentTemplate}
              descriptionExpr="location"
              // crossScrollingEnabled={true}
              editing={{
                allowAdding: false,
                allowDeleting: false,
                allowResizing: false,
                allowDragging: false,
                allowUpdating: true,
              }}
              onAppointmentFormOpening={this.onAppointmentFormOpening}
              //dataCellRender={this.renderDataCell}
              // dateCellRender={this.renderDateCell}
              // timeCellRender={this.renderTimeCell}
            >
              <Resource
                allowMultiple={false}
                displayExpr="name"
                fieldExpr="displaySportId"
                dataSource={this.state.sports}
                label="Sport"
                useColorAsDefault={this.state.selectedResourceItem === "Sport"}
              />
              <Resource
                allowMultiple={false}
                fieldExpr="isConference"
                dataSource={isConference}
                label="Game Type"
                useColorAsDefault={this.state.selectedResourceItem === "Conference"}
              />
              <Resource allowMultiple={false} fieldExpr="siteId" dataSource={siteResourceData} label="Site" useColorAsDefault={this.state.selectedResourceItem === "Site"} />
              <Resource
                allowMultiple={false}
                fieldExpr="team1Id"
                displayExpr="name"
                dataSource={this.props.teams}
                label="Team"
                useColorAsDefault={this.state.selectedResourceItem === "Team"}
              />
              <Resource
                allowMultiple={false}
                fieldExpr="dateTypeId"
                displayExpr="name"
                dataSource={dateTypeId_lookup}
                label="Date Type"
                useColorAsDefault={this.state.selectedResourceItem === "DateType"}
              />
            </Scheduler>
            <div className="options">
              <div className="caption">Use colors of:</div>
              <div className="option">
                <RadioGroup items={resourcesList} value={this.state.selectedResourceItem} layout="horizontal" onValueChanged={this.onResourcesListValueChanged} />
              </div>
            </div>
            <div style={{ height: "20px" }}></div>
            <div style={{ height: "20px" }}></div>
            <div style={{ height: "20px" }}></div>
            {/* Adding header row with information */}
            <div>
              <h4>TBD Games</h4>

              <DataGrid
                dataSource={this.state.calendarData.filter(
                  (x) =>
                    this.state.gridBoxValueSelectedTeamIds &&
                    this.state.selectedDisplaySportIds.includes(x.displaySportId) &&
                    this.state.gridBoxValueSelectedTeamIds.includes(x.team1Id) &&
                    x.dateTBD === 1
                )}
                keyExpr="gameKeyId"
                showBorders={true}
              >
                <Export enabled={true} fileName={"KPI-TBG-Games"} />
                <Paging defaultPageSize={50} />
                <Pager visible={true} showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />
                <HeaderFilter visible={true} />
                <Column dataField="text" caption="Title" headerCellRender={renderTitleHeader} allowEditing={false} />
                <Column dataField="team1Name" caption="Team1" dataType="string" headerCellRender={renderTitleHeader} allowEditing={false} />
                <Column dataField="team2Name" caption="Team2" dataType="string" headerCellRender={renderTitleHeader} allowEditing={false} />
                <Column dataField="location" caption="location" dataType="string" headerCellRender={renderTitleHeader} allowEditing={false} />
                <Column dataField="isConference" dataType="number" caption="Game Type" headerCellRender={renderTitleHeader} allowEditing={false}>
                  <Lookup dataSource={isConference} displayExpr="name" valueExpr="id" />
                </Column>
                <Column dataField="startDate" caption="Entered On" dataType="date" headerCellRender={renderTitleHeader} allowEditing={false} />
                <Column dataField="displaySportId" caption="Sport" headerCellRender={renderTitleHeader} allowEditing={false}>
                  <Lookup dataSource={this.props.sports} displayExpr="name" valueExpr="id" />
                </Column>
                <Column
                  dataField="seasonId"
                  caption="Season"
                  dataType="string"
                  headerCellRender={renderTitleHeader}
                  allowEditing={false}
                  calculateDisplayValue={this.calculateDisplayValueSeason}
                ></Column>
                <Column dataField="tournamentName" caption="Tournament" headerCellRender={renderTitleHeader}></Column>
              </DataGrid>
            </div>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default TeamCalendar;
