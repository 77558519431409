//import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.softblue.compact.css";
//import "@fortawesome/fontawesome-free/css/all.min.css";
//import "bootstrap/dist/css/bootstrap.css";
//import "mdbreact/dist/css/mdb.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "./react-auth0-spa";
import config from "./auth_config.json";
import history from "./utils/history";

//import "./assets/base.scss";
//import configureStore from "./config/configureStore";
//import { Provider } from "react-redux";
//import devextremeconfig from "devextreme/core/config";

//const store = configureStore();

// devextremeconfig({
//   forceIsoDateParsing: true
// });

// console.log(config.domain);
// console.log(config.clientId);
console.log("NODE_ENV: " + process.env.NODE_ENV);
console.log("origin: " + window.location.origin);
console.log("REACT_APP_Identifier: " + process.env.REACT_APP_Identifier);
// console.log("REACT_APP_VERSION:" + process.env.REACT_APP_API_URL);

const onRedirectCallback = (appState) => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <Auth0Provider
        domain={config.domain}
        client_id={config.clientId}
        redirect_uri={window.location.origin}
        audience={config.audience}
        onRedirectCallback={onRedirectCallback}
        scope="read:current_user update:current_user_metadata"
        responseType="token id_token"
      >
        {/* <Provider store={store}> */}
        <App />
        {/* </Provider> */}
      </Auth0Provider>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
