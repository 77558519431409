import React, { Component, Fragment } from "react";

import { Progress, FormGroup, Label, Input, FormText, Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { Auth0Context } from "../react-auth0-spa";
import http from "../services/httpService";
import { makeSportAndSeasonName } from "./../utils/common-funcs";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

class ResumeUpload extends Component {
  // Assign the context type to a static property
  static contextType = Auth0Context;
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      loaded: 0,
      processed: 5,
      readyForUpload: false,
      uploadedFileNames: [],
      showProcessUploadedFilesModal: false,
      showRefreshCacheButton: false,
      incorporateMsg: "",
      incorporateButtonDisabled: false,
      selectedSport: this.props.selectedSport,
      selectedSeason: this.props.selectedSeason,
    };
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.selectedSport.id !== prevProps.selectedSport.id || this.props.selectedSeason.id !== prevProps.selectedSeason.id) {
      this.setState({
        selectedFile: null,
        loaded: 0,
        selectedSport: this.props.selectedSport,
        selectedSeason: this.props.selectedSeason,
      });
    }
  }

  checkMimeType = (event) => {
    let files = event.target.files;
    let err = [];
    const types = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    for (var x = 0; x < files.length; ++x) {
      if (types.every((type) => files[x].type !== type)) {
        err[x] = files[x].type + " is not a supported format\n";
      }
    }
    for (var z = 0; z < err.length; ++z) {
      toast.error(err[z]);
      event.target.value = null;
    }
    return true;
  };

  maxSelectFile = (event) => {
    let files = event.target.files;
    if (files.length > 1) {
      const msg = "Only 1 file can be uploaded at a time";
      event.target.value = null;
      toast.warn(msg);
      return false;
    }
    return true;
  };

  checkFileSize = (event) => {
    let files = event.target.files;
    let size = 2000000;
    let err = [];
    for (var x = 0; x < files.length; x++) {
      if (files[x].size > size) {
        err[x] = files[x].type + "is too large, please pick a smaller file\n";
      }
      if (files[x].name.length < 3) {
        err[x] = files[x].name + "is too short, please pick a longer file name\n";
      }
      if (files[x].name.length > 128) {
        err[x] = files[x].name + "is too long, please pick a shorter file name\n";
      }
    }
    for (var z = 0; z < err.length; z++) {
      toast.error(err[z]);
      event.target.value = null;
    }
    return true;
  };

  check_input_ids = () => {
    // //for debug
    // return true;
    if (this.props.selectedSport.id && this.props.selectedSeason.id) {
      return true;
    }
    toast.error("Please select a valid team and season for resume upload");
    return false;
  };

  onChangeHandler = (event) => {
    var files = event.target.files;
    if (this.check_input_ids() && this.maxSelectFile(event) && this.checkMimeType(event) && this.checkFileSize(event)) {
      // if return true allow to setState

      this.setState({
        selectedFile: files,
        loaded: 0,
        readyForUpload: true,
        incorporateMsg: "",
      });
    }
  };

  onClickUpload = async () => {
    try {
      const data = new FormData();

      if (!this.state.selectedFile || this.state.selectedFile.length < 1) {
        toast.error("nothing to upload");
        return;
      }

      if (!this.props.selectedSeason || !this.props.selectedSport) {
        toast.error("Please select a sport and season");
        return;
      }

      for (var x = 0; x < this.state.selectedFile.length; x++) {
        data.append("file", this.state.selectedFile[x]);
      }

      const { isAuthenticated, getTokenSilently } = this.context;
      if (isAuthenticated) {
        const jwtValue = await getTokenSilently();

        const res = await http.post(`/resume-upload/${this.props.selectedSport.id}/${this.props.selectedSeason.id}`, data, {
          headers: { Authorization: `Bearer ${jwtValue}` },
          onUploadProgress: (ProgressEvent) => {
            this.setState({
              loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
            });
          },
        });

        if (res.status === 200) {
          this.setState({
            showProcessUploadedFilesModal: true,
            uploadedFileNames: res.data.filenames,
          });
        }
      } else {
        toast.error("please login before uploading a resume");
      }
    } catch (e) {
      console.log(e);
      toast.error("upload fail");
    }

    this.setState({ selectedFile: [], readyForUpload: false, processed: 5 });
  };

  getUploadButtonClass() {
    const color = this.state.readyForUpload ? "btn-warning" : "btn-primary";
    return `btn ${color} btn-block`;
  }

  toggleProcessUploadedFileModal = () => {
    this.setState({ showProcessUploadedFilesModal: !this.state.showProcessUploadedFilesModal });
  };

  cancelProcessingOfUploadedFile = () => {
    this.setState({ showProcessUploadedFilesModal: false, uploadedFileNames: [] });
  };

  refreshPage = () => {
    window.location.reload(true);
    this.setState({ showRefreshCacheButton: false });
  };

  do_icorporate_uploaded_resume_file = async () => {
    this.setState({ incorporateButtonDisabled: true });
    try {
      const { isAuthenticated, getTokenSilently } = this.context;
      if (isAuthenticated) {
        const jwtValue = await getTokenSilently();

        const data = {
          display_sport_id: this.props.selectedSport.id,
          season_id: this.props.selectedSeason.id,
          filenames: this.state.uploadedFileNames,
        };
        this.setState({ processed: 25 });
        const res = await http.post(`/resume-upload/process`, data, {
          headers: { Authorization: `Bearer ${jwtValue}` },
        });

        if (res.status === 200) {
          this.setState({
            loaded: 0,
            readyForUpload: false,
            showProcessUploadedFilesModal: false,
            showRefreshCacheButton: true,
            uploadedFileNames: [],
            incorporateMsg: `Success: ${res.data}`,
            processed: 100,
          });
          sessionStorage.clear();
        } else {
          this.setState({
            loaded: 0,
            readyForUpload: false,
            showProcessUploadedFilesModal: false,
            uploadedFileNames: [],
            incorporateMsg: `Error: ${res.data}`,
          });
        }
      } else {
        toast.error("please login before uploading a resume");
      }
    } catch (e) {
      this.setState({
        loaded: 0,
        readyForUpload: false,
        showProcessUploadedFilesModal: false,
        uploadedFileNames: [],
        incorporateMsg: `Error: ${e}`,
      });
      console.log(e);
      toast.error("incorporate fail");
    }

    this.setState({ showProcessUploadedFilesModal: false, uploadedFileNames: [], incorporateButtonDisabled: false });
  };

  render() {
    const sport_season_name = makeSportAndSeasonName(this.props.selectedSport.name, this.props.selectedSeason.name);
    return (
      //   <div className="container">
      //     <div className="row">
      //       <div className="offset-md-3 col-md-6">
      //         <div className="form-group kpi-resume-upload color">
      //           <label>Upload Your File </label>
      //           <input type="file" className="form-control" onChange={this.onChangeHandler} />
      //         </div>
      //         <div className="form-group">
      //           <ToastContainer />
      //           <Progress max="100" color="success" value={this.state.loaded}>
      //             {Math.round(this.state.loaded, 2)}%
      //           </Progress>
      //         </div>

      //         <button type="button" className="btn btn-success btn-block" onClick={this.onClickUpload}>
      //           Upload
      //         </button>
      //       </div>
      //     </div>
      //   </div>
      <Fragment>
        <FormGroup>
          <Label for="exampleFile">File</Label>
          <Input type="file" name="file" id="exampleFile" onChange={this.onChangeHandler} />
          <FormText color="muted">
            Choose a resume file to upload, then click the upload button. Once file has been uploaded, you will get an option to incorporate the new file into
            the Faktor sever.
          </FormText>
          <ToastContainer />
          <Progress max="100" color="success" value={this.state.loaded}>
            {Math.round(this.state.loaded, 2)}%
          </Progress>
          <button type="button" className={this.getUploadButtonClass()} onClick={this.onClickUpload}>
            Upload
          </button>
          <Modal isOpen={this.state.showProcessUploadedFilesModal} toggle={this.toggleProcessUploadedFileModal}>
            <ModalHeader>Processing {sport_season_name}</ModalHeader>
            <ModalBody>
              Please click "Incorporate" to process uploaded resume file for <strong>{sport_season_name}</strong> and add it to the Faktor database (this may
              take several minutes). Click "cancel" to ignore the uploaded file.
            </ModalBody>
            <ModalFooter>
              <Col>
                <Progress animated color="success" value={this.state.processed} />
                <Button color="primary" block onClick={this.do_icorporate_uploaded_resume_file} disabled={this.state.incorporateButtonDisabled}>
                  {this.state.incorporateButtonDisabled ? "Incorporating..." : "Incorporate"}
                </Button>{" "}
                <Button color="secondary" block onClick={this.cancelProcessingOfUploadedFile}>
                  Cancel
                </Button>
              </Col>
            </ModalFooter>
          </Modal>
        </FormGroup>
        <Row>
          <Col>{this.state.incorporateMsg}</Col>
        </Row>
        <Row>
          <Col className="text-center">
            {this.state.showRefreshCacheButton && (
              <Button className="my-5 mx-5 " color="danger" onClick={this.refreshPage}>
                Click here after resume upload to refresh local cache
              </Button>
            )}
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default ResumeUpload;
