export const colorsForWhiteText = [
  "#800000",
  "#9A6324",
  "#808000",
  "#469990",
  "#000075",
  "#000000",
  "#e6194B",
  "#f58231",
  "#3cb44b",
  "#4363d8",
  "#911eb4",
  "#f032e6",
  "#a9a9a9"
];

export const colors = [
  "#000000", //black
  "#000080", //navy
  "#0000CD", //mediumBlue
  "#006400", //darkGreen
  "#008080", //teal
  "#663399", //rebeccaPurple
  "#696969", //dimGrey
  "#800080", //purple
  "#800000", //maroon
  "#808000", //olive
  "#8B008B", //darkMagenta
  "#CD853F" //Peru
];
