import React, { Component, Fragment } from "react";

import { isString, makeSportAndSeasonName, getMinMaxDateValues, getDateSequence, make_date_open_interval } from "./../utils/common-funcs";
import {
  dial_kpi_calculator,
  dial_scores_calculator,
  dial_data_quality_calculator,
  dial_net_calculator,
  get_sandbox_for_team_season_sport,
  dial_teams_conferences_calculator,
  dial_team_information_calculator,
  dial_vtp_calculator,
} from "./../services/privateService";
import { Popup } from "devextreme-react/popup";
import { Row, Col, CardHeader, Card, CardBody } from "reactstrap";
import { Button,SelectBox } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { toast } from "react-toastify";
import { LoadIndicator } from "devextreme-react/load-indicator";
import DateBox from "devextreme-react/date-box";
import { format } from "fecha";
import { time_format, siteIdToNameDict, winLossTieIdToNameDict, pager_lengths, isConference } from "./../utils/enums";
import { renderTitleHeader } from "./../utils/common-rendering-funcs";
import { get_overtime_suffix, get_season_sport_conferences_async } from "./../utils/common-funcs";
import DataGrid, { Column, Paging, Pager, Lookup, Export, SearchPanel, Sorting, FilterRow, FilterPanel } from "devextreme-react/data-grid";
import Tabs from "devextreme-react/tabs";

const dial_kpi_text_default = "Get KPI and update database";
const dial_kpi_text_dry_run = "Get KPI and show outcome (no database updates)";

const dial_vtp_text_default = "Get VTP values and update database";
const dial_vtp_text_dry_run = "Get VTP values and show outcome (no database updates)";

const on_click_dial_team_information_text_default = "Get team info and update database";
const on_click_dial_team_information_text_dry_run = "Get team info and show outcome (no database updates)";

const dial_scores_text_default = "Get scores and update database";
const dial_scores_text_dry_run = "Get scores and show outcome (no database updates)";

const dial_teams_conferences_text_default = "Get teams and conferences and update database";
const dial_teams_conferences_text_dry_run = "Get teams and conferences and show outcome (no database updates)";

const tabs = [
  {
    id: 0,
    text: "Update KPI values",
    title: "KPI dialer",
  },
  {
    id: 1,
    text: "Update scores",
    title: "Score dialer",
  },
  {
    id: 2,
    text: "Update teams/conferences",
    title: "Team/Conf dialer",
  },
  {
    id: 3,
    text: "Get raw data",
    title: "Raw data",
  },
  {
    id: 4,
    text: "Update team info (slow)",
    title: "Team info (slow)",
  },
];

const div_style = { borderRadius: "5px", borderWidth: "4px", borderStyle: "solid", borderColor: "green", padding: "10px", margin: "10px" };

const toast_options = {
  autoClose: false,
};

class KpiCaller extends Component {
  constructor(props) {
    super(props);

    this.state = {
      kpi_data: { note: "Click dial first!" },
      vtp_data: { note: "Click dial first!" },
      scores_data: { note: "Click dial first!" },
      teams_conferences_data: { note: "Click dial first!" },
      team_information_data: { note: "Click dial first!" },
      raw_data: {},
      dial_kpi_text_default: dial_kpi_text_default,
      dial_kpi_text_dry_run: dial_kpi_text_dry_run,

      on_click_dial_team_information_text_default: on_click_dial_team_information_text_default,
      on_click_dial_team_information_text_dry_run: on_click_dial_team_information_text_dry_run,

      dial_scores_text_default: dial_scores_text_default,
      dial_scores_text_dry_run: dial_scores_text_dry_run,

      dial_teams_conferences_text_default: dial_teams_conferences_text_default,
      dial_teams_conferences_text_dry_run: dial_teams_conferences_text_dry_run,

      loading_raw_data: false,

      download_kpi_pop_up_visible: false,
      download_vtp_pop_up_visible: false,
      download_team_information_pop_up_visible: false,
      download_raw_data_visible: false,
      download_scores_pop_up_visible: false,
      download_teams_conferences_pop_up_visible: false,

      minDateValue: undefined,
      maxDateValue: undefined,

      search_start: format(new Date(), time_format.yyymmdd),
      search_end: format(new Date(), time_format.yyymmdd),
      search_start_iso: new Date(),
      search_end_iso: new Date(),

      overtime_suffix: "",
      selectedIndex: 0,

      season_sport_conferences: [],
      selectBoxValueConferenecId : -1
    };

    this.on_click_dial_kpi_calculator = this.on_click_dial_kpi_calculator.bind(this);
    this.on_click_dial_kpi_calculator_dry_run = this.on_click_dial_kpi_calculator_dry_run.bind(this);

    this.on_click_dial_vtp_calculator = this.on_click_dial_vtp_calculator.bind(this);
    this.on_click_dial_vtp_calculator_dry_run = this.on_click_dial_vtp_calculator_dry_run.bind(this);

    this.hide_download_kpi_pop_up = this.hide_download_kpi_pop_up.bind(this);
    this.hide_download_vtp_pop_up = this.hide_download_vtp_pop_up.bind(this);

    this.on_click_dial_team_information = this.on_click_dial_team_information.bind(this);
    this.on_click_dial_team_information_dry_run = this.on_click_dial_team_information_dry_run.bind(this);
    this.hide_download_team_information_pop_up = this.hide_download_team_information_pop_up.bind(this);

    this.on_click_dial_scores_calculator = this.on_click_dial_scores_calculator.bind(this);
    this.on_click_dial_scores_calculator_dry_run = this.on_click_dial_scores_calculator_dry_run.bind(this);
    this.hide_download_scores_pop_up = this.hide_download_scores_pop_up.bind(this);

    this.on_click_dial_teams_conferences_updater = this.on_click_dial_teams_conferences_updater.bind(this);
    this.on_click_dial_teams_conferences_updater_dry_run = this.on_click_dial_teams_conferences_updater_dry_run.bind(this);
    this.hide_download_teams_conferences_pop_up = this.hide_download_teams_conferences_pop_up.bind(this);

    this.hide_download_raw_data_pop_up = this.hide_download_raw_data_pop_up.bind(this);

    this.onValueChangedStartDate = this.onValueChangedStartDate.bind(this);
    this.onValueChangedEndDate = this.onValueChangedEndDate.bind(this);
    this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);

    this.onValueChangedSelectBoxConferenceId = this.onValueChangedSelectBoxConferenceId.bind(this);
  }

  onTabsSelectionChanged(args) {
    if (args.name === "selectedIndex" && this.state.selectedIndex !== args.value) {
      this.setState({
        selectedIndex: args.value,
      });
    }
  }

  async componentDidMount() {
    try {
      const overtime_suffix = get_overtime_suffix(this.props.selectedSport.id);
      let minDateValue = undefined;
      let maxDateValue = undefined;
      let season_sport_conferences=[];
      if (this.props.selectedDivision.id && this.props.selectedSport.id && this.props.selectedSeason.id) {
        [minDateValue, maxDateValue] = getMinMaxDateValues(this.props.selectedSport.name, this.props.selectedSeason.name);

        season_sport_conferences = await get_season_sport_conferences_async(this.props.allTeams);
        
        if (this.props.selectedSeason.id === 11) {
          //covid year
          maxDateValue = null;
        }

        this.setState({
          minDateValue: minDateValue,
          maxDateValue: maxDateValue,
          overtime_suffix: overtime_suffix,
          season_sport_conferences : season_sport_conferences
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.user && !prevProps.user) ||
      (!this.props.user && prevProps.user) ||
      this.props.user.hq_current_user.userId !== prevProps.user.hq_current_user.userId ||
      this.props.isAuthenticated !== prevProps.isAuthenticated ||
      this.props.selectedSport.id !== prevProps.selectedSport.id ||
      this.props.selectedDivision.id !== prevProps.selectedDivision.id ||
      this.props.selectedSeason.id !== prevProps.selectedSeason.id
    ) {
      notify(`Refreshing table`);
      let minDateValue = undefined;
      let maxDateValue = undefined;
      let season_sport_conferences = []; 
      if (this.props.selectedDivision.id && this.props.selectedSport.id && this.props.selectedSeason.id) {
        [minDateValue, maxDateValue] = getMinMaxDateValues(this.props.selectedSport.name, this.props.selectedSeason.name);
        season_sport_conferences = await get_season_sport_conferences_async(this.props.allTeams);

        if (this.props.selectedSeason.id === 11) {
          //covid year
          maxDateValue = null;
        }
      }

      this.setState({
        kpi_data: { note: "Click dial first!" },
        vtp_data: { note: "Click dial first!" },
        scores_data: { note: "Click dial first!" },
        teams_conferences_data: { note: "Click dial first!" },
        team_information_data: { note: "Click dial first!" },
        raw_data: {},
        minDateValue: minDateValue,
        maxDateValue: maxDateValue,
        season_sport_conferences : season_sport_conferences
      });
    }
  }

  onValueChangedEndDate(e) {
    let yyy_mm_dd = this.onValueChangedDateBase(e.value);

    if (e.value < this.state.search_start_iso) {
      toast.error("end date must be after start date");
      this.setState({
        search_end: this.state.search_start,
        search_end_iso: this.state.search_start_iso,
      });
    } else {
      this.setState({
        search_end: yyy_mm_dd,
        search_end_iso: e.value,
      });
    }
  }

  onValueChangedStartDate(e) {
    let yyy_mm_dd = this.onValueChangedDateBase(e.value);

    this.setState({
      search_start: yyy_mm_dd,
      search_end: yyy_mm_dd,
      search_start_iso: e.value,
      search_end_iso: e.value,
    });
  }

  onValueChangedDateBase(e_value) {
    let yyy_mm_dd = undefined;
    try {
      if (!isString(e_value)) {
        //yyy_mm_dd = format(parse(e_value, time_format.utc), time_format.yyymmdd);
        yyy_mm_dd = format(e_value, time_format.yyymmdd);
      }
    } catch (error) {
      console.log(error);
    }
    return yyy_mm_dd;
  }

  hide_download_kpi_pop_up() {
    this.setState({
      download_kpi_pop_up_visible: false,
    });
  }

  hide_download_vtp_pop_up() {
    this.setState({
      download_vtp_pop_up_visible: false,
    });
  }

  hide_download_team_information_pop_up() {
    this.setState({
      download_team_information_pop_up_visible: false,
    });
  }

  hide_download_raw_data_pop_up() {
    this.setState({
      download_raw_data_visible: false,
    });
  }

  hide_download_scores_pop_up() {
    this.setState({
      download_scores_pop_up_visible: false,
    });
  }

  hide_download_teams_conferences_pop_up() {
    this.setState({
      download_teams_conferences_pop_up_visible: false,
    });
  }

  async on_click_dial_kpi_calculator(e) {
    this.on_click_dial_kpi_calculator_implementation(0);
  }

  async on_click_dial_kpi_calculator_dry_run(e) {
    this.on_click_dial_kpi_calculator_implementation(1);
  }

  async on_click_dial_vtp_calculator(e) {
    this.on_click_dial_vtp_calculator_implementation(0);
  }

  async on_click_dial_vtp_calculator_dry_run(e) {
    this.on_click_dial_vtp_calculator_implementation(1);
  }

  async on_click_dial_team_information(e) {
    this.on_click_dial_team_information_implementation(0);
  }

  async on_click_dial_team_information_dry_run(e) {
    this.on_click_dial_team_information_implementation(1);
  }

  async on_click_dial_scores_calculator(e) {
    this.on_click_dial_scores_calculator_implementation(0);
  }

  async on_click_dial_teams_conferences_updater(e) {
    this.on_click_dial_teams_conferences_updater_implementation(0);
  }

  async on_click_dial_teams_conferences_updater_dry_run(e) {
    this.on_click_dial_teams_conferences_updater_implementation(1);
  }

  get_division_sport_season_name = () => {
    return `${this.props.selectedDivision.name} :: ${this.props.selectedSport.name} :: ${this.props.selectedSeason.name}`;
  };

  on_click_dial_teams_conferences_updater_implementation = async (dry_run) => {
    notify(`Dialing for teams and conferences`);
    this.setState({
      dial_teams_conferences_text_default: dry_run ? dial_teams_conferences_text_default : "",
      dial_teams_conferences_text_dry_run: dry_run ? "" : dial_teams_conferences_text_dry_run,
    });

    try {
      const res = await dial_teams_conferences_calculator(this.props.auth0jwt, this.props.selectedDivision.id, this.props.selectedSport.id, this.props.selectedSeason.id, dry_run);

      if (res.status === 200) {
        if (dry_run) {
          toast.success(`Successfully fetched metadata for ${res.data.teams.length} teams. [${this.get_division_sport_season_name()}]`, toast_options);
        } else {
          toast.success(`Successfully updated team/conference metadata for ${res.data.teams.length} teams. [${this.get_division_sport_season_name()}]`, toast_options);
        }

        this.setState({
          teams_conferences_data: res.data,

          dial_teams_conferences_text_default: dial_teams_conferences_text_default,
          dial_teams_conferences_text_dry_run: dial_teams_conferences_text_dry_run,
          download_teams_conferences_pop_up_visible: dry_run ? true : false,
        });
      } else {
        toast.error(`Teams and conferences could not be updated. [${this.get_division_sport_season_name()}]`, toast_options);
        this.setState({
          dial_teams_conferences_text_default: dial_teams_conferences_text_default,
          dial_teams_conferences_text_dry_run: dial_teams_conferences_text_dry_run,
          download_teams_conferences_pop_up_visible: false,
        });
      }
    } catch (error) {
      toast.error(`Teams and conferences could not be updated. [${this.get_division_sport_season_name()}]`, toast_options);
      this.setState({
        dial_teams_conferences_text_default: dial_teams_conferences_text_default,
        dial_teams_conferences_text_dry_run: dial_teams_conferences_text_dry_run,
        download_teams_conferences_pop_up_visible: false,
      });
      console.log(error);
    }
  };

  on_click_get_raw_data = async () => {
    try {
      this.setState({ loading_raw_data: true });
      const { data: raw_data } = await get_sandbox_for_team_season_sport(
        this.props.auth0jwt,
        0,
        this.props.selectedSeason.id,
        this.props.selectedSport.id,
        this.props.selectedDivision.id
      );
      const raw_data_with_names = raw_data.map((x) => ({ ...x, site: siteIdToNameDict[x.siteId], WLName: winLossTieIdToNameDict[x.WL] }));
      this.setState({ raw_data: raw_data_with_names, download_raw_data_visible: true, loading_raw_data: false });
    } catch (error) {
      this.setState({ loading_raw_data: false });
      console.log(error);
    }
  };

  async on_click_dial_scores_calculator_dry_run(e) {
    this.on_click_dial_scores_calculator_implementation(1);
  }

  on_click_dial_scores_calculator_implementation = async (dry_run) => {
    if (!this.state.search_start || !this.state.search_end) {
      toast.error("Please select a date");
      return;
    }

    notify(`Dialing for scores`);
    this.setState({
      dial_scores_text_default: dry_run ? dial_scores_text_default : "",
      dial_scores_text_dry_run: dry_run ? "" : dial_scores_text_dry_run,
      scores_data: {},
    });

    const [leftBoundOpen, rightBoundOpen] = make_date_open_interval(this.state.search_start, this.state.search_end, time_format.yyymmdd);
    const all_dates = getDateSequence(leftBoundOpen, rightBoundOpen, time_format.yyymmdd);

    try {
      const faile_runs = [];
      let all_games = [];
      let all_updated_rows = [];
      let duplicate_game_key_id = new Set();
      for (var j = 1; j < all_dates.length - 1; j++) {
        notify(`${all_dates[j]}: Working on (${all_dates[j - 1]} - ${all_dates[j + 1]}] interval`);
        try {
          const scores_res_j = await dial_scores_calculator(
            this.props.auth0jwt,
            this.props.selectedDivision.id,
            this.props.selectedSport.id,
            this.props.selectedSeason.id,
            dry_run,
            all_dates[j],
            all_dates[j - 1],
            all_dates[j + 1]
          );

          if (scores_res_j.status === 200) {
            if (Array.isArray(scores_res_j.data.games)) {
              scores_res_j.data.games.forEach((x) => {
                if (!duplicate_game_key_id.has(x.gameKeyId)) {
                  all_games.push(x);
                }
              });
            }

            all_updated_rows = all_updated_rows.concat(scores_res_j.data.updated_rows);
          } else {
            faile_runs.push(all_dates[j]);
          }
        } catch (error) {
          faile_runs.push(all_dates[j]);
          console.log(error);
        }
      }

      if (all_games.length > 0) {
        if (dry_run) {
          toast.success(`Successfully fetched scores for ${all_games.length} games. [${this.get_division_sport_season_name()}]`, toast_options);
        } else {
          toast.success(
            `Successfully fetched ${all_games.length} game metadata and updated scores for ${all_updated_rows.length} games. [${this.get_division_sport_season_name()}]`,
            toast_options
          );
        }
      }

      if (faile_runs.length > 0) {
        toast.error(`Scores could not be updated for: ${faile_runs.join(", ")}. [${this.get_division_sport_season_name()}]`, toast_options);
      }

      let data_quality_discrepancies = [];
      try {
        const data_quality_qres = await dial_data_quality_calculator(
          this.props.auth0jwt,
          this.props.selectedDivision.id,
          this.props.selectedSport.id,
          this.props.selectedSeason.id,
          dry_run,
          null
        );
        if (data_quality_qres.data.discrepancies) {
          data_quality_discrepancies = data_quality_qres.data.discrepancies;
        } else {
          toast.error(`Data quality checker did not run. [${this.get_division_sport_season_name()}]`, toast_options);
        }
      } catch (error) {
        toast.error(`Data quality checker did not run. [${this.get_division_sport_season_name()}]`, toast_options);
      }

      this.setState({
        scores_data: { dates: all_dates, games: all_games, updated_rows: all_updated_rows, data_quality_discrepancies: data_quality_discrepancies },

        dial_scores_text_default: dial_scores_text_default,
        dial_scores_text_dry_run: dial_scores_text_dry_run,
        download_scores_pop_up_visible: dry_run ? true : false,
      });
    } catch (error) {
      toast.error(`Scores could not be updated. [${this.get_division_sport_season_name()}]`, toast_options);
      this.setState({
        dial_scores_text_default: dial_scores_text_default,
        dial_scores_text_dry_run: dial_scores_text_dry_run,
        download_scores_pop_up_visible: false,
      });
      console.log(error);
    }
  };

  on_click_dial_kpi_calculator_implementation = async (dry_run) => {
    notify(`Dialing KPI calculator`);
    this.setState({
      dial_kpi_text_default: dry_run ? dial_kpi_text_default : "",
      dial_kpi_text_dry_run: dry_run ? "" : dial_kpi_text_dry_run,
    });
    try {
      const res = await dial_kpi_calculator(this.props.auth0jwt, this.props.selectedDivision.id, this.props.selectedSport.id, this.props.selectedSeason.id, dry_run, this.state.selectBoxValueConferenecId);
      //console.log(res);
      if (res.status === 200) {
        if (dry_run) {
          toast.success(`Successfully fetched values for ${res.data.game_values.length} games. [${this.get_division_sport_season_name()}]`, toast_options);
        } else {
          toast.success(`Successfully updated KPI values for ${res.data.game_values.length} games. [${this.get_division_sport_season_name()}]`, toast_options);
        }

        this.setState({
          kpi_data: res.data,

          dial_kpi_text_default: dial_kpi_text_default,
          dial_kpi_text_dry_run: dial_kpi_text_dry_run,
          download_kpi_pop_up_visible: dry_run ? true : false,
        });
      } else {
        toast.error(`KPI values could not be updated. [${this.get_division_sport_season_name()}]`, toast_options);
        this.setState({
          dial_kpi_text_default: dial_kpi_text_default,
          dial_kpi_text_dry_run: dial_kpi_text_dry_run,
          download_kpi_pop_up_visible: false,
        });
      }

      try {
        await dial_net_calculator(this.props.auth0jwt, this.props.selectedDivision.id, this.props.selectedSport.id, this.props.selectedSeason.id, dry_run);
      } catch (error) {
        toast.error(`NET values could not be updated. [${this.get_division_sport_season_name()}]`, toast_options);
      }
    } catch (error) {
      toast.error(`KPI values could not be updated. [${this.get_division_sport_season_name()}]`, toast_options);
      this.setState({
        dial_kpi_text_default: dial_kpi_text_default,
        dial_kpi_text_dry_run: dial_kpi_text_dry_run,
        download_kpi_pop_up_visible: false,
      });
      console.log(error);
    }
  };

  on_click_dial_vtp_calculator_implementation = async (dry_run) => {
    notify(`Dialing vtp calculator`);
    this.setState({
      dial_vtp_text_default: dry_run ? dial_vtp_text_default : "",
      dial_vtp_text_dry_run: dry_run ? "" : dial_vtp_text_dry_run,
    });
    try {
      const res = await dial_vtp_calculator(this.props.auth0jwt, this.props.selectedDivision.id, this.props.selectedSport.id, this.props.selectedSeason.id, dry_run);
      console.log(res);
      if (res.status === 200) {
        if (dry_run) {
          toast.success(`Successfully fetched values for ${res.data.game_values.length} games. [${this.get_division_sport_season_name()}]`, toast_options);
        } else {
          toast.success(`Successfully updated VTP values for ${res.data.game_values.length} games. [${this.get_division_sport_season_name()}]`, toast_options);
        }

        this.setState({
          vtp_data: res.data.vtp_data,

          dial_vtp_text_default: dial_vtp_text_default,
          dial_vtp_text_dry_run: dial_vtp_text_dry_run,
          download_vtp_pop_up_visible: dry_run ? true : false,
        });
      } else {
        toast.error(`VTP values could not be updated. [${this.get_division_sport_season_name()}]`, toast_options);
        this.setState({
          dial_vtp_text_default: dial_kpi_text_default,
          dial_vtp_text_dry_run: dial_kpi_text_dry_run,
          download_vtp_pop_up_visible: false,
        });
      }
    } catch (error) {
      toast.error(`VTP values could not be updated. [${this.get_division_sport_season_name()}]`, toast_options);
      this.setState({
        dial_vtp_text_default: dial_kpi_text_default,
        dial_vtp_text_dry_run: dial_kpi_text_dry_run,
        download_vtp_pop_up_visible: false,
      });
      console.log(error);
    }
  };

  on_click_dial_team_information_implementation = async (dry_run) => {
    notify(`Dialing KPI calculator`);
    this.setState({
      on_click_dial_team_information_text_default: dry_run ? on_click_dial_team_information_text_default : "",
      on_click_dial_team_information_text_dry_run: dry_run ? "" : on_click_dial_team_information_text_dry_run,
    });
    try {
      const res = await dial_team_information_calculator(this.props.auth0jwt, this.props.selectedDivision.id, this.props.selectedSport.id, this.props.selectedSeason.id, dry_run);
      //console.log(res);
      if (res.status === 200) {
        if (dry_run) {
          toast.success(`Successfully fetched values for ${res.data.length} teams. [${this.get_division_sport_season_name()}]`, toast_options);
        } else {
          toast.success(`Successfully updated team info for ${res.data.length} teams. [${this.get_division_sport_season_name()}]`, toast_options);
        }

        this.setState({
          team_information_data: res.data,

          on_click_dial_team_information_text_default: on_click_dial_team_information_text_default,
          on_click_dial_team_information_text_dry_run: on_click_dial_team_information_text_dry_run,
          download_team_information_pop_up_visible: dry_run ? true : false,
        });
      } else {
        toast.error(`Team info could not be updated. [${this.get_division_sport_season_name()}]`, toast_options);
        this.setState({
          on_click_dial_team_information_text_default: on_click_dial_team_information_text_default,
          on_click_dial_team_information_text_dry_run: on_click_dial_team_information_text_dry_run,
          download_team_information_pop_up_visible: false,
        });
      }
    } catch (error) {
      toast.error(`Team info could not be updated. [${this.get_division_sport_season_name()}]`, toast_options);
      this.setState({
        on_click_dial_team_information_text_default: on_click_dial_team_information_text_default,
        on_click_dial_team_information_text_dry_run: on_click_dial_team_information_text_dry_run,
        download_team_information_pop_up_visible: false,
      });
      console.log(error);
    }
  };

  onValueChangedSelectBoxConferenceId(args) {
    this.setState({
      selectBoxValueConferenecId: args.value,
    });
  }

  render() {
    return (
      <Fragment>
        <Card className="main-card my-2 mx-2 border border-primary rounded">
          <CardHeader className="card-header-tab">
            <Tabs dataSource={tabs} selectedIndex={this.state.selectedIndex} onOptionChanged={this.onTabsSelectionChanged} />
            <div className="card-header-title thick mt-3">
              {tabs[this.state.selectedIndex].text} for {this.props.selectedDivision.name} / {makeSportAndSeasonName(this.props.selectedSport.name, this.props.selectedSeason.name)}
            </div>
          </CardHeader>

          <CardBody className="pt-1">
            {this.state.selectedIndex === 0 && (
              <div>
                <div style={div_style}>
                  <h3>KPI</h3>

                  <Row className="my-3">
                    <Col xs={4}>
                      <SelectBox
                        placeholder={"Conference..."}
                        items={this.state.season_sport_conferences}
                        displayExpr="name"
                        valueExpr="id"
                        value={this.state.selectBoxValueConferenecId}
                        onValueChanged={this.onValueChangedSelectBoxConferenceId}
                        searchEnabled={true}
                        searchMode="contains"
                        searchExpr="name"
                      />
                    </Col>
                  </Row>

                  <Row className="my-3">
                    <Col xs={8}>
                      <Button id="button" style={{ fontSize: "14px", fontWeight: "bold" }} icon="tel" type="danger" onClick={this.on_click_dial_kpi_calculator}>
                        <LoadIndicator className="button-indicator" visible={this.state.dial_kpi_text_default === ""} />
                        <span className="dx-button-text">{dial_kpi_text_default}</span>
                      </Button>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col xs={8}>
                      <Button id="button" style={{ fontSize: "14px", fontWeight: "bold" }} icon="tel" type="default" onClick={this.on_click_dial_kpi_calculator_dry_run}>
                        <LoadIndicator className="button-indicator" visible={this.state.dial_kpi_text_dry_run === ""} />
                        <span className="dx-button-text">{dial_kpi_text_dry_run}</span>
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div style={div_style}>
                  <h3>Historical VTP values for sandbox</h3>
                  <Row className="my-3">
                    <Col xs={8}>
                      <Button id="button" style={{ fontSize: "14px", fontWeight: "bold" }} icon="tel" type="danger" onClick={this.on_click_dial_vtp_calculator}>
                        <LoadIndicator className="button-indicator" visible={this.state.dial_vtp_text_default === ""} />
                        <span className="dx-button-text">{dial_kpi_text_default}</span>
                      </Button>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col xs={8}>
                      <Button id="button" style={{ fontSize: "14px", fontWeight: "bold" }} icon="tel" type="default" onClick={this.on_click_dial_vtp_calculator_dry_run}>
                        <LoadIndicator className="button-indicator" visible={this.state.dial_vtp_text_dry_run === ""} />
                        <span className="dx-button-text">{dial_vtp_text_dry_run}</span>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
            {this.state.selectedIndex === 1 && (
              <div style={div_style}>
                <h3>Scores</h3>
                <Row className="my-3">
                  <Col xs={6} md={4}>
                    <DateBox
                      placeholder="Start date"
                      type="date"
                      value={this.state.search_start_iso}
                      min={this.state.minDateValue}
                      max={this.state.maxDateValue}
                      onValueChanged={this.onValueChangedStartDate}
                    />
                  </Col>
                  <Col xs={6} md={4}>
                    <DateBox
                      placeholder="End date"
                      type="date"
                      value={this.state.search_end_iso}
                      min={this.state.minDateValue}
                      max={this.state.maxDateValue}
                      onValueChanged={this.onValueChangedEndDate}
                    />
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col xs={8}>
                    <Button id="button" style={{ fontSize: "14px", fontWeight: "bold" }} icon="tel" type="danger" onClick={this.on_click_dial_scores_calculator}>
                      <LoadIndicator className="button-indicator" visible={this.state.dial_scores_text_default === ""} />
                      <span className="dx-button-text">{dial_scores_text_default}</span>
                    </Button>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col xs={8}>
                    <Button id="button" style={{ fontSize: "14px", fontWeight: "bold" }} icon="tel" type="default" onClick={this.on_click_dial_scores_calculator_dry_run}>
                      <LoadIndicator className="button-indicator" visible={this.state.dial_scores_text_dry_run === ""} />
                      <span className="dx-button-text">{dial_scores_text_dry_run}</span>
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <h3>Faktor database updates</h3>
                    <DataGrid
                      width={"100%"}
                      columnAutoWidth={true}
                      dataSource={this.state.scores_data.updated_rows}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      columnResizingMode={"widget"}
                      showBorders={false}
                      showColumnLines={false}
                      showRowLines={true}
                      rowAlternationEnabled={true}
                      allowSearch={true}
                      selection={{ mode: "multiple", showCheckBoxesMode: "none" }}
                    >
                      <SearchPanel visible={true} />
                      <FilterRow visible={true} />
                      <FilterPanel visible={true} />
                      <Sorting mode="multiple" />
                      <Export enabled={true} fileName={"KPI-Score-Updates"} />
                      <Paging defaultPageSize={800} />
                      <Pager showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />
                      <Column dataField="gameKeyId" caption="gameKeyId" dataType="number" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="isNew" caption="New Game" dataType="boolean" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="doubleHeaderGameNumber" caption="DblHdr#" dataType="number" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="team1Id" caption="Team" fixed={true} fixedPosition="left" headerCellRender={renderTitleHeader}>
                        <Lookup dataSource={this.props.allTeams} displayExpr="name" valueExpr="id" />
                      </Column>
                      <Column dataField="team2Id" caption="Opponent" fixed={true} fixedPosition="left" headerCellRender={renderTitleHeader}>
                        <Lookup dataSource={this.props.allTeams} displayExpr="name" valueExpr="id" />
                      </Column>
                      <Column dataField="site" caption="Site" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="date" caption="Date/Time ***" dataType="datetime" format="longDateLongTime" headerCellRender={renderTitleHeader} sortOrder="asc"></Column>

                      <Column dataField="score1" caption="Score" dataType="number" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="score2" caption="Opp Score" dataType="number" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="overtime" caption={this.state.overtime_suffix} dataType="number" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="isConference" dataType="number" caption="Game Type" headerCellRender={renderTitleHeader}>
                        <Lookup dataSource={isConference} displayExpr="name" valueExpr="id" />
                      </Column>
                      <Column dataField="stadium" caption="Arena" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="city" caption="City" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="state" caption="State" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="country" caption="Country" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="displaySportId" caption="Sport" headerCellRender={renderTitleHeader}>
                        {/* sport is set based on active set */}
                        <Lookup dataSource={this.props.allSports} displayExpr="name" valueExpr="id" />
                      </Column>
                    </DataGrid>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <h3>Faktor data quality</h3>
                    <DataGrid
                      width={"100%"}
                      columnAutoWidth={true}
                      dataSource={this.state.scores_data.data_quality_discrepancies}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      columnResizingMode={"widget"}
                      showBorders={false}
                      showColumnLines={false}
                      showRowLines={true}
                      rowAlternationEnabled={true}
                      allowSearch={true}
                      selection={{ mode: "multiple", showCheckBoxesMode: "none" }}
                    >
                      <SearchPanel visible={true} />
                      <FilterRow visible={true} />
                      <FilterPanel visible={true} />
                      <Sorting mode="multiple" />
                      <Export enabled={true} fileName={"KPI-Data-Quality"} />
                      <Paging defaultPageSize={800} />

                      <Pager showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />
                      <Column dataField="team" caption="team" dataType="string" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="kpiRecord" caption="kpiRecord" dataType="string" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="ncaaRecord" caption="ncaaRecord" dataType="string" headerCellRender={renderTitleHeader}></Column>
                      <Column caption="Truthy" calculateCellValue={(data) => data.kpiRecord === data.ncaaRecord} dataType="boolean" headerCellRender={renderTitleHeader}></Column>
                    </DataGrid>
                  </Col>
                </Row>
              </div>
            )}
            {this.state.selectedIndex === 2 && (
              <div style={div_style}>
                <h3>Teams/Conferences</h3>
                <Row className="my-3">
                  <Col xs={8}>
                    <Button id="button" style={{ fontSize: "14px", fontWeight: "bold" }} icon="tel" type="danger" onClick={this.on_click_dial_teams_conferences_updater}>
                      <LoadIndicator className="button-indicator" visible={this.state.dial_teams_conferences_text_default === ""} />
                      <span className="dx-button-text">{dial_teams_conferences_text_default}</span>
                    </Button>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col xs={8}>
                    <Button id="button" style={{ fontSize: "14px", fontWeight: "bold" }} icon="tel" type="default" onClick={this.on_click_dial_teams_conferences_updater_dry_run}>
                      <LoadIndicator className="button-indicator" visible={this.state.dial_teams_conferences_text_dry_run === ""} />
                      <span className="dx-button-text">{dial_teams_conferences_text_dry_run}</span>
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <h3>Faktor database updates</h3>
                    <DataGrid
                      width={"100%"}
                      columnAutoWidth={true}
                      dataSource={this.state.teams_conferences_data.updated_rows}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      columnResizingMode={"widget"}
                      showBorders={false}
                      showColumnLines={false}
                      showRowLines={true}
                      rowAlternationEnabled={true}
                      allowSearch={true}
                      onEditorPreparing={this.onEditorPreparing}
                      selection={{ mode: "multiple", showCheckBoxesMode: "none" }}
                    >
                      <SearchPanel visible={true} />
                      <FilterRow visible={true} />
                      <FilterPanel visible={true} />
                      <Sorting mode="multiple" />
                      <Export enabled={true} fileName={"KPI-Teams-Conferences-Updates"} />
                      <Paging defaultPageSize={100} />
                      <Pager showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />
                      <Column dataField="isNew" caption="New" dataType="boolean" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="message" caption="Message" dataType="string" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="displaySportId" caption="Sport" headerCellRender={renderTitleHeader}>
                        {/* sport is set based on active set */}
                        <Lookup dataSource={this.props.allSports} displayExpr="name" valueExpr="id" />
                      </Column>
                      <Column dataField="conferenceName" caption="conferenceName" dataType="string" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="conferenceId" caption="conferenceId" dataType="number" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="ncaaConferenceId" caption="ncaaConferenceId" dataType="number" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="teamName" caption="teamName" dataType="string" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="teamId" caption="teamId" dataType="number" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="ncaaTeamId" caption="ncaaTeamId" dataType="number" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="color1" caption="color1" dataType="string" headerCellRender={renderTitleHeader}></Column>
                    </DataGrid>
                  </Col>
                </Row>
              </div>
            )}
            {this.state.selectedIndex === 3 && (
              <div style={div_style}>
                <h3>Raw Data</h3>
                <Row className="my-3">
                  <Col xs={8}>
                    <Button id="button" style={{ fontSize: "14px", fontWeight: "bold" }} icon="tel" type="default" onClick={this.on_click_get_raw_data}>
                      <LoadIndicator className="button-indicator" visible={this.state.loading_raw_data} />
                      <span className="dx-button-text">Get Raw Data as JSON</span>
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12"></Col>
                </Row>
              </div>
            )}
            {this.state.selectedIndex === 4 && (
              <div style={div_style}>
                <h3>Team Information (slow)</h3>
                <Row className="my-3">
                  <Col xs={8}>
                    <Button id="button" style={{ fontSize: "14px", fontWeight: "bold" }} icon="tel" type="danger" onClick={this.on_click_dial_team_information}>
                      <LoadIndicator className="button-indicator" visible={this.state.on_click_dial_team_information_text_default === ""} />
                      <span className="dx-button-text">{on_click_dial_team_information_text_default}</span>
                    </Button>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col xs={8}>
                    <Button id="button" style={{ fontSize: "14px", fontWeight: "bold" }} icon="tel" type="default" onClick={this.on_click_dial_team_information_dry_run}>
                      <LoadIndicator className="button-indicator" visible={this.state.on_click_dial_team_information_text_dry_run === ""} />
                      <span className="dx-button-text">{on_click_dial_team_information_text_dry_run}</span>
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <h3>Faktor database updates</h3>
                    <DataGrid
                      width={"100%"}
                      columnAutoWidth={true}
                      dataSource={this.state.team_information_data}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      columnResizingMode={"widget"}
                      showBorders={false}
                      showColumnLines={false}
                      showRowLines={true}
                      rowAlternationEnabled={true}
                      allowSearch={true}
                      selection={{ mode: "multiple", showCheckBoxesMode: "none" }}
                    >
                      <SearchPanel visible={true} />
                      <FilterRow visible={true} />
                      <FilterPanel visible={true} />
                      <Sorting mode="multiple" />
                      <Export enabled={true} fileName={"KPI-Teams-Info-Updates"} />
                      <Paging defaultPageSize={800} />
                      <Pager showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />
                      <Column dataField="isNew" caption="New" dataType="boolean" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="message" caption="Message" dataType="string" headerCellRender={renderTitleHeader}></Column>

                      <Column dataField="response" caption="conferenceName" dataType="string" headerCellRender={renderTitleHeader}></Column>

                      <Column dataField="city" caption="city" dataType="string" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="arena" caption="arena" dataType="string" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="state" caption="state" dataType="string" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="country" caption="country" dataType="string" headerCellRender={renderTitleHeader}></Column>
                      <Column dataField="teamId" caption="Team" fixed={true} fixedPosition="left" headerCellRender={renderTitleHeader}>
                        <Lookup dataSource={this.props.allTeams} displayExpr="name" valueExpr="id" />
                      </Column>
                    </DataGrid>
                  </Col>
                </Row>
              </div>
            )}
            <Popup
              visible={this.state.download_kpi_pop_up_visible}
              onHiding={this.hide_download_kpi_pop_up}
              dragEnabled={false}
              closeOnOutsideClick={true}
              showTitle={true}
              title="Download calculation results"
              width={300}
              height={250}
            >
              <p>
                <a href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(this.state.kpi_data))}`} download="kpi.json">
                  {`Click to download`}
                </a>
              </p>
            </Popup>
            <Popup
              visible={this.state.download_vtp_pop_up_visible}
              onHiding={this.hide_download_vtp_pop_up}
              dragEnabled={false}
              closeOnOutsideClick={true}
              showTitle={true}
              title="Download VTP results"
              width={300}
              height={250}
            >
              <p>
                <a href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(this.state.vtp_data))}`} download="kpi.json">
                  {`Click to download`}
                </a>
              </p>
            </Popup>
            <Popup
              visible={this.state.download_scores_pop_up_visible}
              onHiding={this.hide_download_scores_pop_up}
              dragEnabled={false}
              closeOnOutsideClick={true}
              showTitle={true}
              title="Download scores"
              width={300}
              height={250}
            >
              <p>
                <a href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(this.state.scores_data))}`} download="scores.json">
                  {`Click to download`}
                </a>
              </p>
            </Popup>
            <Popup
              visible={this.state.download_teams_conferences_pop_up_visible}
              onHiding={this.hide_download_teams_conferences_pop_up}
              dragEnabled={false}
              closeOnOutsideClick={true}
              showTitle={true}
              title="Download teams and conferences"
              width={300}
              height={250}
            >
              <p>
                <a href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(this.state.teams_conferences_data))}`} download="teams_conferences.json">
                  {`Click to download`}
                </a>
              </p>
            </Popup>
            <Popup
              visible={this.state.download_raw_data_visible}
              onHiding={this.hide_download_raw_data_pop_up}
              dragEnabled={false}
              closeOnOutsideClick={true}
              showTitle={true}
              title="Download raw data"
              width={300}
              height={250}
            >
              <p>
                <a href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(this.state.raw_data))}`} download="raw_data.json">
                  {`Click to download`}
                </a>
              </p>
            </Popup>
            <Popup
              visible={this.state.download_team_information_pop_up_visible}
              onHiding={this.hide_download_team_information_pop_up}
              dragEnabled={false}
              closeOnOutsideClick={true}
              showTitle={true}
              title="Download team info"
              width={300}
              height={250}
            >
              <p>
                <a href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(this.state.team_information_data))}`} download="team_info_data.json">
                  {`Click to download`}
                </a>
              </p>
            </Popup>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default KpiCaller;
