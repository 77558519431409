import React, { Component, Fragment } from "react";
import auth_config from "./../auth_config.json";
import { Button } from "devextreme-react";
import { CheckBox } from "devextreme-react/check-box";
import { SelectBox } from "devextreme-react";
import DateBox from "devextreme-react/date-box";
import { Template } from "devextreme-react/core/template";
import { confirm } from "devextreme/ui/dialog";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Pager,
  Lookup,
  FormItem,
  Popup,
  Position,
  Export,
  SearchPanel,
  AsyncRule,
  CustomRule,
  NumericRule,
  Sorting,
  FilterRow,
  FilterPanel,
  HeaderFilter,
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import Tabs from "devextreme-react/tabs";
import { renderTitleHeader } from "./../utils/common-rendering-funcs";
import { isString, utc2db, getMinMaxDateValues, make_date_open_interval, get_overtime_suffix, getUniqueCombinations } from "./../utils/common-funcs";
import { make_session_key_with_ddst, get_tournaments, make_rakings_session_key, get_teams_for_sport_season } from "../services/publicService";
import { set_team_id_to_team_map, set_team_id_to_team_map_lambda } from "./../services/commonDataService";
import "devextreme-react/text-area";
import { StringLengthRule, RequiredRule } from "devextreme-react/validator";
import { Row, Col, CardHeader, Card, CardBody } from "reactstrap";
import { toast } from "react-toastify";
import { parse, format } from "fecha";

import { time_format, isConference, american_states, countries, siteNames } from "../utils/enums";
import {
  get_sandbox_kpi_for_season_and_sport,
  put_game_schedule_entry,
  patch_game_schedule_entry,
  get_game_schedule_entry,
  get_game_schedule_entry_for_conference,
  get_game_schedule_entry_for_interval,
  delete_game_schedule_entry,
  get_blocked_dates,
  get_game_count_limits_for_sport,
} from "../services/privateService";
import { get_broadcasters } from "../services/publicService";
import { pager_lengths } from "./../utils/enums";
import GameCounts from "./home-game-counts";

const tbdDateUTC = "1000-01-02T00:00:00Z";

function get_proposed_date_iso() {
  const proposedDate = new Date();
  proposedDate.setHours(18, 0, 0, 0);

  return proposedDate.toISOString();
}

export const editingChoices = [
  { id: 0, name: "popup" },
  { id: 1, name: "row" },
];

const default_footer = [
  {
    key: 0,
    value: "* Select 'Date TBD' if game date and time will be determined later.",
  },
  {
    key: 1,
    value: "** Select 'Time TBD' if game date is known, but game time will be determined later.",
  },
  {
    key: 3,
    value: `*** All times are represented in ${Intl.DateTimeFormat().resolvedOptions().timeZone} time-zone`,
  },
];

const tabs = [
  {
    id: 0,
    text: "By team",
    title: "Schedule games for a given teams",
    header: "Add games by clicking + on top-right corner of table. Opposing teams can reject/delete scheduled games.",
    footer: default_footer,
  },
  {
    id: 1,
    text: "By date",
    title: "Schedule games for a given date",
    header: "Add games by clicking + on top-right corner of table. Opposing teams can reject/delete scheduled games.",
    footer: default_footer,
  },
  {
    id: 2,
    text: "By conference",
    title: "Schedule games for a conference",
    header: "Add games by clicking + on top-right corner of table. Opposing teams can reject/delete scheduled games.",
    footer: default_footer,
  },
  {
    id: 3,
    text: "By Tournament",
    title: "Schedule games for a tournament",
    header: "Add games by clicking + on top-right corner of table. Opposing teams can reject/delete scheduled games.",
    footer: default_footer,
  },

  {
    id: 4,
    text: "Game Counts",
    title: "View/update game counts",
    header: "View/update game counts",
    footer: [],
  },
];

class GameScheduleEntry extends Component {
  constructor(props) {
    super(props);

    this.checkBoxOptionsDateTBD = {
      onValueChanged: (e) => {
        this.setState({
          isDateVisible: !e.component.option("value"),
        });
      },
    };

    this.allConferences = [];
    this.enable_score_editing = this.props.userPermissions.includes(auth_config.writeDbPermission) || this.props.userPermissions.includes(auth_config.scoreEntryPermission);

    this.state = {
      isDateVisible: true,
      table: [],
      minDateValue: undefined,
      maxDateValue: undefined,
      usePopupEditinMode: true,
      allowedDisplaySportIds: this.props.allSports.filter((x) => this.props.allowedDisplaySportIds.has(x.id)),

      team_id_to_team_map: new Map(),
      broadcasters: [],
      tournaments: [],
      tournament_to_game_type_map: new Map(),

      selectedIndex: 0,
      searchStart: undefined,
      searchEnd: undefined,
      includeTbdDates: true,
      selectBoxValueConferenecId: undefined,
      selectBoxValueTournamentId: undefined,
      overtime_suffix: "",
      selectedItemKeys: [],
    };

    this.validationCallbackBlockedDates = this.validationCallbackBlockedDates.bind(this);
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
    this.onClickRefresh = this.onClickRefresh.bind(this);
    this.onValueChangedUsePopupEditinMode = this.onValueChangedUsePopupEditinMode.bind(this);
    this.onValueChangedIncludeTbdDates = this.onValueChangedIncludeTbdDates.bind(this);
    this.setStateValueOpponent = this.setStateValueOpponent.bind(this);
    this.setStateValueTournamentId = this.setStateValueTournamentId.bind(this);
    this.setStateValueSite = this.setStateValueSite.bind(this);
    //this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);
    this.onValueChangedStartDate = this.onValueChangedStartDate.bind(this);
    this.onValueChangedEndDate = this.onValueChangedEndDate.bind(this);
    this.onValueChangedSelectBoxConferenceId = this.onValueChangedSelectBoxConferenceId.bind(this);
    this.onValueChangedSelectBoxTournamentId = this.onValueChangedSelectBoxTournamentId.bind(this);
    this.selectionChanged = this.selectionChanged.bind(this);
    this.deleteRecords = this.deleteRecords.bind(this);
    this.deleteButtonRender = this.deleteButtonRender.bind(this);
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
  }

  get_game_schedule_entry_table_data_by_team = async () => {
    const { data: table } = await get_game_schedule_entry(
      this.props.auth0jwt,
      -1,
      this.props.selectedTeam.id,
      this.props.selectedDivision.id,
      this.props.selectedSport.id,
      this.props.selectedSeason.id
    );
    return table;
  };

  get_game_schedule_entry_table_data_by_date = async () => {
    if (!this.state.searchStart) {
      toast.error("Please provide a start date");
      return [];
    }
    if (!this.state.searchEnd) {
      toast.error("Please provide an end date");
      return [];
    }

    if (this.state.searchStart > this.state.searchEnd) {
      toast.error("End date must be later than start date");
      return [];
    }

    const [leftBoundOpen, rightBoundOpen] = make_date_open_interval(this.state.searchStart, this.state.searchEnd, time_format.utc);

    const { data: table } = await get_game_schedule_entry_for_interval(
      this.props.auth0jwt,
      -1,
      this.props.selectedDivision.id,
      this.props.selectedSport.id,
      this.props.selectedSeason.id,
      leftBoundOpen,
      rightBoundOpen
    );

    let tableTbdGames = [];
    if (this.state.includeTbdDates) {
      const tableTbdGamesQres = await get_game_schedule_entry_for_interval(
        this.props.auth0jwt,
        -1,
        this.props.selectedDivision.id,
        this.props.selectedSport.id,
        this.props.selectedSeason.id,
        null,
        null
      );
      tableTbdGames = tableTbdGamesQres.data;
    }

    let table_filtered = table.filter((x) => {
      const d = parse(x.date, time_format.utc);
      return d >= this.state.searchStart && d <= this.state.searchEnd;
    });

    if (Array.isArray(tableTbdGames)) {
      return table_filtered.concat(tableTbdGames);
    } else {
      return table_filtered;
    }
  };

  get_game_schedule_entry_table_data_by_conference = async () => {
    if (!this.state.selectBoxValueConferenecId) {
      toast.error("Please select a conference");
      return [];
    }

    const { data: table } = await get_game_schedule_entry_for_conference(
      this.props.auth0jwt,
      -1,
      this.state.selectBoxValueConferenecId,
      this.props.selectedDivision.id,
      this.props.selectedSport.id,
      this.props.selectedSeason.id,
      0
    );

    return getUniqueCombinations(table);
  };

  

  get_game_schedule_entry_table_data_by_tournament = async () => {
    if (!this.state.selectBoxValueTournamentId) {
      toast.error("Please select a tournament");
      return [];
    }

    const { data: table } = await get_game_schedule_entry_for_conference(
      this.props.auth0jwt,
      -1,
      0,
      this.props.selectedDivision.id,
      this.props.selectedSport.id,
      this.props.selectedSeason.id,
      this.state.selectBoxValueTournamentId
    );


    return getUniqueCombinations(table);
  };

  get_game_count_limits = async () => {
    if (this.props.selectedDivision.id && this.props.selectedSport.id) {
      const { data: table } = await get_game_count_limits_for_sport(
        this.props.auth0jwt,
        this.props.selectedDivision.id,
        this.props.selectedSport.id,
        0,
        this.props.selectedSeason.id
      );

      return table;
    } else {
      toast.error(`please select all required fields`);
      return [];
    }
  };

  get_game_schedule_entry_table_data = async () => {
    try {
      if (this.state.selectedIndex === 0) {
        //by team
        return await this.get_game_schedule_entry_table_data_by_team();
      }

      if (this.state.selectedIndex === 1) {
        //by date
        return await this.get_game_schedule_entry_table_data_by_date();
      }

      if (this.state.selectedIndex === 2) {
        //by conference
        return await this.get_game_schedule_entry_table_data_by_conference();
      }

      if (this.state.selectedIndex === 3) {
        //by tournament
        return await this.get_game_schedule_entry_table_data_by_tournament();
      }

      if (this.state.selectedIndex === 4) {
        //get game counts
        return await this.get_game_count_limits();
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  async componentDidMount() {
    try {
      await this.update_implementation();
    } catch (error) {
      console.log(error);
    }
  }

  onTabsSelectionChanged = async (args) => {
    if (args.name === "selectedIndex" && args.value !== this.state.selectedIndex) {
      if (args.value === 0 || args.value === 4) {
        this.setState(
          {
            selectedIndex: args.value,
          },
          async () => {
            if (args.value === 0 || args.value === 4) {
              //only update for the first tab or game counts. the other tabs need drop down selections first
              await this.refresh_data_sources();
            }
          }
        );
      } else {
        this.setState({
          table: [],
          selectedIndex: args.value,
        });
      }
    }
  };

  update_implementation = async () => {
    notify(`Refreshing table`);

    this.allConferences = this.props.allConferences.filter((x) => x.id > 0);
    this.enable_score_editing = this.props.userPermissions.includes(auth_config.writeDbPermission) || this.props.userPermissions.includes(auth_config.scoreEntryPermission);
    const overtime_suffix = get_overtime_suffix(this.props.selectedSport.id);

    const { data: broadcasters } = await get_broadcasters();
    let table = [];
    let tournaments = [];
    let minDateValue = undefined;
    let maxDateValue = undefined;
    let tournament_to_game_type_map = new Map();

    let team_id_to_team_map = new Map();
    if (this.props.selectedDivision.id && this.props.selectedSport.id && this.props.selectedSeason.id) {
      [minDateValue, maxDateValue] = getMinMaxDateValues(this.props.selectedSport.name, this.props.selectedSeason.name);

      team_id_to_team_map = await set_team_id_to_team_map(this.props.allTeams);

      if (this.props.selectedTeam.id) {
        table = await this.get_game_schedule_entry_table_data();
      }

      const tres = await get_tournaments(this.props.selectedDivision.id, this.props.selectedSport.id, this.props.selectedSeason.id);
      tournaments = tres.data;

      tournament_to_game_type_map = await set_team_id_to_team_map_lambda(tournaments, (t) => t.tournamentGameTypeId > 0);
    }

    this.setState({
      broadcasters: broadcasters,
      table: table,
      minDateValue: minDateValue,
      maxDateValue: maxDateValue,

      team_id_to_team_map: team_id_to_team_map,
      allowedDisplaySportIds: this.props.allSports ? this.props.allSports.filter((x) => this.props.allowedDisplaySportIds.has(x.id)) : [],
      tournaments: tournaments,
      tournament_to_game_type_map: tournament_to_game_type_map,
      selectBoxValueConferenecId: team_id_to_team_map.get(this.props.selectedTeam.id)?.conferenceId || undefined,
      overtime_suffix: overtime_suffix,
    });
  };

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.user && !prevProps.user) ||
      (!this.props.user && prevProps.user) ||
      this.props.user.hq_current_user.userId !== prevProps.user.hq_current_user.userId ||
      this.props.isAuthenticated !== prevProps.isAuthenticated ||
      this.props.selectedSport.id !== prevProps.selectedSport.id ||
      this.props.selectedDivision.id !== prevProps.selectedDivision.id ||
      this.props.selectedSeason.id !== prevProps.selectedSeason.id ||
      this.props.selectedTeam.id !== prevProps.selectedTeam.id
    ) {
      await this.update_implementation();
    }
  }

  onInitNewRow = async (e) => {
    e.promise = this.checkTokenLocal();

    const selectedTournamentValue = this.state.selectBoxValueTournamentId;

    e.data.team1Id = this.props.selectedTeam.id;
    e.data.divisionId = this.props.selectedDivision.id;
    e.data.displaySportId = this.props.selectedSport.id;
    e.data.seasonId = this.props.selectedSeason.id;
    e.data.roundId = 0;
    e.data.tournamentId = this.state.selectedIndex === 3 && selectedTournamentValue ? selectedTournamentValue : 0;
    e.data.broadcasterId = 0;
    e.data.userId = this.props.user.hq_current_user.userId;
    e.data.userName = this.props.user.email;
    e.data.sub = this.props.user.sub;
    e.data.isConference = 1;
    e.data.isPrivate = 0;
    e.data.site = "H";
    e.data.kpi = null; //only kpi-dialer can update these. keep it null so we do not overwrite
    e.data.team1KpiRank = null; //only kpi-dialer can update these. keep it null so we do not overwrite
    e.data.team2KpiRank = null; //only kpi-dialer can update these. keep it null so we do not overwrite
    e.data.dateTBD = false;
    e.data.timeTBD = true;
    e.data.date = get_proposed_date_iso();
    e.data.lastUpdate = new Date().toISOString();
    e.data.country = "U.S.A.";
    e.data.gameKeyId = 0;
    e.data.doubleHeaderGameNumber = 0;
    e.data.overtime = null;
  };

  update_blocked_dates_in_place = (arr) => {
    arr.forEach((x) => {
      if (x.startDate) {
        x.startDate = parse(x.startDate, time_format.utc);
      }
      if (x.endDate) {
        x.endDate = parse(x.endDate, time_format.utc);
      }
    });
  };

  update_or_insert_game_schedule_row_to_db = async (e) => {
    try {
      if (e.data.team1Id) {
        let deep_copy = JSON.parse(JSON.stringify(e.data));

        deep_copy.userId = this.props.user.hq_current_user.userId;
        deep_copy.sub = this.props.user.sub;
        deep_copy.userName = this.props.user.email;
        deep_copy.lastUpdate = new Date().toISOString();

        if (deep_copy.dateTBD) {
          deep_copy.timeTBD = true;
        }

        this.handle_TBD_date_in_place_for_upload_to_db(deep_copy);

        let res = null;

        if (deep_copy.gameKeyId) {
          console.log("calling patch");
          res = await patch_game_schedule_entry(this.props.auth0jwt, deep_copy);
        } else {
          console.log("calling put");
          res = await put_game_schedule_entry(this.props.auth0jwt, deep_copy);
          e.data.gameKeyId = res.data.gameKeyId;
        }

        if (res.status === 200) {
          toast.success(`Successfully scheduled game!`);
        } else {
          console.log(res);
          toast.error(`Could not schedule game`);
        }
      } else {
        toast.error(`Could not schedule game`);
      }
    } catch (error) {
      toast.error(`Could not schedule game`);
      console.log(error);
    }
  };

  onRowInserting = async (e) => {
    e.cancel = this.checkTokenLocal();
  };

  onRowInserted = async (e) => {
    console.log("inserted row");
    this.handle_session_storage();
    await this.update_or_insert_game_schedule_row_to_db(e);
    await this.refresh_data_sources();
  };

  onRowUpdating = async (e) => {
    e.cancel = this.checkTokenLocal();
  };

  checkTokenLocal = async (e) => {
    return await this.props.checkToken("Game Schedule Entry");
  };

  onRowUpdated = async (e) => {
    console.log("updated row");
    this.handle_session_storage();
    await this.update_or_insert_game_schedule_row_to_db(e);
    await this.refresh_data_sources();
  };

  onRowRemovedImplementation = async (deep_copy) => {
    deep_copy.userId = this.props.user.hq_current_user.userId;
    deep_copy.sub = this.props.user.sub;
    this.handle_TBD_date_in_place_for_upload_to_db(deep_copy);
    var res = await delete_game_schedule_entry(this.props.auth0jwt, deep_copy);
    const postfix = `game against ${this.state.team_id_to_team_map.get(deep_copy.team2Id)?.name}! (id:${deep_copy.gameKeyId})`;
    if (res.status === 200) {
      toast.success(`Successfully removed ${postfix}`, {
        autoClose: true,
      });
    } else {
      toast.error(`Could not remove ${postfix}`);
    }
  };

  onRowRemoved = async (e) => {
    console.log("removed row");
    try {
      this.handle_session_storage();
      let deep_copy = JSON.parse(JSON.stringify(e.data));
      await this.onRowRemovedImplementation(deep_copy);
    } catch (error) {
      toast.error(`Could not remove game`);
      console.log(error);
    }
    await this.refresh_data_sources();
  };

  async onClickRefresh(e) {
    notify(`Refreshing table`);
    await this.refresh_data_sources();
  }

  refresh_data_sources = async () => {
    if (this.props.selectedTeam.id && this.props.selectedDivision.id && this.props.selectedSport.id && this.props.selectedSeason.id) {
      try {
        console.log("refresh_data_sources");
        const table = await this.get_game_schedule_entry_table_data();

        this.setState({ table: table });
      } catch (error) {
        console.log(error);
      }
    }
  };

  handle_TBD_date_in_place_for_upload_to_db(deep_copy) {
    if (deep_copy.dateTBD && isString(deep_copy.date)) {
      deep_copy.date = utc2db(deep_copy.date);
    } else if (deep_copy.dateTBD && deep_copy.date) {
      deep_copy.date = utc2db(deep_copy.date.toISOString());
    } else if (!deep_copy.date) {
      deep_copy.dateTBD = true;
      // deep_copy.date = utc2db(tbdDateUTC);
      deep_copy.date = utc2db(new Date().toISOString());
    } else if (isString(deep_copy.date)) {
      deep_copy.date = utc2db(deep_copy.date);
    } else {
      deep_copy.date = utc2db(deep_copy.date.toISOString());
    }

    if (isString(deep_copy.lastUpdate)) {
      deep_copy.lastUpdate = utc2db(deep_copy.lastUpdate);
    } else if (deep_copy.lastUpdate) {
      deep_copy.lastUpdate = utc2db(deep_copy.lastUpdate.toISOString());
    }
  }

  handle_session_storage = () => {
    const session_storage_key = make_session_key_with_ddst(this.props.selectedDivision.id, this.props.selectedSport.id, this.props.selectedSeason.id, this.props.selectedTeam.id);

    const session_division_sport_season_key = make_rakings_session_key(this.props.selectedDivision.id, this.props.selectedSport.id, this.props.selectedSeason.id);

    sessionStorage.removeItem(session_storage_key);
    sessionStorage.removeItem(session_division_sport_season_key);
  };

  // allowedDivisionIds={this.state.allowedDivisionIds}
  // allowedDisplaySportIds={this.state.allowedDisplaySportIds}
  // allowedTeamIds={this.state.allowedTeamIds}
  setStateValueDateTBD(rowData, value) {
    if (value) {
      rowData.timeTBD = true;
    } else {
      rowData.timeTBD = false;
    }
    rowData.date = new Date().toISOString();

    this.defaultSetCellValue(rowData, value);
  }

  setStateValueOpponent(newData, value, currentRowData) {
    newData.team2Id = value;

    const team1_conf = this.state.team_id_to_team_map.get(currentRowData.team1Id)?.conferenceId || 0;
    const team2_conf = this.state.team_id_to_team_map.get(value)?.conferenceId || 0;

    if (currentRowData.team2Id === 0) {
      //when changing from TBD team to real team, do NOT change game-type
      newData.isConference = currentRowData.isConference;
    } else {
      newData.isConference = team1_conf === team2_conf ? 1 : 2;
    }

    if (currentRowData.site === "H") {
      this.autoPopulateCityStateArena(newData, this.props.selectedTeam.id);
    } else if (currentRowData.site === "A") {
      this.autoPopulateCityStateArena(newData, value);
    } else {
      //do not overwite existing selections
    }
  }

  autoPopulateCityStateArena = (newData, team2Id) => {
    try {
      const m = this.state.team_id_to_team_map.get(team2Id) || {
        city: "",
        state: "",
        arena: "",
      };

      newData.city = m.city;
      newData.state = m.state;
      newData.stadium = m.arena;
    } catch (error) {
      console.log(error);
    }
  };

  setStateValueSite(newData, value, currentRowData) {
    newData.site = value;

    if (value === "H") {
      this.autoPopulateCityStateArena(newData, currentRowData.team1Id);
    } else if (value === "A") {
      this.autoPopulateCityStateArena(newData, currentRowData.team2Id);
    } else {
      this.autoPopulateCityStateArena(newData, null);
    }
  }

  calculateDisplayValueDate(e) {
    if (e.dateTBD || !e.date) {
      return "<TBD>";
    }

    let dt = null;
    if (isString(e.date)) {
      dt = parse(e.date, time_format.utc);
    } else {
      dt = e.date;
    }

    if (dt) {
      return e.timeTBD ? format(dt, "YYYY-MM-DD ddd") : format(dt, "YYYY-MM-DD ddd hh:mm:ss A");
    } else {
      return "";
    }
  }

  onCalculateSortValueDateTime(data) {
    return data.date;
  }

  calculateEnteredBy(rowData) {
    if (rowData.entryType === 0) {
      return "Faktor";
    } else {
      return rowData.userName;
    }
  }

  calculateEasternTime(e) {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;

    // if (!e.date || e.dateTBD || e.timeTBD) {
    //   return "<TBD>";
    // }

    // let dt = null;
    // if (isString(e.date)) {
    //   dt = parse(e.date, time_format.utc);
    // } else {
    //   dt = e.date;
    // }

    // return dt.toLocaleString("en-US", { timeZone: "America/New_York" });
  }

  validationCallbackTeam2Selection(e) {
    return e.data.team2Id >= 0;
  }

  async validationCallbackBlockedDates(e) {
    if (!e.data.team1Id) {
      console.log("missing team1Id");
      return false;
    }

    if (e.data.dateTBD) {
      return true;
    }

    const { data: team1_blocked_dates } = await get_blocked_dates(
      this.props.auth0jwt,
      this.props.selectedSeason.id,
      e.data.team1Id,
      this.props.selectedSport.id,
      this.props.selectedDivision.id
    );

    this.update_blocked_dates_in_place(team1_blocked_dates);

    for (let i = 0; i < team1_blocked_dates.length; i++) {
      let blocked_set = team1_blocked_dates[i];
      if (e.value && e.value > blocked_set.startDate && e.value < blocked_set.endDate) {
        const msg_stem = `${this.props.selectedTeam.name} is blocked from [${blocked_set.startDate}] to [${blocked_set.endDate}]`;

        const blockedForId = blocked_set.blockedForId;
        const site = e.data.site;

        if (blockedForId === 1 && (site === "A" || site === "N")) {
          console.log(`${msg_stem} for away games`);
          return false;
        } else if (blockedForId === 2 && site === "H") {
          console.log(`${msg_stem} for home games`);
          return false;
        } else if (blockedForId === 3) {
          console.log(`${msg_stem} for home/away/neutral games`);
          return false;
        } else {
          console.log(`team1 not blocked for ${blockedForId} / ${site}`);
          return true;
        }
      }
    }

    if (e.data.team2Id === 0) {
      return true;
    }

    if (!e.data.team2Id) {
      console.log("missing team2Id");
      return false;
    }

    const { data: team2_blocked_dates } = await get_blocked_dates(
      this.props.auth0jwt,
      this.props.selectedSeason.id,
      e.data.team2Id,
      this.props.selectedSport.id,
      0 //team2 might be blocked in another division
    );

    this.update_blocked_dates_in_place(team2_blocked_dates);
    for (let i = 0; i < team2_blocked_dates.length; i++) {
      let blocked_set = team2_blocked_dates[i];
      if (e.value && e.value > blocked_set.startDate && e.value < blocked_set.endDate) {
        const msg_stem = `Opponent is blocked from [${blocked_set.startDate}] to [${blocked_set.endDate}]`;
        const blockedForId = blocked_set.blockedForId;
        const site = e.data.site;
        if (blockedForId === 2 && site === "A") {
          console.log(`${msg_stem} for away games`);
          return false;
        } else if (blockedForId === 1 && (site === "H" || site === "N")) {
          console.log(`${msg_stem} for home games`);
          return false;
        } else if (blockedForId === 3) {
          console.log(`${msg_stem} for home/away/neutral games`);
          return false;
        } else {
          console.log(`team2 not blocked for ${blockedForId} / ${site}`);
          return true;
        }
      }
    }
    return true;
  }

  onEditorPreparing(e) {
    if (e.dataField === "date" && e.parentType === "dataRow") {
      e.editorOptions.min = this.state.minDateValue;
      e.editorOptions.max = this.state.maxDateValue;
      e.editorOptions.disabled = e.row.data && e.row.data.dateTBD;
      e.editorOptions.visible = e.row.data && !e.row.data.dateTBD;
    }
  }

  onValueChangedUsePopupEditinMode(args) {
    const tableToForceRefrsh = [...this.state.table];
    this.setState({
      usePopupEditinMode: args.value,
      table: tableToForceRefrsh,
    });
  }

  onValueChangedIncludeTbdDates(args) {
    this.setState({
      includeTbdDates: args.value,
    });
  }

  onValueChangedStartDate(e) {
    this.setState({
      searchStart: e.value,
    });
  }

  onValueChangedEndDate(e) {
    const tmp = e.value;
    if (tmp && !isString(tmp)) {
      tmp.setHours(23, 59, 59, 59);
    }
    this.setState({
      searchEnd: tmp,
    });
  }

  onClickSearch = async () => {
    notify(`Refreshing table`);
    await this.refresh_data_sources();
  };

  onValueChangedSelectBoxConferenceId(args) {
    this.setState({
      selectBoxValueConferenecId: args.value,
    });
  }

  onValueChangedSelectBoxTournamentId(args) {
    this.setState({
      selectBoxValueTournamentId: args.value,
    });
  }

  deleteButtonRender() {
    return <Button onClick={this.deleteRecords} icon="trash" text="Delete selected games"></Button>; //disabled={!this.state.selectedItemKeys.length}
  }

  async deleteRecords() {
    console.log("deleting records");
    const selectedItemKeysSet = new Set(this.state.selectedItemKeys);

    let deletionConfirmed = await confirm(
      `<i>Are you sure about deleting ${selectedItemKeysSet.size} game${selectedItemKeysSet.size > 1 ? "s" : ""}?</i>`,
      "Confirm Deleting Games"
    );
    if (deletionConfirmed) {
      const full_table = this.state.table;
      for (let index = 0; index < full_table.length; index++) {
        if (selectedItemKeysSet.has(full_table[index].gameKeyId)) {
          let deep_copy = JSON.parse(JSON.stringify(full_table[index]));
          await this.onRowRemovedImplementation(deep_copy);
        }
      }

      this.setState(
        {
          selectedItemKeys: [],
        },
        async () => {
          await this.refresh_data_sources();
        }
      );
    }
  }

  selectionChanged(data) {
    this.setState({
      selectedItemKeys: data.selectedRowKeys,
    });
  }

  onToolbarPreparing(e) {
    //only admins can delete multiple rows at once
    if (this.props.userPermissions && this.props.userPermissions.includes(auth_config.writeDbPermission)) {
      e.toolbarOptions.items[0].showText = "always";
      e.toolbarOptions.items.unshift({
        location: "after",
        template: "deleteButton",
      });
    }
  }

  setStateValueTournamentId(rowData, value) {
    if (value) {
      let candidate = this.state.tournament_to_game_type_map.get(value);

      if (candidate && candidate.tournamentGameTypeId) {
        rowData.isConference = candidate.tournamentGameTypeId;
      }
      rowData.tournamentId = value;
      console.log(rowData);
    }

    //this.defaultSetCellValue(rowData, value);
  }

  render() {
    return (
      <Fragment>
        <Card className="main-card my-2 mx-2 border border-primary rounded">
          <CardHeader className="card-header-tab">
            <Tabs dataSource={tabs} selectedIndex={this.state.selectedIndex} onOptionChanged={this.onTabsSelectionChanged} />
            <div style={{ float: "left" }} className="mt-3">
              <div className="card-header-title thick">{tabs[this.state.selectedIndex].header}</div>
              <small>
                <div>
                  All times are represented in <u>{Intl.DateTimeFormat().resolvedOptions().timeZone}</u> time-zone
                </div>
                <div style={{ color: "red" }}>
                  {this.state.selectedIndex !== 0 &&
                    "Only your accessible teams will be shown in the grid. Please consult 'User Profile and Access Level' page to review your accessible teams."}
                </div>
              </small>
            </div>

            <div style={{ float: "right" }} className="mt-3">
              <Button icon="refresh" hint="Refesh Table" type="back" stylingMode="contained" onClick={this.onClickRefresh} />
            </div>
          </CardHeader>

          <CardBody className="pt-1">
            <Row className="px-2 pt-1">
              <Col xs={{ size: 4 }}>
                <CheckBox value={this.state.usePopupEditinMode} onValueChanged={this.onValueChangedUsePopupEditinMode} width={160} text="Use Popup editing" />
              </Col>
            </Row>
            <Row className="px-2 mt-2 mb-2">
              {this.state.selectedIndex === 0 && <Col xs={4}></Col>}
              {this.state.selectedIndex === 1 && (
                <Fragment>
                  <Col xs={3}>
                    <DateBox
                      placeholder="Start date"
                      type="date"
                      value={this.state.searchStart}
                      min={this.state.minDateValue}
                      max={this.state.maxDateValue}
                      onValueChanged={this.onValueChangedStartDate}
                    />
                  </Col>
                  <Col className="ml-2" xs={3}>
                    <DateBox
                      placeholder="End date"
                      type="date"
                      value={this.state.searchEnd}
                      min={this.state.minDateValue}
                      max={this.state.maxDateValue}
                      onValueChanged={this.onValueChangedEndDate}
                    />
                  </Col>
                  <Col className="ml-2" xs={2}>
                    <CheckBox value={this.state.includeTbdDates} onValueChanged={this.onValueChangedIncludeTbdDates} text="Add TBD dates" />
                  </Col>
                  <Col xs={3} className="ml-2">
                    <Button style={{ fontSize: "14px", fontWeight: "bold" }} icon="search" type="default" width="100%" height="28px" onClick={this.onClickSearch}>
                      Fetch
                    </Button>
                  </Col>
                </Fragment>
              )}
              {this.state.selectedIndex === 2 && (
                <Fragment>
                  <Col xs={3}>
                    <SelectBox
                      placeholder={"Conference..."}
                      items={this.allConferences}
                      displayExpr="name"
                      valueExpr="id"
                      value={this.state.selectBoxValueConferenecId}
                      onValueChanged={this.onValueChangedSelectBoxConferenceId}
                      searchEnabled={true}
                      searchMode="contains"
                      searchExpr="name"
                    />
                  </Col>
                  <Col xs={3} className="ml-2">
                    <Button style={{ fontSize: "14px", fontWeight: "bold" }} icon="search" type="default" width="100%" height="28px" onClick={this.onClickSearch}>
                      Fetch
                    </Button>
                  </Col>
                </Fragment>
              )}
              {this.state.selectedIndex === 3 && (
                <Fragment>
                  <Col xs={3}>
                    <SelectBox
                      placeholder={"Tournaments..."}
                      items={this.state.tournaments}
                      displayExpr="name"
                      valueExpr="id"
                      value={this.state.selectBoxValueTournamentId}
                      onValueChanged={this.onValueChangedSelectBoxTournamentId}
                      searchEnabled={true}
                      searchMode="contains"
                      searchExpr="name"
                    />
                  </Col>
                  <Col xs={3} className="ml-2">
                    <Button style={{ fontSize: "14px", fontWeight: "bold" }} icon="search" type="default" width="100%" height="28px" onClick={this.onClickSearch}>
                      Fetch
                    </Button>
                  </Col>
                </Fragment>
              )}
            </Row>
            <Row>
              <Col xs="12">
                {this.state.selectedIndex === 4 && (
                  <GameCounts
                    {...this.props}
                    table={this.state.table}
                    usePopupEditinMode={this.state.usePopupEditinMode}
                    team_id_to_team_map={this.state.team_id_to_team_map}
                    refresh_data_sources={this.refresh_data_sources}
                  />
                )}
                {this.state.selectedIndex !== 4 && (
                  <DataGrid
                    width={"100%"}
                    columnAutoWidth={true}
                    dataSource={this.state.table}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnResizingMode={"widget"}
                    showBorders={false}
                    showColumnLines={false}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    allowSearch={true}
                    onInitNewRow={this.onInitNewRow}
                    onRowInserted={this.onRowInserted}
                    onRowInserting={this.onRowInserting}
                    onRowUpdated={this.onRowUpdated}
                    onRowUpdating={this.onRowUpdating}
                    keyExpr="gameKeyId"
                    onRowRemoved={this.onRowRemoved}
                    onEditorPreparing={this.onEditorPreparing}
                    selection={{
                      mode: "multiple",
                      selectAllMode: "page",
                      showCheckBoxesMode: this.props.userPermissions && this.props.userPermissions.includes(auth_config.writeDbPermission) ? "always" : "none",
                    }}
                    selectedRowKeys={this.state.selectedItemKeys}
                    onSelectionChanged={this.selectionChanged}
                    onToolbarPreparing={this.onToolbarPreparing}
                  >
                    <SearchPanel visible={true} />
                    <Sorting mode="multiple" />
                    <Export enabled={true} fileName={"KPI-Game-Schedule-Entry"} />
                    <Paging defaultPageSize={100} />
                    <Pager showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />
                    <FilterRow visible={true} />
                    <FilterPanel visible={true} />
                    <HeaderFilter visible={true} />
                    <Column
                      dataField="team1Id"
                      dataType="string"
                      caption="Team"
                      allowEditing={false}
                      fixed={true}
                      fixedPosition="left"
                      visible={this.state.selectedIndex !== 0}
                      headerCellRender={renderTitleHeader}
                    >
                      {/* if you make this editable, then lookup cannot remain allTeams */}
                      <Lookup dataSource={this.props.allTeams} displayExpr="name" valueExpr="id" />
                      <RequiredRule message="Team selection is required" />
                      <FormItem visible={true} />
                    </Column>
                    <Column
                      dataField="team2Id"
                      dataType="string"
                      caption="Opponent"
                      setCellValue={this.setStateValueOpponent}
                      fixed={true}
                      fixedPosition="left"
                      headerCellRender={renderTitleHeader}
                    >
                      <Lookup dataSource={this.props.allTeams} displayExpr="name" valueExpr="id" />
                      <RequiredRule message="Team selection is required" />
                    </Column>
                    <Column dataField="displaySportId" dataType="string" caption="Sport" allowEditing={false} visible={false} headerCellRender={renderTitleHeader}>
                      {/* sport is set based on active set */}
                      <Lookup dataSource={this.state.allowedDisplaySportIds} displayExpr="name" valueExpr="id" />
                      <RequiredRule message="Sport selection is required" />
                      <FormItem visible={true} />
                    </Column>
                    <Column dataField="site" dataType="string" caption="Site" setCellValue={this.setStateValueSite} headerCellRender={renderTitleHeader}>
                      <Lookup dataSource={siteNames} />
                      <RequiredRule message="Site selection is required" />
                    </Column>
                    <Column
                      dataField="dateTBD"
                      dataType="boolean"
                      caption="Date TBD? *"
                      setCellValue={this.setStateValueDateTBD}
                      visible={!this.state.usePopupEditinMode}
                      headerCellRender={renderTitleHeader}
                    >
                      <FormItem visible={true} editorType="dxCheckBox" />
                    </Column>
                    <Column dataField="timeTBD" dataType="boolean" caption="Time TBD? **" visible={!this.state.usePopupEditinMode} headerCellRender={renderTitleHeader}>
                      <FormItem visible={true} editorType="dxCheckBox" />
                    </Column>
                    <Column
                      dataField="date"
                      caption="Date/Time ***"
                      dataType="datetime"
                      format="longDateLongTime"
                      headerCellRender={renderTitleHeader}
                      calculateDisplayValue={this.calculateDisplayValueDate}
                      calculateSortValue={this.onCalculateSortValueDateTime}
                      sortOrder="asc"
                    >
                      <FormItem visible={this.state.isDateVisible} />
                      <CustomRule
                        validationCallback={this.validationCallbackTeam2Selection}
                        reevaluate={true}
                        message="Please select an opponent first so we can resolve blocked dates."
                      />
                      <AsyncRule message="Date has been blocked. Please see blocked dates in Date Manager." validationCallback={this.validationCallbackBlockedDates} />
                    </Column>
                    <Column
                      caption="Entry Time zone"
                      calculateCellValue={this.calculateEasternTime}
                      dataType="string"
                      allowEditing={false}
                      headerCellRender={renderTitleHeader}
                      visible={!this.state.usePopupEditinMode}
                    >
                      <FormItem visible={true} />
                    </Column>
                    <Column dataField="score1" caption="Score" dataType="number" headerCellRender={renderTitleHeader} allowEditing={this.enable_score_editing}>
                      <NumericRule message="Score must be a number" />
                    </Column>
                    <Column dataField="score2" caption="Opp Score" dataType="number" headerCellRender={renderTitleHeader} allowEditing={this.enable_score_editing}>
                      <NumericRule message="Opp score must be a number" />
                    </Column>
                    <Column
                      dataField="overtime"
                      caption={this.state.overtime_suffix}
                      dataType="number"
                      headerCellRender={renderTitleHeader}
                      allowEditing={this.enable_score_editing}
                    >
                      <NumericRule message="must be a number" />
                      <FormItem itemType={this.state.overtime_suffix ? "simple" : "empty"} />
                    </Column>
                    <Column dataField="broadcasterId" dataType="string" caption="Broadcaster" headerCellRender={renderTitleHeader}>
                      <Lookup dataSource={this.state.broadcasters} displayExpr="name" valueExpr="id" />
                      <RequiredRule message="Broadcaster selection is required" />
                    </Column>
                    <Column dataField="city" dataType="string" caption="City" headerCellRender={renderTitleHeader}>
                      <StringLengthRule min={2} max={255} message="City should be at least 2 characters long" />
                      <RequiredRule message="City selection is required" />
                    </Column>
                    <Column dataField="state" dataType="string" caption="State" headerCellRender={renderTitleHeader}>
                      <Lookup dataSource={american_states} />
                      <StringLengthRule min={2} max={255} message="State should be at least 2 characters long" />
                      <RequiredRule message="State selection is required" />
                    </Column>
                    <Column dataField="country" dataType="string" caption="Country" headerCellRender={renderTitleHeader}>
                      <Lookup dataSource={countries} />
                      <StringLengthRule min={2} max={255} message="Country should be at least 2 characters long" />
                      <RequiredRule message="Country selection is required" />
                    </Column>
                    <Column dataField="stadium" dataType="string" caption="Stadium" headerCellRender={renderTitleHeader}>
                      <StringLengthRule min={2} max={255} message="State should be at least 2 characters long" />
                      <RequiredRule message="Stadium selection is required" />
                    </Column>
                    <Column dataField="tournamentId" dataType="string" caption="Tournament" headerCellRender={renderTitleHeader} setCellValue={this.setStateValueTournamentId}>
                      <Lookup dataSource={this.state.tournaments} displayExpr="name" valueExpr="id" />
                      <RequiredRule message="Tournament selection is required" />
                    </Column>
                    <Column dataField="isConference" dataType="number" caption="Game Type" headerCellRender={renderTitleHeader}>
                      <Lookup dataSource={isConference} displayExpr="name" valueExpr="id" />
                      <RequiredRule message="Game type selection is required" />
                    </Column>
                    <Column dataField="roundId" dataType="string" caption="Round" headerCellRender={renderTitleHeader}>
                      <Lookup dataSource={this.props.rounds} displayExpr="name" valueExpr="id" />
                      <RequiredRule message="Round is required" />
                    </Column>
                    <Column dataField="doubleHeaderGameNumber" caption="Double Header Game Number" dataType="number" headerCellRender={renderTitleHeader}>
                      <NumericRule message="must be a number" />
                    </Column>
                    <Column caption="ID" dataField="gameKeyId" dataType="number" allowEditing={false} alignment="left" headerCellRender={renderTitleHeader}>
                      <FormItem visible={false} editorType="dxCheckBox" />
                    </Column>
                    <Column dataField="isPrivate" caption="Private" dataType="boolean" visible={true} headerCellRender={renderTitleHeader}>
                      <FormItem visible={true} editorType="dxCheckBox" />
                    </Column>
                    <Column dataField="note" dataType="string" caption="Notes" minWidth={160} headerCellRender={renderTitleHeader}>
                      <StringLengthRule max={1023} message="Note should be at most 1024 characters long" />
                    </Column>

                    <Column dataType="string" caption="Entered by" calculateCellValue={this.calculateEnteredBy} allowEditing={false} headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="lastUpdate" caption="Last update" dataType="datetime" allowEditing={false} headerCellRender={renderTitleHeader}></Column>

                    <Editing
                      mode={this.state.usePopupEditinMode ? "popup" : "row"}
                      selectTextOnEditStart={true}
                      useIcons={true}
                      allowUpdating={true}
                      allowDeleting={true}
                      allowAdding={true}
                    >
                      <Popup title="Game Schedule Entry" showTitle={true}>
                        <Position my="center" at="center" of={window} />
                      </Popup>

                      {/* <Form colCount={2} className="mx-5 px-5" customizeItem={this.customizeItem}> */}
                      {/* <Form colCount={2} className="mx-5 px-5">
                    <Item itemType="group" caption="Game" colCount={2} colSpan={2}>
                      <Item dataField="displaySportId" />
                      <Item dataField="isConference" />

                      <Item dataField="team1Id" />
                      <Item dataField="team2Id" />

                      <Item dataField="tournamentId" />
                      <Item dataField="roundId" />
                    </Item>
                    <Item itemType="group" caption="Broadcaster and Location" colCount={2} colSpan={2}>
                      <Item dataField="broadcasterId" />
                      <Item dataField="site" />
                      <Item dataField="stadium" />
                      <Item dataField="city" />
                      <Item dataField="state" />
                      <Item dataField="country" />
                    </Item>
                    <Item itemType="group" caption="Notes" colCount={2} colSpan={2}>
                      <Item dataField="note" editorType="dxTextArea" colSpan={2} />
                    </Item>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      <Item dataField="dateTBD" editorType="dxCheckBox" editorOptions={this.checkBoxOptionsDateTBD} />
                      <EmptyItem />
                    </Item>

                    <Item itemType="group" caption="Date" colCount={2} colSpan={2} visible={this.isDateVisible}>
                      <Item dataField="timeTBD" />
                      <EmptyItem />
                      <Item dataField="date" />
                    </Item>
                  </Form> */}
                    </Editing>
                    <Template name="deleteButton" render={this.deleteButtonRender} />
                  </DataGrid>
                )}
              </Col>
            </Row>
            <small>
              {tabs[this.state.selectedIndex].footer.map((item) => (
                <div key={item.key}>{item.value}</div>
              ))}
              <div>{!this.state.usePopupEditinMode && <div>In row editing mode, use the tab key or shift+tab key to navigate between cells in a row</div>}</div>
              <div style={{ color: "red" }}>
                <strong>Sorting by a single column:</strong> Click a column header to sort by this column and click it again to change the sort order (ascending or descending).
              </div>
              <div style={{ color: "red" }}>
                <strong>Sorting by multiple columns:</strong> Hold the Shift key and click column headers in a desired order. To cancel a column's sorting settings, hold the Ctrl
                key and click the column header.
              </div>
            </small>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default GameScheduleEntry;
