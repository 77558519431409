import http from "./httpService";
import { getTok } from "./../utils/common-funcs";

export function get_divisions() {
  return http.get(`/public/divisions/${getTok()}`);
}

export function get_conferences() {
  return http.get(`/public/conferences/${getTok()}`);
}

export function get_sports() {
  return http.get(`/public/sports/${getTok()}`);
}

export function get_sports_for_division_team(division_id,team_id) {
  ///sports/:tok/:division_id/:team_id
  return http.get(`/public/sports/${getTok()}/${division_id}/${team_id}`);
}

export function get_seasons() {
  return http.get(`/public/seasons/${getTok()}`);
}

export function get_teams() {
  return http.get(`/public/teams/${getTok()}`);
}

export function get_teams_for_division_sport_season(division_id, display_sport_id, season_id) {
  return http.get(`/public/teams/${getTok()}/${division_id}/${display_sport_id}/${season_id}/0`);
}

export function get_team_for_division_sport_season(division_id, display_sport_id, season_id, team_id) {
  return http.get(`/public/teams/${getTok()}/${division_id}/${display_sport_id}/${season_id}/${team_id}`);
}

export function get_teams_for_sport_season(display_sport_id, season_id) {
  return http.get(`/public/teams/${getTok()}/${display_sport_id}/${season_id}`);
}

export function get_broadcasters() {
  return http.get(`/public/broadcasters/${getTok()}`);
}

export function get_rounds(division_id, display_sport_id) {
  return http.get(`/public/rounds/${getTok()}/${division_id}/${display_sport_id}`);
}

export function get_tournaments(division_id, display_sport_id, season_id) {
  return http.get(`/public/tournaments/${getTok()}/${division_id}/${display_sport_id}/${season_id}`);
}

export function get_division_sport_season(division_id, display_sport_id, season_id, conference_id) {
  return http.get(`/public/keys/${getTok()}/${division_id}/${display_sport_id}/${season_id}/${conference_id}`);
}

export function get_sandbox_vtp_for_season_and_sport_public(token, season_id, display_sport_id, division_id, conference_id) {
  return http.get(`/sandboxResumes/open/vtp/${getTok()}/${season_id}/${display_sport_id}/${division_id}/${conference_id}`);
}

//resume of one team in division/sport/season
export function get_resume_for_division_sport_season_team(division_id, display_sport_id, season_id, team_id, token) {
  return http.get(`/public/resume/${getTok()}/${division_id}/${display_sport_id}/${season_id}/${team_id}`, { headers: { Authorization: `Bearer ${token}` } });
}

//resume of one team in division/sport/season without auth0 auth
export function get_resume_for_division_sport_season_team_no_auth(division_id, display_sport_id, season_id, team_id, token) {
  return http.get(`/public/resume/open/${getTok()}/${division_id}/${display_sport_id}/${season_id}/${team_id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

//resume of all teams in division/sport/season
export function get_resume_for_division_sport_season(division_id, display_sport_id, season_id, token) {
  return http.get(`/public/resume/${getTok()}/${division_id}/${display_sport_id}/${season_id}`, { headers: { Authorization: `Bearer ${token}` } });
}

export function get_seed_cutlines(token, division_id, display_sport_id, season_id) {
  return http.get(`/public/seedCutLines/${getTok()}/${division_id}/${display_sport_id}/${season_id}`, { headers: { Authorization: `Bearer ${token}` } });
}

export function get_db_last_update() {
  return http.get(`/public/dblastupdate/${getTok()}`);
}

export function get_db_last_update_by_display_sport_id_and_season_id(display_sport_id, season_id) {
  return http.get(`/public/dblastupdate/${getTok()}/${display_sport_id}/${season_id}`);
}

export function get_debug(token) {
  return http.get(`/user/id`, { headers: { Authorization: `Bearer ${token}` } });
}

export function make_session_key_with_ddst(division_id, display_sport_id, season_id, team_id) {
  return `kpi_1_${division_id}_${display_sport_id}_${season_id}_${team_id}`;
}

export function make_rakings_session_key(division_id, display_sport_id, season_id) {
  return `kpi_2_${division_id}_${display_sport_id}_${season_id}`;
}

export function make_all_game_values_session_key(division_id, display_sport_id, season_id) {
  return `kpi_3_${division_id}_${display_sport_id}_${season_id}`;
}
