import React, { Component, Fragment } from "react";
import { Row, Col, CardHeader, Card, CardBody, Badge } from "reactstrap";
import { get_resume_table_values } from "./home-resume-browser-utils";
import { infoRowStyleMeta, get_overtime_suffix, ToMap } from "./../utils/common-funcs";
import { get_game_count_limits_for_sport } from "../services/privateService";
import notify from "devextreme/ui/notify";
import ResumeTable from "./resume-table";
import ResumeTableHeader from "./resume-table-header";
import { display_sports_needing_regions } from "./../utils/enums";

const default_logo_height = 30;

class HomeResumeBrowser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keys: {},
      resume: [],
      logoHeight: 30,
      tableFontSize: 14,
      selectedTeam: {},
      overtime_suffix: "",
      team_id_to_team_meta: new Map(),
      team_id_to_conference_id_map: new Map(),
      game_counts_limits: {},
      showRegionsName: false,
    };
    this.onClickRefresh = this.onClickRefresh.bind(this);
  }

  async componentDidMount() {
    await this.handleTeamChangeDataLoad(true);
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.selectedDivision.id !== prevProps.selectedDivision.id ||
      this.props.selectedSport.id !== prevProps.selectedSport.id ||
      this.props.selectedSeason.id !== prevProps.selectedSeason.id ||
      this.props.selectedTeam.id !== prevProps.selectedTeam.id
    ) {
      await this.handleTeamChangeDataLoad(true);
    }
  }

  async onClickRefresh(e) {
    notify(`Refreshing table`);
    await this.handleTeamChangeDataLoad(true);
  }

  handleTeamChangeDataLoad = async (force) => {
    try {
      const overtime_suffix = get_overtime_suffix(this.props.selectedSport.id);

      const keys = {
        division_id: this.props.selectedDivision.id,
        display_sport_id: this.props.selectedSport.id,
        season_id: this.props.selectedSeason.id,
        team_id: this.props.selectedTeam.id,
      };

      const team_id_to_team_meta = ToMap(this.props.teams);

      if (keys.division_id > 0 && keys.display_sport_id > 0 && keys.season_id > 0 && keys.team_id > 0) {
        const resume = await get_resume_table_values(keys, force, this.props.auth0jwt);

        const new_team_meta = team_id_to_team_meta.get(this.props.selectedTeam.id);
        const team_conference_id = new_team_meta ? new_team_meta.conferenceId : 0;

        const { data: game_counts_limits } = await get_game_count_limits_for_sport(
          this.props.auth0jwt,
          this.props.selectedDivision.id,
          this.props.selectedSport.id,
          team_conference_id,
          this.props.selectedSeason.id
        );

        const showRegionsName = display_sports_needing_regions.has(this.props.selectedSport.id) && this.props.selectedDivision.id === 2;
   
        this.setState({
          keys: keys,
          resume: resume,
          selectedTeam: this.props.selectedTeam,
          overtime_suffix: overtime_suffix,
          team_id_to_team_meta: team_id_to_team_meta,
          game_counts_limits: game_counts_limits && game_counts_limits.length > 0 ? game_counts_limits[0] : {},
          showRegionsName: showRegionsName,
        });
      } else {
        this.setState({ selectedTeam: {}, resume: [], game_counts_limits: {} });
      }
    } catch (error) {
      console.log(error);
      this.setState({ selectedTeam: {}, resume: [], game_counts_limits: {} });
    }
  };

  render() {
    const info_row_style = this.props.selectedTeam.id ? infoRowStyleMeta(this.state.team_id_to_team_meta.get(this.props.selectedTeam.id)) : {};

    return (
      <Fragment>
        <Card className="main-card my-2 mx-2 border border-primary rounded">
          {!this.props.selectedTeam.id && (
            <CardHeader className="card-header-tab">
              <div className="card-header-title thick">Select a team using the above Team selector</div>
            </CardHeader>
          )}
          {this.props.selectedTeam.id && (
            <CardHeader className="card-header-tab" style={{ height: "110px" }}>
              <Row xs="12">
                <Col xs="12">
                  {/* <div style={{ float: "left" }}>
                    <img height={100} width={100} src={`../logos_svg/${this.state.keys.team_id}.svg`} alt={this.state.keys.team_name} />
                  </div>
                  <div style={{ float: "right" }}>
                    <Button icon="refresh" hint="Refesh Table" type="back" stylingMode="contained" onClick={this.onClickRefresh} />
                  </div> */}

                  <ResumeTableHeader
                    keys={this.state.keys}
                    resume={this.state.resume}
                    game_counts_limits={this.state.game_counts_limits}
                    onClickRefresh={this.onClickRefresh}
                    info_row_style={info_row_style}
                    auth0jwt={this.props.auth0jwt}
                  />
                </Col>
              </Row>
            </CardHeader>
          )}

          <div style={{ marginLeft: "auto", marginRight: "auto" }}>
            <Badge color="primary">{this.props.db_last_update}</Badge>
          </div>
          <CardBody className="pt-1">
            <Row className="mb-5">
              <Col xs="12">
                <ResumeTable
                  logoHeight={this.state.logoHeight}
                  tableFontSize={this.state.tableFontSize}
                  resume={this.state.resume}
                  addTeam1Columns={false}
                  id={"ResumeBrowserTable"}
                  metadata={{ overtime_suffix: this.state.overtime_suffix }}
                  dateDefaultSortIndex={0}
                  rankDefaultSortIndex={1}
                  showKpiRank={false}
                  showKpiPercentile={false}
                  db_last_update={this.props.db_last_update}
                  showBroadcaster={true}
                  selectedSport_id={this.props.selectedSport.id}
                  showRegionsName={this.state.showRegionsName}
                />
              </Col>
            </Row>
            <Row>
              <Col>{`*: ${Intl.DateTimeFormat().resolvedOptions().timeZone} time zone`}</Col>
            </Row>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default HomeResumeBrowser;
