import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const Footer = () => (
  <Fragment>
    <footer className="bg-light my-2 py-2 text-center border rounded">
      <div className="mx-2">Faktor © 2020</div>
      <Link className="mx-2" target="_blank" to={{ pathname: `/terms` }}>
        <small>Terms of Service</small>
      </Link>

      <Link className="mx-2" target="_blank" to={{ pathname: `/policy` }}>
        <small>Privacy Policy</small>
      </Link>
    </footer>
  </Fragment>
);

export default Footer;
