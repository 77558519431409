import React, { Component } from "react";
import DataGrid, { Paging, Pager, Column, HeaderFilter, Export, Lookup, ColumnChooser, ColumnFixing, FilterRow, FilterPanel } from "devextreme-react/data-grid";
import { renderTitleHeader, customNumberSortingMethod } from "./home-rankings-utils";
import { format, parse } from "fecha";
import { time_format, winLossTie, pager_lengths, isConference } from "./../utils/enums";
import { team1LinkCellRender, team2LinkCellRender } from "./home-rankings-utils";

const logo_height = 25;
function team1LogoCellRender(props) {
  return <img height={logo_height} width={logo_height} src={`logos_svg/${props.data.team1Id}.svg`} alt={props.data.team1Name} />;
}

function team2LogoCellRender(props) {
  return <img height={logo_height} width={logo_height} src={`logos_svg/${props.data.team2Id}.svg`} alt={props.data.team2Name} />;
}

class ResumeTable extends Component {
  constructor(props) {
    super(props);
  }

  calculateDisplayValueDate(e) {
    if (e.dateTBD) {
      return "<TBD>";
    } else {
      return format(parse(e.date, time_format.utc), "YYYY-MM-DD ddd");
    }
  }

  show_ot_column = () => {
    
    // do not show OT column for Men's and Women's VolleyBall
    if (!this.props.selectedSport_id) {
      return true;
    }

    return !(this.props.selectedSport_id === 65 || this.props.selectedSport_id === 66);
  };

  render() {
    return (
      <DataGrid
        dataSource={this.props.resume}
        showBorders={true}
        rowAlternationEnabled
        columnAutoWidth={true}
        allowColumnReordering={true}
        wordWrapEnabled={false}
        selection={{ mode: "multiple", showCheckBoxesMode: "none" }}
        keyExpr="gameKeyId"
      >
        <Export enabled={true} fileName={this.props.db_last_update ? this.props.db_last_update : "KPI-Resume"} />
        {/* <Scrolling columnRenderingMode="virtual" /> */}
        {/* <Scrolling mode="infinite" /> */}
        {/* <Scrolling mode="virtual" rowRenderingMode="virtual" /> */}
        <Paging defaultPageSize={100} />
        <Pager showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />
        <FilterRow visible={true} />
        <FilterPanel visible={true} />
        <HeaderFilter visible={true} />
        {/* <StateStoring enabled={true} type="localStorage" storageKey={this.props.id} /> */}
        <ColumnChooser enabled={true} />
        <ColumnFixing enabled={true} />
        <Column
          caption="Date"
          dataField="date"
          dataType="string"
          defaultSortOrder="asc"
          defaultSortIndex={this.props.dateDefaultSortIndex}
          minWidth={100}
          alignment="left"
          fixed={true}
          fixedPosition="left"
          calculateDisplayValue={this.calculateDisplayValueDate}
          headerCellRender={renderTitleHeader}
        />
        <Column
          caption="Logo"
          dataField="teamLogo"
          dataType="object"
          visible={this.props.addTeam1Columns}
          minWidth={60}
          cellRender={team1LogoCellRender}
          allowSorting={false}
          alignment="center"
          fixed={true}
          fixedPosition="left"
          headerCellRender={renderTitleHeader}
          allowHeaderFiltering={false}
          allowFiltering={false}
        />
        <Column
          caption={this.props.addTeam1Columns ? "Opp Logo" : "Logo"}
          dataField="team2Id"
          dataType="object"
          minWidth={60}
          cellRender={team2LogoCellRender}
          allowSorting={false}
          alignment="center"
          fixed={true}
          fixedPosition="left"
          headerCellRender={renderTitleHeader}
          allowHeaderFiltering={false}
        />
        <Column
          caption="Rank"
          dataField="team1KpiRank"
          dataType="number"
          visible={this.props.addTeam1Columns}
          minWidth={100}
          alignment="right"
          headerCellRender={renderTitleHeader}
        />
        <Column
          caption="Team"
          dataField="team1Name"
          dataType="string"
          visible={this.props.addTeam1Columns}
          minWidth={160}
          headerCellRender={renderTitleHeader}
          cellRender={team1LinkCellRender}
        />
        <Column
          caption={this.props.addTeam1Columns ? "Opp Rank" : "Rank"}
          dataField="team2KpiRank"
          dataType="number"
          minWidth={80}
          alignment="right"
          headerCellRender={renderTitleHeader}
        />
        <Column
          caption={this.props.addTeam1Columns ? "Opp Team" : "Opponent"}
          dataField="team2Name"
          dataType="string"
          minWidth={160}
          headerCellRender={renderTitleHeader}
          cellRender={team2LinkCellRender}
        />
        <Column caption="WL" dataField="WL" dataType="string" alignment="center" headerCellRender={renderTitleHeader}>
          <Lookup dataSource={winLossTie} displayExpr="name" valueExpr="id" />
        </Column>
        <Column
          caption="Score"
          dataField="score1"
          dataType="string"
          minWidth={80}
          alignment="center"
          calculateCellValue={(data) => `${data.score1 || 0}-${data.score2 || 0}`}
          headerCellRender={renderTitleHeader}
        />
        {this.show_ot_column() && (<Column dataField="overtime" caption={this.props.metadata.overtime_suffix} dataType="number" headerCellRender={renderTitleHeader}></Column>)}
        <Column
          caption="KPI"
          dataField="kpi"
          dataType="number"
          minWidth={60}
          alignment="center"
          defaultSortOrder="desc"
          defaultSortIndex={this.props.rankDefaultSortIndex}
          calculateCellValue={(data) => (data.kpi ? data.kpi.toFixed(3) : 0)}
          headerCellRender={renderTitleHeader}
          sortingMethod={customNumberSortingMethod}
        />
        {this.props.showKpiRank && <Column caption="KPI Rank" dataField="kpiRank" dataType="number" minWidth={60} alignment="center" headerCellRender={renderTitleHeader} />}
        {this.props.showKpiPercentile && (
          <Column
            caption="KPI %"
            dataField="kpiPercentile"
            dataType="number"
            minWidth={60}
            alignment="center"
            headerCellRender={renderTitleHeader}
            visible={this.props.showKpiPercentile}
          />
        )}
        <Column caption="Site" dataField="site" dataType="string" minWidth={80} alignment="center" headerCellRender={renderTitleHeader} />
        <Column caption="Location" dataField="location" dataType="string" minWidth={180} headerCellRender={renderTitleHeader} />
        <Column
          caption="Expected Score"
          dataField="expectedScore1"
          dataType="string"
          minWidth={80}
          alignment="center"
          calculateCellValue={(data) => `${data.expectedScore1 || 0}-${data.expectedScore2 || 0}`}
          headerCellRender={renderTitleHeader}
        />
        <Column caption="Game Type" dataField="isConference" minWidth={80} headerCellRender={renderTitleHeader}>
          <Lookup dataSource={isConference} displayExpr="name" valueExpr="id" />
        </Column>
        <Column
          caption="Conf KPI"
          dataField="confKPI"
          dataType="number"
          minWidth={60}
          alignment="center"
          defaultSortOrder="desc"
          defaultSortIndex={this.props.rankDefaultSortIndex}
          calculateCellValue={(data) => (data && data.confKPI ? data.confKPI.toFixed(3) : null)}
          headerCellRender={renderTitleHeader}
          sortingMethod={customNumberSortingMethod}
        />
        <Column
          caption="Conf KPI Rank"
          dataField="team1ConfKpiRank"
          dataType="number"
          visible={this.props.addTeam1Columns}
          minWidth={100}
          alignment="right"
          headerCellRender={renderTitleHeader}
        />
        <Column
          caption={this.props.addTeam1Columns ? "Opp Conf KPI Rank" : "Conf KPI Rank"}
          dataField="team2ConfKpiRank"
          dataType="number"
          minWidth={80}
          alignment="right"
          headerCellRender={renderTitleHeader}
        />
        {this.props.showRegionsName && <Column caption="Region" dataField="regionsName" dataType="string" minWidth={190} headerCellRender={renderTitleHeader} />}
        {this.props.showBroadcaster && <Column caption="Broadcaster" dataField="broadcaster" dataType="string" minWidth={80} headerCellRender={renderTitleHeader} />}
        <Column caption="Tournament" dataField="tournamentName" dataType="string" minWidth={80} headerCellRender={renderTitleHeader} />
        <Column caption="Round" dataField="roundName" dataType="string" minWidth={80} headerCellRender={renderTitleHeader} />
      </DataGrid>
    );
  }
}

export default ResumeTable;
