import React, { Component, Fragment } from "react";
import { Row, Col, CardHeader, Card, CardBody } from "reactstrap";
import notify from "devextreme/ui/notify";
import { isDoubleYearSportId, makeDoubleYearSport } from "./../utils/common-funcs";
import ResumeRankingTable from "./resume-rankings-table";
import { SelectBox, Button } from "devextreme-react";
import { display_sports_needing_regions } from "./../utils/enums";
import { Auth0Context } from "../react-auth0-spa";
import { get_division_sport_season, get_seasons, get_sports_for_division_team } from "../services/publicService";

const queryString = require("query-string");

class ConferenceKpiBrowser extends Component {
  static contextType = Auth0Context;
  constructor(props) {
    super(props);
    this.state = {
      keys: {},
      auth0jwt: null,
      homeKpiTableData: [],
      seasonsWithData: [],
      sportsWithData: [],
      selected_season_id: null,
      selected_display_sport_id: null,
    };

    // var i;
    // console.log("local storage");
    // for (i = 0; i < localStorage.length; i++) {
    //   console.log(localStorage.key(i) + "=[" + localStorage.getItem(localStorage.key(i)) + "]");
    // }

    this.onClickRefresh = this.onClickRefresh.bind(this);
  }

  async onClickRefresh(e) {
    await this.handleTeamChangeDataLoad();
  }

  async componentDidMount() {
    try {
      const { auth0jwt } = this.context;

      const parsed = queryString.parse(window.location.hash.replace("#/conferenceKPI/", ""));

      console.log(parsed);

      const selected_display_sport_id = this.state.selected_display_sport_id ? this.state.selected_display_sport_id : parseInt(parsed.v2);
      const selected_season_id = this.state.selected_season_id ? this.state.selected_season_id : parseInt(parsed.v3);

      const keys = {
        division_id: parseInt(parsed.v1),
        display_sport_id: selected_display_sport_id,
        season_id: selected_season_id,
        team_id: parseInt(parsed.v4),
        conference_id: parseInt(parsed.v5),
        conference_name: parsed.conferenceName,
      };

      const { data: sportsWithData } = await get_sports_for_division_team(keys.division_id, keys.team_id);
      const { data: allSeasons } = await get_seasons();
      const is_double_season_sport = isDoubleYearSportId(keys.display_sport_id);
      const seasonsWithData = allSeasons
        .filter((x) => x.hasData > 0)
        .map(function (z) {
          const new_name = is_double_season_sport ? makeDoubleYearSport(z.name) : z.name;
          return { id: z.id, name: new_name };
        });

      this.setState(
        {
          seasonsWithData: seasonsWithData,
          sportsWithData: sportsWithData,
          auth0jwt: auth0jwt,
          keys: keys,
          selected_season_id: selected_season_id,
          selected_display_sport_id: selected_display_sport_id,
          showRegionsName: false,
        },
        async () => {
          await this.handleTeamChangeDataLoad(false);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  handleTeamChangeDataLoad = async () => {
    notify(`Refreshing table`);
    try {
      if (
        this.state.keys.division_id > 0 &&
        this.state.keys.display_sport_id > 0 &&
        this.state.keys.season_id > 0 &&
        this.state.keys.team_id &&
        this.state.keys.conference_id
      ) {
        if (this.state.keys.conference_name) {
          document.title = this.state.keys.conference_name;
        }

        //create a new route where you pass in the conference to make this faster
        let { data: homeKpiTableData } = await get_division_sport_season(
          this.state.keys.division_id,
          this.state.keys.display_sport_id,
          this.state.keys.season_id,
          this.state.keys.conference_id
        );
        homeKpiTableData = homeKpiTableData.filter((x) => x.conferenceId === this.state.keys.conference_id);

        const showRegionsName = display_sports_needing_regions.has(this.state.keys.display_sport_id) && this.state.keys.division_id === 2;

        this.setState({
          homeKpiTableData: homeKpiTableData,
          showRegionsName : showRegionsName
        });
      } else {
        this.setState({
          homeKpiTableData: [],
          showRegionsName: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };


  handleSeasonSelectBoxBoxOnValueChanged = async (event) => {
    if (this.state.selected_season_id != event.value) {
      var updated_keys = { ...this.state.keys };
      updated_keys.season_id = event.value;

      this.setState({ selected_season_id: event.value, keys: updated_keys }, async () => {
        await this.handleTeamChangeDataLoad(false);
      });
    }
  };

  handleSportSelectBoxBoxOnValueChanged = async (event) => {
    if (this.state.selected_display_sport_id != event.value) {
      var updated_keys = { ...this.state.keys };
      updated_keys.display_sport_id = event.value;

      this.setState({ selected_display_sport_id: event.value, keys: updated_keys }, async () => {
        await this.handleTeamChangeDataLoad(false);
      });
    }
  };

  render() {
    return (
      <Fragment>
        <Card className="main-card my-2 mx-2 border border-primary rounded">
          <CardHeader className="card-header-tab" style={{ height: "110px" }}>
            <Row xs="12">
              <Col xs="12">
                <div style={{ display: "flex", alignItems: "center", verticalAlign: "middle", justifyContent: "space-between" }}>
                  <div style={{ height: "100px" }}>
                    <img height={100} width={100} src={`../conference_logos_svg/${this.state.keys.conference_id}.svg`} alt={this.state.keys.conference_name} />
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <Button icon="refresh" hint="Refesh Table" type="back" stylingMode="contained" onClick={this.onClickRefresh} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row xs="12">
              <Col xs={6}>{!this.state.auth0jwt && <div style={{ color: "red", fontWeight: "bold" }}>Displaying past games only. Please login to see all future games.</div>}</Col>
              <Col xs={3}>
                <SelectBox
                  width={"100%"}
                  placeholder={"Season"}
                  items={this.state.sportsWithData}
                  displayExpr="name"
                  valueExpr="id"
                  value={this.state.keys.display_sport_id}
                  onValueChanged={this.handleSportSelectBoxBoxOnValueChanged}
                />
              </Col>
              <Col xs={3}>
                <SelectBox
                  width={"100%"}
                  placeholder={"Season"}
                  items={this.state.seasonsWithData}
                  displayExpr="name"
                  valueExpr="id"
                  value={this.state.selected_season_id}
                  onValueChanged={this.handleSeasonSelectBoxBoxOnValueChanged}
                />
              </Col>
            </Row>
            <Row className="mb-5">
              <Col xs="12">
                <ResumeRankingTable
                  homeKpiTableData={this.state.homeKpiTableData}
                  db_last_update={this.props.db_last_update}
                  xls_export_file_name={"Conf-KPI-Rankings"}
                  id={"ConferenceKpiTable"}
                  page_type={1}
                  showRegionsName={this.state.showRegionsName}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default ConferenceKpiBrowser;
