import React, { Component, Fragment } from "react";
import { Row, Col, CardHeader, Card, CardBody } from "reactstrap";
class FaktorTermsOfService extends Component {
  state = {};
  render() {
    return (
      <Fragment>
        <Card className="main-card my-2 mx-2 border border-primary rounded">
          <CardHeader className="card-header-tab">
            <div className="card-header-title thick">Faktor Platform Terms of Service</div>
          </CardHeader>
          <CardBody>
            <Row className="mb-5">
              <Col xs="12"></Col>
            </Row>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default FaktorTermsOfService;
