import { format, parse } from "fecha";
import { time_format, isConferenceEnum, computeSandboxFrom } from "./../utils/enums";

//converts "1000-01-02T00:00:00Z" to "1000-01-02 00:00:00"
export function utc2db(utcDateString) {
  return utcDateString.replace("Z", "").replace("T", " ");
}

export function getTok() {
  return new Date().getTime();
}

export function makeOverallWinLossTieString(calendarData) {
  const obj = computeAggregateWinLoss(calendarData);
  if (obj.tie > 0) {
    return `${obj.win} - ${obj.loss} - ${obj.tie}`;
  } else {
    return `${obj.win} - ${obj.loss}`;
  }
}

export function get_overall_game_counts(calendarData) {
  if (!Array.isArray(calendarData)) {
    return { confCount: null, nonConfCount: null, mteCount: null };
  }

  let confCount = 0;
  let nonConfCount = 0;
  let mteCount = 0;
  //export const isConferenceDict = { 1: "Conf", 2: "Non-Conf", 4: "Conf Tournament", 8: "MTE", 16: "Exhibition", 32: "Post Season" };

  for (var j = 0; j < calendarData.length; j++) {
    let row = calendarData[j];

    if (row.isConference === isConferenceEnum.Conference || row.isConference === isConferenceEnum.CTRN) {
      confCount += 1;
    } else if (row.isConference === isConferenceEnum.NonConference) {
      nonConfCount += 1;
    } else if (row.isConference === isConferenceEnum.MTE) {
      mteCount += 1;
    }
  }

  return { confCount: confCount, nonConfCount: nonConfCount, mteCount: mteCount };
}

export function get_remaing_game_count_string(sportMax, count_meta) {
  if (sportMax && count_meta && count_meta.confCount >= 0) {
    return sportMax - count_meta.confCount - count_meta.nonConfCount - count_meta.mteCount;
  } else {
    return "";
  }
}

export function makeConferenceWinLossTieString(calendarData) {
  const obj = computeAggregateWinLoss(calendarData);
  if (obj.tieConf > 0) {
    return `${obj.winConf} - ${obj.lossConf} - ${obj.tieConf}`;
  } else {
    return `${obj.winConf} - ${obj.lossConf}`;
  }
}

export function computeAggregateWinLoss(calendarData) {
  if (!Array.isArray(calendarData)) {
    calendarData = [];
  }
  let win = 0;
  let loss = 0;
  let tie = 0;

  let winConf = 0;
  let lossConf = 0;
  let tieConf = 0;
  for (var j = 0; j < calendarData.length; j++) {
    let row = calendarData[j];

    if (row.WL === 1) {
      if (row.isConference !== isConferenceEnum.Exhibition) {
        win = win + 1;
      }
      if (row.isConference === 1) {
        winConf = winConf + 1;
      }
    } else if (row.WL === 2) {
      if (row.isConference !== isConferenceEnum.Exhibition) {
        loss = loss + 1;
      }
      if (row.isConference === 1) {
        lossConf = lossConf + 1;
      }
    } else if (row.WL === 3) {
      if (row.isConference !== isConferenceEnum.Exhibition) {
        tie = tie + 1;
      }
      if (row.isConference === 1) {
        tieConf = tieConf + 1;
      }
    }
  }

  return { win: win, loss: loss, tie: tie, winConf: winConf, lossConf: lossConf, tieConf: tieConf };
}

export function is_undefined(value) {
  return value === null || value === "" || value === undefined || value === "null";
}

export function infoRowStyle(color) {
  return {
    height: "50px",
    minWidth: "60px",
    backgroundColor: color,
    borderColor: "#333",
    borderRadius: "5px",
    color: "white",
    textAlign: "center",
    verticalAlign: "center",
    marginLeft: "4px",
    marginRight: "4px",
    paddingLeft: "6px",
    paddingRight: "6px",
  };
}

export function infoRowStyleMeta(meta) {
  const color = meta && meta.color ? meta.color : "#000000";
  return infoRowStyle(color);
}

export function get_overtime_suffix(display_sport_id) {
  switch (display_sport_id) {
    case 5:
    case 6:
    case 49:
    case 50:
      return "Inning";

    case 7:
    case 8:
    case 31:
    case 32:
      return "OT";

    default:
      return "OT";
  }
}

export function getUniqueCombinations(array) {
  try {
    if (!Array.isArray(array)) {
      return [];
    }
    const seenCombinations = new Set();

    return array.filter((item) => {
      // Normalize the team IDs by sorting and joining them

      const combinationKey = item.team1Id < item.team2Id ? `${item.team1Id}-${item.team2Id}` : `${item.team2Id}-${item.team1Id}`;

      // Check if the combination has already been seen
      if (seenCombinations.has(combinationKey)) {
        return false; // Duplicate combination, filter it out
      } else {
        seenCombinations.add(combinationKey); // Add new combination to the set
        return true; // Include this item in the filtered array
      }
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export function isString(value) {
  return typeof value === "string" || value instanceof String;
}

export function makeClinetFriendlySeasonName(sport_name, season_name) {
  if (sport_name && season_name) {
    return isDoubleYearSport(sport_name) ? makeDoubleYearSport(season_name) : season_name;
  } else {
    return "";
  }
}

export function makeClinetFriendlyComputedSandboxFrom(sport_name, season_name) {
  if (sport_name && season_name) {
    let computeSandboxDeepCopy = computeSandboxFrom.map(i => ({ ...i}))
    computeSandboxDeepCopy[0].name= `${computeSandboxDeepCopy[0].name} (${makeClinetFriendlySeasonName(sport_name, season_name)})` 
    return computeSandboxDeepCopy;
  } else {
    return "";
  }
}


export function isDoubleYearSport(sport_name) {
  return sport_name && (sport_name.includes("Ice Hockey") || sport_name.includes("Basketball"));
}

export function isDoubleYearSportId(sport_id) {
  //select * from displaySports where name like '%ice hockey' or name like '%basketball%';
  return sport_id === 7 || sport_id === 8 || sport_id === 31 || sport_id === 32;
}

export function makeDoubleYearSport(season_name) {
  return `${season_name}-${Number(season_name) + 1}`;
}

export function makeSportAndSeasonName(sport_name, season_name) {
  if (isDoubleYearSport(sport_name)) {
    return `${sport_name} / ${makeDoubleYearSport(season_name)}`;
  } else {
    return `${sport_name} / ${season_name}`;
  }
}

export function zero_pad(num, places) {
  return String(num).padStart(places, "0");
}

export function getMinMaxDateValues(sport_name, season_name) {
  if (!sport_name || !season_name) {
    return [undefined, undefined];
  }
  let minDateValue = undefined;
  let maxDateValue = undefined;
  if (isDoubleYearSport(sport_name)) {
    minDateValue = new Date(Number(season_name), 7, 1);
    maxDateValue = new Date(Number(season_name) + 1, 6, 31);
  } else {
    minDateValue = new Date(Number(season_name), 0, 1);
    maxDateValue = new Date(Number(season_name), 11, 31);
  }
  return [minDateValue, maxDateValue];
}

export function remove_illegal_characters(string) {
  if (string) {
    return string.replace(/[|&;$%@"<>()+,]/g, "");
  } else {
    return "";
  }
}

export function GetIdToNameMap(objectWithIdAndName) {
  let output = new Map();

  if (!Array.isArray(objectWithIdAndName)) {
    return output;
  }

  for (var j = 0; j < objectWithIdAndName.length; ++j) {
    const current = objectWithIdAndName[j];
    output.set(current.id, current.name);
  }
  return output;
}

export function ToMap(objectWithId) {
  let output = new Map();

  if (!Array.isArray(objectWithId)) {
    return output;
  }

  for (var j = 0; j < objectWithId.length; ++j) {
    const current = objectWithId[j];
    output.set(current.id, current);
  }
  return output;
}

export function GetNameToIdMap(objectWithIdAndName) {
  let output = new Map();

  if (!Array.isArray(objectWithIdAndName)) {
    return output;
  }

  for (var j = 0; j < objectWithIdAndName.length; ++j) {
    const current = objectWithIdAndName[j];
    output.set(current.name, current.id);
  }
  return output;
}

export function get_team_id_to_conference_id_map(allTeams) {
  let m = new Map();
  if (!Array.isArray(allTeams)) {
    return m;
  }
  for (var j = 0; j < allTeams.length; ++j) {
    const current = allTeams[j];
    m.set(current.id, current.conferenceId);
  }
  return m;
}

export function get_season_sport_conferences_async(all_teams) {
  return new Promise((resolve, reject) => {
    const conferences = [];
    if (!Array.isArray(all_teams)) {
      resolve(conferences);
      return;
    }

    let seenConferenceIds = new Set();
    for (let j = 0; j < all_teams.length; j++) {
      const t = all_teams[j];
      if(!seenConferenceIds.has(t.conferenceId))
      {
        seenConferenceIds.add(t.conferenceId)
        conferences.push({
          id:t.conferenceId,
          name: t.conferenceName
        })
      }
    }

    conferences.sort((a,b) => (a.name > b.name) ? 1 : -1)
    conferences.unshift({
      id:-1,
      name: "<None>"
    })

    resolve(conferences);
    return;
  });
}

export function to_map_async (array, key_lambda) {
  return new Promise((resolve, reject) => {
    const output_map = new Map();
    if (!Array.isArray(array)) {
      resolve(output_map);
      return;
    }

  for (var j = 0; j < array.length; ++j) {
    const current = array[j];
    output_map.set(key_lambda(current), current);
  }
  
    resolve(output_map);
    return;
  });
};

export function make_date_open_interval(start_date, end_date, input_format) {
  let leftBoundOpen = isString(start_date) ? parse(start_date, input_format) : new Date(start_date);
  let rightBoundOpen = isString(end_date) ? parse(end_date, input_format) : new Date(end_date);

  leftBoundOpen.setDate(leftBoundOpen.getDate() - 1);
  rightBoundOpen.setDate(rightBoundOpen.getDate() + 1);

  leftBoundOpen = format(leftBoundOpen, time_format.utc).split("T")[0];
  rightBoundOpen = format(rightBoundOpen, time_format.utc).split("T")[0];
  return [leftBoundOpen, rightBoundOpen];
}

export function getDateSequence(startDate, stopDate, input_format) {
  if (!startDate || !stopDate) {
    return [];
  }

  let dateArray = [];

  let startDate_m = isString(startDate) ? parse(startDate, input_format) : new Date(startDate);
  let stopDate_m = isString(stopDate) ? parse(stopDate, input_format) : new Date(stopDate);

  let yyyymmdd = format(startDate_m, time_format.utc).split("T")[0];
  const stop_yyyymmdd = format(stopDate_m, time_format.utc).split("T")[0];

  //console.log(`>>${startDate_m}`);
  //console.log(`>>${stopDate_m}`);
  while (yyyymmdd <= stop_yyyymmdd) {
    //console.log(`>>${yyyymmdd}`);
    dateArray.push(yyyymmdd);

    startDate_m.setDate(startDate_m.getDate() + 1); //dont compare on dates because hour,sec,ms become a problem
    yyyymmdd = format(startDate_m, time_format.utc).split("T")[0];
  }

  if (dateArray.length < 1) {
    const yyyymmdd = format(startDate_m, time_format.utc).split("T")[0];
    if (yyyymmdd) {
      dateArray.push(yyyymmdd);
    }
  }

  return dateArray;
}

export function getIntervalAroundDate(start_date, input_format) {
  try {
    if (!start_date || !input_format) {
      console.log("start_date and input_format should not be null");
      return [];
    }

    let dateArray = [];
    let startDate_m = isString(start_date) ? parse(start_date, input_format) : new Date(start_date);

    startDate_m.setDate(startDate_m.getDate() - 1);
    let yyyymmdd = format(startDate_m, time_format.utc).split("T")[0];
    dateArray.push(yyyymmdd);

    startDate_m.setDate(startDate_m.getDate() + 2);
    yyyymmdd = format(startDate_m, time_format.utc).split("T")[0];
    dateArray.push(yyyymmdd);

    return dateArray;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export function GetUserAccessKey(divisionId, displaySportId, teamId) {
  if (divisionId && displaySportId && teamId) {
    return `d${divisionId}_s${displaySportId}_t${teamId}`;
  } else {
    return null;
  }
}

export function GetUserAccessFromAuth0(user, teams, conferences, sports, divisions) {
  /*
https://faktor.ddns.net/access: Array(2)
0: {team: 1, sport: 7, division: 1}
1: {team: 2, sport: 7, division: 1}
  */
  //console.log(user);

  let user_access_array = user["https://faktorsports.com/access"] || [];
  return GetUserAccess(user_access_array, teams, conferences, sports, divisions);
}

export function GetUserAccess(user_access_array, teams, conferences, sports, divisions) {
  let accessMap = new Map();
  let allowedDivisionIds = new Set();
  let allowedDisplaySportIds = new Set();
  let allowedTeamIds = new Set();

  if (!user_access_array || !Array.isArray(user_access_array) || user_access_array.length < 1) {
    user_access_array = [];
  }

  const first_object = user_access_array.length > 0 ? user_access_array[0] : {};
  if (!first_object.team || !first_object.sport || !first_object.division) {
    user_access_array = [];
  }

  for (let j = 0; j < user_access_array.length; ++j) {
    let { team, sport, division } = user_access_array[j];

    let d_arr = [];
    if (division === -1) {
      d_arr = divisions.map((y) => y.id);
    } else {
      d_arr = [division];
    }

    let s_arr = [];
    if (sport === -1) {
      s_arr = sports.map((y) => y.id);
    } else {
      s_arr = [sport];
    }

    let t_arr = [];
    if (team === -1) {
      t_arr = teams.map((y) => y.id);
    } else {
      t_arr = [team];
    }

    for (let d = 0; d < d_arr.length; d++) {
      allowedDivisionIds.add(d_arr[d]);
      for (let s = 0; s < s_arr.length; s++) {
        for (let t = 0; t < t_arr.length; t++) {
          let key = GetUserAccessKey(d_arr[d], s_arr[s], t_arr[t]);
          accessMap.set(key, { divisionId: d_arr[d], displaySportId: s_arr[s], teamId: t_arr[t] });
        }
      }
    }

    for (let s = 0; s < s_arr.length; s++) {
      allowedDisplaySportIds.add(s_arr[s]);
    }
    for (let t = 0; t < t_arr.length; t++) {
      allowedTeamIds.add(t_arr[t]);
    }
  }

  return [accessMap, allowedDivisionIds, allowedDisplaySportIds, allowedTeamIds];
}
