import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFootballBall, faHome, faUser, faPowerOff } from "@fortawesome/free-solid-svg-icons";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useAuth0 } from "../react-auth0-spa";
import config from "../auth_config.json";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, isAuthenticated, loginWithRedirect, logout, userPermissions } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  return (
    <Navbar color="light" light expand="md" className="border rounded mx-n3">
      <NavbarToggler onClick={toggle} className="mx-2" />
      <NavbarBrand className="logo" />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="d-none d-md-block mx-2" navbar>
          {!isAuthenticated && (
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret id="profileDropDown">
                KPI
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem tag={Button} id="qsLoginBtn" color="light" className="btn btn-primary" onClick={() => loginWithRedirect({})}>
                  <FontAwesomeIcon icon={faUser} className="mr-3" />
                  Login / Register
                </DropdownItem>
                <DropdownItem tag={RouterNavLink} to="/home" className="dropdown-home">
                  <FontAwesomeIcon icon={faHome} className="mr-3" />
                  Home
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
          {isAuthenticated && (
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret id="profileDropDown">
                <img src={user.picture} alt="Profile" className="nav-user-profile rounded-circle" width="50" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>{user.name}</DropdownItem>
                <DropdownItem tag={RouterNavLink} to="/home" className="dropdown-home">
                  <FontAwesomeIcon icon={faHome} className="mr-3" />
                  Home
                </DropdownItem>
                <DropdownItem tag={Button} id="qsLoginBtn" color="light" className="btn btn-primary" onClick={() => loginWithRedirect({})}>
                  <FontAwesomeIcon icon={faUser} className="mr-3" />
                  Refresh Login
                </DropdownItem>
                {userPermissions.includes(config.viewAnalyticsPermission) && (
                  <DropdownItem tag={RouterNavLink} to="/analytics" className="dropdown-analytics">
                    <FontAwesomeIcon icon={faFootballBall} className="mr-3" />
                    Analytics
                  </DropdownItem>
                )}
                <DropdownItem id="qsLogoutBtn" onClick={() => logoutWithRedirect()}>
                  <FontAwesomeIcon icon={faPowerOff} className="mr-3" /> Log out
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </Nav>
        {!isAuthenticated && (
          <Nav className="d-md-none d-block mx-2" navbar>
            <NavItem className="my-3">
              <Button id="qsLoginBtn" color="primary" block onClick={() => loginWithRedirect({})}>
                Login / Register
              </Button>
            </NavItem>
            <NavItem className="my-3">
              <FontAwesomeIcon icon={faHome} className="mr-3" />
              <RouterNavLink to="/home" activeclassname="router-link-exact-active">
                Home
              </RouterNavLink>
            </NavItem>
          </Nav>
        )}
        {isAuthenticated && (
          <Nav className="d-md-none d-block mx-2" navbar>
            <NavItem className="my-3">
              <FontAwesomeIcon icon={faHome} className="mr-3" />
              <RouterNavLink to="/home" activeclassname="router-link-exact-active">
                Home
              </RouterNavLink>
            </NavItem>
            <NavItem className="my-3">
              <FontAwesomeIcon icon={faPowerOff} className="mr-3" />
              <RouterNavLink to="#" id="qsLogoutBtn" onClick={() => logoutWithRedirect()}>
                Log out
              </RouterNavLink>
            </NavItem>
          </Nav>
        )}
      </Collapse>
    </Navbar>
  );
};

export default NavBar;
