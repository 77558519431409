import React, { Component, Fragment } from "react";
import { Row, Col, CardHeader, Card, CardBody } from "reactstrap";
import DataGrid, { Column, Paging, Pager, FilterRow, FilterPanel, Export } from "devextreme-react/data-grid";
import { NumberBox } from "devextreme-react/number-box";
import { renderTitleHeader } from "./../utils/common-rendering-funcs";
import { makeClinetFriendlySeasonName } from "./../utils/common-funcs";
import auth_config from "../auth_config.json";
import { get_new_game_schedule_entry, get_new_blocked_date_entries } from "../services/privateService";
import { SelectBox } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { Button } from "devextreme-react";
import { pager_lengths } from "./../utils/enums";
export const viewChoices = [
  { id: 1, name: "Game Schedule Entry" },
  { id: 2, name: "Date Blocker" },
];

class NewGameScheduleEntries extends Component {
  constructor(props) {
    super(props);

    this.state = { gamesTable: [], blockedDatesTable: [], intervalDays: 4, selectedViewId: viewChoices[0].id };

    this.onClickRefresh = this.onClickRefresh.bind(this);
  }

  componentDidMount = async () => {
    await this.refresh_data_sources();
  };

  refresh_data_sources = async () => {
    if (this.props.userPermissions && this.props.userPermissions.includes(auth_config.writeDbPermission)) {
      try {
        const { data: gamesTable } = await get_new_game_schedule_entry(this.props.auth0jwt, 1, this.state.intervalDays);
        const { data: blockedDatesTable } = await get_new_blocked_date_entries(this.props.auth0jwt, 1, this.state.intervalDays);

        this.setState({ gamesTable: gamesTable, blockedDatesTable: blockedDatesTable });
      } catch (error) {
        console.log(error);
      }
    }
  };

  async onClickRefresh(e) {
    notify(`Refreshing table`);
    await this.refresh_data_sources();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {}

  onValueChangedInterval = async (e) => {
    if (e.value && this.props.isAuthenticated && this.props.userPermissions.includes(auth_config.writeDbPermission)) {
      try {
        const { data: gamesTable } = await get_new_game_schedule_entry(this.props.auth0jwt, 1, e.value);
        const { data: blockedDatesTable } = await get_new_blocked_date_entries(this.props.auth0jwt, e.value);

        this.setState({ gamesTable: gamesTable, blockedDatesTable: blockedDatesTable });
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleViewSelectBoxOnValueChanged = async (event) => {
    this.setState({ selectedViewId: event.value });
  };

  calculateCellValueSeason(data) {
    return makeClinetFriendlySeasonName(data.sport, data.season);
  }

  render() {
    return (
      <Fragment>
        <Card className="main-card my-2 mx-2 border border-primary rounded font-weight-bold">
          <CardHeader className="card-header-tab">
            <div style={{ float: "left" }}>
              <div className="card-header-title thick">New Entries</div>
              <div>
                <small>
                  All times are represented in <u>{Intl.DateTimeFormat().resolvedOptions().timeZone}</u> time-zone
                </small>
              </div>
            </div>
            <div style={{ float: "right" }}>
              <Button icon="refresh" hint="Refesh Table" type="back" stylingMode="contained" onClick={this.onClickRefresh} />
            </div>
          </CardHeader>

          <CardBody className="pt-1">
            <Row className="px-2 py-3">
              <Col xs={6}>
                <div className="dx-field">
                  <div className="dx-field-label">View new entries in</div>
                  <div className="dx-field-value">
                    <SelectBox
                      placeholder={"View new entries in ..."}
                      items={viewChoices}
                      displayExpr="name"
                      valueExpr="id"
                      value={this.state.selectedViewId}
                      onValueChanged={this.handleViewSelectBoxOnValueChanged}
                    />
                  </div>
                </div>
              </Col>

              <Col xs={6}>
                <div className="dx-field">
                  <div className="dx-field-label">New enteries within last (days)</div>
                  <div className="dx-field-value">
                    <NumberBox
                      defaultValue={this.state.intervalDays}
                      min={0}
                      max={365}
                      step={7}
                      showSpinButtons={true}
                      onValueChanged={this.onValueChangedInterval}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            {this.props.isAuthenticated && this.props.loginApproved && this.state.selectedViewId === 1 && (
              <Row className="px-2 py-1">
                <Col xs={12}>
                  <DataGrid
                    width={"100%"}
                    columnAutoWidth={true}
                    dataSource={this.state.gamesTable}
                    allowColumnReordering={true}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    selection={{ mode: "single" }}
                    wordWrapEnabled={false}
                    allowColumnResizing={false}
                    columnResizingMode={"widget"}
                  >
                    <Export enabled={true} fileName={"KPI-New-Games"} />
                    <Paging defaultPageSize={100} />
                    <Pager showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />
                    <FilterRow visible={true} />
                    <FilterPanel visible={true} />

                    {/* <Editing mode="row" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true} /> */}
                    <Column dataField="division" caption="Division" headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="sport" caption="Sport" headerCellRender={renderTitleHeader}></Column>
                    <Column caption="Season" calculateCellValue={this.calculateCellValueSeason} headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="lastUpdate" caption="Last Update" dataType="datetime" sortOrder="desc" headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="userName" caption="Updated by" headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="team1" caption="Team 1" headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="team2" caption="Team 2" headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="date" caption="Date" dataType="datetime" headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="dateTBD" caption="Date TBD?" dataType="boolean" headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="timeTBD" caption="Time TBD?" dataType="boolean" headerCellRender={renderTitleHeader} allowEditing={false}></Column>
                    <Column dataField="tournamentName" caption="Tournament" headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="roundName" caption="Round" headerCellRender={renderTitleHeader}></Column>
                  </DataGrid>
                </Col>
              </Row>
            )}
            {this.props.isAuthenticated && this.props.loginApproved && this.state.selectedViewId === 2 && (
              <Row className="px-2 py-1">
                <Col xs={12}>
                  <DataGrid
                    
                    width={"100%"}
                    columnAutoWidth={true}
                    dataSource={this.state.blockedDatesTable}
                    allowColumnReordering={true}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    selection={{ mode: "single" }}
                    wordWrapEnabled={false}
                    allowColumnResizing={false}
                    columnResizingMode={"widget"}
                  >
                    <Export enabled={true} fileName={"KPI-New-Blocked-Dates"} />
                    <Paging defaultPageSize={100} />
                    <Pager showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />
                    <FilterRow visible={true} />
                    <FilterPanel visible={true} />

                    {/* <Editing mode="row" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true} /> */}
                    {/* <Column dataField="division" caption="Division" headerCellRender={renderTitleHeader}></Column> */}
                    <Column dataField="sport" caption="Sport" headerCellRender={renderTitleHeader}></Column>
                    <Column caption="Season" calculateCellValue={this.calculateCellValueSeason} headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="lastUpdate" caption="Last Update" dataType="datetime" sortOrder="desc" headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="userName" caption="Updated by" headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="team" caption="Team " headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="startDate" caption="Start Date" dataType="datetime" headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="endDate" caption="End Date" dataType="datetime" headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="blockedFor" caption="Blocked for" headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="note" caption="Note" headerCellRender={renderTitleHeader} allowEditing={false}></Column>
                    <Column dataField="isPrivate" caption="Private?" dataType="boolean" headerCellRender={renderTitleHeader}></Column>
                  </DataGrid>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default NewGameScheduleEntries;
